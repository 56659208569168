import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { AxiosResponse } from "axios";
import { useId } from "react";
import { useMutation } from "react-query";

const useAddUpdateGeneralized = <T>(
  addToRepo: (payload: T) => Promise<AxiosResponse>,
  updateToRepo: (payload: T) => Promise<AxiosResponse>,
  id?: string
) => {
  const mutationKey = useId();
  const { mutateAsync, isLoading } = useMutation(
    id ? updateToRepo : addToRepo,
    {
      onSuccess: () => {
        successToast(id ? "Updated Successfully" : "Added successfully");
      },
      onError: () => {
        errorToast("Something went wrong");
      },
      mutationKey: `${mutationKey}-${id}`,
    }
  );

  return {
    addUpdate: mutateAsync,
    mutationLoading: isLoading,
  };
};

export default useAddUpdateGeneralized;
