export interface ReviewList {
  _id: string;
  userId: string;
  comment: string;
  images: string[];
  rating: number;
  createdDate: Date;
  updatedDate: Date;
  firstName: string;
  lastName: string;
  profilePicture: string;
  status: string;
  reviewNumber?: number;
}

export interface Data {
  list: ReviewList[];
  total: number;
}

export interface RootObject {
  data: Data;
  msg: string;
  statusCode: number;
}

export type ReviewBody = {
  activityEventId: string;
  rating: number;
  comment: string;
  images: string[];
  isAgree: boolean;
  isUpdate: boolean;
};

// List

export interface ReviewsRoot {
  data: Reviews;
  msg: string;
  statusCode: number;
}

export interface Reviews {
  list: Review[];
  total: number;
}

export interface Review {
  _id: string;
  activityEventId: string;
  userId: string;
  comment: string;
  images: string[];
  rating: number;
  createdDate: string;
  updatedDate: string;
  isDeleted: boolean;
  status: string;
  firstName: string;
  lastName: string;
  profilePicture?: string;
  reviewNumber?: number;
}

export enum STATUS {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
}
