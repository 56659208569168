import GoogleMaps from "views/components/googleMaps";
import { LocationDetail } from "core/repository/event/types";
type EventLocationProps = {
  locationDetail: LocationDetail;
};
const EventLocation = ({ locationDetail }: EventLocationProps) => (
  <>
    <h2 className="fs-32 font-medium">Location</h2>
    <p>{locationDetail?.locationDescription}</p>
    <div className="border br-16 h-[538px]">
      <GoogleMaps
        locationDetail={{
          lng: locationDetail?.location.coordinates[0],
          lat: locationDetail?.location.coordinates[1],
        }}
      />
    </div>
  </>
);

export default EventLocation;
