import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useCallback, useEffect, useState } from "react";

import Loader from "../loader";
import { MarkerProps } from "views/layouts/listWithMaps/types";
import { mapStyles } from "./mapStyles";

export { DistanceMatrixService } from "@react-google-maps/api";

type GoogleMapProps = {
  locationDetail?: google.maps.LatLngLiteral;
  origin?: {
    start: [number, number];
    end: [number, number];
  };
  markers?: MarkerProps[];
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: true,
  styles: mapStyles,
};
const GoogleMaps = ({ locationDetail, origin, markers }: GoogleMapProps) => {
  const [directionResponse, setDirectionResponse] = useState<
    google.maps.DirectionsResult | undefined
  >(undefined);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });

  const calculateRoute = useCallback(async () => {
    if (origin) {
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      const result = await directionsService.route({
        origin: new window.google.maps.LatLng(
          origin.start[0],
          origin?.start[1]
        ),
        destination: new window.google.maps.LatLng(
          origin?.end[0],
          origin?.end[1]
        ),
        travelMode: google.maps.TravelMode.DRIVING,
      });
      setDirectionResponse(result);
      directionsRenderer.setDirections(result);
    }
  }, [origin]);

  useEffect(() => {
    if (origin) calculateRoute();
  }, [calculateRoute, isLoaded, origin]);

  if (!isLoaded) return <Loader />;
  return (
    <GoogleMap
      mapContainerStyle={{
        height: "100%",
        width: "100%",
        borderRadius: "8px",
      }}
      center={
        locationDetail && {
          lat: locationDetail.lat,
          lng: locationDetail.lng,
        }
      }
      zoom={15}
      options={options}
    >
      {directionResponse && (
        <DirectionsRenderer directions={directionResponse} />
      )}
      {markers &&
        locationDetail &&
        markers.map((marker, index) => (
          <Marker
            key={index}
            position={{
              lat: marker.position[0],
              lng: marker.position[1],
            }}
            label={marker.label}
          />
        ))}
    </GoogleMap>
  );
};

export default GoogleMaps;
