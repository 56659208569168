import { Link, NavLink } from "react-router-dom";

import { HeadstarttBreadcrumb } from "views/components/headstarttBreadcrumbs";
import { ExploreHeading } from "views/components/typography/popularHeading";
import { EXPLORE_NAV } from "./constants";

type ExploreCoverProps = {
  firstString: string;
  remainingString: string;
};
const ExploreCover = ({ firstString, remainingString }: ExploreCoverProps) => {
  return (
    <div>
      <HeadstarttBreadcrumb separator={">"}>
        <HeadstarttBreadcrumb.Item>
          <Link to="/">Home</Link>
        </HeadstarttBreadcrumb.Item>
        <HeadstarttBreadcrumb.Item>
          <Link to="/explore/all">Search</Link>
        </HeadstarttBreadcrumb.Item>
      </HeadstarttBreadcrumb>
      <ExploreHeading
        headingPartOne={firstString}
        headingPartTwo={remainingString}
      />

      <div className="flex flex-wrap items-center justify-center divide-x text-center border rounded-full w-fit mx-auto">
        {EXPLORE_NAV.map(({ title, path }) => (
          <NavLink
            to={`${path}`}
            key={path}
            className={({ isActive }) =>
              `${
                isActive ? "bg-primary text-white" : "bg-white text-gray-800"
              } fs-20 p-3 px-8 first:rounded-l-full last:rounded-r-full`
            }
          >
            <span className="text-center">{title}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default ExploreCover;
