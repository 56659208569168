import { useCallback, useState } from "react";

import { UploadFilesResponseRoot } from "core/repository/uploadFiles/types";
import { postFilesToRepo } from "core/repository/uploadFiles/postFilesToRepo";

const useUploadFile = (
  onSuccess: (response: UploadFilesResponseRoot["data"]) => void
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const postData = useCallback(
    async (payload: FormData) => {
      setLoading(true);
      setError(false);
      try {
        const { data } = await postFilesToRepo(payload);
        onSuccess(data.data);
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    },
    [onSuccess]
  );

  return { postData, loading, error };
};

export default useUploadFile;
