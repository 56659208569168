import { Input, InputProps } from "antd";

import { Search } from "../icons";

type HeadstarttSearchProps = {
  handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  redirectUserToSearch?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  placeholder?: string;
  value?: string;
} & InputProps;
const HeadstarttSearch = ({
  style = {
    borderRadius: "9999px",
    fontSize: "20px",
    padding: "8px 24px",
    border: "1px solid #006653",
    background: "rgba(72, 185, 178, 0.1)",
  },
  placeholder = "What are you looking for?",
  value,
  handleSearchChange,
  redirectUserToSearch,
  ...props
}: HeadstarttSearchProps) => {
  return (
    <Input
      onKeyUp={redirectUserToSearch}
      onChange={handleSearchChange}
      style={style}
      defaultValue={value}
      className="headstartt-input"
      placeholder={placeholder}
      allowClear
      prefix={<Search height={24} />}
      {...props}
    />
  );
};

export default HeadstarttSearch;
