import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
} from "core/clients/firebase";
import { LoginType, Scopes } from "./../types";
import { useCallback, useState } from "react";

import { SignUpPayload } from "core/repository/user/types";
import { errorToast } from "views/components/headstarttNotifications";

const useLoginWithGoogle = (
  onSuccess: (payload: SignUpPayload) => void,
  onError?: () => void
) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const loginWithGoogle = useCallback(async () => {
    setLoader(true);
    const provider = new GoogleAuthProvider();
    provider.addScope(Scopes.READ_ONLY);
    const auth = getAuth();
    auth.languageCode = "it";
    provider.setCustomParameters({
      login_hint: "user@example.com",
    });
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        result.user.getIdToken().then((idToken) => {
          const user = result.user;
          const payloadForSignUp: SignUpPayload = {
            firstName: user.displayName?.split(" ")[0] || "",
            lastName: user.displayName?.split(" ")[1] || "",
            socialId: user.uid,
            loginType: LoginType.GOOGLE,
            deviceToken: "",
            accessToken: "",
          };
          if (idToken) {
            payloadForSignUp.accessToken = idToken;
            onSuccess(payloadForSignUp);
          }
        });
        // The signed-in user info.
      })
      .catch((err) => {
        setError(err.message);
        errorToast(err.message);
        onError?.();
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      })
      .finally(() => {
        setLoader(false);
      });
  }, [onSuccess, onError]);
  return {
    loginWithGoogle,
    loader,
    error,
  };
};

export default useLoginWithGoogle;
