import { HeadstarttSmallButton } from "../headstarttButton";
type AddButtonProps = {
  onClick: () => void;
  buttonText: string;
};
const AddButton = ({ onClick, buttonText = "Add" }: AddButtonProps) => {
  return (
    <HeadstarttSmallButton
      newstyle={{
        backgroundColor: "#006653",
        padding: "0.5rem 1rem",
        color: "#fff",
      }}
      onClick={onClick}
    >
      {buttonText}
    </HeadstarttSmallButton>
  );
};

export default AddButton;
