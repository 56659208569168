import { useLocation, useNavigate } from "react-router-dom";

import AdminCard from "admin/components/adminCard";
import { DESTINATION_HEADER } from "admin/adminConstants";
import DestinationTableRow from "./destinationTableRow";
import ErrorCard from "views/components/errorCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttPagination from "views/components/headstarttPagination";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PageHeader from "admin/components/pageHeader";
import SecondaryHeader from "views/components/secondaryHeader";
import TableHeader from "admin/components/table/tableHeader";
import { deleteDestinationFromRepo } from "core/repository/destination";
import { useCallback } from "react";
import useDeleteGeneralised from "core/hooks/useDeleteGeneralised";
import useGetDestination from "core/services/destination/useGetDestination";

const AdminDestinationList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleChangePage = useCallback(
    (page: number) => {
      navigate(`${location.pathname}?page=${page}`);
    },
    [location.pathname, navigate]
  );

  const { data, isLoading, isError, page, refetch } = useGetDestination();

  const onSuccess = useCallback(() => {
    refetch();
  }, [refetch]);

  const { deleteFromRepo } = useDeleteGeneralised(
    deleteDestinationFromRepo,
    onSuccess
  );

  const onDelete = useCallback(
    (id: string) => {
      deleteFromRepo(id);
    },
    [deleteFromRepo]
  );

  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  if (!data?.data.total) return <>Data Not Found</>;
  return (
    <div>
      <PageHeader heading="Destination List" />
      <HeadstarttContainer>
        <SecondaryHeader
          addButtonProps={{
            onClick: () => navigate("/admin/destination/add-destination"),
            buttonText: "Add Destination",
          }}
          paginationProps={{
            total: data?.data.total,
            current: page,
            limit: data.data.list.length,
          }}
        />
        <div className="py-4">
          <AdminCard>
            <TableHeader span={2} header={DESTINATION_HEADER} />
            <DestinationTableRow onDelete={onDelete} list={data?.data.list} />
          </AdminCard>
          <div className="flex justify-center my-4">
            <HeadstarttPagination
              total={data?.data.total}
              current={page}
              onChange={handleChangePage}
            />
          </div>
        </div>
      </HeadstarttContainer>
    </div>
  );
};

export default AdminDestinationList;
