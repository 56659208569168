import CoverTextSection from "./coverTextSection";
import HeadstarttContainer from "views/components/headstarttContainer";
import LandingCarousel from "./landingCarousel";
import { PopularActivitiesList } from "core/repository/home/types";
import PopularHeadingGroup from "views/components/typography/popularHeading";
import SearchInputWithModal from "views/components/headstarttSearch/searchInputWithModal";
import styles from "./landingCover.module.scss";
const LandingCover = ({
  popularActivities,
}: {
  popularActivities: PopularActivitiesList["list"];
}) => {
  return (
    <div className="py-24 relative">
      <div
        className={`${styles.cover} absolute top-0 bottom-0 left-0 right-0 -z-10 vh-100`}
      />
      <HeadstarttContainer>
        <CoverTextSection />
        <div className="grid md:grid-cols-12 mb-20">
          <div className="md:col-span-6 md:col-start-4">
            <SearchInputWithModal />
          </div>
        </div>
        <PopularHeadingGroup
          text="Not sure where to go?"
          headingPartTwo="Popular Activity"
          className="text-white"
        />
        <LandingCarousel popularActivities={popularActivities} />
      </HeadstarttContainer>
    </div>
  );
};

export default LandingCover;
