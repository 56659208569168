import { ActivityBookmarksList } from "core/repository/user/types";
import { Document } from "views/components/icons";
import HorizontalActivityCard from "views/components/headstarttCard/activityHorizontalCard";
import SecondaryHeading from "views/components/typography/secondaryHeading";

const SavedActivities = ({
  activities,
}: {
  activities: ActivityBookmarksList["list"];
}) => {
  return (
    <div className="grid grid-cols-12 gap-12">
      <div className="col-span-12 w-full">
        <div className="p-8 bg-white br-16">
          <div className="flex gap-2 items-center mb-8">
            <div className="bg-gray-100 rounded-full h-12 w-12 flex items-center justify-center">
              <Document height={24} />
            </div>
            <SecondaryHeading className="mb-0" heading={"Saved Activities"} />
          </div>
          <div className="space-y-8">
            {activities.map((activity) => (
              <HorizontalActivityCard key={activity._id} activity={activity} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedActivities;
