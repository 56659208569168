import { HeadstarttButton } from "views/components/headstarttButton";
import useLogout from "core/services/authService/hooks/useLogout";
const MyProfile = () => {
  const { logout } = useLogout();
  return (
    <div className="py-24">
      My Profile
      <HeadstarttButton onClick={logout}>Logout</HeadstarttButton>
    </div>
  );
};

export default MyProfile;
