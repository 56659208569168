import { useLayoutEffect, useState } from "react";

import HeadstarttTabs from ".";

type HeadStarttTabsGroupProps = {
  tabs: string[];
  handleTabClick: (tab: string[]) => void;
  multiple?: boolean;
  activeTabs?: string[];
};
const HeadStarttTabsGroup = ({
  tabs,
  handleTabClick,
  multiple = false,
  activeTabs = [],
}: HeadStarttTabsGroupProps) => {
  const [activeTab, setActiveTab] = useState<string[]>(activeTabs);
  const handleClick = (tab: string) => {
    let tempTab: string[] = [];
    if (multiple) {
      if (activeTab.includes(tab)) {
        tempTab = activeTab.filter((t) => t !== tab);
      } else {
        tempTab = [...activeTab, tab];
      }
    } else {
      tempTab = [tab];
    }
    setActiveTab(tempTab);
    handleTabClick?.(tempTab);
  };

  useLayoutEffect(() => {
    if (activeTabs.length > 0) {
      setActiveTab(activeTabs);
    }
  }, [activeTabs]);

  return (
    <div className="flex flex-wrap gap-2">
      {tabs.map((tab) => (
        <HeadstarttTabs
          onHandleClick={handleClick}
          active={activeTab.includes(tab)}
          key={tab}
          tab={tab}
        />
      ))}
    </div>
  );
};

export default HeadStarttTabsGroup;
