import { ActivityPayload } from "core/repository/activity/types";
import FormLabelWithDescription from "views/components/headstarttForm/formLabelWithDescription";
import LocationPickerFromMap from "views/components/locationPickerFromMap";

const ActivityLocationPicker = ({
  handleSelectedLocation,
  locationDetail,
}: {
  handleSelectedLocation: (location: ActivityPayload["locationDetail"]) => void;
  locationDetail: ActivityPayload["locationDetail"];
}) => {
  return (
    <div className="grid grid-cols-12 pb-16">
      <div className="col-span-4">
        <FormLabelWithDescription
          label="Location"
          description="Enter activity location"
        />
      </div>
      <div className="col-span-6">
        <LocationPickerFromMap
          locationDetail={locationDetail}
          handleSelectedLocation={handleSelectedLocation}
        />
      </div>
    </div>
  );
};

export default ActivityLocationPicker;
