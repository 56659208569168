import { DestinationsRoot } from "core/repository/destination/types";
import { SearchParams } from "views/layouts/explore/types";
import { getDestinationFromRepo } from "core/repository/destination";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";

const LIMIT = 12;

const useGetDestination = () => {
  const { page = "1", search = "" } = parse(
    useLocation().search
  ) as SearchParams;

  const { data, isLoading, isError, refetch } = useQuery<
    DestinationsRoot,
    Error
  >(["destinations", page, search], async () => {
    const pagination = {
      limit: LIMIT,
      offset: parseInt(page) - 1,
    };
    const { data: responseData } = await getDestinationFromRepo(pagination);
    return responseData;
  });

  return {
    data,
    isLoading,
    isError,
    page: parseInt(page),
    search,
    refetch,
  };
};

export default useGetDestination;
