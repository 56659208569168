import { useLocation, useNavigate } from "react-router-dom";

import AdminCard from "admin/components/adminCard";
import ErrorCard from "views/components/errorCard";
import { HOST_HEADER } from "admin/adminConstants";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttPagination from "views/components/headstarttPagination";
import HostTableRow from "./hostTableRow";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PageHeader from "admin/components/pageHeader";
import SecondaryHeader from "views/components/secondaryHeader";
import TableHeader from "admin/components/table/tableHeader";
import { deleteHostFromRepo } from "core/repository/host/getHostFromRepo";
import { useCallback } from "react";
import useDeleteGeneralised from "core/hooks/useDeleteGeneralised";
import useGetHostList from "core/services/host/useGetHostList";

const AdminHostList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleChangePage = useCallback(
    (page: number) => {
      navigate(`${location.pathname}?page=${page}`);
    },
    [location.pathname, navigate]
  );
  const { data, isLoading, isError, offset, refetch } = useGetHostList();
  const onSuccess = useCallback(() => {
    refetch();
  }, [refetch]);

  const { deleteFromRepo } = useDeleteGeneralised(
    deleteHostFromRepo,
    onSuccess
  );

  const onDelete = useCallback(
    (id: string) => {
      deleteFromRepo(id);
    },
    [deleteFromRepo]
  );
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  if (!data?.data.total) return <>Data Not Found</>;

  return (
    <div>
      <PageHeader heading="Activity List" />
      <HeadstarttContainer>
        <SecondaryHeader
          addButtonProps={{
            onClick: () => navigate("/admin/host/add-host"),
            buttonText: "Add Host",
          }}
          paginationProps={{
            total: data?.data.total,
            current: offset,
            limit: data.data.list.length,
          }}
        />
        <div className="py-4">
          <AdminCard>
            <TableHeader header={HOST_HEADER} />
            <HostTableRow onDelete={onDelete} list={data?.data.list} />
          </AdminCard>
          <div className="flex justify-center my-4">
            <HeadstarttPagination
              total={data?.data.total}
              current={offset}
              onChange={handleChangePage}
            />
          </div>
        </div>
      </HeadstarttContainer>
    </div>
  );
};

export default AdminHostList;
