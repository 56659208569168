import { Link, useNavigate } from "react-router-dom";

import useGetEventList from "core/services/event/useGetEventList";
import { useCallback } from "react";
import CoverSection from "views/components/coverSection";
import ErrorCard from "views/components/errorCard";
import { HeadstarttButton } from "views/components/headstarttButton";
import EventCard from "views/components/headstarttCard/eventCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PopularHeadingGroup from "views/components/typography/popularHeading";

const EventsList = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetEventList();
  const redirectToShowMore = useCallback(() => {
    navigate("/explore/events?page=1");
  }, [navigate]);

  if (isLoading) {
    return <LoaderFullScreen />;
  }
  if (isError) {
    return <ErrorCard />;
  }
  const events = data?.data.list;
  const count = data?.data.total;
  if (!events?.length || !count) return <>No Events found</>;
  return (
    <>
      <CoverSection
        sectionBackground="bg-midnight-blue"
        btnBackground="#00365f"
        bannerDetails={data.data.bannerDetails}
      />
      <div className="my-24">
        <HeadstarttContainer>
          <PopularHeadingGroup headingPartTwo="Events" />
          <div className="grid md:grid-cols-12 grid-cols-1 md:gap-8 gap-2 my-4">
            {events.map((event) => (
              <div key={event._id} className="col-span-4">
                <Link to={`/discover-events/${event._id}`}>
                  <EventCard height="large" event={event} />
                </Link>
              </div>
            ))}
          </div>
        </HeadstarttContainer>
        <div className="flex justify-center py-2">
          <HeadstarttButton onClick={redirectToShowMore} type="ghost">
            Show More Events
          </HeadstarttButton>
        </div>
      </div>
    </>
  );
};

export default EventsList;
