import { ActivityData, RootObject } from "core/repository/activity/types";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { EventRoot } from "core/repository/event/types";
import { errorToast } from "views/components/headstarttNotifications";
import { getActivityDetailFromRepo } from "core/repository/activity/get/getActivityDetailsFromRepo";
import { getEventByIdFromRepo } from "core/repository/event";
import querystring from "query-string";

const useHandleSettingOfEventOrActivityData = () => {
  const [loading, setLoading] = useState(true);
  const [eventOrActivityData, setEventOrActivityData] = useState<
    EventRoot | RootObject<ActivityData> | undefined
  >(undefined);
  const location = useLocation();
  const queryParams = querystring.parse(location.search);
  const params = useParams();
  const id = params.id;
  const type = queryParams.type as string;

  const isEvent = type === "event";
  const isActivity = type === "activity";

  const getDataBasedOnType = useCallback(async () => {
    setLoading(true);
    try {
      if (isEvent && id) {
        const { data } = await getEventByIdFromRepo(id);
        setEventOrActivityData(data);
      }
      if (isActivity && id) {
        const { data } = await getActivityDetailFromRepo(id);
        setEventOrActivityData(data);
      }
    } catch (err) {
      errorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [isEvent, isActivity, id]);

  useEffect(() => {
    getDataBasedOnType();
  }, [getDataBasedOnType]);

  return {
    loading,
    eventOrActivityData,
  };
};

export default useHandleSettingOfEventOrActivityData;
