import { ReactNode } from "react";
import styles from "./headstarttSearch.module.scss";
type ListSearchItemProps = {
  text: ReactNode;
  icon?: ReactNode;
  subText?: string;
};
const ListSearchItem = ({ icon, text, subText }: ListSearchItemProps) => {
  return (
    <div
      className={`${styles["list-search-item"]} flex gap-4 items-center br-8 my-2 px-4 py-2 cursor-pointer`}
    >
      <div className="bg-gray-100 p-4 rounded-full ">{icon}</div>
      <div>
        <div className="fs-20 text-gray-800 mb-0">{text}</div>
        <span className="fs-14 text-gray-500">{subText}</span>
      </div>
    </div>
  );
};

export default ListSearchItem;
