import { apiRequest } from "core/services/apiServices";
import { SearchParams } from "../search/types";
import { checkIfObjectHasValidValue } from "../utils/checkIfObjectHasValidValue";
import { ACTIVITY_ENDPOINTS } from "./endpoints";
import { ActivityRootObject } from "./types";

export const getActivitiesFromRepo = (params: SearchParams) =>
  apiRequest.getByQueryParam<ActivityRootObject>(
    ACTIVITY_ENDPOINTS.getList,
    checkIfObjectHasValidValue(params)
  );
