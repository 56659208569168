import { getActivitiesFromRepo } from "core/repository/activity/getActivitiesFromRepo";
import { ActivityRootObject } from "core/repository/activity/types";
import { parse } from "query-string";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { SearchParams } from "views/layouts/explore/types";

const LIMIT = 12;
const useGetActivityList = () => {
  const locationParams = parse(useLocation().search) as unknown as SearchParams;

  const { data, isLoading, isError, refetch } = useQuery<
    ActivityRootObject,
    Error
  >(["activities", locationParams], async () => {
    const { data: responseData } = await getActivitiesFromRepo({
      limit: LIMIT,
      offset: locationParams.page ? parseInt(locationParams.page) : 0,
      ...locationParams,
    });
    return responseData;
  });

  return {
    data,
    isLoading,
    isError,
    offset: parseInt(locationParams.page || "0"),
    refetch,
  };
};

export default useGetActivityList;
