import {
  RecentlyViewedData,
  SuggestionType,
} from "core/repository/search/types";

import ListSearchItem from "views/components/headstarttSearch/listSearchItem";
import { Location } from "views/components/icons";

const RecentlyViewed = ({
  recentlyViewedList,
  handleClick,
  clearRecentlyViewed,
  removeLoader,
  recentlyViewLoader,
}: {
  recentlyViewedList: RecentlyViewedData["list"];
  handleClick: (type: SuggestionType, id: string) => void;
  clearRecentlyViewed: () => void;
  removeLoader: boolean;
  recentlyViewLoader: boolean;
}) => {
  return (
    <div>
      <div className="flex items-center justify-between my-8">
        <p className="fs-14 mb-0">Recently Viewed</p>
        <p
          className={`fs-14 mb-0 text-primary ${
            removeLoader ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={clearRecentlyViewed}
        >
          Clear all history
        </p>
      </div>
      {/* Recently Viewed List */}
      {recentlyViewLoader
        ? "Loading..."
        : recentlyViewedList.map((item) => (
            <div
              key={item._id}
              onClick={() =>
                handleClick(item.entityType as SuggestionType, item.entityId)
              }
            >
              <ListSearchItem
                key={item._id}
                icon={<Location height={16} />}
                text={item.title}
              />
            </div>
          ))}
    </div>
  );
};

export default RecentlyViewed;
