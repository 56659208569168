import { parse, stringify } from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

import { useSearchContext } from "context/searchContext";
import { FilterTypes } from "core/repository/search/types";
import useGetActivityList from "core/services/activity/useGetActivityList";
import { useEffect } from "react";
import ErrorCard from "views/components/errorCard";
import HorizontalActivityCard from "views/components/headstarttCard/activityHorizontalCard";
import HeadstarttPagination from "views/components/headstarttPagination";
import { Document } from "views/components/icons";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import SecondaryHeading from "views/components/typography/secondaryHeading";
import { MarkerProps } from "../listWithMaps/types";
import ExploreSidebar from "./exploreSidebar";

const ExploreActivities = () => {
  const { setType } = useSearchContext();

  useEffect(() => {
    setType(FilterTypes.ACTIVITY);
  }, [setType]);

  const navigate = useNavigate();
  const searchParams = parse(useLocation().search);
  const { data, isLoading, isError, offset } = useGetActivityList();
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  const markers: MarkerProps[] =
    data?.data.list.map((item) => ({
      position: [item.locationDetail.latitude, item.locationDetail.longitude],
      label: item.title,
    })) || [];
  return (
    <div className="grid grid-cols-12 gap-12">
      <div className="col-span-3 w-72 fixed z-20 overflow-auto h-full">
        <div className="px-4 pb-24">
          <ExploreSidebar markers={markers} />
        </div>
      </div>
      <div className="col-span-9 w-full ml-72">
        <div className="p-8 bg-white br-16">
          <div className="flex gap-2 items-center mb-8">
            <div className="bg-gray-100 rounded-full h-12 w-12 flex items-center justify-center">
              <Document height={24} />
            </div>
            <SecondaryHeading className="mb-0" heading={"Activities"} />
          </div>
          <div className="space-y-8">
            {data?.data.list.map((activity) => (
              <HorizontalActivityCard key={activity._id} activity={activity} />
            ))}
          </div>
        </div>
        <div className="flex justify-center bg-white my-4 p-3 br-16">
          <HeadstarttPagination
            total={data?.data.total}
            current={offset}
            onChange={(p) => {
              navigate(
                {
                  pathname: "/explore/activities",
                  search: stringify({ ...searchParams, page: p }),
                },
                {
                  relative: "route",
                }
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExploreActivities;
