import { HomeDataRoot } from "core/repository/home/types";
import { getHomeData } from "core/repository/home";
import { useQuery } from "react-query";

const useGetHomeData = () => {
  const { data, isLoading, isError } = useQuery<HomeDataRoot, Error>(
    ["homePageData"],
    async () => {
      const response = await getHomeData({
        limit: 12,
        offset: 0,
      });
      return response.data;
    }
  );
  return {
    homeData: data,
    isLoading,
    isError,
  };
};

export default useGetHomeData;
