import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Divider } from "antd";
import { debounce } from "lodash";
import { HeadstarttInput } from "../headstarttInput";
import FilterHeader from "./filterHeader";

const DurationFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const minDuration = searchParams.get("minDuration");
  const maxDuration = searchParams.get("maxDuration");
  const [hour, setHour] = useState({
    minDuration: minDuration || "",
    maxDuration: maxDuration || "",
  });
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setHour({
        ...hour,
        [e.target.name]: e.target.value,
      });
    },
    [hour]
  );

  useEffect(() => {
    if (hour.minDuration) {
      searchParams.set("minDuration", hour.minDuration);
    }
    if (hour.maxDuration) {
      searchParams.set("maxDuration", hour.maxDuration);
    }
    if (!hour.minDuration) {
      searchParams.delete("minDuration");
    }
    if (!hour.maxDuration) {
      searchParams.delete("maxDuration");
    }

    debounce(() => {
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }, 1000)();
  }, [hour, location.pathname, navigate, location.search, searchParams]);

  return (
    <div>
      <FilterHeader
        heading={"Duration"}
        handleClear={() => {
          setHour({
            minDuration: "",
            maxDuration: "",
          });
        }}
      />
      <div className="grid grid-cols-12 gap-1 items-center justify-center mb-4">
        <div className="col-span-5">
          <HeadstarttInput
            size="middle"
            placeholder="Min. hour"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              borderRadius: "4px",
              border: "none",
            }}
            name="minDuration"
            type="number"
            onChange={onChange}
            value={hour.minDuration}
          />
        </div>
        <div className="col-span-2 text-center">-</div>
        <div className="col-span-5">
          <HeadstarttInput
            size="middle"
            name="maxDuration"
            onChange={onChange}
            type="number"
            placeholder="Max. hour"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              borderRadius: "4px",
              border: "none",
            }}
            value={hour.maxDuration}
          />
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default DurationFilter;
