import { ActivityData } from "core/repository/activity/types";

const KnowBeforeYouGo = ({
  instructions,
}: {
  instructions: ActivityData["instructions"];
}) => {
  return <div>{instructions}</div>;
};

export default KnowBeforeYouGo;
