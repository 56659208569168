import { Link, useNavigate } from "react-router-dom";

import CardSlider from "views/components/cardSlider";
import EventCard from "views/components/headstarttCard/eventCard";
import { EventData } from "core/repository/event/types";
import { HeadstarttButton } from "views/components/headstarttButton";

const EventSlider = ({ items }: { items: EventData[] }) => {
  const navigate = useNavigate();

  return (
    <CardSlider>
      {items.map((item, i) => {
        return i === items.length - 1 ? (
          <div className="br-16 relative w-52 h-64 mx-2 ">
            <div className="flex flex-col items-center h-full justify-center">
              <HeadstarttButton
                type="ghost"
                onClick={() => navigate("/explore/events/?page=1")}
              >
                Show more
              </HeadstarttButton>
            </div>
          </div>
        ) : (
          <Link key={item._id} to={`/discover-events/${item._id}`}>
            <EventCard className="br-16 relative w-52 h-64 mx-2" event={item} />
          </Link>
        );
      })}
    </CardSlider>
  );
};

export default EventSlider;
