import ParkOperationDate, {
  OperationDatesType,
} from "./components/parkOperationDate";
import { useCallback, useState } from "react";

import { ADMIN_ACTIVITY_TABS } from "admin/adminConstants";
import ActivityLocationPicker from "./components/activityLocationPicker";
import { ActivityPayload } from "core/repository/activity/types";
import { ActivityTabView } from "../types";
import FormFooter from "views/components/headstarttForm/formFooter";
import FormGroup from "views/components/headstarttForm/formGroup";
import { HeadstarttForm } from "views/components/headstarttForm";
import { dayjsClient } from "core/clients/dayjs";
import { useNavigate } from "react-router-dom";

const DurationAndLocationForm = ({ payload, setPayload }: ActivityTabView) => {
  const navigate = useNavigate();
  const [form] = HeadstarttForm.useForm();
  const [availableDates, setAvailableDates] = useState<string[]>(
    payload.operationDate?.availableDates || []
  );

  const [selectedLocations, setSelectedLocations] = useState<
    ActivityPayload["locationDetail"]
  >({
    latitude: 0,
    longitude: 0,
    locationDescription: "",
  });
  const handleDates = useCallback(
    (dates: OperationDatesType) => {
      const newDates: string[] = [...availableDates];
      dates.map((date) => {
        const { start, end } = date;
        if (start && end) {
          const startDate = dayjsClient(start);
          const endDate = dayjsClient(end);
          const diff = endDate.diff(startDate, "day");
          for (let i = 0; i <= diff; i++) {
            const newDate = startDate.add(i, "day").format("YYYY-MM-DD");
            if (!newDates.includes(newDate)) {
              newDates.push(newDate);
            }
          }
        }
        setAvailableDates(newDates);
      });
    },
    [availableDates]
  );
  const handleSubmit = (values: Record<string, string>) => {
    const newPayload: ActivityPayload = {
      ...payload,
      operationDate: {
        availableDates,
        operationDateDescription: values.operationDateDescription,
        openMonths: [],
        campgroundOpenMonths: [],
      },
      instructions: values.instructions,
      locationDetail: {
        ...selectedLocations,
        locationDescription: values.locationDescription,
      },
    };
    setPayload(newPayload);
    navigate({
      pathname: location.pathname,
      search: `?tab=${encodeURIComponent(ADMIN_ACTIVITY_TABS[2])}`,
    });
  };

  return (
    <HeadstarttForm
      form={form}
      onFinish={handleSubmit}
      className="w-full space-y-8"
      initialValues={{
        operationDateDescription:
          payload.operationDate?.operationDateDescription,
        instructions: payload.instructions,
        locationDescription: payload.locationDetail?.locationDescription,
      }}
    >
      <FormFooter
        showCancelButton={false}
        showSaveButton={false}
        onBackButtonClick={() => {
          navigate({
            pathname: location.pathname,
            search: `?tab=${encodeURIComponent(ADMIN_ACTIVITY_TABS[0])}`,
          });
        }}
      />
      <ParkOperationDate
        availableDates={payload.operationDate?.availableDates || []}
        handleOperationDatesChange={handleDates}
      />
      <FormGroup
        label={"Park Operation Description"}
        name={"operationDateDescription"}
        description="Enter a description of the park operation"
        inputType="textarea"
      />
      <FormGroup
        label={"Notes for Tourist"}
        name={"instructions"}
        description="Enter notes for tourist"
        inputType="textarea"
      />
      <FormGroup
        label={"Location Description"}
        name={"locationDescription"}
        description="Enter location description"
        inputType="textarea"
      />
      <ActivityLocationPicker
        locationDetail={payload.locationDetail}
        handleSelectedLocation={setSelectedLocations}
      />
    </HeadstarttForm>
  );
};

export default DurationAndLocationForm;
