import { ReviewsRoot, STATUS } from "./types";

import { PaginationParams } from "../types";
import { apiRequest } from "core/services/apiServices";

export const getReviewsFromRepo = (param: PaginationParams) =>
  apiRequest.getByQueryParam<ReviewsRoot>(
    "admin/activity/v1/listReviews",
    param
  );

export const patchReviewToRepo = (reviewId: string, status: STATUS) =>
  apiRequest.patch("admin/activity/v1/review", {
    reviewId,
    status,
  });

export const deleteReviewFromRepo = (reviewId: string) =>
  apiRequest.deleteWithoutId("admin/activity/v1/review", {
    reviewId,
  });
