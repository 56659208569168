import { BannerDetails } from "core/repository/activity/types";
import CardImage from "../headstarttCard/cardImage";
import CoverSectionButtonGroup from "./coverSectionButtonGroup";
import CoverSectionHeading from "./coverSectionHeading";
import { useLocation } from "react-router-dom";

type CoverSectionCardProps = {
  bannerDetails: BannerDetails;
  btnBackground?: string;
};
const CoverSectionCard = ({
  btnBackground,
  bannerDetails,
}: CoverSectionCardProps) => {
  const location = useLocation();
  const isActivityList = location.pathname.includes("activities");
  return (
    <div className="relative">
      <div
        className={`absolute top-0 bottom-0 left-0 right-0 z-10 bg-black opacity-50 br-16`}
      />
      <CardImage
        url={bannerDetails?.image}
        alt={"Activity Cover Page"}
        className="h-96 w-full object-cover"
      />

      <div className="z-10 absolute bottom-24 left-0 right-0 mx-auto flex flex-col justify-start">
        <CoverSectionHeading
          title={bannerDetails?.title}
          headline={bannerDetails?.headline}
        />
        <CoverSectionButtonGroup
          isActivityList={isActivityList}
          btnBackground={btnBackground}
        />
      </div>
    </div>
  );
};

export default CoverSectionCard;
