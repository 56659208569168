import {
  HeadstarttInput,
  HeadstarttInputPassword,
} from "views/components/headstarttInput";
import { LockClosed, Mail, User } from "views/components/icons";

import FormItem from "antd/lib/form/FormItem";
import { HeadstarttButton } from "views/components/headstarttButton";
import { HeadstarttForm } from "views/components/headstarttForm";
import InputLabel from "views/components/typography/inputLabel";
import TermsAndConditionCheckbox from "./termsAndConditionCheckbox";
import useRegisterWithEmailAndPassword from "core/services/authService/hooks/useRegisterWithEmailAndPassword";
import useSignInUser from "core/services/user/useSignInUser";
import useSignUpUser from "core/services/user/useSignUpUser";
import { useState } from "react";
import useUpdateCurrentUser from "core/services/authService/hooks/useUpdateCurrentUser";

type SignUpProps = {
  onCancel?: () => void;
};

type SignUpForm = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const SignUpView = ({ onCancel }: SignUpProps) => {
  const [form] = HeadstarttForm.useForm();
  const [checkboxState, setCheckboxState] = useState(false);
  // sign in user
  const { signInUser, loader: signInLoader } = useSignInUser(() => {
    onCancel?.();
    form.resetFields();
    setCheckboxState(false);
  }, onCancel);

  // sign up user
  const { signUpUser, loader } = useSignUpUser((payload) =>
    signInUser(payload)
  );

  // Update on successful registration
  const { updateCurrentUser, loader: updateLoader } = useUpdateCurrentUser(
    (payload) => {
      signUpUser(payload);
    }
  );

  // Register on Firebase
  const { registerUserOnFirebase, loading } = useRegisterWithEmailAndPassword(
    (firstName, lastName) => updateCurrentUser(`${firstName} ${lastName}`)
  );

  const onSubmit = (values: SignUpForm) => {
    registerUserOnFirebase(
      values.email,
      values.password,
      values.firstName,
      values.lastName
    );
  };

  return (
    <HeadstarttForm onFinish={onSubmit} form={form} className="space-y-2">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <InputLabel>Name</InputLabel>
        </div>
        <div className="col-span-6">
          <FormItem
            name={"firstName"}
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <HeadstarttInput
              placeholder="John"
              prefix={<User color="#696969" height={24} />}
              type={"text"}
            />
          </FormItem>
        </div>
        <div className="col-span-6">
          <FormItem
            name={"lastName"}
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
          >
            <HeadstarttInput
              placeholder="Doe"
              prefix={<User color="#696969" height={24} />}
              type={"text"}
            />
          </FormItem>
        </div>
      </div>
      <InputLabel>Email</InputLabel>
      <div className="col-span-12">
        <FormItem
          name={"email"}
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <HeadstarttInput
            placeholder="user@email.com"
            prefix={<Mail color="#696969" height={24} />}
            type={"email"}
          />
        </FormItem>
      </div>
      <div className="col-span-12">
        <InputLabel>Password</InputLabel>
        <FormItem
          name={"password"}
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <HeadstarttInputPassword
            prefix={<LockClosed color="#696969" height={24} />}
            type={"password"}
          />
        </FormItem>
        <InputLabel>Confirm Password</InputLabel>

        <FormItem
          name={"confirm"}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <HeadstarttInputPassword
            prefix={<LockClosed color="#696969" height={24} />}
            type={"password"}
          />
        </FormItem>
      </div>
      <div className="col-span-12">
        <TermsAndConditionCheckbox
          value={checkboxState}
          onHandleCheckbox={(checked) => setCheckboxState(checked)}
        />
      </div>
      <FormItem>
        <HeadstarttButton
          loading={loading || loader || loading || signInLoader || updateLoader}
          disabled={
            !checkboxState || loading || loader || signInLoader || updateLoader
          }
          htmlType="submit"
          type="ghost"
          className="w-full hover: bg-green-800"
        >
          Sign up
        </HeadstarttButton>
      </FormItem>
    </HeadstarttForm>
  );
};

export default SignUpView;
