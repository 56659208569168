import { useLocation, useNavigate } from "react-router-dom";

import { ACTIVITY_HEADER } from "admin/adminConstants";
import ActivityTableRow from "./activityTableRow";
import AdminCard from "admin/components/adminCard";
import ErrorCard from "views/components/errorCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttPagination from "views/components/headstarttPagination";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import MetaDetailsForm from "views/components/metaDetailsForm";
import PageHeader from "admin/components/pageHeader";
import SecondaryHeader from "views/components/secondaryHeader";
import TableHeader from "../components/table/tableHeader";
import { deleteActivityFromRepo } from "core/repository/activity";
import { successToast } from "views/components/headstarttNotifications";
import { useCallback } from "react";
import useDeleteGeneralised from "core/hooks/useDeleteGeneralised";
import useGetActivityList from "core/services/activity/useGetActivityList";
import { useUpdateActivity } from "core/services/activity";

const AdminActivityList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleChangePage = useCallback(
    (page: number) => {
      navigate(`${location.pathname}?page=${page}`);
    },
    [location.pathname, navigate]
  );

  const { data, isLoading, isError, offset, refetch } = useGetActivityList();
  const { mutateAsync, isLoading: isBookmarkLoading } =
    useUpdateActivity(refetch);
  const onSuccess = useCallback(() => {
    successToast("Activity deleted successfully");
    refetch();
  }, [refetch]);
  const { deleteFromRepo } = useDeleteGeneralised(
    deleteActivityFromRepo,
    onSuccess
  );

  const handleBookmark = useCallback(
    async (activityId: string, isPopular: boolean) => {
      await mutateAsync({ activityId, isPopular });
      successToast("Activity bookmarked successfully");
    },
    [mutateAsync]
  );
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  if (!data?.data.total) return <>Data Not Found</>;

  return (
    <div>
      <PageHeader heading="Activity List" />
      <HeadstarttContainer>
        <SecondaryHeader
          addButtonProps={{
            onClick: () => navigate("/admin/activity/add-activity"),
            buttonText: "Add Activity",
          }}
          paginationProps={{
            total: data?.data.total,
            current: offset,
            limit: data.data.list.length,
          }}
        />
        <div className="py-4">
          <AdminCard>
            <TableHeader header={ACTIVITY_HEADER} />
            <ActivityTableRow
              list={data?.data.list}
              handleBookmark={handleBookmark}
              isBookmarkLoading={isBookmarkLoading}
              handleDelete={deleteFromRepo}
            />
          </AdminCard>
          <div className="flex justify-center my-4">
            <HeadstarttPagination
              total={data?.data.total}
              current={offset}
              onChange={handleChangePage}
            />
          </div>
        </div>
        <MetaDetailsForm
          type={"activity"}
          payload={{
            title: data?.data?.bannerDetails?.title,
            headline: data?.data?.bannerDetails?.headline,
            image: data?.data?.bannerDetails?.image,
          }}
        />
      </HeadstarttContainer>
    </div>
  );
};

export default AdminActivityList;
