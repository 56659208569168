type SecondaryHeadingProps = {
  heading: string;
  className?: string;
};
const SecondaryHeading = ({
  heading,
  className = "",
}: SecondaryHeadingProps) => (
  <h2 className={`${className} fs-32 font-medium lh-38`}>{heading}</h2>
);

export default SecondaryHeading;
