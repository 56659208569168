import { useCallback, useState } from "react";

import { Camera } from "views/components/icons";
import { HeadstarttOutlineButton } from "views/components/headstarttButton";
import { UploadFilesResponseRoot } from "core/repository/uploadFiles/types";
import UploadPhotosModal from "./uploadPhotosModal";

const UploadReviewPhotos = ({
  onSuccess,
}: {
  onSuccess: (res: UploadFilesResponseRoot["data"]) => void;
}) => {
  const [visible, setVisible] = useState(false);
  const onCancel = useCallback(() => setVisible(false), []);
  const onOpen = () => setVisible(true);
  return (
    <div>
      <UploadPhotosModal
        onSuccess={onSuccess}
        visible={visible}
        onCancel={onCancel}
      />
      <HeadstarttOutlineButton onClick={onOpen}>
        <div className="flex gap-2 items-center justify-center p-1">
          <Camera />
          <span className="text-primary">Upload</span>
        </div>
      </HeadstarttOutlineButton>
    </div>
  );
};

export default UploadReviewPhotos;
