import { refreshEventsFromRepo } from "core/repository/event";
import { useMutation } from "react-query";

export const useRefreshEvents = (
  onSuccess?: () => void,
  onError?: () => void
) =>
  useMutation(refreshEventsFromRepo, {
    onSuccess,
    onError,
  });
