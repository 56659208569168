import { USER } from "./endpoint";
import { apiRequest } from "core/services/apiServices";

export const subscribeNotificationsFromRepo = (id: string) =>
  apiRequest.post(USER.subscribeNotification, {
    activityEventId: id,
  });

export const unsubscribeNotificationFromRepo = (id: string) =>
  apiRequest.deleteWithoutId(USER.unsubscribeNotification, {
    activityEventId: id,
  });
