import { Divider } from "antd";
import { ProjectsList } from "core/repository/search/types";
import QuickActionHolder from "admin/components/quickActionHolder";
import { dayjsClient } from "core/clients/dayjs";
import { useNavigate } from "react-router-dom";

const ProjectTableRow = ({
  list,
  onDelete,
}: {
  list: ProjectsList["list"];
  onDelete: (id: string) => void;
}) => {
  const navigate = useNavigate();

  return (
    <>
      {list.map((project) => (
        <div key={project._id}>
          <div className="grid grid-cols-12 py-1">
            <div className="col-span-3">
              <span className="fs-16 font-medium">{project.projectName}</span>
            </div>
            <div className="col-span-3">
              <span className="fs-16 font-medium">
                {project.projectDescription}
              </span>
            </div>
            <div className="col-span-3">
              <span className="fs-16 font-medium">
                {dayjsClient(project.createdDate).format("MM-DD-YYYY")}
              </span>
            </div>
            <div className="col-span-3">
              <QuickActionHolder
                showEdit
                onEdit={() => navigate(`/admin/project/${project._id}`)}
                showBookmark={false}
                onDelete={() => onDelete(project._id)}
              />
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </>
  );
};

export default ProjectTableRow;
