import { useCallback, useState } from "react";

import HeadstarttAppNavbar from "views/components/headstarttAppNavbar";
import SearchModal from "views/components/headstarttSearch/searchModal/searchModal";
import SignInModal from "../core/components/signInModal";
import SignUpModal from "../core/signUp";

type AppNavbarHocProps = {
  mode?: "light" | "dark";
};
const AppNavbarHoc = ({ mode }: AppNavbarHocProps) => {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const handleSignUpClick = useCallback(() => {
    setIsSignInModalOpen(false);
    setIsSignUpModalOpen(true);
  }, []);
  const handleSignInClick = useCallback(() => {
    setIsSignUpModalOpen(false);
    setIsSignInModalOpen(true);
  }, []);
  return (
    <div>
      <SearchModal
        showModal={isSearchModalOpen}
        onCancel={() => setIsSearchModalOpen(false)}
      />
      <SignInModal
        visible={isSignInModalOpen}
        onCancel={() => setIsSignInModalOpen(false)}
        onHandleSignUpClick={handleSignUpClick}
      />
      <SignUpModal
        visible={isSignUpModalOpen}
        onCancel={() => setIsSignUpModalOpen(false)}
        onHandleSignInClick={handleSignInClick}
      />

      <HeadstarttAppNavbar
        handleSearchClick={() => setIsSearchModalOpen(true)}
        handleSignInClick={() => setIsSignInModalOpen(true)}
        handleSignUpClick={() => setIsSignUpModalOpen(true)}
        mode={mode}
      />
    </div>
  );
};

export default AppNavbarHoc;
