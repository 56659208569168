import { Activity } from "core/repository/activity/types";
import CardImage from "./cardImage";
import CardLikeButton from "./cardLikeButton";
import CardOverlay from "./cardOverlay";
import CardTag from "./cardTag";
import { HeadstarttRating } from "../headstarttRating";
import { getOpenStatus } from "views/layouts/home/components/landingCarousel";
type ActivityCardProps = {
  activity?: Activity;
};
const ActivityCard = ({ activity }: ActivityCardProps) => {
  if (!activity) return <></>;
  const openStatus = getOpenStatus(activity.operationDate.openMonths);
  return (
    <div className="br-16 relative">
      <CardLikeButton
        id={activity._id}
        type={"ACTIVITY"}
        isBookmark={activity.isBookmark}
      />
      <CardImage
        alt={activity?.title ?? "Activity Name"}
        className="h-[582px] w-full object-cover"
        url={
          activity?.images?.[0] ??
          "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        }
      />
      <CardOverlay>
        <h2 className="fs-24 lh-28">{activity?.title ?? "Activity Name"}</h2>
        <CardTag text={openStatus ? "Open" : "Closed"} />
        <div className="flex justify-between items-center">
          <HeadstarttRating disabled value={activity?.averageRating} />
          {/* TODO add data when matrix is up */}
          {/* <p className="text-black opacity-50 mb-0">3.4km</p> */}
        </div>
      </CardOverlay>
    </div>
  );
};

export default ActivityCard;
export const ActivityCardSmall = ({ activity }: ActivityCardProps) => {
  if (!activity) return <></>;
  return (
    <div className="br-16 relative w-52 h-64 mx-2">
      <CardLikeButton
        id={activity?._id}
        type={"ACTIVITY"}
        isBookmark={activity?.isBookmark}
      />
      <CardImage
        alt={activity?.title ?? "Activity Name"}
        className="h-full w-full object-cover"
        url={
          activity?.images?.[0] ??
          "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        }
      />
      <CardOverlay>
        <h2 className="fs-16 lh-28 mb-0">
          {activity?.title ?? "Activity Name"}
        </h2>
        <div className="flex justify-between items-center">
          <HeadstarttRating value={4} className="small-rating" />
        </div>
      </CardOverlay>
    </div>
  );
};
