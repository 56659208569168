import { CheckCircleIcon } from "@heroicons/react/solid";
import { HeadstarttButton } from "views/components/headstarttButton";
import { useState } from "react";
const baseStyle = {
  borderRadius: "20px",
  padding: "4px 24px",
  fontSize: "16px",
  fontWeight: 500,
  color: "#fff",
  height: "fit-content",
};
const blockStyle = {
  ...baseStyle,
  backgroundColor: "#F29D38",
  border: "1px solid #F29D38",
};
const unBlockStyle = {
  ...baseStyle,
  backgroundColor: "#5F5F5F",
  border: "1px solid #5F5F5F",
};
const SupportResolveButton = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <HeadstarttButton
      onClick={() => setToggle(true)}
      style={toggle ? unBlockStyle : blockStyle}
    >
      {toggle ? (
        <div className="flex gap-1 items-center">
          <CheckCircleIcon height={24} /> Resolved
        </div>
      ) : (
        "Mark as Resolved"
      )}
    </HeadstarttButton>
  );
};

export default SupportResolveButton;
