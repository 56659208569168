import { Tag, TagProps } from "antd";
export const DefaultTag = (props: TagProps) => (
  <Tag
    style={{
      backgroundColor: "transparent",
      borderRadius: "9999px",
      padding: "4px 12px",
      border: "1px solid #000",
      ...props.style,
    }}
    // {...props}
  >
    {props.children}
  </Tag>
);
