import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setInLocalStorage,
} from "core/clients/lockr";

export const setToken = (token: string) => setInLocalStorage("token", token);
export const getToken = () => getFromLocalStorage("token");
export const getAdmin = () => localStorage.getItem("adminUser");
export const removeToken = () => removeFromLocalStorage("token");
export const removeAdminData = () => removeFromLocalStorage("adminUser");
