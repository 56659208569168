import { HostDataRoot, HostParam, HostPayload, HostRoot } from "./types";

import { PaginationParams } from "../types";
import { apiRequest } from "core/services/apiServices";

export const getHostFromRepo = (param: HostParam) =>
  apiRequest.getByQueryParam<HostRoot>("user/host/v1", param);

export const getHostListFromRepo = (param: PaginationParams) =>
  apiRequest.getByQueryParam<HostDataRoot>("user/host/v1/list", param);

export const addHostToRepo = (payload: HostPayload) =>
  apiRequest.post("admin/host/v1", payload);

export const patchHostToRepo = (payload: HostPayload) =>
  apiRequest.patch("admin/host/v1", payload);

export const deleteHostFromRepo = (id: string) =>
  apiRequest.deleteWithoutId(`admin/host/v1`, {
    hostId: id,
  });
