import {
  addProjectToRepo,
  updateProjectToRepo,
} from "core/repository/projects";
import { useCallback, useEffect, useState } from "react";

import AdminImageUploader from "admin/components/adminImageUploader";
import ErrorCard from "views/components/errorCard";
import FormFooter from "views/components/headstarttForm/formFooter";
import FormGroup from "views/components/headstarttForm/formGroup";
import HeadstarttContainer from "views/components/headstarttContainer";
import { HeadstarttForm } from "views/components/headstarttForm";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PageHeader from "admin/components/pageHeader";
import { Project } from "core/repository/search/types";
import useAddUpdateGeneralized from "core/hooks/useAddUpdateGeneralized";
import useGetProjectById from "core/services/project/useGetProjectById";
import { useNavigate } from "react-router-dom";

const AdminProjectForm = () => {
  const navigate = useNavigate();
  const { project, isLoading, isError, id } = useGetProjectById();
  const [form] = HeadstarttForm.useForm();
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const { addUpdate, mutationLoading } = useAddUpdateGeneralized<Project>(
    addProjectToRepo,
    updateProjectToRepo,
    id
  );

  useEffect(() => {
    if (project?.data) {
      form.setFieldsValue({
        ...project,
        projectImage: [project?.data?.projectImage] || [],
      });
      setUploadedImages([project?.data?.projectImage] || []);
    }
  }, [project, form, id]);

  const handleFinish = useCallback(
    async (values: Project) => {
      if (id) {
        values.projectId = id;
      }
      await addUpdate({
        ...values,
        projectImage: uploadedImages[0],
      });
      navigate("/admin/project/project-list");
    },
    [addUpdate, id, uploadedImages, navigate]
  );

  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  const data = project?.data;

  return (
    <div>
      <PageHeader heading={id ? "Edit Project" : "Create Project"} />
      <HeadstarttContainer>
        <div className="my-8">
          <div className="bg-white p-8 br-8">
            <h1 className="fs-18 font-light  border-b mb-4">Name and Image</h1>

            <HeadstarttForm
              form={form}
              onFinish={handleFinish}
              className="w-full space-y-8"
              initialValues={{
                projectName: data?.projectName,
                projectDescription: data?.projectDescription,
                projectUrl: data?.redirectUrl,
              }}
            >
              <FormFooter
                flexClass="flex items-center justify-end gap-2"
                showBackButton={false}
                nextButtonDisabled={mutationLoading}
                showNextButton={false}
                onCancelButtonClick={() => form.resetFields()}
              />
              <FormGroup
                label={"Project Name"}
                name={"projectName"}
                description={"Enter project name"}
              />
              <FormGroup
                label="Project Description"
                name={"projectDescription"}
                description="Enter project description"
                inputType="textarea"
              />
              <FormGroup
                label="URL"
                name="projectUrl"
                description="Enter project URL address"
              />
              <AdminImageUploader
                label={"Cover Image"}
                name={"coverImage"}
                uploadedImages={uploadedImages}
                form={form}
                multiple={false}
                setUploadedImages={setUploadedImages}
              />
            </HeadstarttForm>
          </div>
        </div>
      </HeadstarttContainer>
    </div>
  );
};

export default AdminProjectForm;
