import { Activity } from "core/repository/activity/types";
import ActivityCard from "views/components/headstarttCard/activityCard";

const ActivityList = ({
  activities,
  handleClick,
}: {
  activities: Activity[];
  handleClick: (id: string) => void;
}) => {
  return (
    <div className="grid md:grid-cols-3 grid-cols-1 gap-8 my-4">
      {activities.map((activity) => (
        <div
          key={activity._id}
          className="cursor-pointer"
          onClick={() => handleClick(activity._id)}
        >
          <ActivityCard activity={activity} />
        </div>
      ))}
    </div>
  );
};

export default ActivityList;
