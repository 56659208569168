import { Cross } from "views/components/icons";
import FormGroup from "views/components/headstarttForm/formGroup";
import { FormInstance } from "antd";
import { RawButton } from "views/components/headstarttButton";
import UploadButton from "./uploadButton";
import { UploadFilesResponseRoot } from "core/repository/uploadFiles/types";
import { useCallback } from "react";

type AdminImageUploaderProps = {
  label: string;
  name: string;
  setUploadedImages: (data: string[]) => void;
  uploadedImages: string[];
  form: FormInstance;
  multiple?: boolean;
  description?: string;
};
const AdminImageUploader = ({
  label,
  name,
  setUploadedImages,
  uploadedImages = [],
  form,
  multiple,
  description,
}: AdminImageUploaderProps) => {
  const handleSuccess = useCallback(
    (res: UploadFilesResponseRoot["data"]) => {
      setUploadedImages([...uploadedImages, res.mediaFiles[0]]);
      form.setFieldsValue({
        coverImage: [...uploadedImages, res.mediaFiles[0]],
      });
    },
    [uploadedImages, form, setUploadedImages]
  );
  const handleOnRemove = useCallback(
    (index: number) => {
      const newImages = uploadedImages.filter((_, i) => i !== index);
      setUploadedImages(newImages);
      form.setFieldsValue({ coverImage: newImages });
    },
    [uploadedImages, form, setUploadedImages]
  );

  return (
    <FormGroup
      required={false}
      inputType="children"
      label={label}
      name={name}
      description={description}
    >
      <div className="flex gap-2 items-center">
        <UploadButton
          multiple={multiple}
          buttonText={"Upload Images"}
          onSuccess={handleSuccess}
        />
        <div className="flex gap-2">
          {uploadedImages.map((image, index) => (
            <div key={index} className="relative gap-2">
              <RawButton
                type="text"
                style={{
                  position: "absolute",
                  backgroundColor: "rgba(256,256,256)",
                  top: "2px",
                  right: "2px",
                  borderRadius: "999px",
                  height: "20px",
                  width: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => handleOnRemove(index)}
              >
                <div>
                  <Cross height={16} width={16} />
                </div>
              </RawButton>
              <img
                src={image}
                alt="uploaded"
                className="w-40 h-40 br-4 object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </FormGroup>
  );
};

export default AdminImageUploader;
