import useGetExploreAll from "core/services/search/useGetExploreAll";
import ErrorCard from "views/components/errorCard";
import Loader from "views/components/loader";
import { MarkerProps } from "views/layouts/listWithMaps/types";
import ExploreSidebar from "../exploreSidebar";
import ActivitySlider from "./activitySlider";
import DestinationSlider from "./destinationSlider";
import EventSlider from "./eventSlider";
import ProjectSlider from "./projectSlider";

const ExploreAll = () => {
  const { data, isLoading, isError } = useGetExploreAll();
  const eventList = data?.data.eventsList.list || [];
  const activityList = data?.data.activitiesList.list || [];

  const cumulativeList = [...eventList, ...activityList];
  const markers: MarkerProps[] =
    cumulativeList.map((item) => ({
      position: [item.locationDetail.latitude, item.locationDetail.longitude],
      label: item.title,
    })) || [];

  if (isError) return <ErrorCard />;
  return (
    <div className="grid grid-cols-12 gap-12">
      <div className="col-span-3 w-72 fixed z-20 overflow-auto h-full">
        <div className="px-4 pb-24">
          <ExploreSidebar markers={markers} />
        </div>
      </div>
      {isLoading ? (
        <div className="col-span-9 w-full ml-72 ">
          <Loader />
        </div>
      ) : (
        <div className="col-span-9 w-full ml-72 space-y-8">
          {!!data?.data.destinationsList.total && (
            <div className="space-y-4">
              <div className="bg-white br-16 p-4">
                <h1 className="fs-26 lh-38 font-medium">Destination</h1>

                <DestinationSlider
                  destination={data?.data.destinationsList.list}
                />
              </div>
            </div>
          )}

          {!!data?.data.activitiesList.total && (
            <div className="bg-white br-16 p-4">
              <h1 className="fs-26 lh-38 font-medium">Self guided tours</h1>
              <ActivitySlider items={data?.data.activitiesList.list} />
            </div>
          )}

          {!!data?.data.eventsList.total && (
            <div className="bg-white br-16 p-4">
              <h1 className="fs-26 lh-38 font-medium">Events</h1>

              <EventSlider items={data?.data.eventsList.list} />
            </div>
          )}

          {!!data?.data.projectsList.total && (
            <div className="bg-white br-16 p-4">
              <h1 className="fs-26 lh-38 font-medium">Projects</h1>
              <ProjectSlider items={data?.data.projectsList.list} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExploreAll;
