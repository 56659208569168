import { Outlet, useLocation } from "react-router-dom";

import { SearchContextProvider } from "context/searchContext";
import { parse } from "query-string";
import HeadstarttContainer from "views/components/headstarttContainer";
import ExploreCover from "./exploreCover";

const Explore = () => {
  const search = parse(useLocation().search).search as string;
  const firstString = search?.split(" ")[0] ?? "";
  const remainingString = search?.replace(firstString, "") ?? "";
  return (
    <SearchContextProvider>
      <div className="pt-24 pb-8 fixed w-full bg-white z-30">
        <HeadstarttContainer>
          <ExploreCover
            firstString={firstString}
            remainingString={remainingString}
          />
        </HeadstarttContainer>
      </div>
      <div className="bg-rainee pt-64 h-full min-h-screen">
        <HeadstarttContainer>
          <div className="py-12">
            <Outlet />
          </div>
        </HeadstarttContainer>
      </div>
    </SearchContextProvider>
  );
};

export default Explore;
