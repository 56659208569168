import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Divider } from "antd";
import { useSearchContext } from "context/searchContext";
import { debounce } from "lodash";
import { HeadstarttInput } from "../headstarttInput";
import { HeadstarttSelect } from "../headstarttSelect";
import { ChevronDown } from "../icons";
import FilterHeader from "./filterHeader";

const PriceFilter = () => {
  const { filters } = useSearchContext();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const minPrice = searchParams.get("minPrice");
  const maxPrice = searchParams.get("maxPrice");
  const priceTypeFromUrl = searchParams.get("priceType") as "Any" | "Free";
  const [priceType, setPriceType] = useState<
    "Any" | "Free" | "Min. price" | "Max. price" | undefined
  >(priceTypeFromUrl);
  const [price, setPrice] = useState<{
    minPrice: number | undefined;
    maxPrice: number | undefined;
  }>({
    minPrice: minPrice ? parseInt(minPrice) : undefined,
    maxPrice: maxPrice ? parseInt(maxPrice) : undefined,
  });

  useEffect(() => {
    if (priceType === "Any") {
      setPrice({
        minPrice: undefined,
        maxPrice: undefined,
      });
      searchParams.delete("minPrice");
      searchParams.delete("maxPrice");
    }
    if (priceType === "Free") {
      setPrice({
        minPrice: 0,
        maxPrice: 0,
      });
      searchParams.set("minPrice", "0");
      searchParams.set("maxPrice", "0");
    }
    if (price.minPrice) {
      searchParams.set("minPrice", price.minPrice.toString());
    }
    if (price.maxPrice) {
      searchParams.set("maxPrice", price.maxPrice.toString());
    }
    if (price.minPrice === undefined) {
      searchParams.delete("minPrice");
    }
    if (price.maxPrice === undefined) {
      searchParams.delete("maxPrice");
    }
    if (priceType) {
      searchParams.set("priceType", priceType);
    }
    if (!priceType) {
      searchParams.delete("priceType");
    }

    debounce(() => {
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }, 1000)();
  }, [
    location.pathname,
    navigate,
    price.maxPrice,
    price.minPrice,
    priceType,
    searchParams,
  ]);
  if (!filters?.priceType?.length) return null;
  return (
    <div>
      <FilterHeader
        heading={"Price type"}
        handleClear={() => {
          setPriceType(undefined);

          setPrice({
            minPrice: undefined,
            maxPrice: undefined,
          });
        }}
      />
      <HeadstarttSelect
        suffixIcon={<ChevronDown height={18} />}
        placeholder="Select"
        onChange={(value) => {
          setPriceType(value);
        }}
        value={priceType}
        style={{ width: "100%", borderRadius: "8px", marginBottom: "8px" }}
      >
        {filters?.priceType?.map((type) => (
          <HeadstarttSelect.Option value={type.label} key={type.label}>
            {type.label}
          </HeadstarttSelect.Option>
        ))}
      </HeadstarttSelect>
      <div className="grid grid-cols-2 gap-1 items-center justify-center mb-4">
        <div className="col-span-1">
          <HeadstarttInput
            size="middle"
            disabled={priceType === "Any" || priceType === "Free"}
            value={priceType === "Free" ? 0 : price.minPrice}
            placeholder="Min. price"
            min={
              filters?.priceType.find((type) => type.label === priceType)?.value
            }
            onChange={(e) => {
              const value = e.target.value;
              setPrice({
                ...price,
                minPrice: value ? parseInt(value) : undefined,
              });
            }}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              borderRadius: "4px",
              border: "none",
            }}
          />
        </div>
        <div className="col-span-1">
          <HeadstarttInput
            size="middle"
            disabled={priceType === "Any" || priceType === "Free"}
            placeholder="Max. price"
            value={priceType === "Free" ? 0 : price.maxPrice}
            onChange={(e) => {
              const value = e.target.value;
              setPrice({
                ...price,
                maxPrice: value ? parseInt(value) : undefined,
              });
            }}
            max={
              filters?.priceType.find((type) => type.label === priceType)?.value
            }
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              borderRadius: "4px",
              border: "none",
            }}
          />
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default PriceFilter;
