import { DestinationsList } from "core/repository/search/types";
import { Divider } from "antd";
import QuickActionHolder from "admin/components/quickActionHolder";
import { useNavigate } from "react-router-dom";

const DestinationTableRow = ({
  list,
  onDelete,
}: {
  list: DestinationsList["list"];
  onDelete: (id: string) => void;
}) => {
  const navigate = useNavigate();
  return (
    <>
      {list.map((destination) => (
        <div key={destination._id}>
          <div className="grid grid-cols-12 py-1 gap-4">
            <div className="col-span-2">
              <span className="fs-16 font-medium">
                {destination.destinationName}
              </span>
            </div>
            <div className="col-span-2">
              <span className="fs-16 font-medium">
                {destination.destinationDescription}
              </span>
            </div>
            <div className="col-span-2">
              <span className="fs-16 font-medium">{destination.province}</span>
            </div>
            <div className="col-span-2">
              {
                <span className="fs-16 font-medium">
                  {destination.totalActivities}
                </span>
              }
            </div>
            <div className="col-span-2">
              <span className="fs-16 font-medium">
                {destination.totalEvents}
              </span>
            </div>
            <div className="col-span-2">
              <QuickActionHolder
                onEdit={() => navigate(`/admin/destination/${destination._id}`)}
                showBookmark={false}
                onDelete={() => onDelete(destination._id)}
              />
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </>
  );
};

export default DestinationTableRow;
