import useGetActivityList from "core/services/activity/useGetActivityList";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CoverSection from "views/components/coverSection";
import { HeadstarttButton } from "views/components/headstarttButton";
import ActivityCard from "views/components/headstarttCard/activityCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PopularHeadingGroup from "views/components/typography/popularHeading";

const ActivityList = () => {
  const navigate = useNavigate();

  const { data, isLoading, isError } = useGetActivityList();

  const redirectToShowMore = useCallback(() => {
    navigate("/explore/activities");
  }, [navigate]);

  if (isLoading)
    return (
      <div className="bg-gray">
        <LoaderFullScreen />
      </div>
    );
  if (isError) return <div>Error!</div>;
  if (!data) return <div>No activities found</div>;
  const list = data?.data.list;
  const handleClick = (id: string) => {
    navigate(`/discover-activities/${id}`);
  };
  return (
    <>
      <CoverSection bannerDetails={data?.data?.bannerDetails} />
      <div className="my-24">
        <HeadstarttContainer>
          <PopularHeadingGroup headingPartTwo="Activity" />
          <div className="grid md:grid-cols-3 grid-cols-1 gap-8 my-4">
            {list?.map((item) => (
              <div
                key={item._id}
                className="cursor-pointer"
                onClick={() => handleClick(item._id)}
              >
                <ActivityCard activity={item} />
              </div>
            ))}
          </div>
        </HeadstarttContainer>
        <div className="flex justify-center py-2">
          <HeadstarttButton onClick={redirectToShowMore} type="ghost">
            Show More Activities
          </HeadstarttButton>
        </div>
      </div>
    </>
  );
};

export default ActivityList;
