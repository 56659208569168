import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { markAllAsReadFromRepo } from "core/repository/alerts";
import { useMutation } from "react-query";

const useMarkAllAsRead = () => {
  const { mutate, isLoading } = useMutation(markAllAsReadFromRepo, {
    onSuccess: () => {
      successToast("All notifications marked as read");
    },
    onError: () => {
      errorToast("Something went wrong. Please try again later.");
    },
  });
  return {
    markAllAsRead: async () => mutate(true),
    isLoading,
  };
};

export default useMarkAllAsRead;
