import { EventRoot } from "core/repository/event/types";
import { getEventByIdFromRepo } from "core/repository/event";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

const useGetEventById = (lazy = false) => {
  const params = useParams();
  const { id } = params as { id: string };

  const {
    data: event,
    isError,
    isLoading,
    refetch,
  } = useQuery<EventRoot, Error>(
    ["eventById", id],
    async () => {
      const { data } = await getEventByIdFromRepo(id);
      return data;
    },
    {
      enabled: !lazy,
    }
  );

  return { event, isError, isLoading, refetch };
};

export default useGetEventById;
