import HeadstarttLikeButton from "views/components/headstarttButton/headstarttLikeButton";
import HeadstarttNotificationButton from "views/components/headstarttButton/headstarttNotificationButton";
import useHandleNotificationSubscription from "core/services/user/useHandleNotificationSubscription";
import { useState } from "react";

const ActivityMetaActions = ({
  id,
  isBookmark,
  isSubscribed = false,
}: {
  id: string;
  isBookmark?: boolean;
  isSubscribed?: boolean;
}) => {
  const {
    subscribeNotifications,
    unsubscribeNotifications,
    subscribeLoading,
    unsubscribeLoading,
  } = useHandleNotificationSubscription();
  const [isSubscribedLocal, setIsSubscribedLocal] = useState(isSubscribed);
  const handleBellButtonClick = () => {
    if (isSubscribedLocal) unsubscribeNotifications(id);
    else subscribeNotifications(id);
    setIsSubscribedLocal((prev) => !prev);
  };
  return (
    <div className="flex items-center justify-end gap-2">
      <div onClick={handleBellButtonClick}>
        <HeadstarttNotificationButton
          color={isSubscribedLocal ? "#EE7654" : "#666"}
          fill={isSubscribedLocal ? "#EE7654" : "transparent"}
          className={`bg-gray-200 ${
            subscribeLoading || unsubscribeLoading ? "cursor-not-allowed" : ""
          }`}
        />
      </div>
      {!!id && (
        <HeadstarttLikeButton
          isBookmark={isBookmark}
          id={id}
          type="ACTIVITY"
          className="bg-gray-200"
        />
      )}
    </div>
  );
};

export default ActivityMetaActions;
