import { SuggestionsRoot } from "core/repository/search/types";
import { getSearchSuggestionsFromRepo } from "core/repository/search";
import { useQuery } from "react-query";
import { useState } from "react";

const LIMIT = 12;
const useGetSearchSuggestions = () => {
  const [params, setParams] = useState({
    search: "",
    limit: LIMIT,
    offset: 0,
  });

  const { data, isLoading, isError } = useQuery<SuggestionsRoot, Error>(
    ["searchSuggestions", params.search, params.limit, params.offset],
    async () => {
      const response = await getSearchSuggestionsFromRepo(params);
      return response.data;
    },
    {
      enabled: params.search.length > 2,
    }
  );

  return {
    data,
    isLoading,
    isError,
    setParams,
    params,
  };
};

export default useGetSearchSuggestions;
