import { DefaultTag } from "views/components/headstarttTag";

const AdminTags = ({ text }: { text: string }) => {
  return (
    <DefaultTag
      style={{
        border: " 1px solid #EE7654",
        textTransform: "uppercase",
        color: "#EE7654",
      }}
    >
      <div className="flex gap-1 items-center">
        <span className="fs-16 font-medium">{text}</span>
      </div>
    </DefaultTag>
  );
};

export default AdminTags;
