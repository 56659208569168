import { AlertParams, AlertsRoot, UnreadAlertCountRoot } from "./types";

import { apiRequest } from "core/services/apiServices";

export const getAlertsFromRepo = async (params: AlertParams) =>
  apiRequest.getByQueryParam<AlertsRoot>(
    "user/alert_notification/v1/list",
    params
  );

export const deleteAlertFromRepo = async (alertNotificationId: string) =>
  apiRequest.deleteWithoutId(`user/alert_notification/v1`, {
    alertNotificationId,
  });

export const markAsReadFromRepo = async (alertNotificationId?: string) =>
  apiRequest.patch(`user/alert_notification/v1`, {
    alertNotificationId,
  });

export const markAllAsReadFromRepo = async (isMarkAllAsRead: boolean) =>
  apiRequest.patch(`user/alert_notification/v1`, {
    isMarkAllAsRead,
  });

export const unreadCountFromRepo = async () =>
  apiRequest.get<UnreadAlertCountRoot>(
    `user/alert_notification/v1/unreadCount`
  );
