import CardImage from "views/components/headstarttCard/cardImage";
type ReviewEntityProps = {
  image: string;
  title: string;
  description: string;
};

const ReviewEntity = ({ title, image, description }: ReviewEntityProps) => {
  return (
    <div className="flex gap-8 items-center my-16">
      {!!image && (
        <CardImage className="h-32 w-32 object-cover" url={image} alt={title} />
      )}
      <div>
        <h2 className="fs-32 font-medium mb-0">{title}</h2>
        <p className="fs-16 text-muted mb-0">{description}</p>
      </div>
    </div>
  );
};

export default ReviewEntity;
