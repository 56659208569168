import { Facility, SubActivity } from "core/repository/activity/types";

import { ADMIN_ACTIVITY_TABS } from "admin/adminConstants";
import ActivitySelection from "./components/activitySelection";
import { ActivityTabView } from "../types";
import FacilitySelection from "./components/facilitySelection";
import FormFooter from "views/components/headstarttForm/formFooter";
import { HeadstarttForm } from "views/components/headstarttForm";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ActivitiesAndFacilitiesForm = ({
  payload,
  setPayload,
  subActivity = [],
  facility = [],
}: ActivityTabView & {
  subActivity: SubActivity[];
  facility: Facility[];
}) => {
  const [values, setValues] = useState<{
    subActivity: string[];
    facility: string[];
  }>({
    subActivity: payload.subActivity,
    facility: payload.facility,
  });
  const navigate = useNavigate();
  return (
    <HeadstarttForm
      onFinish={() => {
        const newPayload = {
          ...payload,
          subActivity: values.subActivity,
          facility: values.facility,
        };
        setPayload(newPayload);
        navigate({
          pathname: location.pathname,
          search: `?tab=${encodeURIComponent(ADMIN_ACTIVITY_TABS[4])}`,
        });
      }}
      className="w-full space-y-8"
    >
      <FormFooter
        showSaveButton={false}
        showCancelButton={false}
        onBackButtonClick={() => {
          navigate({
            pathname: location.pathname,
            search: `?tab=${encodeURIComponent(ADMIN_ACTIVITY_TABS[2])}`,
          });
        }}
      />
      <ActivitySelection
        setValues={(v: string[]) => {
          setValues({
            ...values,
            subActivity: v,
          });
        }}
        values={values.subActivity}
        subActivity={subActivity}
      />
      <FacilitySelection
        setValues={(v: string[]) => {
          setValues({
            ...values,
            facility: v,
          });
        }}
        values={values.facility}
        dataFacility={facility}
      />
    </HeadstarttForm>
  );
};

export default ActivitiesAndFacilitiesForm;
