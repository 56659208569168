import { DocumentTwo, DownloadTwo } from "../icons";

const DocumentCard = ({ url, index }: { url: string; index: number }) => {
  return (
    <div className="border p-4 flex items-center justify-between br-8">
      <div className="flex items-center gap-2">
        <DocumentTwo />
        <p className="mb-0">PDF file 0{index}</p>
      </div>
      <a href={url} download rel="noreferrer noopener nofollow">
        <DownloadTwo />
      </a>
    </div>
  );
};

export default DocumentCard;
