import { SearchParams } from "../search/types";

export const checkIfObjectHasValidValue = (params: SearchParams) => {
  const newParams = { ...params };
  // iterate through the params and remove the ones that are empty
  if (!newParams.limit) {
    newParams.limit = 12;
  }
  if (!newParams.offset) {
    newParams.offset = 0;
  }

  Object.keys(newParams).forEach((key) => {
    //  type definition for params is SearchParams, which is a string
    //  but the value of the key can be undefined
    //  so we need to check if the value is undefined
    //  and if it is, we delete the key

    if (!newParams[key as keyof SearchParams]) {
      // skip if offset is 0
      if (key === "offset" && newParams.offset === 0) {
        return;
      }
      delete newParams[key as keyof SearchParams];
    }
  });
  return newParams;
};
