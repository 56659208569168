import { ProjectRoot } from "core/repository/projects/types";
import { getProjectByIdFromRepo } from "core/repository/projects";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

const useGetProjectById = (lazy = false) => {
  const params = useParams();
  const { id } = params as { id: string };

  const {
    data: project,
    isError,
    isLoading,
    refetch,
  } = useQuery<ProjectRoot, Error>(
    ["projectById", id],
    async () => {
      const { data } = await getProjectByIdFromRepo(id);
      return data;
    },
    {
      enabled: !lazy && !!id,
      cacheTime: 0,
    }
  );

  return { project, isError, isLoading, refetch, id };
};

export default useGetProjectById;
