import { DotsHorizontal } from "views/components/icons";
import { HeadstarttDropdown } from "views/components/headstarttDropdown";
import { RawButton } from "views/components/headstarttButton";
import { TrashIcon } from "@heroicons/react/outline";
import { useCallback } from "react";

const ActionDropdown = ({ handleDelete }: { handleDelete?: () => void }) => {
  const onClick = useCallback(() => {
    handleDelete?.();
  }, [handleDelete]);
  return (
    <HeadstarttDropdown
      overlay={
        <>
          {!!handleDelete && (
            <RawButton danger onClick={onClick} type="primary">
              <TrashIcon height={24} width={24} />
            </RawButton>
          )}
        </>
      }
      placement="bottomRight"
    >
      <div className="bg-gray-200 p-3 br-8 cursor-pointer">
        <DotsHorizontal height={24} />
      </div>
    </HeadstarttDropdown>
  );
};

export default ActionDropdown;
