import { OAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { useCallback, useState } from "react";

import { FirebaseError } from "firebase/app";
import { LoginType } from "../types";
import { SignUpPayload } from "core/repository/user/types";
import { errorToast } from "views/components/headstarttNotifications";

const useLoginWithApple = (
  onSuccess?: (payload: SignUpPayload) => void,
  onError?: () => void
) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const loginWithApple = useCallback(() => {
    setLoader(true);
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        const user = result.user;

        // Apple credential
        // get id token from current user
        const accessToken = await auth?.currentUser?.getIdToken();

        const payloadForSignUp: SignUpPayload = {
          firstName: user.displayName?.split(" ")[0] || "",
          lastName: user.displayName?.split(" ")[1] || "",
          socialId: user.uid,
          loginType: LoginType.APPLE,
          deviceToken: "",
          accessToken: "",
        };
        if (accessToken) {
          payloadForSignUp.accessToken = accessToken;
        }
        onSuccess?.(payloadForSignUp);
        setLoader(false);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((err: FirebaseError) => {
        // Handle Errors here.
        setError(err.message);
        errorToast(err.message);
        onError?.();
        setLoader(false);

        // ...
      });
  }, [onError, onSuccess]);
  return {
    loginWithApple,
    loader,
    error,
  };
};

export default useLoginWithApple;
