import { useState } from "react";
/*
This component is used to display a text in a more or less readable way.
*/
type HeadstarttReadMoreLessProps = {
  text: string;
  sliceLength?: number;
};
const HeadstarttReadMoreLess = ({
  text,
  sliceLength = 100,
}: HeadstarttReadMoreLessProps) => {
  const [isOpen, setIsOpen] = useState(false);
  if (text.length < 100) return <p className="text-gray-500 fs-16">{text}</p>;
  return (
    <div>
      <p className="text-gray-700 fs-16">
        {isOpen ? text : text.substring(0, sliceLength) + "..."}{" "}
      </p>
      <p>
        <span
          className="cursor-pointer text-primary"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? "Read Less" : "Read More"}
        </span>
      </p>
    </div>
  );
};

export default HeadstarttReadMoreLess;
