import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AdminTab from "./adminTab";
import querystring from "query-string";

const AdminTabsGroup = ({ tabs }: { tabs: string[] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = querystring.parse(location.search);
  const [activeTab, setActiveTab] = useState(tabs[0] || params.tab);
  const addParams = useCallback(
    (tab: string) => {
      navigate({
        pathname: location.pathname,
        search: `?tab=${encodeURIComponent(tab)}`,
      });
    },
    [location.pathname, navigate]
  );
  const handleTabsClick = useCallback(
    (tab: string) => {
      setActiveTab(tab);
      addParams(tab);
    },
    [addParams]
  );

  useEffect(() => {
    if (params.tab) {
      setActiveTab(params.tab as string);
    } else {
      addParams(tabs[0]);
    }
  }, [tabs, addParams, params.tab]);

  return (
    <div className="flex">
      {tabs.map((tab) => (
        <AdminTab
          key={tab}
          tab={tab}
          active={tab === activeTab}
          onHandleClick={handleTabsClick}
        />
      ))}
    </div>
  );
};

export default AdminTabsGroup;
