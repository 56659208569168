import { Input, InputProps } from "antd";

export const HeadstarttInput = (props: InputProps) => (
  <Input
    size="large"
    style={{
      borderRadius: "9999px",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      padding: "8px 24px",
      fontSize: "20px",
      fontWeight: "medium",
      height: "100%",
    }}
    {...props}
  />
);
export const HeadstarttInputPassword = (props: InputProps) => (
  <Input.Password
    size="large"
    style={{
      borderRadius: "9999px",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      padding: "8px 24px",
      fontSize: "20px",
      fontWeight: "medium",
      height: "100%",
    }}
    {...props}
  />
);
