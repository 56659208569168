import styles from "./loader.module.scss";
const Loader = () => {
  return (
    <div className={styles["lds-ripple"]}>
      <div />
      <div />
    </div>
  );
};

export default Loader;
