import AbsoluteColorBackground from "./absoluteColorBackground";
import { ActivityListData } from "core/repository/activity/types";
import CoverSectionCard from "./coverSectionCard";
import CoverSectionText from "./coverSectionText";
import HeadstarttContainer from "../headstarttContainer";
import SearchInputWithModal from "../headstarttSearch/searchInputWithModal";
type CoverSectionProps = {
  sectionBackground?: string;
  btnBackground?: string;
  bannerDetails: ActivityListData["bannerDetails"];
};
const CoverSection = ({
  sectionBackground,
  btnBackground,
  bannerDetails,
}: CoverSectionProps) => {
  return (
    <div className="py-24 relative">
      <AbsoluteColorBackground className={sectionBackground} />
      <HeadstarttContainer>
        <CoverSectionText />
        <div className="grid md:grid-cols-12 mb-16">
          <div className="md:col-span-6 md:col-start-4">
            <SearchInputWithModal />
          </div>
        </div>

        <CoverSectionCard
          bannerDetails={bannerDetails}
          btnBackground={btnBackground}
        />
      </HeadstarttContainer>
    </div>
  );
};

export default CoverSection;
