import { BookmarkType } from "core/repository/user/types";
import HeadstarttLikeButton from "../headstarttButton/headstarttLikeButton";
type CardLikeButtonProps = {
  id: string;
  type: BookmarkType;
  isBookmark: boolean;
  className?: string;
};
const CardLikeButton = ({
  className = "",
  id,
  type,
  isBookmark,
}: CardLikeButtonProps) => (
  <div className={`${className} absolute right-0 m-4`}>
    <HeadstarttLikeButton id={id} type={type} isBookmark={isBookmark} />
  </div>
);

export default CardLikeButton;
