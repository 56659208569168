import { BookMarkListRoot, BookmarkPayload, ListParams } from "./types";

import { apiRequest } from "core/services/apiServices";

export const addBookmark = (payload: BookmarkPayload) =>
  apiRequest.post("/user/v1/bookmark", payload);
export const getBookmarks = (params: ListParams) =>
  apiRequest.getByQueryParam<BookMarkListRoot>(
    "/user/v1/listBookmarks",
    params
  );

export const deleteBookmark = (entityId: string) =>
  apiRequest.deleteWithoutId("/user/v1/bookmark", {
    entityId,
  });
