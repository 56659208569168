export const AppleLogo = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1224_42004)">
      <path
        d="M3.20564 9.32313C1.17666 12.844 2.46652 18.1872 4.71025 21.427C5.83022 23.0467 6.96412 24.5006 8.51719 24.5006C8.54647 24.5006 8.57582 24.5001 8.60588 24.4989C9.33555 24.4698 9.86285 24.2451 10.3727 24.028C10.945 23.7841 11.537 23.5319 12.4655 23.5319C13.3489 23.5319 13.9126 23.7759 14.4578 24.0118C14.9951 24.2443 15.5485 24.4847 16.3662 24.4694C18.1156 24.4368 19.1897 22.8679 20.1374 21.4839C21.1262 20.0388 21.6223 18.6357 21.7897 18.0995L21.7964 18.0782C21.8283 17.9829 21.7833 17.8789 21.6919 17.8369C21.6894 17.8357 21.6801 17.8319 21.6775 17.8308C21.3695 17.7048 18.6671 16.506 18.6387 13.2385C18.6124 10.5839 20.666 9.17811 21.0778 8.92331L21.097 8.91135C21.1417 8.88263 21.1727 8.83707 21.1833 8.78498C21.1938 8.73297 21.1828 8.67883 21.1529 8.63508C19.7354 6.56069 17.5628 6.24806 16.6881 6.21045C16.5612 6.19778 16.4302 6.19141 16.2986 6.19141C15.2713 6.19141 14.2871 6.57942 13.4964 6.89118C12.9507 7.1064 12.4792 7.29226 12.1541 7.29226C11.7887 7.29226 11.3146 7.10411 10.7656 6.8863C10.0315 6.59492 9.19935 6.26474 8.31843 6.26474C8.29742 6.26474 8.27672 6.26498 8.25634 6.26537C6.20808 6.29551 4.27266 7.46734 3.20564 9.32313Z"
        fill="black"
      />
      <path
        d="M16.69 0.500151C15.4496 0.550669 13.9617 1.31418 13.0723 2.35586C12.3165 3.23143 11.5782 4.69126 11.7727 6.16274C11.7849 6.2548 11.8598 6.32586 11.9524 6.33302C12.0361 6.33955 12.1216 6.34285 12.2067 6.34293C13.4196 6.34293 14.7279 5.67196 15.6214 4.59156C16.5619 3.45074 17.0374 1.98666 16.8935 0.675233C16.8822 0.572466 16.7918 0.496138 16.69 0.500151Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1224_42004">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const FacebookLogo = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1224_42010)">
      <path
        d="M14.9994 0.504161L12.4059 0.5C9.49215 0.5 7.60917 2.43188 7.60917 5.42198V7.69134H5.0015C4.77616 7.69134 4.59369 7.87402 4.59369 8.09935V11.3874C4.59369 11.6127 4.77637 11.7952 5.0015 11.7952H7.60917V20.092C7.60917 20.3173 7.79164 20.4998 8.01697 20.4998H11.4192C11.6446 20.4998 11.8271 20.3171 11.8271 20.092V11.7952H14.876C15.1014 11.7952 15.2838 11.6127 15.2838 11.3874L15.2851 8.09935C15.2851 7.99116 15.242 7.88754 15.1657 7.81097C15.0893 7.73441 14.9853 7.69134 14.8771 7.69134H11.8271V5.76757C11.8271 4.84293 12.0474 4.37354 13.2519 4.37354L14.999 4.37291C15.2241 4.37291 15.4066 4.19023 15.4066 3.96511V0.911968C15.4066 0.68705 15.2243 0.504577 14.9994 0.504161Z"
        fill="#3A559F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1224_42010">
        <rect
          width="20"
          height="19.9998"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const GoogleLogo = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1224_3160)">
      <path
        d="M5.2662 10.2645C6.19879 7.43863 8.85445 5.40909 12 5.40909C13.6909 5.40909 15.2182 6.00909 16.4182 6.99091L19.9091 3.5C17.7818 1.64545 15.0545 0.5 12 0.5C7.27007 0.5 3.19775 3.1983 1.23999 7.15002L5.2662 10.2645Z"
        fill="#EA4335"
      />
      <path
        d="M16.0408 18.5123C14.9509 19.216 13.5661 19.5906 12 19.5906C8.86651 19.5906 6.21914 17.5766 5.27701 14.7676L1.23749 17.8347C3.19282 21.7933 7.26503 24.4997 12 24.4997C14.9329 24.4997 17.7354 23.4571 19.8342 21.4993L16.0408 18.5123Z"
        fill="#34A853"
      />
      <path
        d="M19.8342 21.4998C22.0292 19.4523 23.4545 16.4038 23.4545 12.5002C23.4545 11.7911 23.3455 11.0275 23.1818 10.3184H12V14.9547H18.4364C18.1188 16.5138 17.2663 17.7214 16.0407 18.5128L19.8342 21.4998Z"
        fill="#4A90E2"
      />
      <path
        d="M5.27698 14.7682C5.03833 14.0567 4.90909 13.2941 4.90909 12.5004C4.90909 11.7186 5.03444 10.9672 5.2662 10.2649L1.23999 7.15039C0.436587 8.7608 0 10.5758 0 12.5004C0 14.4199 0.444781 16.2305 1.23746 17.8354L5.27698 14.7682Z"
        fill="#FBBC05"
      />
    </g>
    <defs>
      <clipPath id="clip0_1224_3160">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
