import { HeadstarttSmallButton } from "views/components/headstarttButton";
type AlertHeaderProps = {
  onMarkAllAsRead: () => void;
  onNotificationSettings: () => void;
  isLoading?: boolean;
};
const AlertHeader = ({
  onMarkAllAsRead,
  onNotificationSettings,
  isLoading,
}: AlertHeaderProps) => {
  return (
    <div className="flex justify-between items-center">
      <div>
        <h2 className="fs-24 font-medium">Alerts</h2>
      </div>
      <div className="flex gap-2">
        <HeadstarttSmallButton
          loading={isLoading}
          disabled={isLoading}
          onClick={onMarkAllAsRead}
        >
          <span className="font-medium px-2">Mark all as Read</span>
        </HeadstarttSmallButton>
        <HeadstarttSmallButton onClick={onNotificationSettings}>
          <span className="font-medium px-2">Notifications Settings</span>
        </HeadstarttSmallButton>
      </div>
    </div>
  );
};

export default AlertHeader;
