import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AdminCard from "admin/components/adminCard";
import ErrorCard from "views/components/errorCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttPagination from "views/components/headstarttPagination";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PageHeader from "admin/components/pageHeader";
import SecondaryHeader from "views/components/secondaryHeader";
import TableHeader from "admin/components/table/tableHeader";
import { USER_HEADER } from "admin/adminConstants";
import UserTableRow from "./userTableRow";
import useBlockUser from "core/services/user/useBlockUser";
import useGetUserList from "core/services/user/useGetUserList";

const AdminUserList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleChangePage = useCallback(
    (page: number) => {
      navigate(`${location.pathname}?page=${page}`);
    },
    [location.pathname, navigate]
  );

  const { data, isLoading, isError, offset, refetch } = useGetUserList();
  const [selected, setSelected] = useState<string>("");
  const { blockUser, loader } = useBlockUser(refetch);
  const onBlock = (userId: string, isBlock: boolean) => {
    setSelected(userId);
    blockUser({
      userId,
      isBlock: !isBlock,
    });
  };
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  if (!data?.data.total) return <>Data Not Found</>;
  return (
    <div>
      <PageHeader heading="User List" />
      <HeadstarttContainer>
        <SecondaryHeader
          paginationProps={{
            total: data?.data.total,
            current: offset,
            limit: data.data.list.length,
          }}
        />
        <div className="py-4">
          <AdminCard>
            <TableHeader header={USER_HEADER} />
            <UserTableRow
              list={data?.data.list}
              onBlock={onBlock}
              loader={loader}
              selected={selected}
            />
          </AdminCard>
          <div className="flex justify-center my-4">
            <HeadstarttPagination
              total={data?.data.total}
              current={offset}
              onChange={handleChangePage}
            />
          </div>
        </div>
      </HeadstarttContainer>
    </div>
  );
};

export default AdminUserList;
