import AudioCard from "views/components/headstarttCard/audioCard";
import { DefaultTag } from "views/components/headstarttTag";
import { Document } from "views/components/icons";
import DocumentCard from "views/components/headstarttCard/documentCard";
import GoogleMaps from "views/components/googleMaps";
import { HeadstarttOutlineButton } from "views/components/headstarttButton";
import HeadstarttReadMoreLess from "views/components/headstarttReadMoreLess";
import { ReactNode } from "react";
import { Trail } from "core/repository/activity/types";

type ActivityTrailCardProps = {
  trail: Trail;
};
type TrailCharacteristicsProps = {
  about: { title: string; value: ReactNode };
};
const TrailCardHeading = ({ title, pdf }: { title: string; pdf?: string }) => (
  <div className="flex gap-4 items-center">
    <h2 className="fs-24 font-medium mb-0">{title}</h2>
    {pdf && (
      <a href={pdf} rel="noopener noreferrer">
        <DefaultTag>
          <div className="flex gap-1 items-center">
            <Document height={20} />
            <span className="fs-16 font-bold">PDF</span>
          </div>
        </DefaultTag>
      </a>
    )}
  </div>
);

const TrailCharacteristics = ({ about }: TrailCharacteristicsProps) => (
  <>
    <div className="relative">
      <p className="fs-16 mb-1 before:absolute before:content['*'] before:rounded-full before:h-1 before:w-1 before:bg-gray-500 before:top-0 before:bottom-0 before:my-auto">
        <span className="px-2">{about.title}</span>
      </p>
    </div>
    <DefaultTag>
      <div className="flex gap-1 items-center">
        <span className="fs-16 font-bold">{about.value}</span>
      </div>
    </DefaultTag>
  </>
);

const ActivityTrailCard = ({ trail }: ActivityTrailCardProps) => {
  const aboutTrail: TrailCharacteristicsProps["about"][] = [
    {
      title: "Difficulty",
      value: trail.difficulty,
    },
    {
      title: "Dog Friendly",
      value: trail.isDogFriendly ? "Yes" : "No",
    },
    {
      title: "Kid Friendly",
      value: trail.isKidFriendly ? "Yes" : "No",
    },
    {
      title: "Length",
      value: `${trail.length} km`,
    },
    {
      title: "Elevation",
      value: `${trail.elevation} m`,
    },
    {
      title: "Accessible",
      value: (
        <a href={trail.accessible} target="_blank" rel="noreferrer noopener">
          <span className="fs-16 font-bold text-orange-500">Check Now</span>
        </a>
      ),
    },
  ];
  return (
    <div className="grid grid-cols-12 gap-12 items-start py-8">
      <div className="md:col-span-4 col-span-12">
        <div className="h-72 w-full border br-4">
          <GoogleMaps
            origin={{
              start: trail.trailStartLocation,
              end: trail.trailEndLocation,
            }}
          />
        </div>
      </div>
      <div className="md:col-span-8 col-span-12">
        <TrailCardHeading title={trail.trailTitle} />
        <div className="grid grid-cols-12 my-4 items-start">
          {aboutTrail.map((about) => (
            <div className="col-span-4 my-2" key={about.title}>
              <TrailCharacteristics about={about} />
            </div>
          ))}
        </div>
        <div className="my-8">
          <HeadstarttReadMoreLess
            sliceLength={200}
            text={trail.trailDescription}
          />
        </div>
        <HeadstarttOutlineButton size="small">
          {/* not sure about thi */}
          {/* <div className="flex items-center justify-center gap-2">
            <Play fill="#006653" />
            <span className="fs-16 font-bold text-primary">Play All</span>
          </div> */}
        </HeadstarttOutlineButton>
        <div className="my-4">
          <p className="text-capitalize fs-14 font-medium">Audio Files</p>
          <div className="grid grid-cols-12 gap-4">
            {trail.audioFiles.map((audioFile, index) => (
              <div key={audioFile} className="md:col-span-6 col-span-12">
                <AudioCard url={audioFile} index={index + 1} />
              </div>
            ))}
          </div>
        </div>

        {/* PDF */}
        <div className="my-4">
          <p className="text-capitalize fs-14 font-medium">PDF Files</p>
          <div className="grid grid-cols-12 gap-4">
            {trail.pdfFiles.map((pdfFile, index) => (
              <div key={pdfFile} className="md:col-span-6 col-span-12">
                <DocumentCard url={pdfFile} index={index + 1} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityTrailCard;
