import { HeadstarttButton } from "views/components/headstarttButton";
import { HeadstarttCheckbox } from "views/components/headstarttCheckbox";
import HeadstarttContainer from "views/components/headstarttContainer";
import { HeadstarttRating } from "views/components/headstarttRating";
import { HeadstarttTextArea } from "views/components/headstarttInput/textArea";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import { ReviewBody } from "core/repository/reviews/types";
import ReviewEntity from "./reviewEntity";
import { UploadFilesResponseRoot } from "core/repository/uploadFiles/types";
import UploadReviewPhotos from "./uploadPhotos";
import useCreateReview from "core/services/reviews/useCreateReview";
import useHandleSettingOfEventOrActivityData from "core/services/reviews/useHandleSettingOfEventOrActivityData";
import { useParams } from "react-router-dom";
import { useState } from "react";

const WriteReview = () => {
  const params = useParams();
  const { postData, loading } = useCreateReview();
  const { loading: loadingEventOrActivityData, eventOrActivityData } =
    useHandleSettingOfEventOrActivityData();

  const [state, setState] = useState<ReviewBody>({
    activityEventId: params.id || "",
    rating: 0,
    comment: "",
    images: [],
    isAgree: false,
    isUpdate: true,
  });

  const handleSuccess = (res: UploadFilesResponseRoot["data"]) => {
    setState({
      ...state,
      images: [...state.images, ...res.mediaFiles],
    });
  };

  const handleSubmit = () => {
    postData(state);
  };

  if (loadingEventOrActivityData) return <LoaderFullScreen />;
  return (
    <HeadstarttContainer>
      <div className="grid grid-cols-12 gap-24 py-24">
        <div className="md:col-start-3 md:col-span-8 col-span-12 col-start-1">
          <h1 className="fs-48 font-medium mb-0">Write your review</h1>
          <p className="fs-16 text-muted">
            Thank you for sharing your experience.
          </p>
          <ReviewEntity
            image={eventOrActivityData?.data.images[0] || ""}
            title={eventOrActivityData?.data.title || ""}
            description={eventOrActivityData?.data.description || ""}
          />
          <p className="fs-32 font-32 font-medium">How would you rate?</p>
          <HeadstarttRating
            defaultValue={0}
            className="review-rating"
            onChange={(value) => {
              setState({
                ...state,
                rating: value,
              });
            }}
          />
          <div className="my-16">
            <p className="fs-32 font-32 font-medium mb-0">Write your review</p>
            <div className="flex justify-between">
              <p className="text-muted fs-16 font-medium">
                Please tell us more than 100 characters
              </p>
              <p className="text-black">(0/ Min 100)</p>
            </div>
            <HeadstarttTextArea
              onChange={(e) => {
                const value = e.target.value;
                setState({
                  ...state,
                  comment: value,
                });
              }}
              placeholder="Type here"
              rows={6}
            />
          </div>

          <div className="my-16">
            <p className="fs-32 font-32 font-medium">
              Share Photos &nbsp;
              <span className="fs-16 text-muted">(OPTIONAL)</span>
            </p>
            <UploadReviewPhotos onSuccess={handleSuccess} />
          </div>
          <div className="my-16">
            <HeadstarttCheckbox
              onChange={(e) => {
                setState({
                  ...state,
                  isAgree: e.target.checked,
                });
              }}
            >
              <span className="fs-16 font-weight font-medium">
                I certify that this review is based on my own experience and is
                my genuine opinion of this restaurant, and that I have no
                personal or business relationship with this establishment, and
                have not been offered any incentive or payment originating from
                the establishment to write this review. I understand that
                Tripadvisor has a zero-tolerance policy on fake reviews.
              </span>
            </HeadstarttCheckbox>
          </div>
          <div className="my-18 flex items-center justify-center">
            <HeadstarttButton
              loading={loading}
              disabled={loading || state.isAgree === false}
              onClick={handleSubmit}
            >
              Submit your Review
            </HeadstarttButton>
          </div>
        </div>
      </div>
    </HeadstarttContainer>
  );
};

export default WriteReview;
