import { SignInPayload, SignUpPayload } from "core/repository/user/types";
import { useCallback, useState } from "react";

import { signUpUserToRepo } from "core/repository/user/signUpUserToRepo";

const useSignUpUser = (
  onSuccess?: (user: SignInPayload) => void,
  onError?: (err: Error) => void
) => {
  const [loader, setLoader] = useState(false);

  const signUpUser = useCallback(
    async (userData: SignUpPayload) => {
      setLoader(true);
      try {
        await signUpUserToRepo(userData);
        const { firstName, lastName, ...signInPayload } = userData;
        onSuccess?.(signInPayload);
      } catch (err) {
        onError?.(err as Error);
      }
      setLoader(false);
    },
    [onSuccess, onError]
  );

  return { signUpUser, loader };
};

export default useSignUpUser;
