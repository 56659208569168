import { useLocation, useNavigate } from "react-router-dom";

import { SupportRequestRoot } from "./../../repository/support/types";
import { getSupportRequestListFromRepo } from "core/repository/support/getSupportRequestList";
import { parse } from "query-string";
import { useEffect } from "react";
import { useQuery } from "react-query";

const LIMIT = 12;

const useGetSupportList = (redirectToPageParam = true) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = parse(location.search);
  const search = queryParams.search ?? "";
  const pageFromUrl = queryParams.page ?? "1";
  const offset = Number(pageFromUrl);

  const { data, isLoading, isError } = useQuery<SupportRequestRoot, Error>(
    ["supportList", offset, search],
    async () => {
      const { data: responseData } = await getSupportRequestListFromRepo({
        limit: LIMIT,
        offset: offset - 1,
        search: search as string,
      });
      return responseData;
    }
  );

  useEffect(() => {
    if (redirectToPageParam) {
      navigate({
        pathname: location.pathname,
        search: `?page=${offset}`,
      });
    }
  }, [location.pathname, navigate, redirectToPageParam, offset]);

  return {
    data,
    isLoading,
    isError,
    offset,
  };
};

export default useGetSupportList;
