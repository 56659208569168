import { SignUpPayload } from "core/repository/user/types";
import useLoginWithFacebook from "core/services/authService/hooks/useLoginWithFacebook";
import useSignInUser from "core/services/user/useSignInUser";
import useSignUpUser from "core/services/user/useSignUpUser";
import { useNavigate } from "react-router-dom";
import { HeadstarttOutlineButton } from "views/components/headstarttButton";
import { FacebookLogo } from "views/components/icons/brandIcons";

const LoginWithFacebook = ({ onCancel }: { onCancel?: () => void }) => {
  const navigate = useNavigate();

  const { signUpUser, loader: signUpLoader } = useSignUpUser();
  const { signInUser, loader: signInLoader } = useSignInUser();
  const onSuccess = async (payload: SignUpPayload) => {
    await signUpUser(payload);
    const { firstName, lastName, ...signInPayload } = payload;
    await signInUser(signInPayload);
    // redirect to home page
    navigate("/");
    onCancel?.();
  };
  const { loginWithFacebook, loader } = useLoginWithFacebook(onSuccess);
  return (
    <HeadstarttOutlineButton
      type="ghost"
      buttonspan="full"
      onClick={loginWithFacebook}
      disabled={loader || signUpLoader || signInLoader}
      style={{
        borderRadius: "4rem",
        border: "1px solid #006653",
        color: "#000",
        fontSize: "16px",
        padding: "16px 24px",
        fontWeight: "500",
        height: "fit-content",
        width: "100%",
      }}
    >
      <div className="flex items-center gap-2">
        <FacebookLogo /> Login with Facebook
      </div>
    </HeadstarttOutlineButton>
  );
};

export default LoginWithFacebook;
