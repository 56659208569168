import {
  HeadstarttSmallButton,
  RawButton,
} from "views/components/headstarttButton";
import dayjs, { Dayjs } from "dayjs";
import { groupBy, uniqBy } from "lodash";
import { useCallback, useEffect, useId, useState } from "react";

import { CircleCross } from "views/components/icons";
import FormLabelWithDescription from "views/components/headstarttForm/formLabelWithDescription";
import { HeadstarttDatepicker } from "views/components/headstarttDatepicker";
import { PlusIcon } from "@heroicons/react/solid";

const WIDTH = 280;

export type OperationDatesType = {
  start: Dayjs | null;
  end: Dayjs | null;
}[];

const ErrorBanner = ({ message }: { message: string }) => {
  if (!message) return null;
  return (
    <div className="bg-red-50 border-l-4 border-red-400 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CircleCross />
        </div>
        <div className="ml-3">
          <p className="text-sm text-red-700 mb-0">{message}</p>
        </div>
      </div>
    </div>
  );
};
const initialValues = [
  {
    start: null,
    end: null,
  },
];

const ParkOperationDate = ({
  handleOperationDatesChange,
  availableDates,
}: {
  handleOperationDatesChange: (operationDates: OperationDatesType) => void;
  availableDates: string[];
}) => {
  const id = useId();

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [operationDates, setOperationDates] =
    useState<OperationDatesType>(initialValues);

  const handleAddOperationDate = useCallback(() => {
    setOperationDates((prev) => [...prev, { start: null, end: null }]);
  }, []);

  useEffect(() => {
    if (availableDates.length) {
      const newOperationDates: OperationDatesType = [];
      // group dates by month and year
      const groupedDates = groupBy(availableDates, (date) => {
        return `${dayjs(date).month()}-${dayjs(date).year()}`;
      });
      // get the first and last date of each month
      Object.keys(groupedDates).forEach((key) => {
        const dates = groupedDates[key];
        const firstDate = dates[0];
        const lastDate = dates[dates.length - 1];
        newOperationDates.push({
          start: dayjs(firstDate),
          end: dayjs(lastDate),
        });
      });
      setOperationDates(newOperationDates);
    }
  }, [availableDates]);

  const handleStartDateChange = useCallback(
    (index: number, date: Dayjs | null) => {
      setOperationDates((prev) => {
        const newOperationDates = [...prev];
        newOperationDates[index].start = date;
        handleOperationDatesChange(newOperationDates);
        return newOperationDates;
      });
    },
    [handleOperationDatesChange]
  );

  const handleEndDateChange = useCallback(
    (index: number, date: Dayjs | null) => {
      setOperationDates((prev) => {
        const newOperationDates = [...prev];
        newOperationDates[index].end = date;
        handleOperationDatesChange(newOperationDates);
        return newOperationDates;
      });
    },
    [handleOperationDatesChange]
  );

  const handleClearOperationDate = useCallback(
    (index: number) => {
      setOperationDates((prev) => {
        const newOperationDates = [...prev];
        newOperationDates.splice(index, 1);
        return newOperationDates;
      });
    },
    [setOperationDates]
  );

  useEffect(() => {
    //  new set
    const uniqueDates = uniqBy(operationDates, (operationDate) => {
      if (operationDate.start && operationDate.end) {
        return (
          operationDate.start.format("YYYY-MM-DD") +
          operationDate.end.format("YYYY-MM-DD")
        );
      }
      return [];
    });
    const isDuplicateDates = uniqueDates.length !== operationDates.length;
    setIsDuplicate(isDuplicateDates);
  }, [operationDates]);
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-4">
        <FormLabelWithDescription
          label={"Park Operation Date"}
          description="Select a date"
        />
      </div>
      <div className="col-span-6 space-y-4">
        <ErrorBanner
          message={isDuplicate ? "Operation date cannot be duplicate" : ""}
        />
        {operationDates.map((date) => (
          <div key={id} className="flex gap-4 items-center">
            <HeadstarttDatepicker
              onChange={(newDate) =>
                handleStartDateChange(operationDates.indexOf(date), newDate)
              }
              value={date.start}
              width={WIDTH}
            />
            <HeadstarttDatepicker
              disabled={date.start === null}
              onChange={(newDate) =>
                handleEndDateChange(operationDates.indexOf(date), newDate)
              }
              disabledDate={(current) =>
                current && current.isBefore(date.start || undefined)
              }
              value={date.end}
              width={WIDTH}
            />
            {operationDates.length > 1 && (
              <RawButton
                onClick={() =>
                  handleClearOperationDate(operationDates.indexOf(date))
                }
                type="text"
              >
                <CircleCross height={24} width={24} />
              </RawButton>
            )}
          </div>
        ))}
        <HeadstarttSmallButton
          type="primary"
          newstyle={{
            padding: "4px 20px",
          }}
          onClick={handleAddOperationDate}
        >
          <div className="flex gap-2">
            <PlusIcon height={18} /> Add more open duration
          </div>
        </HeadstarttSmallButton>
      </div>
    </div>
  );
};

export default ParkOperationDate;
