import { SubActivity } from "core/repository/activity/types";

const ActivitiesListCard = ({ subActivity }: { subActivity: SubActivity }) => {
  return (
    <div className="border p-4 br-16">
      <div className="flex gap-4">
        <img
          src={subActivity.activityImage}
          alt={subActivity.activityTitle}
          loading="lazy"
          className="h-10 w-10 br-4 object-contain"
        />
        <div>
          <h3 className="fs-16 font-medium mb-0">
            {subActivity.activityTitle}
          </h3>
          <p className="fs-16 font-normal mb-0">
            {subActivity.activityDescription}
          </p>
        </div>
      </div>
    </div>
  );
};
const ActivityListCardGroup = ({
  activities,
}: {
  activities: SubActivity[];
}) => {
  return (
    <div className="grid grid-cols-12 gap-8 my-4">
      {activities.map((subActivity) => (
        <div key={subActivity._id} className="md:col-span-6 col-span-12">
          <ActivitiesListCard subActivity={subActivity} />
        </div>
      ))}
    </div>
  );
};

export default ActivityListCardGroup;
