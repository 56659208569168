import { AdminData, SignInAdminPayload } from "core/repository/user/types";
import { FormItem, HeadstarttForm } from "views/components/headstarttForm";
import {
  HeadstarttInput,
  HeadstarttInputPassword,
} from "views/components/headstarttInput";
import { LockClosed, Mail } from "views/components/icons";
import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { HeadstarttButton } from "views/components/headstarttButton";
import { useLocalStorage } from "core/hooks/useLocalStorage";
import useLoginWithEmail from "core/services/authService/hooks/useLoginWithEmail";
import { useNavigate } from "react-router-dom";
import useSignInAdmin from "core/services/admin/login/useSignInAdmin";
import useSignInUser from "core/services/user/useSignInUser";

type SignInWithEmailValues = {
  email: string;
  password: string;
};
type SignInWithEmailProps = {
  isAdmin?: boolean;
  onCancel?: () => void;
};
const SignInWithEmail = ({
  onCancel,
  isAdmin = false,
}: SignInWithEmailProps) => {
  const navigate = useNavigate();
  const [, setAdmin] = useLocalStorage<AdminData>("adminUser", {} as AdminData);

  const { signInUser, loader } = useSignInUser(() => {
    navigate("/");
  });

  const { signInAdmin, adminLoader } = useSignInAdmin(
    (adminData) => {
      setAdmin(adminData);
      successToast("Login successful");
      navigate("/admin/activity/activity-list?page=1");
      window.location.reload();
    },
    () => {
      errorToast("Invalid email or password");
    }
  );

  const { loginWithEmail, loading } = useLoginWithEmail(async (payload) => {
    await signInUser(payload);
    onCancel?.();
  });
  const onSubmit = (values: SignInWithEmailValues) => {
    if (isAdmin) {
      const payload: SignInAdminPayload = {
        email: values.email,
        password: values.password,
        deviceToken: "",
      };
      signInAdmin(payload);
    } else loginWithEmail(values.email, values.password);
  };
  return (
    <div>
      <h2 className="fs-24 font-medium">Sign in</h2>
      <HeadstarttForm onFinish={onSubmit} className="space-y-2">
        <FormItem
          name={"email"}
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <HeadstarttInput
            prefix={<Mail color="#696969" height={24} />}
            type={"email"}
          />
        </FormItem>
        <FormItem
          name={"password"}
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <HeadstarttInputPassword
            prefix={<LockClosed color="#696969" height={24} />}
            type={"password"}
          />
        </FormItem>
        <FormItem>
          <HeadstarttButton
            disabled={loading || loader || adminLoader}
            htmlType="submit"
            type="ghost"
            className="w-full hover: bg-green-800"
          >
            Sign in
          </HeadstarttButton>
        </FormItem>
      </HeadstarttForm>
    </div>
  );
};

export default SignInWithEmail;
