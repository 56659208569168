import { FormInstance, RadioChangeEvent } from "antd";
import { useCallback, useEffect, useState } from "react";

import { CheckboxValueType } from "antd/lib/checkbox/Group";
import FormItem from "antd/lib/form/FormItem";
import FormLabelWithDescription from "views/components/headstarttForm/formLabelWithDescription";
import { HeadstarttCheckbox } from "views/components/headstarttCheckbox";
import { HeadstarttRadio } from "views/components/headstarttRadio";
import InputLabel from "views/components/typography/inputLabel";

const CLOSURE_STATUS = [
  {
    label: "Open",
    value: "OPEN",
  },
  {
    label: "Full Closure",
    value: "FULL_CLOSURE",
  },
  {
    label: "Partial Closure",
    value: "PARTIAL_CLOSURE",
  },
];

const ADDITIONAL_STATUS = [
  {
    label: "Campfire Ban",
    value: "CAMPFIRE_BAN",
  },
  {
    label: "Smoking Ban",
    value: "SMOKING_BAN",
  },
];

const StatusField = ({ form }: { form: FormInstance }) => {
  const [closureValue, setClosureValue] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const [additionalStatus, setAdditionalStatus] = useState<string[]>();

  const handleStatusChange = useCallback(
    (e: RadioChangeEvent) => {
      setClosureValue(e.target.value);
      form.setFieldsValue({ status: e.target.value });
    },
    [form]
  );

  const handleAdditionalStatusChange = useCallback(
    (checkedValues: CheckboxValueType[]) => {
      setAdditionalStatus(checkedValues as string[]);
      form.setFieldsValue({ additionalStatus: checkedValues });
    },
    [form]
  );
  useEffect(() => {
    if (openStatus) {
      setClosureValue("");
    }
    if (closureValue) {
      setOpenStatus(false);
    }
  }, [openStatus, closureValue, additionalStatus, form]);

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-4">
        <FormLabelWithDescription
          label={"Status"}
          description="Select a status"
        />
      </div>
      <div className="col-span-6 space-y-4">
        <div>
          <InputLabel className="text-gray-500">Close status</InputLabel>
          <div className="block">
            <FormItem
              name={"status"}
              rules={[
                {
                  required: true,
                  message: "Please select a status",
                },
              ]}
            >
              <HeadstarttRadio.Group
                onChange={handleStatusChange}
                value={closureValue}
              >
                <div className="flex gap-4 items-start">
                  {CLOSURE_STATUS.map((status) => {
                    return (
                      <HeadstarttRadio
                        key={status.value}
                        value={status.value}
                        checked={closureValue === status.value}
                      >
                        {status.label}
                      </HeadstarttRadio>
                    );
                  })}
                </div>
              </HeadstarttRadio.Group>
            </FormItem>
          </div>
          <div>
            <InputLabel className="text-gray-500">Additional status</InputLabel>

            <FormItem name={"additionalStatus"}>
              <HeadstarttCheckbox.Group
                defaultValue={additionalStatus}
                options={ADDITIONAL_STATUS}
                onChange={handleAdditionalStatusChange}
              />
            </FormItem>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StatusField;
