import {
  RecentlyViewedPayload,
  RecentlyViewedRoot,
} from "core/repository/search/types";
import {
  addRecentlyViewed,
  getRecentlyViewed,
  removeRecentlyViewed,
} from "core/repository/search";
import { useMutation, useQuery } from "react-query";

const LIMIT = 9;
const useRecentlyViewed = (isAuthenticated = false) => {
  const { data, isLoading, isError } = useQuery<RecentlyViewedRoot, Error>(
    ["getRecentlyViewed"],
    async () => {
      const response = await getRecentlyViewed({
        limit: LIMIT,
        offset: 0,
      });
      return response.data;
    },
    {
      enabled: isAuthenticated,
    }
  );

  //   post recently viewed items to the server
  const { mutate: post, isLoading: postLoading } =
    useMutation(addRecentlyViewed);

  // delete recently viewed items from the server
  const { mutate: remove, isLoading: deleteLoading } = useMutation(
    removeRecentlyViewed,
    {
      onSuccess: () => {
        // refresh the recently viewed items
        getRecentlyViewed({
          limit: LIMIT,
          offset: 0,
        });
      },
    }
  );

  return {
    get: {
      data,
      isLoading,
      isError,
    },
    post: {
      create: ({ entityId, entityType }: RecentlyViewedPayload) =>
        post({
          entityId,
          entityType,
        }),
      isLoading: postLoading,
    },
    remove: {
      remove,
      isLoading: deleteLoading,
    },
  };
};

export default useRecentlyViewed;
