import HeadstarttSearchButton from "./headstarttSearchButton";
import SearchModal from "./searchModal/searchModal";
import { useState } from "react";

const SearchInputWithModal = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCancelModal = () => setShowModal(false);
  return (
    <>
      <HeadstarttSearchButton
        onClick={handleShowModal}
        buttonText={
          <p className="fs-20 mb-0 text-white">What are you looking for?</p>
        }
      />
      <SearchModal showModal={showModal} onCancel={handleCancelModal} />
    </>
  );
};

export default SearchInputWithModal;
