import { UnreadAlertCountRoot } from "core/repository/alerts/types";
import { getToken } from "./../authService/index";
import { unreadCountFromRepo } from "core/repository/alerts";
import { useQuery } from "react-query";

const useGetUnreadAlertCount = () => {
  const token = getToken();
  const { data, isLoading, isError } = useQuery<UnreadAlertCountRoot, Error>(
    "unreadAlertCount",
    async () => {
      const response = await unreadCountFromRepo();
      return response.data;
    },
    {
      // Refetch the data every second
      enabled: !!token,
    }
  );

  return {
    unreadCount: data?.data.unreadAlertNotificationsCount,
    isLoading,
    isError,
  };
};

export default useGetUnreadAlertCount;
