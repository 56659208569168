import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { UserGroupIcon } from "@heroicons/react/outline";
import { useSearchContext } from "context/searchContext";
import { FilterTypes } from "core/repository/search/types";
import useGetEventList from "core/services/event/useGetEventList";
import ErrorCard from "views/components/errorCard";
import EventHorizontalCard from "views/components/headstarttCard/eventHorizontalCard";
import HeadstarttPagination from "views/components/headstarttPagination";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import SecondaryHeading from "views/components/typography/secondaryHeading";
import { MarkerProps } from "../listWithMaps/types";
import ExploreSidebar from "./exploreSidebar";

const ExploreEvents = () => {
  const { setType } = useSearchContext();

  useEffect(() => {
    setType(FilterTypes.EVENT);
  }, [setType]);

  const location = useLocation();
  const navigate = useNavigate();
  const { data, isLoading, isError, offset } = useGetEventList();
  const handleChangePage = useCallback(
    (page: number) => {
      navigate(`${location.pathname}?page=${page}`);
    },
    [location.pathname, navigate]
  );
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  const markers: MarkerProps[] =
    data?.data.list.map((item) => ({
      position: [item.locationDetail.latitude, item.locationDetail.longitude],
      label: item.title,
    })) || [];
  return (
    <div className="grid grid-cols-12 gap-12">
      <div className="col-span-3 w-72 fixed z-20 overflow-auto h-full">
        <div className="px-4 pb-24">
          <ExploreSidebar markers={markers} />
        </div>
      </div>
      <div className="col-span-9 w-full ml-72">
        <div className="p-8 bg-white br-16">
          <div className="flex gap-2 items-center mb-8">
            <div className="bg-gray-100 rounded-full h-12 w-12 flex items-center justify-center">
              <UserGroupIcon height={24} />
            </div>
            <SecondaryHeading className="mb-0" heading={"Events"} />
          </div>
          <div className="space-y-8">
            {data?.data.list.map((event) => (
              <EventHorizontalCard key={event._id} event={event} />
            ))}
          </div>
        </div>
        <div className="flex justify-center bg-white my-4 p-3 br-16">
          <HeadstarttPagination
            total={data?.data.total}
            current={offset}
            onChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default ExploreEvents;
