const CoverTextSection = () => {
  return (
    <div className="flex flex-col justify-center items-center text-center mx-auto my-8 w-3/5">
      <h1 className="fs-48 text-white mb-8 font-medium">Welcome</h1>
      <div className="space-y-8">
        <p className="fs-20 lh-28 text-white">
          We are the official charitable partner to BC’s world class park
          system.
        </p>
        <p className="fs-20 lh-28 text-white">
          To help you enjoy your time outside in the magic of nature, this site
          helps you quickly and easily find and book fun activities in parks,
          Including experiences guided by local British Columbians.
        </p>
        <p className="fs-20 text-white">Thank you for keeping BC beautiful!</p>
      </div>
    </div>
  );
};

export default CoverTextSection;
