import { ChevronLeft, ChevronRight } from "../icons";
import { Pagination, PaginationProps } from "antd";

const HeadstarttPagination = (props: PaginationProps) => (
  <Pagination
    defaultCurrent={1}
    showSizeChanger={false}
    itemRender={(page, type, originalElement) => {
      if (type === "prev") {
        return (
          <a>
            <ChevronLeft />
          </a>
        );
      }
      if (type === "next") {
        return (
          <a>
            <ChevronRight color="#006653" />
          </a>
        );
      }
      return originalElement;
    }}
    {...props}
  />
);
export default HeadstarttPagination;
