import { Divider } from "antd";
import { SupportData } from "core/repository/support/types";
import SupportResolveButton from "./supportResolveButton";

const SupportRequestTableRow = ({ list }: { list: SupportData["list"] }) => {
  return (
    <>
      {list.map((support) => (
        <div key={support._id}>
          <div className="grid grid-cols-12 py-1">
            <div className="col-span-3">
              <div className="flex gap-2 items-center">
                <span className="fs-16 font-medium">
                  {support.firstName} {support.lastName}
                </span>
              </div>
            </div>
            <div className="col-span-3">
              <span className="fs-16 font-medium">{support.comment}</span>
            </div>
            <div className="col-span-3">
              <span className="fs-16 font-medium">{support._id}</span>
            </div>
            <div className="col-span-3">
              <SupportResolveButton />
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </>
  );
};

export default SupportRequestTableRow;
