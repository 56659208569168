import SignInModalContent from "./signInModalContent";
import SignInWithEmailModalContent from "./signinWithEmailModalContent";
import { useState } from "react";

type LoginContainerProps = {
  onCancel: () => void;
  onHandleSignUpClick: () => void;
};
const LoginContainer = ({
  onHandleSignUpClick,
  onCancel,
}: LoginContainerProps) => {
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const handleSignInClick = () => setIsSignInOpen(true);

  return (
    <div className="border br-8 px-6 py-4 bg-white drop-shadow-2xl">
      {isSignInOpen ? (
        <SignInWithEmailModalContent
          onEmailModalCancel={() => setIsSignInOpen(false)}
          onCancel={() => {
            onCancel();
            setIsSignInOpen(false);
          }}
          onSignUpClick={onHandleSignUpClick}
        />
      ) : (
        <SignInModalContent
          handleSignInClick={handleSignInClick}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};

export default LoginContainer;
