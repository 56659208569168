import { Link, useNavigate } from "react-router-dom";

import { Activity } from "core/repository/search/types";
import { ActivityCardSmall } from "views/components/headstarttCard/activityCard";
import CardSlider from "views/components/cardSlider";
import { HeadstarttButton } from "views/components/headstarttButton";

const ActivitySlider = ({
  items,
  navigateTo,
}: {
  items: Activity[];
  navigateTo?: string;
}) => {
  const navigate = useNavigate();

  return (
    <CardSlider>
      {items.map((item, i) => {
        return i === items.length - 1 ? (
          <div className="br-16 relative w-52 h-64 mx-2 ">
            <div className="flex flex-col items-center h-full justify-center">
              <HeadstarttButton
                type="ghost"
                onClick={() =>
                  navigate(navigateTo ?? "/explore/activities/?page=1")
                }
              >
                Show more
              </HeadstarttButton>
            </div>
          </div>
        ) : (
          <Link key={item._id} to={`/discover-activities/${item._id}`}>
            <ActivityCardSmall activity={item} />
          </Link>
        );
      })}
    </CardSlider>
  );
};

export default ActivitySlider;
