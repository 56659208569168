import { Bell } from "../../icons";
type HeadstarttNotificationButtonProps = {
  className?: string;
  color?: string;
  fill?: string;
};
const HeadstarttNotificationButton = ({
  className = "bg-white",
  color = "666",
  fill = "",
}: HeadstarttNotificationButtonProps) => (
  <div className={`${className} p-2 br-8`}>
    <Bell fill={fill} color={color} height={28} width={28} />
  </div>
);

export default HeadstarttNotificationButton;
