export const ACTIVITY_RADIO_GROUP = [
  {
    title: "Dog Friendly",
    value: "isDogFriendly",
  },
  {
    title: "Kid Friendly",
    value: "isKidFriendly",
  },
];
