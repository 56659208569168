const CancellationPolicy = ({
  cancellationPolicy,
}: {
  cancellationPolicy: string;
}) => {
  return (
    <div>
      <h2 className="fs-32 font-medium">Cancellation Policy</h2>
      <p>{cancellationPolicy}</p>
    </div>
  );
};

export default CancellationPolicy;
