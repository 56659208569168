export enum Environment {
  DEVELOPMENT = "development",
  PRODUCTION = "production",
}
export enum ContentType {
  JSON = "application/json",
  MULTIPART_FORM_DATA = "multipart/form-data",
}

export const ApiConfig = Object.freeze({
  timeOut: 30000,
  accept: "application/json",
  contentType: ContentType,
  platform: "WEB",
  timezone: new Date().getTimezoneOffset() * 60,
});

export const HttpStatusCode = Object.freeze({
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
});
