import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { BookmarkPayload } from "core/repository/user/types";
import { addBookmark } from "core/repository/user/bookmark";
import { useMutation } from "react-query";

const useAddBookmark = () => {
  const { mutate, isLoading } = useMutation(addBookmark, {
    onSuccess: () => {
      successToast("Added in saved listing");
    },
    onError: () => {
      errorToast("Something went wrong. Please try again later.");
    },
  });
  return {
    add: (payload: BookmarkPayload) => mutate(payload),
    isLoading,
  };
};

export default useAddBookmark;
