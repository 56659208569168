import { HeadstarttRating } from "../headstarttRating";

const CardReviews = ({
  rating = 0,
  reviews = 0,
}: {
  rating?: number;
  reviews?: number;
}) => (
  <>
    <HeadstarttRating disabled value={rating} />
    <div className="flex gap-4">
      <p className="mb-0">
        <span>{rating} /</span>
        <span className="text-gray-500">5.0</span>
      </p>
      <p className="text-gray-500 mb-0">{reviews} reviews</p>
    </div>
  </>
);
export default CardReviews;
