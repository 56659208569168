import {
  BellIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DocumentTextIcon,
  DotsHorizontalIcon,
  DownloadIcon,
  FilterIcon,
  HeartIcon,
  LocationMarkerIcon,
  LockClosedIcon,
  MailIcon,
  MapIcon,
  MenuIcon,
  SearchIcon,
  UserIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  MailIcon as MailIconSolid,
  StatusOnlineIcon,
} from "@heroicons/react/solid";

type IconProps = {
  fill?: string;
};

export const Star = StatusOnlineIcon;
export const Heart = HeartIcon;
export const Search = SearchIcon;
export const Menu = MenuIcon;
export const Cross = XIcon;
export const CircleCross = XCircleIcon;
export const Location = LocationMarkerIcon;
export const ChevronLeft = ChevronLeftIcon;
export const ChevronRight = ChevronRightIcon;
export const ChevronUp = ChevronUpIcon;
export const ChevronDown = ChevronDownIcon;
export const Map = MapIcon;
export const Mail = MailIcon;
export const MailSolid = MailIconSolid;
export const LockClosed = LockClosedIcon;
export const DotsHorizontal = DotsHorizontalIcon;
export const User = UserIcon;
export const Bell = BellIcon;
export const Document = DocumentTextIcon;
export const Download = DownloadIcon;
export const Check = CheckIcon;
export const Filter = FilterIcon;

export const Tree = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9996 12H18.9996L11.9996 2L5.04961 12H6.99961L3.09961 18H10.0196V22H13.9796V18H20.9996L16.9996 12Z"
      fill="#006653"
    />
  </svg>
);

export const NearBy = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.27 6.73L13.03 16.86L11.71 13.44L11.39 12.61L10.57 12.29L7.14 10.96L17.27 6.73ZM21 3L3 10.53V11.51L9.84 14.16L12.48 21H13.46L21 3Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
);

export const PencilIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1467_6606)">
      <path
        d="M18.41 6.29988L17.2 5.08988C16.42 4.30988 15.15 4.30988 14.37 5.08988L11.69 7.76988L3 16.4599V20.4999H7.04L15.78 11.7599L18.41 9.12988C19.2 8.34988 19.2 7.07988 18.41 6.29988ZM6.21 18.4999H5V17.2899L13.66 8.62988L14.87 9.83988L6.21 18.4999ZM11 20.4999L15 16.4999H21V20.4999H11Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1467_6606">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Headphone = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0C4.03 0 0 4.03 0 9V16C0 17.1 0.9 18 2 18H6V10H2V9C2 5.13 5.13 2 9 2C12.87 2 16 5.13 16 9V10H12V18H16C17.1 18 18 17.1 18 16V9C18 4.03 13.97 0 9 0ZM4 12V16H2V12H4ZM16 16H14V12H16V16Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
);

export const Play = ({ fill = "black" }: IconProps) => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0V14L11 7L0 0Z" fill={fill} fillOpacity="0.8" />
  </svg>
);
export const Pause = ({ fill = "black" }: IconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0V14H4V0H0ZM10 0V14H14V0H10Z" fill={fill} fillOpacity="0.8" />
  </svg>
);

export const DownloadTwo = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
);

export const DocumentTwo = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 14H12V16H4V14ZM4 10H12V12H4V10ZM10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM14 18H2V2H9V7H14V18Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
);

export const ParkShield = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.5 41.5502C44.0463 41.5502 45.3 40.2966 45.3 38.7502C45.3 37.2038 44.0463 35.9502 42.5 35.9502C40.9536 35.9502 39.7 37.2038 39.7 38.7502C39.7 40.2966 40.9536 41.5502 42.5 41.5502Z"
      fill="black"
      fillOpacity="0.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.4999 43.75C40.6749 43.75 37.0249 44.65 36.8999 46.45C38.1499 48.225 40.1999 49.375 42.4999 49.375C44.7999 49.375 46.8499 48.225 48.0999 46.45C47.9749 44.65 44.3249 43.75 42.4999 43.75Z"
      fill="black"
      fillOpacity="0.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45 27.725V15.675L26.25 7.5L7.5 15.675V27.95C7.5 39.3 15.5 49.925 26.25 52.5C27.625 52.175 28.95 51.7 30.25 51.125C32.95 54.975 37.425 57.5 42.5 57.5C50.775 57.5 57.5 50.775 57.5 42.5C57.5 35.075 52.1 28.925 45 27.725ZM27.5 42.5C27.5 43.9 27.7 45.275 28.075 46.55C27.475 46.825 26.875 47.1 26.25 47.3C18.325 44.8 12.5 36.7 12.5 27.95V18.95L26.25 12.95L40 18.95V27.725C32.9 28.925 27.5 35.075 27.5 42.5ZM42.5 52.5C36.975 52.5 32.5 48.025 32.5 42.5C32.5 36.975 36.975 32.5 42.5 32.5C48.025 32.5 52.5 36.975 52.5 42.5C52.5 48.025 48.025 52.5 42.5 52.5Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
);

export const Rating = () => (
  <svg
    width="36"
    height="33"
    viewBox="0 0 36 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.3001 11.08L23.6201 10.24L19.8401 1.34C19.1601 -0.28 16.8401 -0.28 16.1601 1.34L12.3801 10.26L2.72005 11.08C0.960054 11.22 0.240054 13.42 1.58005 14.58L8.92005 20.94L6.72005 30.38C6.32005 32.1 8.18005 33.46 9.70005 32.54L18.0001 27.54L26.3001 32.56C27.8201 33.48 29.6801 32.12 29.2801 30.4L27.0801 20.94L34.4201 14.58C35.7601 13.42 35.0601 11.22 33.3001 11.08ZM18.0001 23.8L10.4801 28.34L12.4801 19.78L5.84005 14.02L14.6001 13.26L18.0001 5.2L21.4201 13.28L30.1801 14.04L23.5401 19.8L25.5401 28.36L18.0001 23.8Z"
      fill="#FFC900"
    />
  </svg>
);

export const Camera = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1753_3278)">
      <path
        d="M14.12 4.5L15.95 6.5H20V18.5H4V6.5H8.05L9.88 4.5H14.12ZM15 2.5H9L7.17 4.5H4C2.9 4.5 2 5.4 2 6.5V18.5C2 19.6 2.9 20.5 4 20.5H20C21.1 20.5 22 19.6 22 18.5V6.5C22 5.4 21.1 4.5 20 4.5H16.83L15 2.5ZM12 9.5C13.65 9.5 15 10.85 15 12.5C15 14.15 13.65 15.5 12 15.5C10.35 15.5 9 14.15 9 12.5C9 10.85 10.35 9.5 12 9.5ZM12 7.5C9.24 7.5 7 9.74 7 12.5C7 15.26 9.24 17.5 12 17.5C14.76 17.5 17 15.26 17 12.5C17 9.74 14.76 7.5 12 7.5Z"
        fill="#006653"
      />
    </g>
    <defs>
      <clipPath id="clip0_1753_3278">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const LocationMark = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 8C9.79 8 8 9.79 8 12C8 14.21 9.79 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM20.94 11C20.48 6.83 17.17 3.52 13 3.06V1H11V3.06C6.83 3.52 3.52 6.83 3.06 11H1V13H3.06C3.52 17.17 6.83 20.48 11 20.94V23H13V20.94C17.17 20.48 20.48 17.17 20.94 13H23V11H20.94ZM12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5C15.87 5 19 8.13 19 12C19 15.87 15.87 19 12 19Z"
      fill="#006653"
    />
  </svg>
);

export const MapMark = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 3L20.34 3.03L15 5.1L9 3L3.36 4.9C3.15 4.97 3 5.15 3 5.38V20.5C3 20.78 3.22 21 3.5 21L3.66 20.97L9 18.9L15 21L20.64 19.1C20.85 19.03 21 18.85 21 18.62V3.5C21 3.22 20.78 3 20.5 3ZM10 5.47L14 6.87V18.53L10 17.13V5.47ZM5 6.46L8 5.45V17.15L5 18.31V6.46ZM19 17.54L16 18.55V6.86L19 5.7V17.54Z"
      fill="#006653"
    />
  </svg>
);

export const Indicator = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
      fill="black"
      fillOpacity="0.48"
    />
  </svg>
);

export const AgeIcon = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_440_37325)">
      <g clipPath="url(#clip1_440_37325)">
        <path
          d="M40 10C40 7.225 42.225 5 45 5C47.775 5 50 7.225 50 10C50 12.775 47.775 15 45 15C42.225 15 40 12.775 40 10ZM50 55V40H56.25L49.9 20.925C49.2 18.875 47.3 17.5 45.15 17.5H44.85C42.7 17.5 40.775 18.875 40.1 20.925L37.95 27.375C40.65 28.875 42.5 31.7 42.5 35V55H50ZM31.25 28.75C33.325 28.75 35 27.075 35 25C35 22.925 33.325 21.25 31.25 21.25C29.175 21.25 27.5 22.925 27.5 25C27.5 27.075 29.175 28.75 31.25 28.75ZM13.75 15C16.525 15 18.75 12.775 18.75 10C18.75 7.225 16.525 5 13.75 5C10.975 5 8.75 7.225 8.75 10C8.75 12.775 10.975 15 13.75 15ZM18.75 55V37.5H22.5V22.5C22.5 19.75 20.25 17.5 17.5 17.5H10C7.25 17.5 5 19.75 5 22.5V37.5H8.75V55H18.75ZM35 55V45H37.5V35C37.5 32.95 35.8 31.25 33.75 31.25H28.75C26.7 31.25 25 32.95 25 35V45H27.5V55H35Z"
          fill="black"
          fillOpacity="0.8"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_440_37325">
        <rect width="60" height="60" fill="white" />
      </clipPath>
      <clipPath id="clip1_440_37325">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const GroupSize = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_440_37360)">
      <g clipPath="url(#clip1_440_37360)">
        <path
          d="M40 27.5C44.15 27.5 47.475 24.15 47.475 20C47.475 15.85 44.15 12.5 40 12.5C35.85 12.5 32.5 15.85 32.5 20C32.5 24.15 35.85 27.5 40 27.5ZM20 27.5C24.15 27.5 27.475 24.15 27.475 20C27.475 15.85 24.15 12.5 20 12.5C15.85 12.5 12.5 15.85 12.5 20C12.5 24.15 15.85 27.5 20 27.5ZM20 32.5C14.175 32.5 2.5 35.425 2.5 41.25V45C2.5 46.375 3.625 47.5 5 47.5H35C36.375 47.5 37.5 46.375 37.5 45V41.25C37.5 35.425 25.825 32.5 20 32.5ZM40 32.5C39.275 32.5 38.45 32.55 37.575 32.625C37.625 32.65 37.65 32.7 37.675 32.725C40.525 34.8 42.5 37.575 42.5 41.25V45C42.5 45.875 42.325 46.725 42.05 47.5H55C56.375 47.5 57.5 46.375 57.5 45V41.25C57.5 35.425 45.825 32.5 40 32.5Z"
          fill="black"
          fillOpacity="0.8"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_440_37360">
        <rect width="60" height="60" fill="white" />
      </clipPath>
      <clipPath id="clip1_440_37360">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const PriceIcon = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_440_37406)">
      <g clipPath="url(#clip1_440_37406)">
        <path
          d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5ZM30 50C18.975 50 10 41.025 10 30C10 18.975 18.975 10 30 10C41.025 10 50 18.975 50 30C50 41.025 41.025 50 30 50ZM30.775 27.85C26.35 26.725 24.925 25.5 24.925 23.675C24.925 21.575 26.9 20.1 30.175 20.1C33.625 20.1 34.925 21.75 35.025 24.2H39.3C39.175 20.85 37.125 17.775 33.075 16.775V12.5H27.25V16.725C23.475 17.525 20.45 19.975 20.45 23.75C20.45 28.225 24.175 30.475 29.6 31.775C34.475 32.925 35.45 34.65 35.45 36.45C35.45 37.775 34.475 39.925 30.2 39.925C26.2 39.925 24.625 38.125 24.4 35.825H20.1C20.35 40.075 23.5 42.475 27.25 43.25V47.5H33.1V43.325C36.9 42.6 39.9 40.425 39.925 36.4C39.9 30.9 35.175 29 30.775 27.85V27.85Z"
          fill="black"
          fillOpacity="0.8"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_440_37406">
        <rect width="60" height="60" fill="white" />
      </clipPath>
      <clipPath id="clip1_440_37406">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DurationIcon = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_440_37371)">
      <g clipPath="url(#clip1_440_37371)">
        <path
          d="M29.975 5C16.175 5 5 16.2 5 30C5 43.8 16.175 55 29.975 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 29.975 5ZM30 50C18.95 50 10 41.05 10 30C10 18.95 18.95 10 30 10C41.05 10 50 18.95 50 30C50 41.05 41.05 50 30 50ZM31.25 17.5H27.5V32.5L40.625 40.375L42.5 37.3L31.25 30.625V17.5Z"
          fill="black"
          fillOpacity="0.8"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_440_37371">
        <rect width="60" height="60" fill="white" />
      </clipPath>
      <clipPath id="clip1_440_37371">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Alert = () => (
  <svg
    width="30"
    height="27"
    viewBox="0 0 30 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.705 26.5H26.295C28.605 26.5 30.045 23.995 28.89 22L17.595 2.48502C16.44 0.49002 13.56 0.49002 12.405 2.48502L1.11 22C-0.0450013 23.995 1.395 26.5 3.705 26.5ZM15 16C14.175 16 13.5 15.325 13.5 14.5V11.5C13.5 10.675 14.175 10 15 10C15.825 10 16.5 10.675 16.5 11.5V14.5C16.5 15.325 15.825 16 15 16ZM16.5 22H13.5V19H16.5V22Z"
      fill="#EE7654"
    />
  </svg>
);
