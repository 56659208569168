import { Facility } from "core/repository/activity/types";

type ActivityFacilityCardGroupProps = {
  facilities: Facility[];
};
const ActivityFacilityCard = ({ facility }: { facility: Facility }) => {
  return (
    <div className="border p-4 br-16">
      <div className="flex gap-4">
        <img
          src={facility.facilityImage}
          alt={facility.facilityTitle}
          loading="lazy"
          className="h-10 w-10 br-4 object-contain"
        />
        <div>
          <h3 className="fs-16 font-medium mb-0">{facility.facilityTitle}</h3>
          <p className="fs-16 font-normal mb-0">
            {facility.facilityDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

const ActivityFacilityCardGroup = ({
  facilities,
}: ActivityFacilityCardGroupProps) => {
  return (
    <div className="grid grid-cols-12 gap-12">
      {facilities.map((facility) => (
        <div key={facility._id} className="md:col-span-6 col-span-12">
          <ActivityFacilityCard facility={facility} />
        </div>
      ))}
    </div>
  );
};

export default ActivityFacilityCardGroup;
