import { Divider, RadioChangeEvent } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import FilterHeader from "./filterHeader";
import { HeadstarttRadio } from "../headstarttRadio";
import { useSearchContext } from "context/searchContext";

const MultidayFilter = () => {
  const { filters } = useSearchContext();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const multiDay = searchParams.get("multiDay");

  const [value, setValue] = useState(multiDay || "");
  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      setValue(e.target.value);
      if (e.target.value) {
        searchParams.set("multiDay", e.target.value);
      } else {
        searchParams.delete("multiDay");
      }
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [location.pathname, navigate, searchParams]
  );
  if (!filters?.multiday?.length) return null;
  return (
    <div>
      <FilterHeader
        heading={"Multiday"}
        handleClear={() => {
          setValue("");
          searchParams.delete("multiDay");
          navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
          });
        }}
      />
      <HeadstarttRadio.Group onChange={onChange} value={value}>
        <div className="grid grid-cols-2 space-y-2 items-end">
          {filters?.multiday.map((day) => {
            return (
              <div key={day.value} className="col-span-1">
                <HeadstarttRadio value={day.value}>{day.label}</HeadstarttRadio>
              </div>
            );
          })}
        </div>
      </HeadstarttRadio.Group>
      <Divider />
    </div>
  );
};

export default MultidayFilter;
