import { useLocation, useNavigate } from "react-router-dom";

import { UserDataRoot } from "core/repository/user/types";
import { getUserList } from "./../../repository/user/getUserList";
import { parse } from "query-string";
import { useEffect } from "react";
import { useQuery } from "react-query";

const LIMIT = 12;

const useGetUserList = (redirectToPageParam = true) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = parse(location.search);
  const search = queryParams.search ?? "";
  const pageFromUrl = queryParams.page ?? "1";
  const offset = Number(pageFromUrl);

  const { data, isLoading, isError, refetch } = useQuery<UserDataRoot, Error>(
    ["users", offset, search],
    async () => {
      const { data: responseData } = await getUserList({
        limit: LIMIT,
        offset: offset - 1,
        search: search as string,
      });
      return responseData;
    }
  );
  useEffect(() => {
    if (redirectToPageParam) {
      navigate({
        pathname: location.pathname,
        search: `?page=${offset}`,
      });
    }
  }, [location.pathname, navigate, redirectToPageParam, offset]);

  return {
    data,
    isError,
    isLoading,
    offset,
    refetch,
  };
};

export default useGetUserList;
