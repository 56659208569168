import { Cross } from "views/components/icons";
import GoogleMaps from "views/components/googleMaps";
import HeadstarttPagination from "views/components/headstarttPagination";
import { MarkerProps } from "./types";
import { ReactNode } from "react";

type ListWithMapsProps = {
  onHandleCancel: () => void;
  handlePagination: (page: number) => void;
  children: ReactNode;
  markers: MarkerProps[];
  page: number;
  totalCount: number;
};
const ListWithMaps = ({
  onHandleCancel,
  handlePagination,
  children,
  markers,
  page,
  totalCount,
}: ListWithMapsProps) => {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-4">
        <aside
          className="bg-gray-50 border-b border-gray-200"
          style={{
            minHeight: "600px",
            padding: "24px",
          }}
        >
          <div className="flex items-center justify-end">
            <div
              className="bg-gray-200 p-2 br-4 cursor-pointer"
              onClick={onHandleCancel}
            >
              <Cross height={24} />
            </div>
          </div>
          {children}
          <HeadstarttPagination
            current={page}
            total={totalCount}
            onChange={handlePagination}
          />
        </aside>
      </div>
      <div className="col-span-8">
        {markers.length && (
          <GoogleMaps
            locationDetail={{
              lat: markers[0].position[0],
              lng: markers[0].position[1],
            }}
            markers={markers}
          />
        )}
      </div>
    </div>
  );
};

export default ListWithMaps;
