import CardImage from "./cardImage";
import CardOverlay from "./cardOverlay";
import { DefaultTag } from "../headstarttTag";
import { Destination } from "core/repository/destination/types";
import MutedText from "../typography/mutedText";
import SecondaryHeading from "../typography/secondaryHeading";

type DestinationCardProps = {
  destination: Destination;
};
const DestinationCard = ({ destination }: DestinationCardProps) => {
  return (
    <div className="flex gap-8">
      <img
        src={destination.destinationImages[0]}
        alt={destination.destinationName}
        className="br-16 h-40 w-40 object-cover"
      />
      <div>
        <SecondaryHeading heading={destination.destinationName} />
        <MutedText text={destination.province} />
        <div className="flex gap-2 mt-4">
          <DefaultTag className="font-bold">
            Activities: {destination.totalActivities}
          </DefaultTag>
          <DefaultTag className="font-bold">
            Events: {destination.totalEvents}
          </DefaultTag>
        </div>
      </div>
    </div>
  );
};

export default DestinationCard;

export const DestinationCardSmall = ({
  destination,
}: {
  destination: Destination;
}) => {
  return (
    <div className="br-16 relative w-52 h-64 mx-2">
      <CardImage
        alt={destination.destinationName}
        className="object-cover h-full w-full"
        url={destination.destinationImages[0]}
      />
      <CardOverlay>
        <h2 className="fs-16 lh-28 mb-0">{destination.destinationName}</h2>
        <div className="flex justify-between items-center">
          <p className="text-black opacity-50 mb-0">{destination.province}</p>
        </div>
      </CardOverlay>
    </div>
  );
};
