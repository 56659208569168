type HeadstarttTabsProps = {
  tab: string;
  active: boolean;
  onHandleClick: (tab: string) => void;
};

const HeadstarttTabs = ({
  tab,
  active,
  onHandleClick,
}: HeadstarttTabsProps) => {
  return (
    <div
      onClick={() => onHandleClick(tab)}
      className={`${
        active ? "bg-primary text-white" : "bg-white text-black"
      } p-2 px-4 br-8 fs-20 br-4 cursor-pointer border border-black`}
    >
      {tab}
    </div>
  );
};

export default HeadstarttTabs;
