import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";
import { useLocation, useNavigate } from "react-router-dom";

import AdminCard from "admin/components/adminCard";
import { EVENT_HEADER } from "admin/adminConstants";
import ErrorCard from "views/components/errorCard";
import EventTableRow from "./eventTableRow";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttPagination from "views/components/headstarttPagination";
import { HeadstarttSmallButton } from "views/components/headstarttButton";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import MetaDetailsForm from "views/components/metaDetailsForm";
import PageHeader from "admin/components/pageHeader";
import TableHeader from "admin/components/table/tableHeader";
import { useCallback } from "react";
import useGetEventList from "core/services/event/useGetEventList";
import { useRefreshEvents } from "core/services/event";

const RefreshEventsButton = () => {
  const { mutateAsync, isLoading } = useRefreshEvents(
    () => {
      successToast("Events Refreshed");
    },
    () => {
      errorToast("Error in refreshing events");
    }
  );

  return (
    <HeadstarttSmallButton
      newstyle={{
        backgroundColor: "#006653",
        padding: "0.5rem 1rem",
        color: "white",
      }}
      onClick={() => {
        mutateAsync();
      }}
      disabled={isLoading}
    >
      Refresh Events
    </HeadstarttSmallButton>
  );
};

const AdminEventList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleChangePage = useCallback(
    (page: number) => {
      navigate(`${location.pathname}?page=${page}`);
    },
    [location.pathname, navigate]
  );
  const { data, isLoading, isError, offset } = useGetEventList();
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  if (!data?.data.total) return <>Data Not Found</>;

  return (
    <div>
      <PageHeader heading="Event List" />
      <HeadstarttContainer>
        <div className="py-4">
          <div className="flex justify-end my-4">
            <RefreshEventsButton />
          </div>
          <AdminCard>
            <TableHeader span={3} header={EVENT_HEADER} />
            <EventTableRow list={data?.data.list} />
          </AdminCard>
          <div className="flex justify-center my-4">
            <HeadstarttPagination
              total={data?.data.total}
              current={offset}
              onChange={handleChangePage}
            />
          </div>
        </div>
        <MetaDetailsForm
          type={"event"}
          payload={{
            title: data?.data?.bannerDetails?.title,
            headline: data?.data?.bannerDetails?.headline,
            image: data?.data?.bannerDetails?.image,
          }}
        />
      </HeadstarttContainer>
    </div>
  );
};

export default AdminEventList;
