import { HeadstarttCard } from "views/components/headstarttCard";
type AdminCardProps = {
  children: React.ReactNode;
};
const AdminCard = ({ children }: AdminCardProps) => {
  return (
    <HeadstarttCard
      style={{
        borderRadius: "24px",
        padding: "12px",
      }}
    >
      {children}
    </HeadstarttCard>
  );
};

export default AdminCard;
