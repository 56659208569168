import { createUserWithEmailAndPassword, getAuth } from "core/clients/firebase";
import { useCallback, useState } from "react";

import { errorToast } from "views/components/headstarttNotifications";

const useRegisterWithEmailAndPassword = (
  onSuccess?: (firstName: string, lastName: string) => void,
  onError?: () => void
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const auth = getAuth();
  const registerUserOnFirebase = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string
    ) => {
      setLoading(true);
      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          // Signed in
          onSuccess?.(firstName, lastName);
          // ...
        })
        .catch((err) => {
          const errorMessage = err.message;
          setError(true);
          onError?.();
          errorToast(errorMessage);
          // ..
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [auth, onSuccess, onError]
  );
  return {
    registerUserOnFirebase,
    loading,
    error,
  };
};

export default useRegisterWithEmailAndPassword;
