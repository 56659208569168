import { Activity } from "core/repository/activity/types";
import BannerCard from "views/components/headstarttCard/bannerCard";
import { useNavigate } from "react-router-dom";

type LandingPageCarouselSlideProps = {
  id: string;
  isBookmark: boolean;
  url: string;
  title: string;
  description: string;
  reviews: number;
  ratings: number;
  category: string;
  openStatus?: boolean;
  locationDetail?: Activity["locationDetail"];
  calculatedDistance?: string;
};

const LandingPageCarouselSlide = ({
  id,
  isBookmark,
  url,
  title,
  description,
  reviews,
  ratings,
  openStatus,
  category,
  locationDetail,
  calculatedDistance,
}: LandingPageCarouselSlideProps) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "480px",
        backgroundImage: `url(${url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        borderRadius: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: "6rem",
        cursor: "pointer",
      }}
    >
      <BannerCard
        onClick={() => navigate(`/discover-activities/${id}`)}
        id={id}
        isBookmark={isBookmark}
        reviews={reviews}
        ratings={ratings}
        title={title}
        description={description}
        openStatus={openStatus}
        category={category}
        locationDetail={locationDetail}
        distance={calculatedDistance}
      />
    </div>
  );
};

export default LandingPageCarouselSlide;
