import CardImage from "./cardImage";
import CardOverlay from "./cardOverlay";
import { CurrencyDollarIcon } from "@heroicons/react/outline";
import { DefaultTag } from "../headstarttTag";
import { EventListData } from "core/repository/event/types";
import { HeadstarttRating } from "../headstarttRating";
import styles from "./headstarttCard.module.scss";

type EventCardProps = {
  event?: EventListData["list"][0];
  height?: "medium" | "large";
  className?: string;
};
const EventCard = ({ height = "medium", event, className }: EventCardProps) => {
  return (
    <div
      className={`br-16 relative ${className} h-[${
        height === "medium" ? "292px" : "582px"
      }]`}
    >
      <CardImage
        className={`${
          styles[height === "large" ? "event-card-long" : "event-card"]
        }`}
        alt={event?.title ?? ""}
        url={
          event?.images[0] ??
          "https://images.unsplash.com/photo-1651062108412-36a68f3748dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80"
        }
      />
      <CardOverlay>
        <h2 className="fs-20 lh-28 mb-0">{event?.title ?? "Event"}</h2>
        <DefaultTag
          style={{
            border: "1px solid #006653",
            borderRadius: "99px",
          }}
        >
          <div className="flex items-center gap-1 font-bold">
            <CurrencyDollarIcon height={12} color={"#006653"} />
            <div className="text-primary">{event?.price} CAD</div>
          </div>
        </DefaultTag>
        <HeadstarttRating disabled value={event?.averageRating} />
        <p className="mb-0 text-gray-500">125 results</p>
      </CardOverlay>
    </div>
  );
};

export default EventCard;
