import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Divider } from "antd";
import { useSearchContext } from "context/searchContext";
import { HeadstarttCheckbox } from "../headstarttCheckbox";
import FilterHeader from "./filterHeader";

const CategoryFilter = () => {
  const { filters } = useSearchContext();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const categories = searchParams.get("categories");
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    categories ? categories.split(",") : []
  );

  useEffect(() => {
    if (selectedCategories.length > 0) {
      searchParams.set("categories", selectedCategories.join(","));
    } else {
      searchParams.delete("categories");
    }
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [selectedCategories, location.pathname, navigate, searchParams]);
  if (!filters?.category?.length) return null;
  return (
    <div>
      <FilterHeader
        heading={"Category"}
        handleClear={() => {
          setSelectedCategories([]);
        }}
      />
      {filters?.category.map((category) => (
        <div key={category.value} className="space-y-2">
          <HeadstarttCheckbox
            checked={selectedCategories.includes(category.value)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedCategories([...selectedCategories, category.value]);
              } else {
                setSelectedCategories(
                  selectedCategories.filter((cat) => cat !== category.value)
                );
              }
            }}
          >
            <span className="fs-14 font-medium text-muted">
              {category.label}
            </span>
          </HeadstarttCheckbox>
        </div>
      ))}
      <Divider />
    </div>
  );
};

export default CategoryFilter;
