import { getAuth } from "core/clients/firebase";
import { removeToken } from "./../index";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    removeToken();
    await auth.signOut();
    navigate("/login");
  }, [auth, navigate]);

  return {
    logout,
  };
};

export default useLogout;
