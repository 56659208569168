import {
  addHostToRepo,
  patchHostToRepo,
} from "core/repository/host/getHostFromRepo";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ADMIN_HOST_TABS } from "admin/adminConstants";
import AdminTabsGroup from "admin/components/adminTabsGroup";
import PageHeader from "admin/components/pageHeader";
import useAddUpdateGeneralized from "core/hooks/useAddUpdateGeneralized";
import { HostPayload } from "core/repository/host/types";
import useGetHostById from "core/services/host/useGetHostById";
import { parse } from "query-string";
import ErrorCard from "views/components/errorCard";
import { HeadstarttCard } from "views/components/headstarttCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import AssignedEventsForHost from "./assignedEventsForHost";
import ContactDetailsForm from "./contactDetailsForm";
import NameAndDescriptionForm from "./nameAndDescriptionForm";

const AdminHostForm = () => {
  const location = useLocation();
  const { host, loader, error, hostId: id } = useGetHostById();

  const navigate = useNavigate();
  const params = parse(useLocation().search);
  const [payload, setPayload] = useState<HostPayload>({} as HostPayload);
  const { addUpdate, mutationLoading } = useAddUpdateGeneralized<HostPayload>(
    addHostToRepo,
    patchHostToRepo,
    id
  );

  useEffect(() => {
    if (host && !loader) {
      setPayload({
        firstName: host.firstName,
        lastName: host.lastName,
        introduction: host.introduction,
        email: host.email,
        website: host.website,
        instagram: host.instagram,
        profilePicture: host.profilePicture,
      });
    }
  }, [host, loader]);

  const onFinish = useCallback(
    async (values: Record<string, string>) => {
      const newPayload = {
        ...payload,
        ...values,
      };
      if (newPayload) {
        await addUpdate(newPayload);
        if (!id) {
          navigate("/admin/host/host-list");
        } else {
          navigate({
            pathname: location.pathname,
            search: `?tab=${
              encodeURIComponent(
                ADMIN_HOST_TABS[ADMIN_HOST_TABS.length - 1]
              ) as string
            }`,
          });
        }
      }
    },
    [addUpdate, payload, id, navigate, location.pathname]
  );

  const onNext = useCallback(() => {
    // index of the filer tab
    const index = ADMIN_HOST_TABS.findIndex((tab) => tab === params.tab);
    if (index !== -1) {
      const nextTab = ADMIN_HOST_TABS[index + 1];
      if (nextTab) {
        navigate({
          pathname: location.pathname,
          search: `?tab=${encodeURIComponent(nextTab) as string}`,
        });
      }
    }
  }, [location.pathname, navigate, params.tab]);

  const onBack = useCallback(() => {
    // index of the filer tab
    const index = ADMIN_HOST_TABS.findIndex((tab) => tab === params.tab);
    if (index !== -1) {
      const backTab = ADMIN_HOST_TABS[index - 1];
      if (backTab) {
        navigate({
          pathname: location.pathname,
          search: `?tab=${encodeURIComponent(backTab) as string}`,
        });
      }
    }
  }, [location.pathname, navigate, params.tab]);
  const setPayloadForHost = useCallback(
    (values: HostPayload) => {
      const hostPayload = {
        ...payload,
        ...values,
      };
      if (id) {
        hostPayload.hostId = id;
      }
      setPayload(hostPayload);
    },
    [id, payload]
  );

  const getView = () => {
    if (id && !loader && !Object.keys(payload).length)
      return <div>No Host Found</div>;
    switch (params.tab) {
      case ADMIN_HOST_TABS[0]:
      default:
        return (
          <NameAndDescriptionForm
            payload={payload}
            setPayload={setPayloadForHost}
            onNext={onNext}
          />
        );
      case ADMIN_HOST_TABS[1]:
        return (
          <ContactDetailsForm
            loading={mutationLoading}
            setPayload={setPayloadForHost}
            payload={payload}
            onSubmit={onFinish}
            onBack={onBack}
          />
        );
      case ADMIN_HOST_TABS[2]:
        return id ? (
          <AssignedEventsForHost id={id} />
        ) : (
          "Come From Edit Section"
        );
    }
  };

  if (loader) return <LoaderFullScreen />;
  if (error) return <ErrorCard />;

  return (
    <div>
      <PageHeader heading="Edit Host" />
      <HeadstarttContainer>
        <div className="py-4">
          <AdminTabsGroup tabs={ADMIN_HOST_TABS} />
          <HeadstarttCard className="-mt-1">
            <h1 className="fs-18 font-light  border-b mb-4">{params.tab}</h1>
            <div className="p-2">{getView()}</div>
          </HeadstarttCard>
        </div>
      </HeadstarttContainer>
    </div>
  );
};

export default AdminHostForm;
