import {
  BucketName,
  UploadFilesResponseRoot,
} from "core/repository/uploadFiles/types";
import { Upload, UploadProps } from "antd";
import { errorToast, successToast } from "../headstarttNotifications";

import useUploadFile from "core/services/uploadFiles/useUploadFile";

const { Dragger } = Upload;
type HeadstarttUploaderProps = UploadProps & {
  buttonView?: "simple" | "dragger";
  onSuccess: (res: UploadFilesResponseRoot["data"]) => void;
};
const HeadstarttUploader = (props: HeadstarttUploaderProps) => {
  const { postData, loading } = useUploadFile(props.onSuccess);
  const restProps: UploadProps = {
    ...props,
    name: "file",
    multiple: true,
    beforeUpload: (file: File) => {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "audio/mpeg" ||
        file.type === "audio/mp3" ||
        file.type === "application/pdf";
      if (!isJpgOrPng) {
        errorToast("You can only upload JPG/PNG file!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        errorToast("Image must smaller than 2MB!");
        return false;
      }
      successToast("Uploading...");
      return true;
    },

    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        successToast(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        errorToast(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: async (options) => {
      const formData = new FormData();
      formData.append("bucketName", BucketName.ActivityReviewImages);
      formData.append("mediaFiles", options.file);
      await postData(formData);
      options?.onSuccess?.("Ok");
    },
    disabled: loading,
  };

  if (props.buttonView === "simple") {
    return <Upload {...restProps}>{props.children}</Upload>;
  }
  return (
    <Dragger {...restProps}>
      {props.children ? (
        props.children
      ) : (
        <>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
        </>
      )}
    </Dragger>
  );
};

export default HeadstarttUploader;
