import AdminButton from "admin/components/adminButton";
import AdminEventForm from "./adminEventForm";
import { Divider } from "antd";
import { EventListData } from "core/repository/event/types";
import { HeadstarttModal } from "views/components/headstarttModal";
import { HeadstarttSmallButton } from "views/components/headstarttButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const EventTableRow = ({ list }: { list: EventListData["list"] }) => {
  const host = window.location.host.includes("localhost")
    ? "http://" + window.location.host
    : window.location.host;
  const navigate = useNavigate();
  const [eventId, setSelectedEventId] = useState<string>("");
  return (
    <>
      {list.map((event) => (
        <div key={event._id}>
          <div className="grid grid-cols-12 py-1">
            <div className="col-span-3">
              <a
                href={host + "/discover-events/" + event._id}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="fs-16 font-medium">{event.title}</span>
              </a>
            </div>
            <div className="col-span-3">
              <span className="fs-16 font-medium">
                {event?.hostDetail?.profilePicture ? (
                  <img
                    src={event?.hostDetail?.profilePicture}
                    alt={event?.hostDetail?.firstName}
                    className={`rounded-full pointer flex items-center justify-center h-10 w-10 cursor-pointer object-cover`}
                  />
                ) : (
                  <div
                    className={`fs-16 font-medium h-10 w-10 rounded-full flex items-center justify-center cursor-pointer bg-gray-200 text-gray-500`}
                  >
                    {event?.hostDetail?.firstName.charAt(0)}{" "}
                    {event?.hostDetail?.lastName.charAt(0)}
                  </div>
                )}
                <span className="fs-16 font-medium">
                  {event?.hostDetail?.firstName} {event?.hostDetail?.lastName}
                </span>
              </span>
            </div>
            <div className="col-span-3">
              <AdminButton
                disabled={!event.totalPendingReviews}
                text={`${event.totalPendingReviews} Pending Reviews`}
                onClick={() =>
                  navigate(`/admin/event/pending-reviews/${event._id}`)
                }
              />
            </div>
            <div className="col-span-3">
              <HeadstarttSmallButton
                onClick={() => {
                  setSelectedEventId(event._id);
                }}
              >
                Update Event
              </HeadstarttSmallButton>
              <HeadstarttModal
                footer={null}
                onCancel={() => {
                  setSelectedEventId("");
                }}
                width={1000}
                open={eventId === event._id}
              >
                <h1 className="fs-24">
                  Update Event: {event.title} - {event._id}
                </h1>
                <Divider />
                <AdminEventForm
                  eventPayload={{
                    eventId: event._id,
                    orcs: event.orcs,
                    hostId: event.hostDetail._id,
                    destinationId: event.destinationId,
                  }}
                  onComplete={() => {
                    setSelectedEventId("");
                  }}
                />
              </HeadstarttModal>
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </>
  );
};

export default EventTableRow;
