import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";
import { useCallback, useState } from "react";

import { ReviewBody } from "core/repository/reviews/types";
import { createReviewOnRepo } from "core/repository/reviews/createReviewOnRepo";

const useCreateReview = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const postData = useCallback(async (payload: ReviewBody) => {
    setLoading(true);
    setError(undefined);
    try {
      await createReviewOnRepo(payload);
      successToast("Review added successfully");
    } catch (err) {
      setError(err as Error);
      errorToast("Something went wrong");
    }
    setLoading(false);
  }, []);

  return { loading, error, postData };
};

export default useCreateReview;
