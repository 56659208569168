import { ReactNode } from "react";

type HeadstarttContainerProps = {
  children: ReactNode;
};
const HeadstarttContainer = ({ children }: HeadstarttContainerProps) => {
  return <div className="container mx-auto px-4">{children}</div>;
};

export default HeadstarttContainer;
