import { HeadstarttCheckbox } from "views/components/headstarttCheckbox";
import { Link } from "react-router-dom";
type TermsAndConditionCheckboxProps = {
  onHandleCheckbox: (checked: boolean) => void;
  value: boolean;
};
const TermsAndConditionCheckbox = ({
  onHandleCheckbox,
  value,
}: TermsAndConditionCheckboxProps) => {
  return (
    <div className="flex gap-2 items-center mb-8">
      <HeadstarttCheckbox
        value={value}
        checked={value}
        onChange={(e) => onHandleCheckbox(e.target.checked)}
      />
      <p className="font-medium fs-16 mb-0">
        I agree to Discover Park’s{" "}
        <Link to="/terms-and-services">Terms and Services</Link>
      </p>
    </div>
  );
};

export default TermsAndConditionCheckbox;
