import { useLocation, useNavigate } from "react-router-dom";

import { getProjectsFromRepo } from "core/repository/projects";
import { ProjectsRoot } from "core/repository/projects/types";
import queryString from "query-string";
import { useEffect } from "react";
import { useQuery } from "react-query";

const LIMIT = 10;
const useGetProject = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const offset = Number(params.page) || 1;
  const search = params.search || "";

  const navigate = useNavigate();

  const { data, status, refetch } = useQuery<ProjectsRoot, Error>(
    ["projects", offset, search],
    async () => {
      const { data: responseData } = await getProjectsFromRepo({
        limit: LIMIT,
        offset: offset - 1,
        search: search as string,
      });
      return responseData;
    }
  );

  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: `?page=${offset}${search ? `&search=${search}` : ""}`,
    });
  }, [navigate, offset, location.pathname, search]);

  return {
    data,
    isLoading: status === "loading",
    isError: status === "error",
    offset,
    refetch,
  };
};

export default useGetProject;
