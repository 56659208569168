import { DiscoverAppLogo, DiscoverAppLogoDark } from "../icons/discoverAppLogo";
import { Link, useLocation } from "react-router-dom";
import { Menu, Search } from "../icons";
import { useContext, useEffect, useState } from "react";

import AlertButton from "../alertButton/alertButton";
import { AppContext } from "context/appContext";
import { HeadstarttDropdown } from "../headstarttDropdown";
import MemoisedProfileDropdownOverlay from "./profileDropdownOverlay";
import { getAuth } from "core/clients/firebase";
import useGetUser from "core/services/user/useGetUser";

type HeadstarttAppNavbarProps = {
  handleSearchClick: () => void;
  handleSignInClick: () => void;
  handleSignUpClick: () => void;
  mode?: "light" | "dark";
};
const HeadstarttAppNavbar = ({
  handleSearchClick,
  handleSignInClick,
  handleSignUpClick,
  mode = "light",
}: HeadstarttAppNavbarProps) => {
  const { user: sessionUser } = useGetUser();
  const auth = getAuth();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const { loading, isAuthenticated } = useContext(AppContext);
  // on scroll change the color of the navbar
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarClassBasedOnMode =
    mode === "light"
      ? "bg-white opacity-80 px-2 sm:px-4 py-2.5 fixed z-50 left-0 right-0 text-black border-b"
      : `${
          isScrolled
            ? "bg-gray"
            : location.pathname === "/"
            ? "bg-transparent"
            : "bg-gray"
        } border-gray-200  px-2 sm:px-4 py-2.5 fixed z-50 left-0 right-0 transition-all duration-200 ease-in-out text-white
  `;
  const navbarLinkText = mode === "dark" ? "text-white" : "text-black";
  const navbarLinkActiveClassBasedOnMode = `after:content['*'] after:h-1 after:w-4 after:bg-green-500 after:rounded-lg after:absolute after:-bottom-2 after:left-0 after:right-0 after:mx-auto after:transform translate-y-1`;
  if (loading) return <></>;
  return (
    <nav className={navbarClassBasedOnMode} id={"navbarRef"}>
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <Link to="/" className="flex items-center">
          {mode === "light" ? <DiscoverAppLogoDark /> : <DiscoverAppLogo />}
        </Link>
        {isAuthenticated ? (
          <div className="flex md:order-2 gap-4 items-center">
            <AlertButton mode={mode} />
            <HeadstarttDropdown
              overlay={<MemoisedProfileDropdownOverlay />}
              placement="bottomRight"
            >
              {sessionUser?.profilePicture ? (
                <img
                  src={sessionUser.profilePicture}
                  alt={sessionUser.firstName}
                  className={`rounded-full pointer flex items-center justify-center ${
                    mode === "light" ? "bg-gray-800" : "bg-white"
                  } h-10 w-10 cursor-pointer object-cover`}
                />
              ) : (
                <div
                  className={`rounded-full pointer flex items-center justify-center ${
                    mode === "light" ? "bg-gray-800" : "bg-white"
                  } h-10 w-10 cursor-pointer`}
                >
                  <span
                    className={`${
                      mode === "light" ? "text-white" : "text-black"
                    } fs-16 font-medium`}
                  >
                    {auth.currentUser?.displayName?.split("")[0][0]}
                  </span>
                </div>
              )}
            </HeadstarttDropdown>
          </div>
        ) : (
          <div className="flex md:order-2 gap-4">
            <button
              onClick={handleSignInClick}
              type="button"
              className="hover:text-green-400 transition duration-200 ease-in-out font-medium"
            >
              Sign in
            </button>
            <button
              onClick={handleSignUpClick}
              type="button"
              className={`border ${
                mode === "light" ? "border-gray-200" : "border-white"
              } br-24 rounded-lg text-sm px-5 py-2.5 text-center mr-2 md:mr-0 hover:bg-green-800 ${
                mode === "light" ? "hover:text-white" : ""
              } transition duration-200 ease-in-out font-medium`}
            >
              Sign up
            </button>
            <button
              data-collapse-toggle="mobile-menu-4"
              type="button"
              className={`inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600`}
              aria-controls="mobile-menu-4"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <Menu width={24} />
            </button>
          </div>
        )}
        <div
          className="hidden justify-between items-center w-full md:flex md:w-auto md:order-1"
          id="mobile-menu-4"
        >
          <ul className="flex flex-col md:flex-row md:space-x-16 md:mt-0 md:text-sm md:font-medium mb-0">
            <li
              className="flex gap-2 items-center cursor-pointer hover:text-green-400 transition duration-200 ease-in-out"
              onClick={handleSearchClick}
            >
              <Search height={18} width={18} />
              Search
            </li>
            <li className="relative">
              <Link
                className={`hover:text-green-500  ${
                  location.pathname.includes("/discover-activities")
                    ? navbarLinkActiveClassBasedOnMode
                    : ""
                } transition duration-200 ease-in-out ${navbarLinkText}`}
                to="/discover-activities"
              >
                Activity
              </Link>
            </li>
            <li className="relative">
              <Link
                className={`hover:text-green-500  ${
                  location.pathname.includes("/discover-events")
                    ? navbarLinkActiveClassBasedOnMode
                    : ""
                } transition duration-200 ease-in-out ${navbarLinkText}`}
                to="/discover-events"
              >
                Events
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default HeadstarttAppNavbar;
