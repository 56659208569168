import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { deleteAlertFromRepo } from "core/repository/alerts";
import { useMutation } from "react-query";

const useRemoveAlert = () => {
  const { mutate, isLoading } = useMutation(deleteAlertFromRepo, {
    onSuccess: () => {
      successToast("Notification deleted successfully!");
    },
    onError: () => {
      errorToast("Something went wrong. Please try again later.");
    },
  });
  return {
    removeAlert: async (id: string) => mutate(id),
    isLoading,
  };
};

export default useRemoveAlert;
