import { EventData, ImportantNotice } from "../event/types";

export type SearchParams = {
  limit?: number;
  offset?: number;
  search?: string;
  latitude?: string;
  longitude?: string;
  trailDifficulties?: string;
  categories?: string;
  rating?: string;
  minDuration?: string;
  maxDuration?: string;
  hasImage?: string;
  multiDay?: string;
  minPrice?: string;
  maxPrice?: string;
  ages?: string;
  groups?: string;
  activityTypes?: string;
  status?: string;
  additionalStatus?: string;
  subActivities?: string;
  facilities?: string;
  destinationId?: string;
  hostID?: string;
};

export interface ExploreAllRoot {
  data: Explore;
  msg: string;
  statusCode: number;
}

export interface Explore {
  destinationsList: DestinationsList;
  eventsList: EventsList;
  activitiesList: ActivitiesList;
  projectsList: ProjectsList;
}

export interface DestinationsList {
  list: Destination[];
  total: number;
}

export interface Destination {
  _id: string;
  destinationName: string;
  destinationDescription: string;
  province: string;
  destinationImages: string[];
  createdDate: string;
  totalActivities: number;
  totalEvents: number;
}

export interface EventsList {
  list: EventData[];
  total: number;
}

export interface Event {
  _id: string;
  destinationId: string;
  isActivity: boolean;
  title: string;
  description: string;
  images: string[];
  category: string;
  createdDate: string;
  updatedDate: string;
  operationDate: EventOperationDate;
  locationDetail: EventLocationDetail;
  price: number;
  priceDescription: string;
  averageRating: number;
  totalReviews: number;
  isBookmark: boolean;
}

export interface EventOperationDate {
  _id: string;
  activityEventId: string;
  campgroundOpenMonths: number[];
  createdDate: string;
  openMonths: number[];
  operationDateDescription: string;
  updatedDate: string;
}

export interface EventLocationDetail {
  _id: string;
  activityEventId: string;
  createdDate: string;
  latitude: number;
  locationDescription: string;
  longitude: number;
  updatedDate: string;
  location: EventLocation;
}

export interface EventLocation {
  type: string;
  coordinates: number[];
}

export interface ActivitiesList {
  list: Activity[];
  total: number;
}

export interface Activity {
  _id: string;
  destinationId: string;
  isActivity: boolean;
  title: string;
  description: string;
  images: string[];
  category: string;
  createdDate: Date;
  updatedDate: Date;
  operationDate: ActivityOperationDate;
  locationDetail: ActivityLocationDetail;
  averageRating: number;
  totalReviews: number;
  isBookmark: boolean;
  totalPendingReviews: number;
  status: string;
  additionalStatus: string[];
  isPopular: boolean;
  importantNotice: ImportantNotice[];
}

export interface ActivityOperationDate {
  _id: string;
  activityEventId: string;
  campgroundOpenMonths: number[];
  createdDate: Date;
  openMonths: number[];
  availableDates: string[];
  operationDateDescription: string;
  updatedDate: Date;
}

export interface ActivityLocationDetail {
  _id: string;
  activityEventId: string;
  createdDate: Date;
  latitude: number;
  locationDescription: string;
  longitude: number;
  updatedDate: Date;
  location: ActivityLocation;
}

export interface ActivityLocation {
  type: string;
  coordinates: number[];
}

export interface ProjectsList {
  list: Project[];
  total: number;
}

export interface Project {
  _id: string;
  projectName: string;
  projectHeadline: string;
  projectDescription: string;
  projectImage: string;
  redirectUrl: string;
  isDeleted: boolean;
  createdDate: string;
  updatedDate: string;
  projectId?: string;
}

export type SuggestionType = "EVENT" | "ACTIVITY" | "DESTINATION" | "PROJECT";

export interface SuggestionsRoot {
  data: Suggestions;
  msg: string;
  statusCode: number;
}

export interface Suggestions {
  list: Suggestion[];
  total: number;
}

export interface Suggestion {
  _id: string;
  title: string;
  subTitle: string;
  description: string;
  createdDate: string;
  type: SuggestionType;
}

export type RecentlyViewedPayload = {
  entityId: string;
  entityType: SuggestionType;
};

export interface RecentlyViewedRoot {
  data: RecentlyViewedData;
  msg: string;
  statusCode: number;
}

export interface RecentlyViewedData {
  list: RecentlyViewed[];
  total: number;
}

export interface RecentlyViewed {
  _id: string;
  entityId: string;
  entityType: string;
  createdDate: string;
  title: string;
  subTitle: string;
}
export enum FilterDifficulty {
  EASY = "EASY",
  MODERATE = "MODERATE",
  DIFFICULT = "DIFFICULT",
}

export enum FilterTypes {
  ALL = "ALL",
  EVENT = "EVENT",
  ACTIVITY = "ACTIVITY",
}

export type FiltersForTypes = {
  type: FilterTypes;
};

// Filters

export interface FiltersRoot {
  data: FiltersData;
  msg: string;
  statusCode: number;
}

export interface FiltersData {
  ratings: Rating[];
  hasImage: HasImage[];
  difficulty: Difficulty[];
  activityTypes: ActivityType[];
  status: Status[];
  additionalStatus: AdditionalStatu[];
  subActivities: SubActivity[];
  facilities: Facility[];
  audioOrPdf: AudioOrPdf[];
  duration: Duration[];
  priceType: PriceType[];
  category: Category[];
  multiday: Multiday[];
  groups: Group[];
  ages: Age[];
}

export interface Rating {
  label: string;
  value: number;
}

export interface HasImage {
  label: string;
  value: boolean;
}

export interface Difficulty {
  label: string;
  value: FilterDifficulty;
}

export interface ActivityType {
  label: string;
  value: string;
}

export interface Status {
  label: string;
  value: string;
}

export interface AdditionalStatu {
  label: string;
  value: string;
}

export interface SubActivity {
  label: string;
  value: string;
}

export interface Facility {
  label: string;
  value: string;
}

export interface AudioOrPdf {
  label: string;
  value: string;
}

export interface Duration {
  label: string;
  value: number;
}

export interface PriceType {
  label: string;
  value: number;
}

export interface Category {
  label: string;
  value: string;
}

export interface Multiday {
  label: string;
  value: string;
}

export interface Group {
  label: string;
  value: string;
}

export interface Age {
  label: string;
  value: string;
}
