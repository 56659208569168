import EventCard from "views/components/headstarttCard/eventCard";
import { EventData } from "core/repository/event/types";
import { Link } from "react-router-dom";

const EventList = ({ events }: { events: EventData[] }) => (
  <div className="grid md:grid-cols-4 grid-cols-1 md:gap-8 gap-2">
    {events.map((event) => (
      <Link key={event._id} to={`/discover-events/${event._id}`}>
        <EventCard event={event} />
      </Link>
    ))}
  </div>
);

export default EventList;
