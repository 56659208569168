type FormLabelWithDescriptionProps = {
  label: string;
  description?: string;
};

const FormLabelWithDescription = ({
  label,
  description,
}: FormLabelWithDescriptionProps) => {
  return (
    <div>
      <div className="fs-20 font-normal">{label}</div>
      {description && (
        <div className="fs-14 font-light text-gray-500">{description}</div>
      )}
    </div>
  );
};

export default FormLabelWithDescription;
