import { getAuth, signInWithEmailAndPassword } from "core/clients/firebase";
import { useCallback, useState } from "react";

import { SignInPayload } from "core/repository/user/types";
import { errorToast } from "views/components/headstarttNotifications";

const useLoginWithEmail = (
  onSuccess?: (payload: SignInPayload) => void,
  onError?: () => void
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const auth = getAuth();
  const loginWithEmail = useCallback(
    async (email: string, password: string) => {
      setLoading(true);
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          // get accessToken
          const accessToken = await user.getIdTokenResult();
          if (user) {
            const payload: SignInPayload = {
              socialId: user.uid,
              loginType: "EMAIL",
              deviceToken: "",
              accessToken: accessToken.token,
            };

            onSuccess?.(payload);
          }
        })
        .catch((err) => {
          const errorMessage = err.message;
          errorToast(errorMessage);
          setError(true);
          onError?.();
          // ..
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [auth, onSuccess, onError]
  );
  return {
    loginWithEmail,
    loading,
    error,
  };
};

export default useLoginWithEmail;
