import { HeadstarttCard } from "views/components/headstarttCard";
import SignInWithEmail from "views/layouts/core/components/signInWithEmail";

const AdminLogin = () => {
  return (
    <div className="container py-24 mx-auto">
      <h1 className="text-center text-3xl">Admin Login</h1>
      <div className="grid grid-cols-12">
        <div className="col-span-6 col-start-4">
          <HeadstarttCard>
            <SignInWithEmail isAdmin />
          </HeadstarttCard>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
