import { useCallback, useState } from "react";

import { AlertsRoot } from "core/repository/alerts/types";
import { getAlertsFromRepo } from "core/repository/alerts";
import { useQuery } from "react-query";

const LIMIT = 12;
const useGetAlerts = () => {
  const [page, setPage] = useState(1);
  const {
    data: alerts,
    isLoading,
    isError,
    refetch,
  } = useQuery<AlertsRoot, Error>(["alerts", page], async () => {
    const { data } = await getAlertsFromRepo({
      limit: LIMIT,
      offset: page - 1,
    });
    return data;
  });

  const handlePagination = useCallback((currentPage: number) => {
    setPage(currentPage);
  }, []);

  return {
    alerts: alerts?.data,
    loader: isLoading,
    error: isError,
    setPage,
    page,
    getAlerts: refetch,
    handlePagination,
  };
};

export default useGetAlerts;
