import {
  HeadstarttCarousel,
  HeadstarttCarouselRef,
} from "../headstarttCarousel";

import { EventData } from "core/repository/event/types";
import { useRef } from "react";
import { Link } from "react-router-dom";
import HeadstarttLikeButton from "../headstarttButton/headstarttLikeButton";
import CarouselWrapperWithIndicators from "../headstarttCarousel/carouselWrapperWithIndicators";
import HeadstarttReadMoreLess from "../headstarttReadMoreLess";
import CardReviews from "./cardReviews";
import CardTag from "./cardTag";

const EventHorizontalCard = ({ event }: { event: EventData }) => {
  const carouseNodeRef = useRef<HeadstarttCarouselRef | null>(null);
  const next = () => {
    if (carouseNodeRef?.current) carouseNodeRef.current.next();
  };
  const previous = () => {
    if (carouseNodeRef?.current) carouseNodeRef.current.prev();
  };

  return (
    <div className="grid grid-cols-12 gap-8 my-8">
      <div className="col-span-4">
        <CarouselWrapperWithIndicators
          previous={previous}
          next={next}
          carousel={
            <HeadstarttCarousel
              ref={(node) => (carouseNodeRef.current = node)}
              effect="fade"
              autoplay={false}
              dots={false}
            >
              {event.images.map((item) => (
                <div key={item} className="relative">
                  <img
                    className="w-full h-40 br-16 object-cover select-none"
                    src={item}
                    loading="lazy"
                    alt={event.title}
                  />
                </div>
              ))}
            </HeadstarttCarousel>
          }
        />
      </div>
      <div className="col-span-7">
        <Link to={`/discover-events/${event._id}`}>
          <h2 className="fs-24">{event.title}</h2>
          <HeadstarttReadMoreLess text={event.description} />
          <div className="flex gap-2 mb-4">
            <CardTag text={`$${event.price}CAD`} />
            <CardTag text={`${event.category}`} />
          </div>
          <CardReviews
            rating={event.averageRating}
            reviews={event.totalReviews}
          />
        </Link>
      </div>
      <div className="col-span-1">
        <HeadstarttLikeButton
          id={event._id}
          type="EVENT"
          isBookmark={event.isBookmark}
          className="bg-gray-300 h-12 w-12 mx-auto flex items-center justify-center"
        />
      </div>
    </div>
  );
};

export default EventHorizontalCard;
