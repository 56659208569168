import { DotsHorizontal, MailSolid } from "views/components/icons";

import CardReviews from "views/components/headstarttCard/cardReviews";
import { DefaultTag } from "views/components/headstarttTag";
import ErrorCard from "views/components/errorCard";
import EventCard from "views/components/headstarttCard/eventCard";
import GroupByRatings from "views/components/groupByRatings";
import { HeadstarttButton } from "views/components/headstarttButton";
import HeadstarttContainer from "views/components/headstarttContainer";
import { HeadstarttDropdown } from "views/components/headstarttDropdown";
import HeadstarttLikeButton from "views/components/headstarttButton/headstarttLikeButton";
import HeadstarttPagination from "views/components/headstarttPagination";
import { Link } from "react-router-dom";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PopularHeadingGroup from "views/components/typography/popularHeading";
import ReviewCard from "views/components/headstarttCard/reviewCard";
import SearchReviewInput from "../activities/activityDetails/activityReviews/searchReviewInput";
import { dayjsClient } from "core/clients/dayjs";
import useGetHostById from "core/services/host/useGetHostById";

const AboutTheHostPage = () => {
  const { host, loader, error, hostId } = useGetHostById();
  if (loader) return <LoaderFullScreen />;
  if (error) return <ErrorCard />;

  return (
    <HeadstarttContainer>
      <div className="grid grid-cols-12 gap-24 py-24">
        <div className="md:col-span-3 col-span-12">
          <img
            className="h-48 w-48 rounded-full object-cover"
            src={host?.profilePicture}
            alt={host?.firstName}
          />
        </div>
        <div className="md:col-span-9 col-span-12 py-8">
          <div className="flex justify-between items-start">
            <div>
              <p className="fs-24 font-medium mb-0">
                {host?.firstName} {host?.lastName}
              </p>
              <p className="text-muted">
                Member since {dayjsClient(host?.createdDate).format("YYYY")}
              </p>
              <div className="my-8" />
              <div className="flex gap-8">
                <p className="text-gray-800 font-medium mb-0">
                  {host?.reviewsList.list.length} reviews
                </p>
              </div>
            </div>
            <div className="flex gap-2">
              <HeadstarttLikeButton
                id={hostId}
                isBookmark={host?.isHostBookmark}
                type="HOST"
                className="bg-gray-200"
              />
              <HeadstarttDropdown overlay={<></>} placement="bottomRight">
                <div className="bg-gray-200 p-2 br-8 cursor-pointer">
                  <DotsHorizontal height={24} />
                </div>
              </HeadstarttDropdown>
            </div>
          </div>
          <div className="flex my-8">
            <DefaultTag>
              <span className="font-medium">
                <a rel="noopener noreferrer" href={`mailto:${host?.email}`}>
                  <div className="flex gap-1 items-center">
                    <MailSolid height={16} />
                    Email
                  </div>
                </a>
              </span>
            </DefaultTag>
            <DefaultTag>
              <span className="font-medium">
                <a
                  target={"blank"}
                  rel="noopener noreferrer"
                  href={host?.website}
                >
                  Website
                </a>
              </span>
            </DefaultTag>
            <DefaultTag>
              <span className="font-medium">
                <a
                  target={"blank"}
                  rel="noopener noreferrer"
                  href={host?.instagram}
                >
                  Instagram
                </a>
              </span>
            </DefaultTag>
          </div>
          <h2 className="fs-20 font-medium">Introduction</h2>
          <p className="fs-16 font-normal mb-0">{host?.introduction}</p>
        </div>
      </div>
      <div className="border h-1/4 mb-16" />
      <PopularHeadingGroup headingPartTwo="Events" />
      <div className="grid md:grid-cols-3 grid-cols-1 gap-8 my-4">
        {host?.eventsList.list.map((event) => (
          <Link key={event._id} to={`/discover-events/${event._id}`}>
            <EventCard event={event} height="large" />
          </Link>
        ))}
      </div>
      <div className="flex justify-center my-8">
        <Link to="/explore/events" type="ghost">
          <HeadstarttButton type="ghost">Show More Tours</HeadstarttButton>
        </Link>
      </div>
      {!!host?.reviewsList.total && (
        <div className="my-16">
          <h2 className="fs-32 font-medium">Reviews</h2>
          <div className="grid grid-cols-12 gap-16">
            <div className="md:col-span-4 col-span-12">
              <CardReviews />
              <div className="my-4">
                <GroupByRatings />
              </div>
            </div>
            <div className="md:col-span-8 col-span-12 space-y-12">
              <SearchReviewInput />
              {host?.reviewsList.list.map((review) => (
                <>
                  <ReviewCard review={review} />
                </>
              ))}
              <HeadstarttPagination total={24} />
            </div>
          </div>
        </div>
      )}
    </HeadstarttContainer>
  );
};

export default AboutTheHostPage;
