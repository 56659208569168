import GoogleMaps from "views/components/googleMaps";
import { MarkerProps } from "../listWithMaps/types";

const ExploreSidebarMap = ({ markers }: { markers: MarkerProps[] }) => (
  <GoogleMaps
    locationDetail={{
      lat: markers[0].position[0],
      lng: markers[0].position[1],
    }}
    markers={markers}
  />
);

export default ExploreSidebarMap;
