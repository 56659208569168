import { useCallback, useEffect, useState } from "react";

import { UserRoot } from "core/repository/user/types";
import { getToken } from "../authService";
import { getUserFromRepo } from "core/repository/user/getUserFromRepo";

const useGetUser = (lazy = false) => {
  const token = getToken();
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState<UserRoot["data"] | undefined>(undefined);
  const [error, setError] = useState(false);

  const getUser = useCallback(async () => {
    setLoader(true);
    try {
      const { data } = await getUserFromRepo();
      setUser(data.data);
    } catch (err) {
      setError(true);
    }
    setLoader(false);
  }, []);

  useEffect(() => {
    if (!lazy && token) getUser();
  }, [getUser, lazy, token]);

  return { user, getUser, loader, error };
};

export default useGetUser;
