import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { BookmarkPayload } from "core/repository/user/types";
import { deleteBookmark } from "core/repository/user/bookmark";
import { useMutation } from "react-query";

const useDeleteBookmark = () => {
  const { mutate, isLoading } = useMutation(deleteBookmark, {
    onSuccess: () => {
      successToast("Removed from Saved");
    },
    onError: () => {
      errorToast("Something went wrong. Please try again later.");
    },
  });
  return {
    deleteBookmark: (payload: BookmarkPayload["entityId"]) => mutate(payload),
    isLoading,
  };
};

export default useDeleteBookmark;
