import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useCallback, useState } from "react";

const useResetPassword = () => {
  const [loader, setLoader] = useState(false);
  const auth = getAuth();
  const resetPassword = useCallback(
    async (email: string) => {
      setLoader(true);
      try {
        await sendPasswordResetEmail(auth, email);
        successToast(
          "Password reset email sent successfully. Please check your email for further instructions."
        );
      } catch (error) {
        errorToast(
          "There was an error while trying to reset your password. Please try again later."
        );
      }
      setLoader(false);
    },
    [auth]
  );

  return { resetPassword, loader };
};

export default useResetPassword;
