import { FacebookAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { useCallback, useState } from "react";

import { LoginType } from "./../types";
import { SignUpPayload } from "core/repository/user/types";
import { errorToast } from "views/components/headstarttNotifications";

const useLoginWithFacebook = (
  onSuccess?: (payload: SignUpPayload) => void,
  onError?: () => void
) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const loginWithFacebook = useCallback(() => {
    setLoader(true);

    const provider = new FacebookAuthProvider();
    provider.addScope("user_birthday");
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const accessToken = await auth?.currentUser?.getIdToken();
        const payloadForSignUp: SignUpPayload = {
          firstName: user.displayName?.split(" ")[0] || "",
          lastName: user.displayName?.split(" ")[1] || "",
          socialId: user.uid,
          loginType: LoginType.FACEBOOK,
          deviceToken: "",
          accessToken: "",
        };
        if (accessToken) {
          payloadForSignUp.accessToken = accessToken;
        }
        onSuccess?.(payloadForSignUp);
        setLoader(false);
      })
      .catch((err) => {
        // Handle Errors here.
        setError(err.message);
        errorToast(err.message);
        onError?.();
        setLoader(false);

        // ...
      });
  }, [onSuccess, onError]);
  return {
    loginWithFacebook,
    loader,
    error,
  };
};

export default useLoginWithFacebook;
