import { Cross } from "views/components/icons";
import { SignInModalProps } from "./signInModal";
import { SignInView } from "./signInView";
type SignInModalContentProps = {
  handleSignInClick: () => void;
  onCancel: SignInModalProps["onCancel"];
};

const SignInModalContent = ({
  handleSignInClick,
  onCancel,
}: SignInModalContentProps) => (
  <>
    <div className="flex items-center justify-between mb-8">
      <p className="fs-24 mb-0 font-medium">Sign In</p>
      <div className="bg-gray-100 p-2 br-4 cursor-pointer" onClick={onCancel}>
        <Cross height={24} />
      </div>
    </div>
    <SignInView handleSignInClick={handleSignInClick} onCancel={onCancel} />
  </>
);
export default SignInModalContent;
