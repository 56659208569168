import { Review, ReviewList, STATUS } from "core/repository/reviews/types";

import { DotsHorizontal } from "../icons";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { HeadstarttDropdown } from "../headstarttDropdown";
import { HeadstarttRating } from "../headstarttRating";
import HeadstarttReadMoreLess from "../headstarttReadMoreLess";
import { RawButton } from "../headstarttButton";
import { dayjsClient } from "core/clients/dayjs";
import { patchReviewToRepo } from "core/repository/reviews";
import { useState } from "react";

const ReviewDropdownOverlay = ({
  isAdmin,
  onDelete,
}: {
  isAdmin?: boolean;
  onDelete: () => void;
}) => (
  <div className="br-16 bg-white space-y-2 shadow my-2 p-2">
    {!isAdmin && (
      <p className="fs-16 font-medium mb-0 cursor-pointer hover:bg-gray-100 p-2 br-8">
        Edit Review
      </p>
    )}
    <p
      onClick={onDelete}
      className="fs-16 font-medium mb-0 text-red-400 cursor-pointer hover:bg-gray-100 p-2 br-8"
    >
      Delete Review
    </p>
  </div>
);

type ReviewCardProps = {
  review?: Review | ReviewList;
  isAdmin?: boolean;
  onSuccess?: () => void;
  onDelete?: (id: string) => void;
};
const ReviewCard = ({
  review,
  isAdmin,
  onSuccess,
  onDelete,
}: ReviewCardProps) => {
  const [selected, setSelected] = useState("");
  const onStatusClick = async (id: string) => {
    setSelected(id);
    await patchReviewToRepo(id, STATUS.APPROVED);
    onSuccess?.();
  };
  return (
    <div>
      <div className="flex items-start justify-between mb-8">
        <div className="flex items-center gap-4">
          {review?.images[0] && (
            <img
              className="h-20 w-20 rounded-full object-cover"
              src={review?.images[0]}
              alt={`${review?.firstName} ${review?.lastName}`}
            />
          )}
          <div>
            <p className="fs-16 font-medium mb-0">
              {review?.firstName + " " + review?.lastName}
            </p>
            <p className="fs-12 font-medium text-gray-500 mb-0">
              {review?.reviewNumber || 0} reviews
            </p>
          </div>
        </div>
        <div>
          <div className="flex gap-8">
            {isAdmin && (
              <RawButton
                style={{
                  backgroundColor:
                    review?.status === STATUS.APPROVED ? "#333333" : "#F29D38",
                  padding: "2px 12px",
                  borderRadius: "32px",
                  border: `1px solid ${
                    review?.status === STATUS.APPROVED ? "#333333" : "#F29D38"
                  }`,
                }}
                disabled={
                  review?.status === STATUS.APPROVED || selected === review?._id
                }
                onClick={() => onStatusClick(review?._id || "")}
              >
                {review?.status === STATUS.APPROVED ? (
                  <span className="font-medium text-white">Approved</span>
                ) : (
                  <div className="flex items-center gap-1">
                    <ExclamationCircleIcon fill="white" height={24} />
                    <span className="font-medium text-white">
                      Pending Approval
                    </span>
                  </div>
                )}
              </RawButton>
            )}
            <HeadstarttDropdown
              overlay={
                <ReviewDropdownOverlay
                  onDelete={() => {
                    onDelete?.(review?._id || "");
                  }}
                  isAdmin={isAdmin}
                />
              }
              placement="bottomRight"
            >
              <div className="bg-gray-200 p-2 br-8 cursor-pointer">
                <DotsHorizontal height={24} />
              </div>
            </HeadstarttDropdown>
          </div>
        </div>
      </div>
      <HeadstarttRating value={4} />
      <p className="fs-12 font-medium text-gray-500">
        Written {dayjsClient(review?.createdDate).format("MMM DD, YYYY")}
      </p>
      <HeadstarttReadMoreLess
        sliceLength={300}
        text={review?.comment ?? "Lorem"}
      />
    </div>
  );
};

export default ReviewCard;
