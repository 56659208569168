import { Heart } from "../icons";
import { Link } from "react-router-dom";
import { memo } from "react";
import useLogout from "core/services/authService/hooks/useLogout";

const ProfileDropdownOverlay = () => {
  const { logout } = useLogout();
  return (
    <div className="br-16 bg-white space-y-1 shadow my-2 p-2 w-48">
      <Link
        className="fs-16 font-medium mb-0 cursor-pointer hover:bg-gray-100 p-2 br-8 block text-black"
        to={"/saved/all"}
      >
        <span className="text-dark flex items-center gap-1">
          <Heart height={18} /> Saved
        </span>
      </Link>
      <Link
        className="fs-16 font-medium mb-0 cursor-pointer hover:bg-gray-100 p-2 br-8 block text-black"
        to={"/edit-profile"}
      >
        <span className="text-dark">Edit Profile</span>
      </Link>
      <Link
        className="fs-16 font-medium mb-0 cursor-pointer hover:bg-gray-100 p-2 br-8 block text-black"
        to={"/my-reviews?page=1"}
      >
        <span className="text-dark">My Review</span>
      </Link>
      <Link
        className="fs-16 font-medium mb-0 cursor-pointer hover:bg-gray-100 p-2 br-8 block text-black"
        to={"/bookings?page=1"}
      >
        <span className="text-dark">My Bookings</span>
      </Link>

      <Link
        className="fs-16 font-medium mb-0 cursor-pointer hover:bg-gray-100 p-2 br-8 block text-black"
        to={"/support"}
      >
        <span className="text-dark">Support</span>
      </Link>
      <p
        className="fs-16 font-medium mb-0 cursor-pointer hover:bg-gray-100 p-2 br-8 block text-black"
        onClick={logout}
      >
        <span className="text-dark">Logout</span>
      </p>
    </div>
  );
};

const MemoisedProfileDropdownOverlay = memo(ProfileDropdownOverlay);

export default MemoisedProfileDropdownOverlay;
