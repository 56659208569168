import FormFooter from "views/components/headstarttForm/formFooter";
import FormGroup from "views/components/headstarttForm/formGroup";
import { HeadstarttForm } from "views/components/headstarttForm";
import { HostPayload } from "core/repository/host/types";

type ContactDetailsFormProps = {
  loading: boolean;
  setPayload: (payload: HostPayload) => void;
  onSubmit: (values: Record<string, string>) => void;
  onBack: () => void;
  payload?: HostPayload;
};
const ContactDetailsForm = ({
  loading,
  setPayload,
  onSubmit,
  onBack,
  payload,
}: ContactDetailsFormProps) => {
  return (
    <HeadstarttForm
      onFinish={(values) => {
        setPayload(values);
        onSubmit(values);
      }}
      className="w-full space-y-8"
      initialValues={{
        instagram: payload?.instagram,
        email: payload?.email,
        website: payload?.website,
      }}
    >
      <FormFooter
        flexClass="flex items-center justify-end gap-2"
        showNextButton={false}
        onBackButtonClick={onBack}
        nextButtonDisabled={loading}
        showCancelButton={false}
      />

      <FormGroup
        label={"Instagram"}
        name={"instagram"}
        description="Enter instagram account link"
        type="url"
      />
      {/* email */}
      <FormGroup
        label={"Email"}
        name={"email"}
        type="email"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        description="Enter the email of host"
      />
      {/* phone number */}
      <FormGroup
        label={"Website"}
        name={"website"}
        description="Enter the website of the host"
        type="url"
        pattern="https?://.+"
      />
    </HeadstarttForm>
  );
};

export default ContactDetailsForm;
