import { USER } from "./endpoint";
import { UserReviewsRoot } from "./types";
import { apiRequest } from "core/services/apiServices";

export const getUserReviewsFromRepo = (limit: number, offset: number) =>
  apiRequest.getByQueryParam<UserReviewsRoot>(USER.userReviews, {
    limit,
    offset,
  });

export default getUserReviewsFromRepo;
