import { ChevronDownIcon } from "@heroicons/react/outline";
import { Divider } from "antd";

const TableHeader = ({
  header,
  span = 3,
}: {
  header: {
    title: string;
    isSortable?: boolean;
  }[];
  span?: number | number[];
}) => {
  return (
    <>
      <div className="grid grid-cols-12">
        {header.map((h, i) => {
          return (
            <div
              className={`col-span-${Array.isArray(span) ? span[i] : span}`}
              key={h.title}
            >
              <div className="flex gap-1">
                <div className="text-sm font-bold fs-16 text-gray-500 uppercase">
                  {h.title}
                </div>
                {h.isSortable && <ChevronDownIcon height={18} />}
              </div>
            </div>
          );
        })}
      </div>
      <Divider />
    </>
  );
};

export default TableHeader;
