import { CircleCross, LockClosed, Mail, User } from "views/components/icons";
import {
  FormItem,
  HeadstarttForm,
  useForm,
} from "views/components/headstarttForm";
import {
  HeadstarttButton,
  HeadstarttOutlineButton,
} from "views/components/headstarttButton";
import {
  HeadstarttInput,
  HeadstarttInputPassword,
} from "views/components/headstarttInput";

import ErrorCard from "views/components/errorCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttUploader from "views/components/headstarttUploader";
import InputLabel from "views/components/typography/inputLabel";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import { UpdatePayload } from "core/repository/user/types";
import { UploadFilesResponseRoot } from "core/repository/uploadFiles/types";
import { useEffect } from "react";
import useGetUser from "core/services/user/useGetUser";
import usePatchUser from "core/services/user/usePatchUser";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};
const EditProfile = () => {
  const [form] = useForm();
  const { user, loader, error, getUser } = useGetUser();
  const { updateData, loader: updateLoader } = usePatchUser();

  const removeImage = async () => {
    const payload: UpdatePayload = {
      profilePicture: null,
    };
    await updateData(payload);
    await getUser();
  };

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: "",
      });
    }
  }, [user, form]);

  if (loader) return <LoaderFullScreen />;
  if (error) return <ErrorCard />;

  const onSubmit = (values: FormValues) => {
    if (user) {
      const payload: UpdatePayload = {
        firstName: values.firstName,
        lastName: values.lastName,
        profilePicture: user.profilePicture,
      };
      updateData(payload);
    }
  };

  const updateImage = async (res: UploadFilesResponseRoot["data"]) => {
    const payload: UpdatePayload = {
      profilePicture: res.mediaFiles[0],
    };
    await updateData(payload);
    await getUser();
  };

  return (
    <HeadstarttContainer>
      <div className="grid grid-cols-12 py-24">
        <div className="col-span-5 col-start-5">
          <h1 className="fs-24 font-medium mb-8">Edit Profile</h1>
          {user?.profilePicture ? (
            <img
              loading="lazy"
              className="h-24 w-24 rounded-full object-cover mx-auto block"
              src={user?.profilePicture}
              alt={`${user?.firstName} ${user?.lastName}`}
            />
          ) : (
            <div
              className={`rounded-full flex items-center justify-center bg-gray-800 h-24 w-24 mx-auto`}
            >
              <span className="text-white fs-24 font-medium">
                {user?.firstName?.charAt(0)} {user?.lastName?.charAt(0)}
              </span>
            </div>
          )}
          <div className="flex items-center justify-center gap-2 my-8">
            <HeadstarttOutlineButton onClick={removeImage}>
              <div className="flex gap-1 items-center">
                <CircleCross color="#006653" height={24} />
                <span className="text-primary fs-16 font-medium">Remove</span>
              </div>
            </HeadstarttOutlineButton>
            <HeadstarttUploader buttonView="simple" onSuccess={updateImage}>
              <HeadstarttOutlineButton>
                <div className="flex gap-1 items-center">
                  <span className="text-primary fs-16 font-medium">
                    Change photo
                  </span>
                </div>
              </HeadstarttOutlineButton>
            </HeadstarttUploader>
          </div>
          <div className="my-16">
            <HeadstarttForm
              onFinish={onSubmit}
              form={form}
              className="space-y-2"
            >
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <InputLabel>Name</InputLabel>
                </div>
                <div className="col-span-6">
                  <FormItem
                    name={"firstName"}
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <HeadstarttInput
                      placeholder="John"
                      prefix={<User color="#696969" height={24} />}
                      type={"text"}
                    />
                  </FormItem>
                </div>
                <div className="col-span-6">
                  <FormItem
                    name={"lastName"}
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <HeadstarttInput
                      placeholder="Doe"
                      prefix={<User color="#696969" height={24} />}
                      type={"text"}
                    />
                  </FormItem>
                </div>
              </div>
              <InputLabel>Email</InputLabel>
              <div className="col-span-12">
                <FormItem
                  name={"email"}
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <HeadstarttInput
                    readOnly={true}
                    placeholder="user@email.com"
                    prefix={<Mail color="#696969" height={24} />}
                    type={"email"}
                  />
                </FormItem>
              </div>
              <div className="col-span-12">
                <InputLabel>Password</InputLabel>
                <FormItem
                  name={"password"}
                  rules={[
                    {
                      required: false,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <HeadstarttInputPassword
                    prefix={<LockClosed color="#696969" height={24} />}
                    type={"password"}
                  />
                </FormItem>
                <InputLabel>Confirm Password</InputLabel>

                <FormItem
                  name={"confirm"}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: false,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <HeadstarttInputPassword
                    prefix={<LockClosed color="#696969" height={24} />}
                    type={"password"}
                  />
                </FormItem>
              </div>

              <FormItem>
                <HeadstarttButton
                  loading={updateLoader}
                  disabled={updateLoader}
                  htmlType="submit"
                  type="ghost"
                  className="w-full hover: bg-green-800"
                >
                  Update Profile
                </HeadstarttButton>
              </FormItem>
            </HeadstarttForm>
          </div>
        </div>
      </div>
    </HeadstarttContainer>
  );
};

export default EditProfile;
