import { useCallback, useState } from "react";

import AdminCard from "admin/components/adminCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttPagination from "views/components/headstarttPagination";
import PageHeader from "admin/components/pageHeader";
import ReviewCard from "views/components/headstarttCard/reviewCard";
import SecondaryHeader from "views/components/secondaryHeader";
import { deleteReviewFromRepo } from "core/repository/reviews";
import useDeleteGeneralised from "core/hooks/useDeleteGeneralised";
import { useGetReviewsByParams } from "core/services/reviews/useGetReviews";
import { useParams } from "react-router-dom";

const AdminActivityPendingReviews = () => {
  const [offset, setOffset] = useState(0);
  const { id } = useParams() as { id: string };
  const { data, isLoading, isError, refetch } = useGetReviewsByParams({
    activityEventId: id,
    limit: 25,
    offset,
  });
  const onSuccess = useCallback(() => {
    refetch();
  }, [refetch]);
  const { deleteFromRepo } = useDeleteGeneralised(
    deleteReviewFromRepo,
    onSuccess
  );
  const onDelete = useCallback(
    (reviewId: string) => {
      deleteFromRepo(reviewId);
    },
    [deleteFromRepo]
  );

  const handleChangePage = useCallback((page: number) => {
    setOffset(page);
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error</div>;
  const reviews = data?.data.list;
  if (!reviews?.length) return <div>No reviews</div>;
  if (!data?.data.total) return <div>No reviews</div>;
  return (
    <div>
      <PageHeader heading="Reviews List" />
      <HeadstarttContainer>
        <SecondaryHeader
          paginationProps={{
            total: data?.data?.total,
            current: offset,
            limit: data.data.list.length,
          }}
        />
        <div className="py-4">
          <AdminCard>
            {reviews.map((review) => {
              return (
                <div key={review._id} className="space-y-12">
                  <ReviewCard
                    onSuccess={onSuccess}
                    onDelete={onDelete}
                    review={review}
                    isAdmin
                  />
                </div>
              );
            })}
            <div className="flex justify-center my-4">
              <HeadstarttPagination
                total={data?.data.total}
                current={offset}
                onChange={handleChangePage}
              />
            </div>
          </AdminCard>
        </div>
      </HeadstarttContainer>
    </div>
  );
};

export default AdminActivityPendingReviews;
