import FormItem from "antd/es/form/FormItem";
import { HeadstarttButton } from "views/components/headstarttButton";
import { HeadstarttCard } from "views/components/headstarttCard";
import { HeadstarttForm } from "views/components/headstarttForm";
import { HeadstarttInput } from "views/components/headstarttInput";
import { Mail } from "views/components/icons";
import useResetPassword from "core/services/authService/hooks/useResetPassword";
type FormValues = {
  email: string;
};
const ForgetPassword = () => {
  const { resetPassword, loader } = useResetPassword();
  const onSubmit = (values: FormValues) => {
    resetPassword(values.email);
  };

  return (
    <div className="grid grid-cols-12 py-24">
      <div className="col-span-4 col-start-5">
        <HeadstarttCard>
          <h2 className="fs-24 font-medium text-center">Reset password</h2>

          <HeadstarttForm onFinish={onSubmit} className="space-y-2">
            <FormItem
              name={"email"}
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <HeadstarttInput
                placeholder="Registered email"
                prefix={<Mail color="#696969" height={24} />}
                type={"email"}
              />
            </FormItem>
            <FormItem>
              <HeadstarttButton
                disabled={loader}
                htmlType="submit"
                type="ghost"
                className="w-full hover: bg-green-800"
              >
                Send Reset Password Link
              </HeadstarttButton>
            </FormItem>
          </HeadstarttForm>
        </HeadstarttCard>
      </div>
    </div>
  );
};

export default ForgetPassword;
