import { ADMIN_ACTIVITY_TABS } from "admin/adminConstants";
import { ActivityTabView } from "../types";
import FormFooter from "views/components/headstarttForm/formFooter";
import FormGroup from "views/components/headstarttForm/formGroup";
import { HeadstarttForm } from "views/components/headstarttForm";
import { useNavigate } from "react-router-dom";

const ContactDetailsForm = ({ payload, setPayload }: ActivityTabView) => {
  const navigate = useNavigate();
  return (
    <HeadstarttForm
      initialValues={payload.contactDetail}
      onFinish={(values) => {
        const newPayload = {
          ...payload,
          contactDetail: {
            operatedBy: values.operatedBy,
            email: values.email,
            mobileNumber: values.mobileNumber,
          },
        };
        setPayload(newPayload);
        navigate({
          pathname: location.pathname,
          search: `?tab=${encodeURIComponent(ADMIN_ACTIVITY_TABS[3])}`,
        });
      }}
      className="w-full space-y-8"
    >
      <FormFooter
        showCancelButton={false}
        showSaveButton={false}
        onBackButtonClick={() => {
          navigate({
            pathname: location.pathname,
            search: `?tab=${encodeURIComponent(ADMIN_ACTIVITY_TABS[1])}`,
          });
        }}
      />
      <FormGroup
        label={"Operated by"}
        name={"operatedBy"}
        description="Enter the name of operator"
      />
      <FormGroup
        label={"Email"}
        name={"email"}
        type="email"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        description="Enter the email of operator"
      />
      <FormGroup
        label={"Phone number"}
        name={"mobileNumber"}
        description="Enter the phone number of operator"
      />
    </HeadstarttForm>
  );
};

export default ContactDetailsForm;
