import { Link } from "react-router-dom";
const LINKS = [
  {
    text: "Terms of Use",
    to: "/terms-of-use",
  },
  {
    text: "Privacy and Cookies Statement",
    to: "/privacy-and-cookies-statement",
  },
  {
    text: "Cookie Consent",
    to: "/cookie-consent",
  },
];

type FooterLinkProps = {
  text: string;
  to: string;
};

const FooterLink = ({ text, to }: FooterLinkProps) => (
  <Link className="fs-16 text-gray-700 inline-block" to={to}>
    {text}
  </Link>
);

const FooterLinks = () => (
  <div className="flex gap-8">
    {LINKS.map(({ text, to }) => (
      <FooterLink key={text} text={text} to={to} />
    ))}
  </div>
);

export default FooterLinks;
