import { HeadstarttModal } from "views/components/headstarttModal";
import { ReactNode } from "react";
type ListWithMapsModalProps = {
  visible: boolean;
  children: ReactNode;
};
const ListWithMapsModal = ({ visible, children }: ListWithMapsModalProps) => {
  return (
    <HeadstarttModal
      closable={false}
      footer={null}
      maskStyle={{
        backgroundColor: "rgba(256, 256, 256, 0.95)",
      }}
      className="headstartt-antd-modal"
      width={1400}
      open={visible}
    >
      <div className="border br-8 bg-white drop-shadow-2xl">{children}</div>
    </HeadstarttModal>
  );
};

export default ListWithMapsModal;
