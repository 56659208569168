import CoverCard from "views/components/headstarttCard/coverCard";
import PopularHeadingGroup from "views/components/typography/popularHeading";
import ProjectList from "./projectList";
import { ProjectsList } from "core/repository/home/types";

const LandingProjectGroup = ({
  popularProjects,
}: {
  popularProjects: ProjectsList["list"];
}) => (
  <div>
    <PopularHeadingGroup
      text="Want to try out more experiences near you?"
      headingPartTwo="Projects"
    />
    <a
      href={popularProjects[0].redirectUrl}
      rel="noopener noreferrer"
      target={"_blank"}
    >
      <CoverCard coverProject={popularProjects[0]} />
    </a>
    <ProjectList projectList={popularProjects} />
  </div>
);

export default LandingProjectGroup;
