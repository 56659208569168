import CardImage from "./cardImage";
import CardOverlay from "./cardOverlay";
import MutedText from "../typography/mutedText";
import PrimaryHeading from "../typography/primaryHeading";
import { ProjectsList } from "core/repository/home/types";

const CoverCard = ({
  coverProject,
}: {
  coverProject: ProjectsList["list"][0];
}) => (
  <div className="grid md:grid-cols-12 gap-8 my-4">
    <div className="md:col-span-7 col-span-12">
      <div className="br-16 relative">
        <CardImage
          className="h-[490px] w-full object-cover"
          url={coverProject.projectImage}
          alt={coverProject.projectName}
        />
        <CardOverlay className="bg-black opacity-80 p-2 br-t-r-0 br-t-l-0 br-b-r-16 br-b-l-16">
          <p className="mb-0 text-gray-200 text-right">Cover</p>
        </CardOverlay>
      </div>
    </div>
    <div className="md:col-span-5 col-span-12">
      <MutedText text="Latest project" />
      <PrimaryHeading heading={coverProject.projectName} />
      <p className="fs-16 text-gray-600">{coverProject.projectDescription}</p>
    </div>
  </div>
);

export default CoverCard;
