import {
  ADMIN_ACTIVITY_TABS,
  TRAIL_DIFFICULTY,
  YES_NO_RADIO_GROUP,
} from "admin/adminConstants";
import { CircleCross, Cross } from "views/components/icons";
import { useCallback, useState } from "react";

import { ACTIVITY_RADIO_GROUP } from "./constants";
import { ActivityPayload } from "core/repository/activity/types";
import ActivityRadioGroup from "./components/activityRadioGroup";
import AddButton from "views/components/secondaryHeader/addButton";
import { Collapse } from "antd";
import FormFooter from "views/components/headstarttForm/formFooter";
import FormGroup from "views/components/headstarttForm/formGroup";
import FormLabelWithDescription from "views/components/headstarttForm/formLabelWithDescription";
import { HeadstarttForm } from "views/components/headstarttForm";
import { HeadstarttSmallButton } from "views/components/headstarttButton";
import LocationPickerFromMap from "views/components/locationPickerFromMap";
import UploadButton from "admin/components/adminImageUploader/uploadButton";
import { UploadFilesResponseRoot } from "core/repository/uploadFiles/types";
import { useNavigate } from "react-router-dom";

interface TrailsFormProps {
  loading: boolean;
  payload: ActivityPayload;
  onFinish: (payload: ActivityPayload) => void;
  setPayload: (payload: ActivityPayload) => void;
}
const initialValues = {
  trailTitle: "",
};
const TrailsForm = ({
  onFinish,
  loading,
  payload,
  setPayload,
}: TrailsFormProps) => {
  const navigate = useNavigate();
  const trails = payload?.trail?.length ? payload.trail : [initialValues];
  const [trailPayload, setTrailPayload] =
    useState<ActivityPayload["trail"]>(trails);

  const handleUploadAudio = useCallback(
    (data: UploadFilesResponseRoot["data"], index: number) => {
      trailPayload[index].audioFiles = [
        ...(trailPayload[index]?.audioFiles || []),
        ...data.mediaFiles,
      ];
      setTrailPayload([...trailPayload]);
    },
    [trailPayload]
  );
  const handlePDFAudio = useCallback(
    (data: UploadFilesResponseRoot["data"], index: number) => {
      trailPayload[index].pdfFiles = [
        ...(trailPayload[index]?.pdfFiles || []),
        ...data.mediaFiles,
      ];
      setTrailPayload([...trailPayload]);
    },
    [trailPayload]
  );
  const removeTrail = useCallback(
    (index: number) => {
      // find trail by id
      let trailIndex = index;
      if (trailPayload[index]._id) {
        trailIndex = trailPayload.findIndex(
          (it) => it._id === trailPayload[index]._id
        );
        trailPayload[trailIndex].isDeleted = true;
      } else {
        trailPayload.splice(trailIndex, 1);
      }

      setTrailPayload([...trailPayload]);
    },
    [trailPayload]
  );
  const handleLocation = useCallback(
    (
      location: [number, number],
      key: "trailStartLocation" | "trailEndLocation",
      index: number
    ) => {
      trailPayload[index][key] = location;
      setTrailPayload([...trailPayload]);
    },
    [trailPayload]
  );
  const handleOnFinish = useCallback(
    (values: Record<string, string>, index: number) => {
      const newTrailPayload = trailPayload;
      let selectedTrail = trailPayload[index];

      selectedTrail = {
        ...selectedTrail,
        trailTitle: values.trailTitle,
        trailDescription: values.trailDescription,
        difficulty: values.difficulty,
        length: parseFloat(values.length),
        elevation: Number(values.elevation),
        accessible: values.accessible,
        isDogFriendly: values.isDogFriendly === "yes",
        isKidFriendly: values.isKidFriendly === "yes",
        trailStartLocation: selectedTrail.trailStartLocation,
        trailEndLocation: selectedTrail.trailEndLocation,
        audioFiles: selectedTrail.audioFiles,
        pdfFiles: selectedTrail.pdfFiles,
        activityEventId: payload.activityId,
      };
      const trailIndex = trailPayload.findIndex(
        (it) => it._id === selectedTrail._id
      );
      if (trailIndex > -1) {
        newTrailPayload[trailIndex] = selectedTrail;
      } else {
        newTrailPayload.push(selectedTrail);
      }

      console.log("newTrailPayload", newTrailPayload);
      setTrailPayload(newTrailPayload);
      setPayload({
        ...payload,
        trail: newTrailPayload,
      });
    },
    [trailPayload, payload, setPayload]
  );

  return (
    <div>
      <FormFooter
        showCancelButton={false}
        showNextButton={false}
        saveButtonDisabled={loading}
        cancelButtonDisabled={loading}
        onSaveButtonClick={() => {
          onFinish(payload);
        }}
        onBackButtonClick={() =>
          navigate({
            pathname: location.pathname,
            search: `?tab=${encodeURIComponent(ADMIN_ACTIVITY_TABS[3])}`,
          })
        }
      />

      {trailPayload?.map((trail, index) => {
        return (
          <Collapse
            key={index}
            defaultActiveKey={trailPayload.length - 1 === index ? [index] : []}
            className={`${
              trail.isDeleted ? "hidden" : "border border-gray-300 rounded-md"
            }`}
          >
            <Collapse.Panel
              key={index}
              header={
                <div className="flex items-center justify-between w-full">
                  <div>{trail.trailTitle}</div>
                  <div
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeTrail(index);
                    }}
                  >
                    <Cross height={24} />
                  </div>
                </div>
              }
              className="mb-4"
            >
              <HeadstarttForm
                name={`trail-${index}`}
                onFinish={(values) => handleOnFinish(values, index)}
                className="w-full space-y-8"
                initialValues={{
                  trailTitle: trail.trailTitle,
                  trailDescription: trail.trailDescription,
                  difficulty: trail.difficulty,
                  length: trail.length,
                  elevation: trail.elevation,
                  accessible: trail.accessible,
                  isDogFriendly: trail.isDogFriendly ? "yes" : "no",
                  isKidFriendly: trail.isKidFriendly ? "yes" : "no",
                }}
              >
                <FormGroup
                  label={"Trail Name"}
                  name={"trailTitle"}
                  description={"Enter trail name"}
                />
                <FormGroup
                  label={"Trail Description"}
                  name={"trailDescription"}
                  description={"Enter trail description"}
                  inputType={"textarea"}
                />
                <ActivityRadioGroup
                  label={"Trail Difficulty"}
                  name={"difficulty"}
                  description={"Enter Trail Difficulty"}
                  radioGroup={TRAIL_DIFFICULTY}
                />

                <FormGroup
                  label={"Length (km)"}
                  name={"length"}
                  type="number"
                  description="Enter trail length"
                />
                <FormGroup
                  label={"Elevation (m)"}
                  name={"elevation"}
                  type="number"
                  description="Enter elevation"
                />
                <FormGroup
                  label={"Accessible"}
                  name={"accessible"}
                  type="url"
                  description="Enter accessible Url"
                />
                {ACTIVITY_RADIO_GROUP.map((it) => (
                  <ActivityRadioGroup
                    key={it.value}
                    label={it.title}
                    name={it.value}
                    description={`Enter ${it.title}`}
                    radioGroup={YES_NO_RADIO_GROUP}
                  />
                ))}
                <FormGroup
                  label={"Uploads"}
                  name={"uploads"}
                  description={"Upload PDF or audio related to this trail"}
                  inputType="children"
                  required={false}
                >
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6">
                      <UploadButton
                        accept="audio/*"
                        buttonText={"Upload Audio"}
                        onSuccess={(data) => handleUploadAudio(data, index)}
                      />
                      <div className="my-2" />
                      <div className="flex flex-wrap gap-2">
                        {trail?.audioFiles?.map((it) => (
                          <div
                            className="flex border p-2 br-4 items-start w-full"
                            key={it}
                          >
                            <audio controls>
                              <source src={it} type="audio/mpeg" />
                              <source src={it} type="audio/ogg" />
                              Your browser does not support the audio element.
                            </audio>
                            <div
                              onClick={() => {
                                const newTrailPayload = trailPayload;
                                newTrailPayload[index].audioFiles =
                                  newTrailPayload[index]?.audioFiles?.filter(
                                    (audio) => audio !== it
                                  );
                                setTrailPayload([...newTrailPayload]);
                              }}
                              className="cursor-pointer"
                            >
                              <CircleCross height={24} width={24} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-span-6">
                      <UploadButton
                        accept="application/pdf"
                        buttonText={"Upload PDF"}
                        onSuccess={(data) => handlePDFAudio(data, index)}
                      />
                      <div className="my-1" />
                      <div className="flex flex-wrap gap-1">
                        {trail?.pdfFiles?.map((it) => (
                          <div
                            className="flex border p-2 br-4 items-start w-full"
                            key={it}
                          >
                            <p>
                              {/* Audio player */}
                              <a
                                href={it}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {it.split("/")[it.split("/").length - 1]}
                              </a>
                            </p>
                            <div
                              onClick={() => {
                                const newTrailPayload = [...trailPayload];
                                newTrailPayload[index].pdfFiles =
                                  newTrailPayload?.[index]?.pdfFiles?.filter(
                                    (pdf) => pdf !== it
                                  );
                              }}
                              className="cursor-pointer"
                            >
                              <CircleCross height={24} width={24} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </FormGroup>
                <div className="grid grid-cols-12 pb-16">
                  <div className="col-span-12">
                    <FormLabelWithDescription
                      label="Location"
                      description="Enter trail start and end location"
                    />
                  </div>
                  <div className="col-span-12">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <p>Start Location</p>
                        <LocationPickerFromMap
                          locationDetail={{
                            latitude: trail?.trailStartLocation?.[0] || 0,
                            longitude: trail?.trailStartLocation?.[1] || 0,
                            locationDescription: "",
                          }}
                          handleSelectedLocation={(location) =>
                            handleLocation(
                              [location.latitude, location.longitude],
                              "trailStartLocation",
                              index
                            )
                          }
                        />
                      </div>
                      <div>
                        <p>End Location</p>
                        <LocationPickerFromMap
                          locationDetail={{
                            latitude: trail?.trailEndLocation?.[0] || 0,
                            longitude: trail?.trailEndLocation?.[1] || 0,
                            locationDescription: "",
                          }}
                          handleSelectedLocation={(location) => {
                            handleLocation(
                              [location?.latitude, location?.longitude],
                              "trailEndLocation",
                              index
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 mt-32">
                    <HeadstarttSmallButton
                      type="ghost"
                      size="small"
                      htmlType="submit"
                      newstyle={{
                        width: "100%",
                        height: "50px",
                      }}
                    >
                      {trail._id ? "Update Trail" : "Add Trail"}
                    </HeadstarttSmallButton>
                  </div>
                </div>
              </HeadstarttForm>
            </Collapse.Panel>
          </Collapse>
        );
      })}
      <div className="my-4" />
      <AddButton
        onClick={() => {
          setTrailPayload([
            ...(trailPayload || []),
            {
              trailTitle: "",
            },
          ]);
        }}
        buttonText={"Add New Trail"}
      />
    </div>
  );
};

export default TrailsForm;
