import { getFiltersFromRepo } from "core/repository/search";
import { FilterTypes } from "core/repository/search/types";
import { useQuery } from "react-query";
import { FiltersData } from "./../../repository/search/types";

export const useGetAllFilters = (type: FilterTypes) =>
  useQuery<FiltersData, Error>(["filters", type], async () => {
    const { data } = await getFiltersFromRepo({ type });
    return data.data;
  });
