import { ADMIN, USER } from "./endpoint";
import { SignInAdminPayload, SignInPayload } from "./types";

import { apiRequest } from "core/services/apiServices";

export const signInUserToRepo = (data: SignInPayload) =>
  apiRequest.post(USER.signIn, data);

export const signInAdminToRepo = (data: SignInAdminPayload) =>
  apiRequest.post(
    ADMIN.signIn,
    data,
    {},
    {
      username: process.env.REACT_APP_ADMIN_BASIC_AUTH_USERNAME || "",
      password: process.env.REACT_APP_ADMIN_BASIC_AUTH_PASSWORD || "",
    }
  );
