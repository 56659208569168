import { useCallback, useEffect, useState } from "react";

import HeadStarttTabsGroup from "views/components/headstarttTabs/headstarttTabsGroup";
import { HeadstarttCard } from "views/components/headstarttCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import { SignInView } from "../core/components/signInView";
import SignInWithEmailModalContent from "../core/components/signinWithEmailModalContent";
import SignUpView from "../core/components/signUpView";

enum LoginLayoutTabs {
  SignIn = "Sign In",
  SignUp = "Sign Up",
}

const Login = () => {
  const [activeTab, setActiveTab] = useState<LoginLayoutTabs>(
    LoginLayoutTabs.SignIn
  );
  const handleClick = useCallback((tab: string[]) => {
    setActiveTab(tab[0] as LoginLayoutTabs);
  }, []);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const handleSignInClick = () => setIsSignInOpen(true);

  const handleOnSignUpClick = useCallback(() => {
    setActiveTab(LoginLayoutTabs.SignUp);
  }, []);

  useEffect(() => {
    if (activeTab) {
      handleClick([activeTab]);
    }
  }, [activeTab, handleClick]);

  return (
    <HeadstarttContainer>
      <div className="h-full flex items-center justify-center flex-col py-24">
        <HeadStarttTabsGroup
          activeTabs={[activeTab]}
          tabs={[LoginLayoutTabs.SignIn, LoginLayoutTabs.SignUp]}
          handleTabClick={handleClick}
        />
        <div className="my-4" />
        <HeadstarttCard className="mx-auto w-1/2">
          {activeTab === LoginLayoutTabs.SignIn ? (
            <>
              {isSignInOpen ? (
                <SignInWithEmailModalContent
                  onSignUpClick={handleOnSignUpClick}
                  onEmailModalCancel={() => setIsSignInOpen(false)}
                  onCancel={() => {
                    setIsSignInOpen(false);
                  }}
                />
              ) : (
                <SignInView handleSignInClick={handleSignInClick} />
              )}
            </>
          ) : (
            <>
              <SignUpView />
              <p className="fs-16 my-4">
                Already have account?{" "}
                <span
                  className="font-medium text-primary cursor-pointer"
                  onClick={() => {
                    setActiveTab(LoginLayoutTabs.SignIn);
                  }}
                >
                  Sign In
                </span>
              </p>
            </>
          )}
        </HeadstarttCard>
      </div>
    </HeadstarttContainer>
  );
};

export default Login;
