import { useCallback, useEffect, useState } from "react";

import { ActivityData } from "core/repository/activity/types";
import { getActivityDetailFromRepo } from "core/repository/activity/get/getActivityDetailsFromRepo";
import { useParams } from "react-router-dom";

const useGetActivityDetails = (lazy = false) => {
  const { id = "" } = useParams();
  const [activityDetails, setActivityDetails] = useState<ActivityData>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  const getActivityDetails = useCallback(async (activityId: string) => {
    setLoading(true);
    setError(undefined);
    setActivityDetails(undefined);
    try {
      const { data } = await getActivityDetailFromRepo(activityId);
      setActivityDetails(data.data);
    } catch (err: unknown) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!lazy && id) getActivityDetails(id);
  }, [getActivityDetails, id, lazy]);

  return {
    error,
    loading,
    data: activityDetails,
    getActivityDetails,
    id,
  };
};

export default useGetActivityDetails;
