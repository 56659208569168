type AbsoluteColorBackgroundProps = {
  className?: string;
};
const AbsoluteColorBackground = ({
  className = "bg-primary",
}: AbsoluteColorBackgroundProps) => {
  // get element by id height
  const navbarHeight = document.getElementById("navbarRef")?.clientHeight;
  return (
    <div
      style={{
        marginTop: `${navbarHeight}px`,
      }}
      className={`${className} absolute top-0 bottom-0 left-0 right-0 -z-10 vh-65`}
    />
  );
};

export default AbsoluteColorBackground;
