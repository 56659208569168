import { Navigate, Route, Routes } from "react-router-dom";

import AdminActivityForm from "admin/adminActivity/adminActivityForm";
import AdminActivityList from "admin/adminActivity/adminActivityList";
import AdminActivityPendingReviews from "admin/adminActivity/adminActivityPendingReviews";
import AdminDestinationForm from "admin/adminDestination/adminDestinationForm";
import AdminDestinationList from "admin/adminDestination/adminDestinationList";
import AdminEventList from "admin/adminEvent/adminEventList";
import AdminHostForm from "admin/adminHost/adminHostForm";
import AdminHostList from "admin/adminHost/adminHostList";
import AdminProjectForm from "admin/adminProject/adminProjectForm";
import AdminProjectList from "admin/adminProject/adminProjectList";
import AdminReviewList from "admin/adminReview/adminReviewList";
import AdminSupportRequestList from "admin/adminSupportRequest/adminSupportRequestList";
import AdminUserList from "admin/adminUser/adminUserList";
import Dashboard from "admin/dashboard";
import PageLayout from "admin/layout";
import AdminLogin from "admin/login";
import AboutTheHostPage from "views/layouts/aboutTheHostPage";
import ActivityDetails from "views/layouts/activities/activityDetails";
import ActivityList from "views/layouts/activities/activityList";
import EventDetails from "views/layouts/events/eventDetails";
import EventsList from "views/layouts/events/eventsList";
import Explore from "views/layouts/explore";
import ExploreActivities from "views/layouts/explore/exploreActivities";
import ExploreAll from "views/layouts/explore/exploreAll/exploreAll";
import ExploreDestination from "views/layouts/explore/exploreDestination";
import ExploreEvents from "views/layouts/explore/exploreEvents";
import ExploreProjects from "views/layouts/explore/exploreProjects";
import ForgetPassword from "views/layouts/forgetPassword";
import Home from "views/layouts/home";
import Login from "views/layouts/login";
import BookingDetails from "views/layouts/privateLayouts/booking/bookingDetails";
import BookingList from "views/layouts/privateLayouts/booking/bookingList";
import EditProfile from "views/layouts/privateLayouts/editProfile";
import MyProfile from "views/layouts/privateLayouts/myProfile";
import SavedItems from "views/layouts/privateLayouts/savedItems";
import Support from "views/layouts/privateLayouts/support";
import UserAlerts from "views/layouts/privateLayouts/userAlerts";
import UserReviews from "views/layouts/privateLayouts/userReviews";
import WriteReview from "views/layouts/privateLayouts/writeReview";
import PrivateRoute from "./hoc/privateRoute";
import PrivateRouteAdmin from "./hoc/privateRouteAdmin";
import PublicRoute from "./hoc/publicRoute";

const AppRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PublicRoute
          mode={"dark"}
          title={"Home"}
          description={"Home"}
          keywords={"home"}
        >
          <Home />
        </PublicRoute>
      }
    />
    <Route
      path="/login"
      element={
        <PublicRoute
          withoutFooter
          mode={"dark"}
          title={"Login"}
          description={"Login to book events"}
          keywords={"login"}
        >
          <Login />
        </PublicRoute>
      }
    />
    <Route
      path="/forget-password"
      element={
        <PublicRoute
          withoutFooter
          mode={"dark"}
          title={"Login"}
          description={"Forget password"}
        >
          <ForgetPassword />
        </PublicRoute>
      }
    />
    <Route
      path="/about-the-host/:id"
      element={
        <PublicRoute
          mode={"light"}
          title={"About the host"}
          description={"Events by host"}
          keywords={"events, host"}
        >
          <AboutTheHostPage />
        </PublicRoute>
      }
    />
    <Route
      path="/support"
      element={
        <PublicRoute
          mode={"light"}
          title={"Support"}
          description={"Support"}
          keywords={"support, support"}
        >
          <Support />
        </PublicRoute>
      }
    />
    <Route path="/write-review">
      <Route
        path="/write-review/:id"
        element={
          <PrivateRoute
            mode={"light"}
            title={"Write a review"}
            description={"Write a review"}
          >
            <WriteReview />
          </PrivateRoute>
        }
      />
    </Route>
    <Route
      path="/edit-profile"
      element={
        <PrivateRoute
          mode={"light"}
          title={"Edit Profile"}
          description={"Edit Profile"}
        >
          <EditProfile />
        </PrivateRoute>
      }
    />
    <Route
      path="/saved/:path"
      element={
        <PrivateRoute
          withoutFooter
          mode={"light"}
          title={"Saved Items"}
          description={"Saved Items"}
        >
          <SavedItems />
        </PrivateRoute>
      }
    />

    <Route
      path="/explore"
      element={
        <PublicRoute
          withoutFooter
          title={"Explore"}
          description={"Explore around you"}
          keywords={"explore events and activities"}
          mode={"light"}
        >
          <Explore />
        </PublicRoute>
      }
    >
      <Route path="all" element={<ExploreAll />} />
      <Route path="destinations" element={<ExploreDestination />} />
      <Route path="activities" element={<ExploreActivities />} />
      <Route path="events" element={<ExploreEvents />} />
      <Route path="projects" element={<ExploreProjects />} />
    </Route>

    <Route path="/discover-activities">
      <Route
        path="/discover-activities"
        element={
          <PublicRoute
            title={"Discover Activities"}
            description={"Discover activities around you"}
            keywords={"activities"}
            mode={"dark"}
          >
            <ActivityList />
          </PublicRoute>
        }
      />
      <Route
        path="/discover-activities/:id"
        element={
          <PublicRoute
            title={"Discover Activities"}
            description={"Discover activities around you"}
            keywords={"activities"}
          >
            <ActivityDetails />
          </PublicRoute>
        }
      />
    </Route>
    <Route path="/discover-events">
      <Route
        path="/discover-events"
        element={
          <PublicRoute
            title={"Discover Events"}
            description={"Discover events around you"}
            keywords={"events"}
            mode={"dark"}
          >
            <EventsList />
          </PublicRoute>
        }
      />
      <Route
        path="/discover-events/:id"
        element={
          <PublicRoute
            title={"Discover Events"}
            description={"Discover Events around you"}
            keywords={"events"}
          >
            <EventDetails />
          </PublicRoute>
        }
      />
    </Route>
    <Route path="/bookings">
      <Route
        path="/bookings"
        element={
          <PublicRoute
            title={"My Bookings"}
            description={"My Bookings"}
            keywords={"bookings"}
            mode={"light"}
          >
            <BookingList />
          </PublicRoute>
        }
      />
      <Route
        path="/bookings/:id"
        element={
          <PublicRoute
            title={"My Booking Details"}
            description={"My Booking Details"}
            keywords={"booking details"}
          >
            <BookingDetails />
          </PublicRoute>
        }
      />
    </Route>
    <Route
      path="/my-profile"
      element={
        <PrivateRoute
          mode={"light"}
          title={"My Profile"}
          description={"My Profile"}
        >
          <MyProfile />
        </PrivateRoute>
      }
    />
    <Route
      path="/my-reviews"
      element={
        <PrivateRoute
          mode={"light"}
          title={"User Reviews"}
          description={"User Reviews"}
        >
          <UserReviews />
        </PrivateRoute>
      }
    />
    <Route
      path="/my-alerts"
      element={
        <PrivateRoute
          mode={"light"}
          title={"My Alerts"}
          description={"My Alerts"}
        >
          <UserAlerts />
        </PrivateRoute>
      }
    />

    {/* Admin Routes */}

    <Route path="/admin" element={<PageLayout />}>
      <Route
        path="login"
        element={
          <PublicRoute withoutHeader withoutFooter title={"Admin Login"}>
            <AdminLogin />
          </PublicRoute>
        }
      />
      <Route
        path="dashboard"
        element={
          <PrivateRouteAdmin title={"Admin Dashboard"}>
            <Dashboard />
          </PrivateRouteAdmin>
        }
      />
      <Route path="activity">
        <Route
          path="activity-list"
          element={
            <PrivateRouteAdmin title={"Activity List"}>
              <AdminActivityList />
            </PrivateRouteAdmin>
          }
        />
        <Route
          path="pending-reviews/:id"
          element={
            <PrivateRouteAdmin title={"Pending Review List"}>
              <AdminActivityPendingReviews />
            </PrivateRouteAdmin>
          }
        />
        <Route
          path=":id"
          element={
            <PrivateRouteAdmin title={"Edit Activity"}>
              <AdminActivityForm />
            </PrivateRouteAdmin>
          }
        />
        <Route
          path="add-activity"
          element={
            <PrivateRouteAdmin title={"Add Activity"}>
              <AdminActivityForm />
            </PrivateRouteAdmin>
          }
        />
      </Route>
      <Route path="destination">
        <Route
          path="destination-list"
          element={
            <PrivateRouteAdmin title={"Destination List"}>
              <AdminDestinationList />
            </PrivateRouteAdmin>
          }
        />
        <Route
          path=":id"
          element={
            <PrivateRouteAdmin title={"Edit Destination"}>
              <AdminDestinationForm />
            </PrivateRouteAdmin>
          }
        />
        <Route
          path="add-destination"
          element={
            <PrivateRouteAdmin title={"Add Destination"}>
              <AdminDestinationForm />
            </PrivateRouteAdmin>
          }
        />
      </Route>
      <Route path="event">
        <Route
          path="event-list"
          element={
            <PrivateRouteAdmin title={"Event List"}>
              <AdminEventList />
            </PrivateRouteAdmin>
          }
        />
        <Route
          path="pending-reviews/:id"
          element={
            <PrivateRouteAdmin title={"Pending Review List"}>
              <AdminActivityPendingReviews />
            </PrivateRouteAdmin>
          }
        />
      </Route>
      <Route path="host">
        <Route
          path="host-list"
          element={
            <PrivateRouteAdmin title={"Host List"}>
              <AdminHostList />
            </PrivateRouteAdmin>
          }
        />
        <Route
          path=":id"
          element={
            <PrivateRouteAdmin title={"Edit Host"}>
              <AdminHostForm />
            </PrivateRouteAdmin>
          }
        />
        <Route
          path="add-host"
          element={
            <PrivateRouteAdmin title={"Add Host"}>
              <AdminHostForm />
            </PrivateRouteAdmin>
          }
        />
      </Route>
      <Route path="project">
        <Route
          path="project-list"
          element={
            <PrivateRouteAdmin title={"Project List"}>
              <AdminProjectList />
            </PrivateRouteAdmin>
          }
        />
        <Route
          path=":id"
          element={
            <PrivateRouteAdmin title={"Edit Project"}>
              <AdminProjectForm />
            </PrivateRouteAdmin>
          }
        />
        <Route
          path="add-project"
          element={
            <PrivateRouteAdmin title={"Add Project"}>
              <AdminProjectForm />
            </PrivateRouteAdmin>
          }
        />
      </Route>
      <Route path="review">
        <Route
          path="review-list"
          element={
            <PrivateRouteAdmin title={"Reviews List"}>
              <AdminReviewList />
            </PrivateRouteAdmin>
          }
        />
      </Route>
      <Route path="user">
        <Route
          path="user-list"
          element={
            <PrivateRouteAdmin title={"Users List"}>
              <AdminUserList />
            </PrivateRouteAdmin>
          }
        />
      </Route>
      <Route path="support-request">
        <Route
          path="support-request-list"
          element={
            <PrivateRouteAdmin title={"Support Request List"}>
              <AdminSupportRequestList />
            </PrivateRouteAdmin>
          }
        />
      </Route>
      {/* redirect admin to dashboard */}
      <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
    </Route>

    <Route path="/*" element={<Navigate to="/" replace />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

export default AppRoutes;
