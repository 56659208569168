import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { AxiosResponse } from "axios";
import { useId } from "react";
import { useMutation } from "react-query";

const useDeleteGeneralised = <T>(
  deleteFromRepo: (payload: T) => Promise<AxiosResponse>,
  onSuccess: () => void
) => {
  const mutationKey = useId();
  const { mutateAsync, isLoading } = useMutation(deleteFromRepo, {
    onSuccess: () => {
      successToast("Successfully deleted");
      onSuccess();
    },
    onError: () => {
      errorToast("Something went wrong");
    },
    mutationKey,
  });

  return {
    deleteFromRepo: mutateAsync,
    deleteLoading: isLoading,
  };
};

export default useDeleteGeneralised;
