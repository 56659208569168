import { EventListRoot, EventPayload, EventRoot } from "./types";

import { apiRequest } from "core/services/apiServices";
import { SearchParams } from "./../search/types";
import { checkIfObjectHasValidValue } from "./../utils/checkIfObjectHasValidValue";

export const getEventsFromRepo = (params: SearchParams) =>
  apiRequest.getByQueryParam<EventListRoot>(
    "/user/event/v1/list",
    checkIfObjectHasValidValue(params)
  );

export const getEventByIdFromRepo = (id: string) =>
  apiRequest.getByQueryParam<EventRoot>("/user/event/v1", {
    eventId: id,
  });

export const updateEventToRepo = (payload: EventPayload) =>
  apiRequest.patch("/admin/event/v1", payload);

export const refreshEventsFromRepo = () =>
  apiRequest.post("/admin/event/v1/refreshEvents");
