import CardTag, { TagType } from "../headstarttCard/cardTag";

import { ActivityData } from "core/repository/activity/types";
import ActivityMetaActions from "views/layouts/activities/activityDetails/activityMetaActions";
import CardReviews from "views/components/headstarttCard/cardReviews";
import HeadstarttReadMoreLess from "views/components/headstarttReadMoreLess";

type MetaDetailsProps = {
  title: string;
  description: string;
  id: string;
  isSubscribed?: boolean;
  isBookmark?: boolean;
  rating?: number;
  totalCount?: number;
  additionalStatus?: ActivityData["additionalStatus"];
  category?: ActivityData["category"];
};
type TagRenderer = {
  tag: string;
  type: TagType;
};
const MetaDetails = ({
  title,
  description,
  id,
  isBookmark,
  rating,
  totalCount,
  isSubscribed,
  additionalStatus,
  category,
}: MetaDetailsProps) => {
  const tagsToRender: TagRenderer[] = [
    ...(additionalStatus?.map((status) => ({
      tag: status,
      type: TagType.danger,
    })) || []),
    {
      tag: category || "",
      type: TagType.secondary,
    },
  ];
  return (
    <div>
      <ActivityMetaActions
        isSubscribed={isSubscribed}
        isBookmark={isBookmark}
        id={id}
      />
      <h1 className="fs-48 font-medium">{title}</h1>
      <HeadstarttReadMoreLess sliceLength={200} text={description} />
      <div className="flex items-center mt-4 gap-1">
        {tagsToRender.map(({ tag, type }) => {
          if (!tag) return <></>;
          return <CardTag key={tag} text={tag} type={type} />;
        })}
      </div>
      <CardReviews rating={rating} reviews={totalCount} />
    </div>
  );
};

export default MetaDetails;
