type AdminTabProps = {
  tab: string;
  active: boolean;
  onHandleClick: (tab: string) => void;
};

const AdminTab = ({ tab, active, onHandleClick }: AdminTabProps) => {
  return (
    <div
      onClick={() => onHandleClick(tab)}
      className={`${
        active
          ? "bg-white text-black z-50  font-semibold"
          : "bg-[#D9D9D9] text-gray-500"
      } py-6 px-16 br-t-r-16 br-t-l-16 cursor-pointer fs-14 border border-gray-200 border-b first:ml-0 -ml-4`}
      style={{
        boxShadow: "4px 0px 4px 0px #0000001A",
      }}
    >
      {tab}
    </div>
  );
};

export default AdminTab;
