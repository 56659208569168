import { ActivityTypesRoot } from "core/repository/home/types";
import { getActivityTypes } from "core/repository/home";
import { useQuery } from "react-query";
const useGetActivityType = () => {
  const { data, isLoading, isError } = useQuery<ActivityTypesRoot, Error>(
    "activityTypes",
    async () => {
      const response = await getActivityTypes();
      return response.data;
    }
  );

  return {
    data,
    isLoading,
    isError,
  };
};

export default useGetActivityType;
