import { useLocation, useNavigate } from "react-router-dom";

import AddButton from "./addButton";
import HeadstarttSearch from "../headstarttSearch";
import { debounce } from "lodash";
import queryString from "query-string";
import { useCallback } from "react";

type SecondaryHeaderProps = {
  addButtonProps?: {
    onClick: () => void;
    buttonText: string;
  };
  paginationProps?: {
    total: number;
    current: number;
    limit: number;
  };
};
const SecondaryHeader = ({
  addButtonProps,
  paginationProps,
}: SecondaryHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const navigateHandler = (value: string) => {
    navigate({
      search: `?page=1&search=${value}`,
    });
  };

  const debouncedNavigation = debounce(navigateHandler, 1000);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      debouncedNavigation(value);
    },
    [debouncedNavigation]
  );

  return (
    <div className="flex items-center justify-between my-4 gap-4">
      <HeadstarttSearch
        handleSearchChange={handleSearchChange}
        value={params.search as string}
        style={{
          width: "30%",
          borderRadius: "12px",
          padding: "4px 8px",
          border: "1px solid #006653",
          background: "rgba(72, 185, 178, 0.1)",
        }}
        placeholder="search"
        size="small"
      />
      {paginationProps && (
        <div className="mb-0">
          Showing {paginationProps.limit} of {paginationProps.total}
        </div>
      )}
      {addButtonProps && (
        <AddButton
          onClick={addButtonProps?.onClick}
          buttonText={addButtonProps.buttonText}
        />
      )}
    </div>
  );
};

export default SecondaryHeader;
