import { BellIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import useGetUnreadAlertCount from "core/services/alerts/useGetUnreadAlertCount";

const AlertButton = ({ mode }: { mode: "light" | "dark" }) => {
  const { unreadCount: active } = useGetUnreadAlertCount();

  return (
    <Link
      to="/my-alerts?page=1"
      className={`flex gap-1 items-center border rounded-full px-4 py-1
      ${
        active && mode === "light"
          ? "border-primary"
          : mode === "dark"
          ? "border-white"
          : "border-black"
      }} 
   `}
    >
      <div className="relative">
        {!!active && (
          <div className="h-2 w-2 bg-orange-600 absolute -right-1 -top-0.5 rounded-full" />
        )}
        <BellIcon
          color={
            active && mode === "light"
              ? "#006653"
              : mode === "dark"
              ? "#fff"
              : "#000"
          }
          height={18}
        />
      </div>
      <span
        className={`font-bold ${
          active && mode === "light"
            ? "text-primary"
            : mode === "dark"
            ? "text-white"
            : "text-black"
        }`}
      >
        Alerts
      </span>
    </Link>
  );
};

export default AlertButton;
