const CoverSectionText = () => {
  return (
    <div className="flex flex-col justify-center items-center text-center my-8">
      <h1 className="fs-32 text-white mb-0 font-bold">
        Your Guide to Getting Outside
      </h1>
      <p className="fs-16 font-medium text-white">
        Great places and memories, at your fingertips
      </p>
    </div>
  );
};

export default CoverSectionText;
