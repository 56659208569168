import { BannerPayload } from "core/repository/common/types";
import { patchBannerDetails } from "core/repository/common";
import { useMutation } from "react-query";

export const useUpdateBannerDetails = (
  onSuccess?: () => void,
  onError?: () => void
) =>
  useMutation(async (payload: BannerPayload) => patchBannerDetails(payload), {
    onSuccess,
    onError,
  });
