import AdminImageUploader from "admin/components/adminImageUploader";
import FormFooter from "views/components/headstarttForm/formFooter";
import FormGroup from "views/components/headstarttForm/formGroup";
import { HeadstarttForm } from "views/components/headstarttForm";
import { HostPayload } from "core/repository/host/types";
import { useState } from "react";

const NameAndDescriptionForm = ({
  payload,
  setPayload,
  onNext,
}: {
  payload: HostPayload;
  setPayload: (payload: HostPayload) => void;
  onNext: () => void;
}) => {
  const [form] = HeadstarttForm.useForm();
  const [uploadedImages, setUploadedImages] = useState<string[]>(
    payload?.profilePicture ? [payload?.profilePicture] : []
  );

  return (
    <HeadstarttForm
      form={form}
      onFinish={(values) => {
        setPayload({
          ...values,
          profilePicture: uploadedImages[0],
        });
        onNext();
      }}
      className="w-full space-y-8"
      initialValues={{
        firstName: payload?.firstName,
        lastName: payload?.lastName,
        introduction: payload?.introduction,
      }}
    >
      <FormFooter
        flexClass="flex items-center justify-end gap-2"
        showBackButton={false}
        showSaveButton={false}
        onCancelButtonClick={() => {
          form.resetFields();
        }}
      />

      <FormGroup
        label={"First Name"}
        name={"firstName"}
        description="Enter first name"
        placeholder="Enter first name"
      />
      <FormGroup
        label={"Last Name"}
        name={"lastName"}
        description="Enter last name"
        placeholder="Enter last name"
      />
      <FormGroup
        label="Host Introduction"
        name="introduction"
        description="Enter introduction"
        placeholder="Enter introduction"
        inputType="textarea"
      />
      <AdminImageUploader
        label={"Profile Image"}
        name={"profilePicture"}
        description="Upload profile image"
        multiple={false}
        setUploadedImages={setUploadedImages}
        uploadedImages={uploadedImages}
        form={form}
      />
    </HeadstarttForm>
  );
};

export default NameAndDescriptionForm;
