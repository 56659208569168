import { useLocation, useNavigate } from "react-router-dom";

import AdminCard from "admin/components/adminCard";
import ErrorCard from "views/components/errorCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttPagination from "views/components/headstarttPagination";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PageHeader from "admin/components/pageHeader";
import ProjectTableRow from "./projectTableRow";
import SecondaryHeader from "views/components/secondaryHeader";
import { deleteReviewFromRepo } from "core/repository/reviews";
import { useCallback } from "react";
import useDeleteGeneralised from "core/hooks/useDeleteGeneralised";
import useGetReviews from "core/services/reviews/useGetReviews";

const AdminReviewList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, isLoading, isError, offset, refetch } = useGetReviews();

  const handleChangePage = useCallback(
    (page: number) => {
      navigate(`${location.pathname}?page=${page}`);
    },
    [location.pathname, navigate]
  );
  const onSuccess = useCallback(() => {
    refetch();
  }, [refetch]);
  const { deleteFromRepo } = useDeleteGeneralised(
    deleteReviewFromRepo,
    onSuccess
  );
  const onDelete = useCallback(
    (id: string) => {
      deleteFromRepo(id);
    },
    [deleteFromRepo]
  );

  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  if (!data?.data.total) return <>Data Not Found</>;
  return (
    <div>
      <PageHeader heading="Reviews List" />
      <HeadstarttContainer>
        <SecondaryHeader
          paginationProps={{
            total: data?.data.total,
            current: offset,
            limit: data.data.list.length,
          }}
        />
        <div className="py-4">
          <AdminCard>
            <ProjectTableRow
              onDelete={onDelete}
              onSuccess={onSuccess}
              reviews={data?.data.list}
            />
          </AdminCard>
        </div>
        <div className="flex justify-center my-4">
          <HeadstarttPagination
            total={data?.data.total}
            current={offset}
            onChange={handleChangePage}
          />
        </div>
      </HeadstarttContainer>
    </div>
  );
};

export default AdminReviewList;
