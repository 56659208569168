import { ChevronLeft, ChevronRight } from "views/components/icons";

import { Calendar } from "views/components/headstarttCalendar";

const ActivityCalendar = ({ availableDates }: { availableDates: string[] }) => {
  return (
    <div className="select-none">
      <div className="border br-8 mb-2" style={{ width: "300px" }}>
        <Calendar
          style={{
            background: "transparent",
          }}
          dateFullCellRender={(date) => {
            return (
              <div
                className={
                  "flex justify-center items-center p-2" +
                  `${
                    availableDates
                      ?.map((d) => d.split("T")[0])
                      ?.includes(date.format("YYYY-MM-DD"))
                      ? " bg-green-50 "
                      : " bg-orange-50"
                  }`
                }
              >
                <div className="text-center">
                  <div className="fs-14 text-black">{date.format("DD")}</div>
                </div>
              </div>
            );
          }}
          headerRender={({ value, onChange }) => (
            <div className="p-3 flex items-center justify-between">
              <div className="fs-16 font-medium">
                {value && value.format("MMMM YYYY")}
              </div>
              <div className="flex items-center justify-center gap-4">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    onChange(value.clone().subtract(1, "month"));
                  }}
                >
                  <ChevronLeft height={15} />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    onChange(value.clone().add(1, "month"));
                  }}
                >
                  <ChevronRight height={15} />
                </div>
              </div>
            </div>
          )}
          fullscreen={false}
        />
      </div>
    </div>
  );
};

export default ActivityCalendar;
