import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useSearchContext } from "context/searchContext";
import { HeadstarttCheckbox } from "../headstarttCheckbox";
import FilterHeader from "./filterHeader";

const FacilitiesFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { filters } = useSearchContext();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const facilities = searchParams.get("facilities");
  const [selectedFacilities, setSelectedFacilities] = useState<string[]>(
    facilities ? facilities.split(",") : []
  );

  useEffect(() => {
    if (selectedFacilities.length > 0) {
      searchParams.set("facilities", selectedFacilities.join(","));
    } else {
      searchParams.delete("facilities");
    }
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [selectedFacilities, location.pathname, navigate, searchParams]);
  if (!filters?.facilities?.length) return null;
  return (
    <div>
      <FilterHeader
        heading={"Facilities"}
        handleClear={() => {
          setSelectedFacilities([]);
        }}
      />
      <div className="grid grid-cols-2">
        {filters?.facilities.map((item) => (
          <div key={item.value} className="space-y-2 col-span-1">
            <div className="flex gap-2">
              <HeadstarttCheckbox
                checked={selectedFacilities.includes(item.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedFacilities([...selectedFacilities, item.value]);
                  } else {
                    setSelectedFacilities(
                      selectedFacilities.filter((fac) => fac !== item.value)
                    );
                  }
                }}
              >
                <span className="fs-14 text-muted font-medium">
                  {item.label}
                </span>
              </HeadstarttCheckbox>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FacilitiesFilter;
