export const USER = {
  signIn: "user/v1/signIn",
  signUp: "user/v1/signUp",
  getJwtToken: "user/v1/token",
  user: "user/v1",
  userReviews: "user/v1/listUserReviews",
  subscribeNotification: "user/v1/subscribeNotification",
  unsubscribeNotification: "user/v1/unsubscribeNotification",
};
export const ADMIN = {
  signIn: "admin/v1/signIn",
  getUser: "admin/user/v1/list",
  patchUser: "admin/user/v1",
};
