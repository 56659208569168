import { FilterTypes, FiltersData } from "core/repository/search/types";
import { ReactNode, createContext, useContext, useState } from "react";

import { Spin } from "antd";
import { useGetAllFilters } from "core/services/search";
import { useSearchParams } from "react-router-dom";
import ErrorCard from "views/components/errorCard";

type SearchContextValue = {
  filters: FiltersData | undefined;
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  setType: (type: FilterTypes) => void;
  isLoading?: boolean;
  isError?: boolean;
};

interface SearchContextProps {
  children: ReactNode;
}
export const SearchContext = createContext<SearchContextValue>({
  filters: undefined,
  setType: () => {},
  isError: false,
  isLoading: false,
  searchParams: new URLSearchParams(),
  setSearchParams: () => {},
});

export const SearchContextProvider = ({ children }: SearchContextProps) => {
  const [type, setType] = useState<FilterTypes>(FilterTypes.ALL);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: filters, isLoading, isError } = useGetAllFilters(type);
  if (isLoading) return <Spin />;
  if (isError) return <ErrorCard />;
  return (
    <SearchContext.Provider
      value={{
        filters,
        setType,
        isLoading,
        isError,
        searchParams,
        setSearchParams,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);
