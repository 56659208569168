import { DownloadTwo, Headphone, Pause, Play } from "../icons";
import { useCallback, useState } from "react";

const AudioCard = ({ url, index }: { url: string; index: number }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayClick = useCallback(() => {
    const audio = document.getElementById(
      `audio-${url}-${index}`
    ) as HTMLAudioElement;
    if (audio) {
      setIsPlaying(!isPlaying);
      audio.play();
      audio.onended = () => {
        setIsPlaying(false);
      };
      audio.onpause = () => {
        setIsPlaying(false);
      };
      audio.onplay = () => {
        setIsPlaying(true);
      };
    }
    if (isPlaying) {
      audio.pause();
    }
  }, [url, index, isPlaying]);

  return (
    <div className="border p-4 br-8">
      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-2">
          <Headphone />
          <p className="mb-0">
            <span className="fs-16 font-bold text-primary">
              Audio File 0{index}
            </span>
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <div onClick={handlePlayClick}>
            {isPlaying ? <Pause /> : <Play />}
          </div>

          <a href={url} download>
            <DownloadTwo />
          </a>
        </div>
      </div>

      <audio
        id={`audio-${url}-${index}`}
        src={url}
        controls
        className="w-full py-2 hidden"
      />
    </div>
  );
};

export default AudioCard;
