import {
  addActivityToRepo,
  updateActivityToRepo,
} from "core/repository/activity";
import { useCallback, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ADMIN_ACTIVITY_TABS } from "admin/adminConstants";
import ActivitiesAndFacilitiesForm from "./activitiesAndFacilitiesForm";
import { ActivityPayload } from "core/repository/activity/types";
import AdminTabsGroup from "admin/components/adminTabsGroup";
import ContactDetailsForm from "./contactDetailsForm";
import DurationAndLocationForm from "./durationAndLocationForm";
import { HeadstarttCard } from "views/components/headstarttCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import NameAndDescriptionForm from "./nameAndDescriptionForm";
import PageHeader from "admin/components/pageHeader";
import TrailsForm from "./trailsForm";
import querystring from "query-string";
import useAddUpdateGeneralized from "core/hooks/useAddUpdateGeneralized";
import useGetActivityDetails from "core/services/activity/useGetActivityDetails";

const AdminActivityForm = () => {
  const navigate = useNavigate();
  const [payload, setPayload] = useState<ActivityPayload>(
    {} as ActivityPayload
  );

  const location = useLocation();
  const params = querystring.parse(location.search);

  const { error, loading, data, id } = useGetActivityDetails();
  const { addUpdate, mutationLoading } =
    useAddUpdateGeneralized<ActivityPayload>(
      addActivityToRepo,
      updateActivityToRepo,
      id
    );

  const onFinish = useCallback(
    async (updatedPayload: ActivityPayload) => {
      await addUpdate(updatedPayload);
      navigate("/admin/activity/activity-list");
    },
    [addUpdate, navigate]
  );

  useLayoutEffect(() => {
    if (data && id && !loading) {
      const newPayload: Partial<ActivityPayload> = {
        title: data.title,
        description: data.description,
        destinationId: data.destinationId,
        status: data.status,
        additionalStatus: data.additionalStatus,
        orcs: data.orcs,
        category: data.category,
        operationDate: {
          operationDateDescription: data.operationDate.operationDateDescription,
          availableDates: data.operationDate.availableDates,
          openMonths: data.operationDate.openMonths,
          campgroundOpenMonths: data.operationDate.campgroundOpenMonths,
        },
        instructions: data.instructions,
        locationDetail: {
          locationDescription: data.locationDetail.locationDescription,
          latitude: data.locationDetail.latitude,
          longitude: data.locationDetail.longitude,
        },
        contactDetail: data.contactDetail,
        trail: data.trail,
        images: data.images,
        facility: data.facility.map((it) => it._id),
        subActivity: data.subActivity.map((it) => it._id),
      };
      if (id) {
        newPayload.activityId = id;
      }
      setPayload(newPayload as ActivityPayload);
    }
  }, [data, id, loading]);

  const getView = () => {
    if (!Object.keys(payload).length && id && !loading)
      return <div>No Payload</div>;
    switch (params.tab) {
      case ADMIN_ACTIVITY_TABS[0]:
      default:
        return (
          <NameAndDescriptionForm setPayload={setPayload} payload={payload} />
        );
      case ADMIN_ACTIVITY_TABS[1]:
        return (
          <DurationAndLocationForm payload={payload} setPayload={setPayload} />
        );
      case ADMIN_ACTIVITY_TABS[2]:
        return <ContactDetailsForm payload={payload} setPayload={setPayload} />;
      case ADMIN_ACTIVITY_TABS[3]:
        return (
          <ActivitiesAndFacilitiesForm
            payload={payload}
            subActivity={data?.subActivity || []}
            facility={data?.facility || []}
            setPayload={setPayload}
          />
        );
      case ADMIN_ACTIVITY_TABS[4]:
        return (
          <TrailsForm
            loading={mutationLoading}
            onFinish={onFinish}
            payload={payload}
            setPayload={setPayload}
          />
        );
    }
  };
  if (loading) return <LoaderFullScreen />;
  if (error) return <div>Error</div>;
  if (!data && id) return <div>No Data</div>;
  if (!payload && id) return <div>No Payload</div>;
  return (
    <div>
      <PageHeader heading={`${id ? "Edit" : "Add"} Activity`} />
      <HeadstarttContainer>
        <div className="py-4">
          <AdminTabsGroup tabs={ADMIN_ACTIVITY_TABS} />
          <HeadstarttCard className="-mt-1">
            <h1 className="fs-18 font-light  border-b mb-4">{params.tab}</h1>
            <div className="p-2">{getView()}</div>
          </HeadstarttCard>
        </div>
      </HeadstarttContainer>
    </div>
  );
};

export default AdminActivityForm;
