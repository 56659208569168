import { ReactNode } from "react";

export type CardOverlayProps = {
  children: ReactNode;
  className?: string;
};
const CardOverlay = ({ children, className = "" }: CardOverlayProps) => (
  <div
    className={`absolute bottom-0 left-0 right-0 border-0 ${
      className ? className : "p-4 bg-white opacity-90"
    }`}
  >
    {children}
  </div>
);
export default CardOverlay;
