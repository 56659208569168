import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { UserReviewsRoot } from "core/repository/user/types";
import getUserReviewsFromRepo from "core/repository/user/getUserReviewsFromRepo";
import querystring from "query-string";

const LIMIT = 10;
const useGetUserReviews = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = querystring.parse(location.search);
  const page = parseInt(params.page as string);
  const [reviews, setReviews] = useState<UserReviewsRoot>();
  const [error, setError] = useState<boolean>(false);
  const [loader, setLoader] = useState(true);

  const getUserReviews = useCallback(async () => {
    setLoader(true);
    setError(false);
    try {
      const { data } = await getUserReviewsFromRepo(LIMIT, Number(page) - 1);
      setReviews(data);
    } catch (err) {
      setError(true);
    }
    setLoader(false);
  }, [page]);

  const handlePagination = useCallback(
    (p: number) => {
      navigate({
        pathname: location.pathname,
        search: `?page=${p}`,
      });
    },
    [location.pathname, navigate]
  );

  useEffect(() => {
    getUserReviews();
  }, [getUserReviews, navigate, location.pathname]);

  return {
    reviews: reviews?.data,
    error,
    loader,
    getUserReviews,
    page: parseInt(params.page as string),
    handlePagination,
  };
};

export default useGetUserReviews;
