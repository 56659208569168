import { ContactDetail } from "core/repository/activity/types";
import { ParkShield } from "views/components/icons";

const ParkContact = ({ contact }: { contact: ContactDetail }) => {
  return (
    <div className="border br-16 p-4">
      <ParkShield />
      <div className="mt-4">
        <p className="font-medium mb-1">This park proudly operated by:</p>
        <p>{contact.operatedBy}</p>
        <div className="my-4" />
        <p className="mb-1">{contact.email}</p>
        <p className="mb-0">{contact.mobileNumber}</p>
      </div>
    </div>
  );
};

export default ParkContact;
