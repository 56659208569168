import { Activity } from "core/repository/activity/types";
import CardReviews from "./cardReviews";
import CardTag from "./cardTag";
import HeadstarttLikeButton from "../headstarttButton/headstarttLikeButton";

type BannerCardProps = {
  id: string;
  isBookmark: boolean;
  title: string;
  description: string;
  reviews: number;
  ratings: number;
  distance?: string;
  locationDetail?: Activity["locationDetail"];
  category: string;
  openStatus?: boolean;
  onClick?: () => void;
};

const BannerCardFooter = ({ distance = "" }: { distance: string }) => {
  return (
    <div className="flex justify-end">
      <p className="text-gray-500 fs-12 mb-0">{distance}</p>
    </div>
  );
};
const BannerCard = ({
  id,
  title,
  description,
  ratings,
  reviews,
  openStatus,
  category,
  distance,
  isBookmark,
  onClick,
}: BannerCardProps) => (
  <div className="br-16 py-4 px-8 bg-white md:w-2/5" onClick={onClick}>
    <div className="flex justify-between mb-2">
      <h2 className="fs-32 mb-0">{title}</h2>
      <HeadstarttLikeButton
        className="bg-gray-300 shadow h-12 w-12 flex items-center justify-center"
        id={id}
        type={"ACTIVITY"}
        isBookmark={isBookmark}
      />
    </div>
    <p className="text-gray-500 fs-14">{description}</p>
    <div className="flex gap-2 mb-2">
      <CardTag text={openStatus ? "Open" : "Closed"} />
      {!!category && <CardTag text={category} />}
    </div>
    <CardReviews rating={ratings} reviews={reviews} />
    {!!distance && <BannerCardFooter distance={distance} />}
  </div>
);

export default BannerCard;
