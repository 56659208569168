import {
  getDestinationByIdFromRepo,
  getDestinationFromRepo,
} from "core/repository/destination";
import {
  getHostFromRepo,
  getHostListFromRepo,
} from "core/repository/host/getHostFromRepo";
import { useCallback, useEffect, useState } from "react";
import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { Spin } from "antd";
import { Destination } from "core/repository/destination/types";
import { updateEventToRepo } from "core/repository/event";
import { EventPayload } from "core/repository/event/types";
import { Host } from "core/repository/host/types";
import { HeadstarttForm } from "views/components/headstarttForm";
import FormFooter from "views/components/headstarttForm/formFooter";
import FormGroup from "views/components/headstarttForm/formGroup";
import { SelectSearch } from "views/components/headstarttSearch/selectSearch";

const AdminEventForm = ({
  eventPayload,
  onComplete,
}: {
  eventPayload: EventPayload;
  onComplete: () => void;
}) => {
  const [formLoader, setFormLoader] = useState(false);
  const [form] = HeadstarttForm.useForm();
  const [destById, setDestById] = useState<Destination | undefined>(undefined);
  const [hostBydId, setHostById] = useState<Host | undefined>(undefined);
  const [payload, setPayload] = useState<EventPayload>(eventPayload);
  const [loading, setLoading] = useState<boolean>(false);
  const [hostLoading, setHostLoading] = useState<boolean>(false);
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [hosts, setHosts] = useState<Host[]>([]);
  useEffect(() => {
    const getDestinationById = async () => {
      if (payload.destinationId) {
        const { data } = await getDestinationByIdFromRepo(
          payload.destinationId
        );
        if (data) {
          setDestById(data.data);
        }
      }
    };
    getDestinationById();
  }, [payload.destinationId, form, payload]);
  useEffect(() => {
    const getHostById = async () => {
      if (payload.hostId) {
        const { data } = await getHostFromRepo({
          hostId: payload.hostId,
        });
        if (data) {
          setHostById(data.data);
        }
        setPayload({
          ...payload,
          hostId: data.data._id,
        });
      }
    };
    getHostById();
  }, [payload.hostId, form, payload]);
  const handleSearch = useCallback((value: string) => {
    setLoading(true);
    setDestinations([]);
    getDestinationFromRepo({
      limit: 25,
      offset: 0,
      search: value,
    })
      .then((res) => {
        setDestinations(res.data.data.list);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err.message);
      });
  }, []);
  const handleSearchForHost = useCallback((value: string) => {
    setHostLoading(true);
    setHosts([]);
    getHostListFromRepo({
      limit: 25,
      offset: 0,
      search: value,
    })
      .then((res) => {
        setHosts(res.data.data.list);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err.message);
      });
  }, []);
  return (
    <HeadstarttForm
      form={form}
      initialValues={payload}
      onFinish={async (values) => {
        setFormLoader(true);
        const newPayload = {
          ...payload,
          ...values,
          orcs: parseInt(values.orcs),
        };
        try {
          await updateEventToRepo(newPayload);
          setFormLoader(false);
          successToast("Event updated successfully.");
          onComplete();
        } catch (err) {
          setFormLoader(false);
          errorToast("Something went wrong. Please try again later.");
        }
      }}
    >
      <FormGroup
        label={
          destById?.destinationName
            ? `Selected Destination - ${destById.destinationName}`
            : "Destination"
        }
        name={"destinationId"}
        description="Search for a destination"
        placeholder="Select a destination"
        inputType="children"
      >
        <SelectSearch
          size="large"
          showSearch
          className="activity-select-small"
          clearIcon
          filterOption={false}
          loading={hostLoading}
          value={
            destinations.find(
              (destination) => destination._id === payload.destinationId
            )?.destinationName
          }
          onChange={(value) => {
            form.setFieldsValue({
              destinationId: value,
            });
          }}
          onSearch={handleSearch}
          notFoundContent={loading ? <Spin size="small" /> : null}
          options={destinations.map((destination) => ({
            label: destination.destinationName,
            value: destination._id,
          }))}
        />
      </FormGroup>
      <FormGroup
        label={
          hostBydId?.firstName
            ? `Selected Host - ${
                hostBydId.firstName + " " + hostBydId.lastName
              }`
            : "Host"
        }
        name={"hostId"}
        description="Search for a host"
        placeholder="Select a host"
        inputType="children"
      >
        <SelectSearch
          size="large"
          showSearch
          className="activity-select-small"
          clearIcon
          filterOption={false}
          loading={loading}
          value={hosts.find((host) => host._id === payload.hostId)?._id}
          onChange={(value) => {
            form.setFieldsValue({
              hostId: value,
            });
          }}
          onSearch={handleSearchForHost}
          notFoundContent={loading ? <Spin size="small" /> : null}
          options={hosts.map((host) => ({
            label: host.firstName + " " + host.lastName,
            value: host._id,
          }))}
        />
      </FormGroup>
      <FormGroup
        label={"ORCS"}
        name={"orcs"}
        description="Enter ORCS"
        placeholder="Enter ORCS"
        type="number"
      />
      <FormFooter
        showBackButton={false}
        showCancelButton={false}
        showNextButton={false}
        showSaveButton
        saveButtonDisabled={formLoader}
      />
    </HeadstarttForm>
  );
};

export default AdminEventForm;
