import BookingCard from "../components/bookingCard";
import ErrorCard from "views/components/errorCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PrimaryHeading from "views/components/typography/primaryHeading";
import useGetBookingById from "core/services/bookings/useGetBookingById";

const BookingDetails = () => {
  const { bookingDetails, isError, isLoading } = useGetBookingById();
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  if (!bookingDetails) return <div>No data available</div>;
  return (
    <HeadstarttContainer>
      <div className="py-24">
        <PrimaryHeading heading="Booking Details" />
        <BookingCard booking={bookingDetails.data} />
      </div>
    </HeadstarttContainer>
  );
};

export default BookingDetails;
