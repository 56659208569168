import { Check, Cross } from "../icons";
import { useRef, useState } from "react";

import { HeadstarttButton } from "../headstarttButton";
import useGetUser from "core/services/user/useGetUser";
import useScript from "core/hooks/useScript";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const DROPLET: any;

const CheckoutButton = ({
  checkfrontProductId,
}: {
  checkfrontProductId: number;
}) => {
  const { user, getUser } = useGetUser(true);
  const [isOpen, setIsOpen] = useState(false);
  const checkfrontRef = useRef({
    render: () => {},
    close: () => {},
  });
  // const { createBooking, isLoading } = useCreateBooking();
  const status = useScript(`//test-discobc.checkfront.com/lib/interface--0.js`);

  if (status === "loading") {
    return <div>Loading...</div>;
  }
  if (typeof DROPLET !== "undefined") {
    // initiate instance
    checkfrontRef.current = new DROPLET.Widget({
      host: "test-discobc.checkfront.com",
      target: "CHECKFRONT_WIDGET_01",
      options: "tabs",
      provider: "droplet",
      popup: 1,
      item_id: checkfrontProductId,
      tid: user?._id,
    });
  }

  return (
    <>
      <div
        className={
          isOpen
            ? "fixed top-0 bottom-0 left-0 right-0  z-50 bg-white border-2 md:m-24 br-16 shadow overflow-scroll"
            : "hidden"
        }
        id="CHECKFRONT_WIDGET_01"
      >
        {isOpen && (
          <div
            className="absolute top-0 right-0 m-2 cursor-pointer z-50"
            onClick={() => {
              setIsOpen(false);
            }}
            style={{
              zIndex: "9999",
            }}
          >
            <div className="bg-white rounded-full p-2 shadow">
              <Cross height={24} />
            </div>
          </div>
        )}
      </div>
      <HeadstarttButton
        onClick={async () => {
          if (checkfrontRef.current) {
            await getUser();
            setIsOpen(true);
            checkfrontRef.current.render();
          }
        }}
        shape="circle"
      >
        <div className="flex px-24 items-center gap-4">
          <Check height={24} />
          Checkout
        </div>
      </HeadstarttButton>
    </>
  );
};
export default CheckoutButton;
