import { ExploreNav } from "./types";

export const EXPLORE_NAV: ExploreNav[] = [
  {
    title: "All",
    path: "all",
  },
  {
    title: "Destinations",
    path: "destinations",
  },
  {
    title: "Activities",
    path: "activities",
  },
  {
    title: "Events",
    path: "events",
  },
  {
    title: "Projects",
    path: "projects",
  },
];
