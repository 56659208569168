import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Divider } from "antd";
import { useSearchContext } from "context/searchContext";
import { HeadstarttCheckbox } from "../headstarttCheckbox";
import FilterHeader from "./filterHeader";

const GroupsFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { filters } = useSearchContext();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const groups = searchParams.get("groups");
  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    groups ? groups.split(",") : []
  );

  useEffect(() => {
    if (selectedGroups.length > 0) {
      searchParams.set("groups", selectedGroups.join(","));
    } else {
      searchParams.delete("groups");
    }
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [selectedGroups, location.pathname, navigate, searchParams]);
  if (!filters?.groups?.length) return null;
  return (
    <div>
      <FilterHeader
        heading={"Group"}
        handleClear={() => {
          setSelectedGroups([]);
        }}
      />
      <div className="grid grid-cols-2 gap-4">
        {filters?.groups.map((item) => (
          <div key={item.value} className="space-y-2 col-span-1">
            <div className="flex gap-2">
              <HeadstarttCheckbox
                checked={selectedGroups.includes(item.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedGroups([...selectedGroups, item.value]);
                  } else {
                    setSelectedGroups(
                      selectedGroups.filter(
                        (difficulty) => difficulty !== item.value
                      )
                    );
                  }
                }}
              >
                <span className="fs-14 text-muted font-medium">
                  {item.label}
                </span>
              </HeadstarttCheckbox>
            </div>
          </div>
        ))}
      </div>
      <Divider />
    </div>
  );
};

export default GroupsFilter;
