import { HeadstarttModal } from "views/components/headstarttModal";
import SearchBody from "views/components/headstarttModal/searchBody";

type SearchModalProps = {
  onCancel: () => void;
  showModal: boolean;
};

const SearchModal = ({ showModal, onCancel }: SearchModalProps) => (
  <HeadstarttModal
    closable={false}
    open={showModal}
    onCancel={onCancel}
    footer={null}
    maskStyle={{
      backgroundColor: "rgba(256, 256, 256, 0.95)",
    }}
    className="headstartt-antd-modal"
    width={600}
    centered
    destroyOnClose={true}
  >
    <SearchBody onCancel={onCancel} />
  </HeadstarttModal>
);

SearchModal.propTypes = {};

export default SearchModal;
