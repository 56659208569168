import ActionDropdown from "./actionDropdown";
import BookmarkButton from "./bookmarkButton";
import EditButton from "./editButton";
type QuickActionHolderProps = {
  onEdit?: () => void;
  onBookmark?: () => void;
  onDelete?: () => void;
  showEdit?: boolean;
  showBookmark?: boolean;
  showAction?: boolean;
  isBookmarked?: boolean;
  disabledBookmark?: boolean;
};
const QuickActionHolder = ({
  onEdit,
  onBookmark,
  showBookmark = true,
  showEdit = true,
  onDelete,
  isBookmarked,
  disabledBookmark,
}: QuickActionHolderProps) => {
  return (
    <div className="flex items-center gap-4 justify-start">
      {showEdit && onEdit && <EditButton onClick={onEdit} />}
      {showBookmark && (
        <BookmarkButton
          onClick={onBookmark}
          disabled={disabledBookmark}
          isActive={isBookmarked}
        />
      )}
      <ActionDropdown handleDelete={onDelete} />
    </div>
  );
};

export default QuickActionHolder;
