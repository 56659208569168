import { ReactNode } from "react";

type InputLabelProps = {
  children: ReactNode;
  className?: string;
};

const InputLabel = ({
  children,
  className = "fs-16 font-medium",
}: InputLabelProps) => <label className={` ${className}`}>{children}</label>;

export default InputLabel;
