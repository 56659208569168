import { useLocation, useNavigate } from "react-router-dom";

import { HostDataRoot } from "core/repository/host/types";
import { getHostListFromRepo } from "core/repository/host/getHostFromRepo";
import { parse } from "query-string";
import { useEffect } from "react";
import { useQuery } from "react-query";

const LIMIT = 12;

const useGetHostList = (redirectToPageParam = true) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = parse(location.search);
  const pageFromUrl = queryParams.page ?? "1";
  const offset = Number(pageFromUrl);
  const search = queryParams.search ?? "";
  const { data, isLoading, isError, refetch } = useQuery<HostDataRoot, Error>(
    ["hosts", offset, search],
    async () => {
      const { data: responseData } = await getHostListFromRepo({
        limit: LIMIT,
        offset: offset - 1,
        search: search as string,
      });
      return responseData;
    }
  );

  useEffect(() => {
    if (redirectToPageParam) {
      navigate({
        pathname: location.pathname,
        search: `?page=${offset}`,
      });
    }
  }, [location.pathname, navigate, redirectToPageParam, offset]);

  return {
    data,
    isError,
    isLoading,
    offset,
    refetch,
  };
};

export default useGetHostList;
