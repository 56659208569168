import { useLocation, useNavigate } from "react-router-dom";

import { PaginationParams } from "core/repository/types";
import { ReviewsRoot } from "core/repository/reviews/types";
import { getReviewsFromRepo } from "core/repository/reviews";
import { parse } from "query-string";
import { useEffect } from "react";
import { useQuery } from "react-query";

const LIMIT = 10;

const useGetReviews = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = parse(location.search);
  const offset = Number(params.page) || 1;
  const search = params.search || "";
  const { data, isLoading, isError, refetch } = useQuery<ReviewsRoot, Error>(
    ["reviewsList", offset, search],

    async () => {
      const { data: responseData } = await getReviewsFromRepo({
        limit: LIMIT,
        offset: offset - 1,
        search: search as string,
      });
      return responseData;
    }
  );
  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: `?page=${offset}&search=${search}`,
    });
  }, [navigate, offset, location.pathname, search]);

  return {
    data,
    isLoading,
    isError,
    offset,
    refetch,
  };
};

export default useGetReviews;

export const useGetReviewsByParams = (params: PaginationParams) =>
  useQuery<ReviewsRoot, Error>(
    [
      "reviewsListForEventActivity",
      params.offset,
      params.search,
      params.activityEventId,
    ],
    async () => {
      const { data: responseData } = await getReviewsFromRepo(params);
      return responseData;
    }
  );
