import HeadstarttReadMoreLess from "views/components/headstarttReadMoreLess";
import { ReactNode } from "react";
type EventInformationProps = {
  heading?: string;
  title?: string | number | ReactNode;
  description?: string;
  icon?: ReactNode;
};
const EventInformation = ({
  heading,
  title,
  description,
  icon,
}: EventInformationProps) => {
  return (
    <>
      <h2 className="fs-32 font-medium">{heading}</h2>
      <div className="border br-16 p-4">
        {icon}
        <div className="mt-4">
          <h3 className="fs-24 font-medium">{title}</h3>
          {description && <HeadstarttReadMoreLess text={description} />}
        </div>
      </div>
    </>
  );
};

export default EventInformation;
