import { Destination } from "core/repository/destination/types";
import { useNavigate } from "react-router-dom";
import CardSlider from "views/components/cardSlider";
import { HeadstarttButton } from "views/components/headstarttButton";
import { DestinationCardSmall } from "views/components/headstarttCard/destinationCard";
const DestinationSlider = ({ destination }: { destination: Destination[] }) => {
  const navigate = useNavigate();
  return (
    <CardSlider>
      {destination.map((dest, i) => {
        return i === destination.length - 1 ? (
          <div className="br-16 relative w-52 h-64 mx-2 ">
            <div className="flex flex-col items-center h-full justify-center">
              <HeadstarttButton
                type="ghost"
                onClick={() => navigate("/explore/destinations")}
              >
                Show more
              </HeadstarttButton>
            </div>
          </div>
        ) : (
          <DestinationCardSmall key={dest._id} destination={dest} />
        );
      })}
    </CardSlider>
  );
};

export default DestinationSlider;
