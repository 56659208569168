import { Divider } from "antd";
import { HeadstarttRating } from "views/components/headstarttRating";
import { HostData } from "core/repository/host/types";
import QuickActionHolder from "admin/components/quickActionHolder";
import { useNavigate } from "react-router-dom";

const HostTableRow = ({
  list,
  onDelete,
}: {
  list: HostData["list"];
  onDelete: (id: string) => void;
}) => {
  const navigate = useNavigate();
  return (
    <>
      {list.map((host) => (
        <div key={host._id}>
          <div className="grid grid-cols-12 py-1">
            <div className="col-span-3">
              <div className="flex gap-2 items-center">
                {host.profilePicture ? (
                  <img
                    src={host.profilePicture}
                    alt={host.firstName}
                    className={`rounded-full pointer flex items-center justify-center h-10 w-10 cursor-pointer object-cover`}
                  />
                ) : (
                  <div
                    className={`fs-16 font-medium h-10 w-10 rounded-full flex items-center justify-center cursor-pointer bg-gray-200 text-gray-500`}
                  >
                    {host.firstName.charAt(0)} {host.lastName.charAt(0)}
                  </div>
                )}
                <span className="fs-16 font-medium">
                  {host.firstName} {host.lastName}
                </span>
              </div>
            </div>
            <div className="col-span-3">
              <span className="fs-16 font-medium">
                {host?.hostTotalEvents ?? "--"}
              </span>
            </div>
            <div className="col-span-3">
              <HeadstarttRating disabled value={host?.hostAverageRating} />
            </div>
            <div className="col-span-3">
              <QuickActionHolder
                onEdit={() => navigate(`/admin/host/${host._id}`)}
                showBookmark={false}
                onDelete={() => onDelete(host._id)}
              />
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </>
  );
};

export default HostTableRow;
