import { BookingListRoot, BookingRoot } from "./types";

import { BOOKING_ENDPOINT } from "./endpoint";
import { PaginationParams } from "core/repository/types";
import { apiRequest } from "core/services/apiServices";

export const getBookingListFromRepo = (params: PaginationParams) =>
  apiRequest.getByQueryParam<BookingListRoot>(BOOKING_ENDPOINT.get, params);

export const getBookingDetailsFromRepo = (bookingId: string) =>
  apiRequest.getByQueryParam<BookingRoot>(BOOKING_ENDPOINT.getById, {
    bookingId,
  });
