import { Helmet } from "react-helmet-async";
interface ISeoWrapper {
  title: string;
  description: string;
  keywords?: string;
}

const SEOWrapper = ({
  title = "Discover App",
  description = "Explore experiences",
  keywords = "experiences",
}: ISeoWrapper) => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta name="author" content="Discover App" />
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <title>{title}</title>
  </Helmet>
);

export default SEOWrapper;
