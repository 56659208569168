import {
  HeadstarttCarousel,
  HeadstarttCarouselRef,
} from "views/components/headstarttCarousel";

import { ActivityData } from "core/repository/activity/types";
import CarouselWrapperWithIndicators from "views/components/headstarttCarousel/carouselWrapperWithIndicators";
import { useRef } from "react";

const DetailsImageCarousel = ({
  images,
  title,
}: {
  images?: ActivityData["images"];
  title?: string;
}) => {
  const carouseNodeRef = useRef<HeadstarttCarouselRef | null>(null);
  const next = () => {
    if (carouseNodeRef?.current) carouseNodeRef.current.next();
  };
  const previous = () => {
    if (carouseNodeRef?.current) carouseNodeRef.current.prev();
  };
  const goToSlide = (index: number) => {
    if (carouseNodeRef?.current) carouseNodeRef.current.goTo(index);
  };

  if (!images?.length) return <></>;
  return (
    <>
      <CarouselWrapperWithIndicators
        previous={previous}
        next={next}
        carousel={
          <HeadstarttCarousel
            ref={(node) => (carouseNodeRef.current = node)}
            effect="fade"
            dots={false}
          >
            {images.map((item) => (
              <div className="relative" key={item}>
                <img
                  className="w-full h-128 br-16 object-cover select-none"
                  src={item}
                  alt={title}
                />
              </div>
            ))}
          </HeadstarttCarousel>
        }
      />
      <div className="flex gap-2 my-2">
        {images.map((it, index) => (
          <div
            onClick={() => goToSlide(index)}
            className="h-16 w-16 rounded cursor-pointer"
            key={it}
          >
            <img
              className="h-full w-full object-cover rounded-xl"
              src={it}
              alt={title}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default DetailsImageCarousel;
