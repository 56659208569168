import {
  HeadstarttRadio,
  RadioChangeEvent,
} from "views/components/headstarttRadio";
import { memo, useCallback, useState } from "react";

import { Divider } from "antd";
import FilterHeader from "./filterHeader";
import { useSearchContext } from "context/searchContext";

const RatingsFilter = () => {
  const { filters, searchParams, setSearchParams } = useSearchContext();
  const rating = parseInt(searchParams.get("rating") ?? "", 10);
  const [value, setValue] = useState(rating || "");

  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      setValue(e.target.value);
      if (e.target.value) {
        searchParams.set("rating", e.target.value);
      } else {
        searchParams.delete("rating");
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );
  if (!filters?.ratings?.length) return null;
  return (
    <div>
      <FilterHeader
        heading={"Ratings"}
        handleClear={() => {
          setValue("");
          searchParams.delete("rating");
          setSearchParams(searchParams);
        }}
      />
      <HeadstarttRadio.Group onChange={onChange} value={value}>
        <div className="grid grid-cols-2 space-y-2 items-end">
          {filters?.ratings.map((rate) => {
            return (
              <div key={rate.value} className="col-span-1">
                <HeadstarttRadio value={rate.value}>
                  {rate.label}
                </HeadstarttRadio>
              </div>
            );
          })}
        </div>
      </HeadstarttRadio.Group>
      <Divider />
    </div>
  );
};

export default memo(RatingsFilter);
