import { FormItem } from ".";
import FormLabelWithDescription from "./formLabelWithDescription";
import { HeadstarttInput } from "../headstarttInput";
import { HeadstarttTextArea } from "../headstarttInput/textArea";

type FormGroupProps = {
  label: string;
  name: string;
  children?: React.ReactNode;
  description?: string;
  placeholder?: string;
  inputType?: "text" | "textarea" | "children";
  type?: "text" | "password" | "email" | "number" | "url";
  required?: boolean;
  pattern?: string;
};

const FormGroup = ({
  label,
  name,
  description,
  placeholder,
  inputType = "text",
  required = true,
  type = "text",
  pattern,
  children,
}: FormGroupProps) => {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-4">
        <FormLabelWithDescription label={label} description={description} />
      </div>
      <div className="col-span-6">
        <FormItem
          name={name}
          rules={[
            {
              required,
              message: `Please enter a ${label}`,
            },
          ]}
        >
          {inputType === "textarea" ? (
            <HeadstarttTextArea placeholder={placeholder} />
          ) : inputType === "text" ? (
            <HeadstarttInput
              pattern={pattern}
              type={type}
              placeholder={placeholder}
            />
          ) : (
            children
          )}
        </FormItem>
      </div>
    </div>
  );
};

export default FormGroup;
