import { HeadstarttButton, HeadstarttWhiteButton } from "../headstarttButton";
import { useCallback, useState } from "react";

import LandingActivityMapView from "views/layouts/home/components/landingActivityGroup/landingActivityMapView";
import LandingEventMapView from "views/layouts/home/components/landingEventGroup/landingEventMapView";
import { Map } from "../icons";
import { useNavigate } from "react-router-dom";

type CoverSectionButtonGroupProps = {
  btnBackground?: string;
  isActivityList?: boolean;
};
const CoverSectionButtonGroup = ({
  btnBackground,
  isActivityList,
}: CoverSectionButtonGroupProps) => {
  const [viewMap, setViewMap] = useState(false);
  const onHandleCancel = useCallback(() => {
    setViewMap(false);
  }, []);
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(isActivityList ? "/explore/activities" : "/explore/events");
  }, [navigate, isActivityList]);
  return (
    <div className="flex gap-2 flex-wrap justify-center">
      {isActivityList ? (
        <LandingActivityMapView
          visible={viewMap}
          handleCancel={onHandleCancel}
        />
      ) : (
        <LandingEventMapView visible={viewMap} handleCancel={onHandleCancel} />
      )}
      <HeadstarttWhiteButton onClick={handleClick}>
        Discover a random {isActivityList ? "activity" : "event"}
      </HeadstarttWhiteButton>
      <HeadstarttButton
        onClick={() => setViewMap(true)}
        backgroundcolor={btnBackground}
      >
        <div className="flex gap-1 items-center">
          <Map height={24} /> Discover on Map
        </div>
      </HeadstarttButton>
    </div>
  );
};

export default CoverSectionButtonGroup;
