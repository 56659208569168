const CoverSectionHeading = ({
  title,
  headline,
}: {
  title: string;
  headline: string;
}) => (
  <>
    <p className="text-white text-center fs-32 font-bold">{title}</p>
    <p className="fs-16 font-medium text-center text-white">{headline}</p>
  </>
);

export default CoverSectionHeading;
