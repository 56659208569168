import { useLocation, useNavigate } from "react-router-dom";

import { HeadstarttButton } from "../headstarttButton";

const ResetFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <HeadstarttButton
      style={{
        borderRadius: "4rem",
        background: "#006653",
        color: "white",
        padding: "0.5rem 1rem",
        height: "fit-content",
      }}
      size="small"
      onClick={() => {
        navigate(location.pathname, { replace: true });
      }}
    >
      Reset Filter
    </HeadstarttButton>
  );
};

export default ResetFilter;
