import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { HeadstarttButton } from "views/components/headstarttButton";

const AdminButton = ({
  text,
  onClick,
  disabled,
}: {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <HeadstarttButton
      style={{
        backgroundColor: "#F29D38",
        padding: "2px 12px",
        borderRadius: "32px",
        border: "1px solid #F29D38",
        height: "fit-content",
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <div className="flex gap-1 items-center">
        <ExclamationCircleIcon fill="white" stroke="#F29D38" height={30} />
        <span className="fs-16 text-white font-medium">{text}</span>
      </div>
    </HeadstarttButton>
  );
};

export default AdminButton;
