import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BookMarkListRoot } from "core/repository/user/types";
import { getBookmarks } from "core/repository/user/bookmark";
import querystring from "query-string";

const useGetBookmarks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = querystring.parse(location.search);
  // const page = parseInt(params.page as string) ?? 1;
  const [bookmarks, setBookmarks] = useState<BookMarkListRoot>();
  const [error, setError] = useState<boolean>(false);
  const [loader, setLoader] = useState(true);

  const get = useCallback(async () => {
    setLoader(true);
    setError(false);
    try {
      const response = await getBookmarks({
        limit: 24,
        offset: 0,
      });
      setBookmarks(response.data as BookMarkListRoot);
    } catch (err) {
      setError(true);
    }
    setLoader(false);
  }, []);

  const handlePagination = useCallback(
    (p: number) => {
      navigate({
        pathname: location.pathname,
        search: `?page=${p}`,
      });
    },
    [location.pathname, navigate]
  );

  useEffect(() => {
    get();
  }, [get, navigate, location.pathname]);

  return {
    bookmarks: bookmarks?.data,
    error,
    loader,
    get,
    page: parseInt(params.page as string),
    handlePagination,
  };
};

export default useGetBookmarks;
