import { Button, ButtonProps } from "antd";

import { CSSProperties } from "react";

type HeadstarttButtonProps = ButtonProps & {
  backgroundcolor?: string;
  buttonspan?: "full";
};
export const RawButton = Button;
export const HeadstarttButton = (props: HeadstarttButtonProps) => {
  const { backgroundcolor = "#006653", buttonspan = "" } = props;

  return (
    <Button
      style={
        props.type === "ghost"
          ? {
              borderRadius: "4rem",
              border: "1px solid #006653",
              color: "#006653",
              padding: "12px 24px",
              fontSize: "20px",
              fontWeight: "medium",
              height: "fit-content",
              width: buttonspan === "full" ? "100%" : "",
              ...props.style,
            }
          : props.type !== "text"
          ? {
              borderRadius: "4rem",
              border: `1px solid ${backgroundcolor}`,
              background: backgroundcolor,
              padding: "12px 48px",
              fontSize: "20px",
              fontWeight: "medium",
              color: "#fff",
              height: "fit-content",
              ...props.style,
            }
          : {}
      }
      {...props}
    />
  );
};

export const HeadstarttOutlineButton = (props: HeadstarttButtonProps) => {
  const { buttonspan = "" } = props;

  return (
    <Button
      type="ghost"
      style={
        props.size === "middle"
          ? {
              borderRadius: "4rem",
              border: "1px solid #000",
              color: "#000",
              padding: "6px 32px",
              fontSize: "20px",
              fontWeight: "medium",
              height: "fit-content",
              minWidth: "300px",
            }
          : {
              borderRadius: "4rem",
              border: "1px solid #006653",
              color: "#000",
              fontSize: "20px",
              padding: "4px 20px",
              fontWeight: "medium",
              height: "fit-content",
              width: buttonspan === "full" ? "100%" : "",
            }
      }
      {...props}
    />
  );
};
export const HeadstarttWhiteButton = (props: ButtonProps) => (
  <Button
    style={{
      borderRadius: "4rem",
      color: "#000",
      padding: "12px 48px",
      fontSize: "20px",
      fontWeight: "medium",
      height: "100%",
      minWidth: "300px",
      backgroundColor: "#fff",
    }}
    {...props}
  />
);

export const HeadstarttSmallButton = (
  props: ButtonProps & {
    newstyle?: CSSProperties;
  }
) => {
  const { type = "ghost", newstyle } = props;
  return (
    <Button
      size="small"
      style={{
        backgroundColor: type === "ghost" ? "transparent" : "#006653",
        border: "1px solid #006653",
        borderRadius: "4rem",
        color: type === "ghost" ? "#006653" : "#fff",
        height: "fit-content",
        ...newstyle,
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};
