import CardImage from "./cardImage";
import HeadstarttReadMoreLess from "../headstarttReadMoreLess";
import MutedText from "../typography/mutedText";
import { Project } from "core/repository/projects/types";
import SecondaryHeading from "../typography/secondaryHeading";
import styles from "./headstarttCard.module.scss";
const dummyText =
  "write some dummy text here long enough to see the read more/less button appear and disappear when clicked";
type ProjectCardProps = {
  project?: Project;
  className?: string;
  standardImage?: boolean;
};
const ProjectCard = ({
  project,
  className = "",
  standardImage,
}: ProjectCardProps) => {
  return (
    <div className={className}>
      <CardImage
        className={`${
          standardImage ? "object-cover h-full w-full" : styles["project-card"]
        } mb-4`}
        url={
          project?.projectImage ??
          "https://images.unsplash.com/photo-1651062108412-36a68f3748dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80"
        }
        alt={project?.projectName ?? "Project Card"}
      />
      <h2 className="fs-24 lh-28">
        {project?.projectName ?? "The Parks Bank of British Columbia"}
      </h2>
      <MutedText text={project?.projectHeadline ?? ""} />
      <HeadstarttReadMoreLess text={project?.projectDescription ?? dummyText} />
    </div>
  );
};

export default ProjectCard;

export const ProjectCardHorizontal = ({ project }: ProjectCardProps) => {
  return (
    <div className="flex gap-8">
      <img
        src={project?.projectImage}
        alt={project?.projectName}
        className="br-16 h-40 w-40 object-cover"
      />
      <div>
        <SecondaryHeading heading={project?.projectName ?? ""} />
        <MutedText text={project?.projectHeadline ?? ""} />
        <HeadstarttReadMoreLess
          text={project?.projectDescription ?? dummyText}
        />
      </div>
    </div>
  );
};
