import { ActivityData, ImportantNotice } from "core/repository/activity/types";

import AdvisoryCard from "../advisoryCard";
import CheckoutButton from "./checkoutButton";
import DetailsImageCarousel from "./detailsImageCarousel";
import MetaDetails from "./metaDetails";

type DetailsCoverSectionProps = {
  checkfrontProductId: number;
  title: string;
  description: string;
  id: string;
  images: ActivityData["images"];
  showCheckoutButton?: boolean;
  isSubscribed?: boolean;
  isBookmark?: boolean;
  rating?: number;
  totalCount?: number;
  importantNotice: ImportantNotice[];
  additionalStatus?: ActivityData["additionalStatus"];
  category?: ActivityData["category"];
};
const DetailsCoverSection = ({
  title,
  showCheckoutButton = false,
  description,
  images,
  id,
  isBookmark,
  isSubscribed,
  rating,
  totalCount,
  checkfrontProductId,
  importantNotice = [],
  additionalStatus,
  category,
}: DetailsCoverSectionProps) => {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-6">
        <AdvisoryCard id={id} importantNotice={importantNotice} />

        <MetaDetails
          id={id}
          isBookmark={isBookmark}
          isSubscribed={isSubscribed}
          description={description}
          title={title}
          rating={rating}
          totalCount={totalCount}
          additionalStatus={additionalStatus}
          category={category}
        />
        {showCheckoutButton && (
          <div className="my-8">
            <CheckoutButton checkfrontProductId={checkfrontProductId} />
          </div>
        )}
      </div>
      <div className="col-span-12 md:col-span-6">
        <DetailsImageCarousel title={title} images={images} />
      </div>
    </div>
  );
};

export default DetailsCoverSection;
