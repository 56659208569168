import CardSlider from "views/components/cardSlider";
import { HeadstarttButton } from "views/components/headstarttButton";
import { Host } from "core/repository/host/types";
import HostCard from "views/components/headstarttCard/hostCard";
import { useNavigate } from "react-router-dom";

const HostSlider = ({ items }: { items: Host[] }) => {
  const navigate = useNavigate();

  return (
    <CardSlider>
      {items.map((item, i) => {
        if (items.length === 1)
          return (
            <div className="br-16 relative w-52 h-64 mx-2 ">
              <HostCard
                className="br-16 relative w-52 h-64 mx-2"
                key={item._id}
                host={item}
              />
            </div>
          );
        return i === items.length - 1 ? (
          <div className="br-16 relative w-52 h-64 mx-2 ">
            <div className="flex flex-col items-center h-full justify-center">
              <HeadstarttButton
                type="ghost"
                onClick={() => navigate("/saved/hosts")}
              >
                Show more
              </HeadstarttButton>
            </div>
          </div>
        ) : (
          <HostCard host={item} />
        );
      })}
    </CardSlider>
  );
};

export default HostSlider;
