import { Link } from "react-router-dom";
import SignIn from "../signIn";
type SignInViewProps = {
  handleSignInClick: () => void;
  onCancel?: () => void;
};
export const SignInView = ({
  handleSignInClick,
  onCancel,
}: SignInViewProps) => (
  <>
    <p className="fs-16 font-medium">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue ullamcorper
      a sed scelerisque nisl.
    </p>
    <p className="fs-12 text-gray-500 font-medium mb-12">
      By proceeding, you agree to our{" "}
      <Link className="text-primary" to="/terms-of-use">
        Terms of Use
      </Link>{" "}
      and confirm you have read our
      <Link className="text-primary" to="/privacy-and-cookies-statement">
        Privacy and Cookie Statement
      </Link>
      .
    </p>
    <SignIn onCancel={onCancel} onHandleSignInClick={handleSignInClick} />
  </>
);
