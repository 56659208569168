import { Review } from "core/repository/reviews/types";
import ReviewCard from "views/components/headstarttCard/reviewCard";

const ProjectTableRow = ({
  reviews,
  onSuccess,
  onDelete,
}: {
  reviews: Review[];
  onSuccess: () => void;
  onDelete: (id: string) => void;
}) => {
  return (
    <div>
      {reviews.map((review) => {
        return (
          <div key={review._id} className="space-y-12">
            <ReviewCard
              onSuccess={onSuccess}
              onDelete={onDelete}
              review={review}
              isAdmin
            />
          </div>
        );
      })}
    </div>
  );
};

export default ProjectTableRow;
