import { HeadstarttCard } from "views/components/headstarttCard";
import { IBooking } from "core/repository/bookings/types";
import { ReactNode } from "react";
import { dayjsClient } from "core/clients/dayjs";

const BookingCardText = ({
  title,
  value,
}: {
  title: string;
  value: ReactNode;
}) => (
  <div>
    <div className="fs-12 text-uppercase mb-0">{title}</div>
    <div className="fs-16 font-medium">{value}</div>
  </div>
);

const BookingCard = ({ booking }: { booking: IBooking }) => {
  return (
    <HeadstarttCard>
      <div className="grid grid-cols-4 gap-4">
        <BookingCardText title="Booking ID" value={booking._id} />
        <BookingCardText
          title="Booking Date"
          value={dayjsClient(booking.bookingDate).format("YYYY-MM-DD")}
        />
        <BookingCardText title="Booking Code" value={booking.code} />
        <BookingCardText title="Email" value={booking.customerEmail} />
        <BookingCardText title="Full Name" value={booking.customerName} />
        <BookingCardText
          title="Total Paid"
          value={`$${booking.paidTotal} CAD`}
        />
        <BookingCardText title="Status" value={booking.statusId} />
        <BookingCardText title="Total tax" value={`$${booking.taxTotal} CAD`} />
        <BookingCardText title="Total" value={`$${booking.total} CAD`} />
        <BookingCardText
          title="Items"
          value={booking.items.map((item) => (
            <div key={item.itemId}>
              <p className="fs-16 font-medium mb-1">{item.statusId}</p>
              <p className="fs-12 text-uppercase mb-0">
                Quantity: {item.quantity}
              </p>
              <p className="fs-12 text-uppercase mb-0">Price: ${item.total}</p>
            </div>
          ))}
        />
      </div>
    </HeadstarttCard>
  );
};

export default BookingCard;
