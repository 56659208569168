type PopularHeadingProps = {
  headingPartOne?: string;
  headingPartTwo?: string;
  text?: string;
  className?: string;
};

export const HeadingTopText = ({ text }: PopularHeadingProps) => (
  <p className="fs-16 font-medium mb-0 text-gray-400">{text}</p>
);

export const PopularHeading = ({
  headingPartOne = "Discover",
  headingPartTwo,
  className = "text-black",
}: PopularHeadingProps) => {
  return (
    <h1 className={`${className} fs-32 mb-2`}>
      <span className="font-light">{headingPartOne}-</span>
      <span className="font-bold">{headingPartTwo}</span>
    </h1>
  );
};
export const ExploreHeading = ({
  headingPartOne = "Discover",
  headingPartTwo,
  className = "text-black",
}: PopularHeadingProps) => {
  return (
    <h1 className={`${className} fs-32 mb-2`}>
      <span className="font-bold">{headingPartOne}&nbsp;</span>
      <span className="font-light">{headingPartTwo}</span>
    </h1>
  );
};

const PopularHeadingGroup = ({
  text,
  headingPartOne,
  headingPartTwo,
  className = "text-black",
}: PopularHeadingProps) => (
  <>
    <HeadingTopText text={text} />
    <PopularHeading
      className={className}
      headingPartOne={headingPartOne}
      headingPartTwo={headingPartTwo}
    />
  </>
);

export default PopularHeadingGroup;
