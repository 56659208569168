import AppNavbarHoc from "views/layouts/hoc/appNavbarHoc";
import HeadstarttFooter from "views/components/headstarttFooter";
import { IPublicRoute } from "../types";
import NamedRoute from "./namedRoute";

const PublicRoute = ({
  children,
  title,
  description,
  keywords,
  mode,
  withoutFooter = false,
  withoutHeader = false,
}: IPublicRoute) => (
  <NamedRoute keywords={keywords} title={title} description={description}>
    {!withoutHeader && <AppNavbarHoc mode={mode} />}
    {children}
    {!withoutFooter && <HeadstarttFooter />}
  </NamedRoute>
);

export default PublicRoute;
