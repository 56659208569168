import AdminTags from "admin/components/adminTags";
import { DefaultTag } from "views/components/headstarttTag";
import { HeadstarttDropdown } from "views/components/headstarttDropdown";

const ActivityStatus = ({ status }: { status: string[] }) => {
  return (
    <div>
      <AdminTags text={status[0]} />
      <HeadstarttDropdown
        overlay={
          <div className="bg-white shadow br-8 p-2 flex flex-col gap-2">
            {status.splice(1, status.length - 1).map((txt) => (
              <AdminTags key={txt} text={txt} />
            ))}
          </div>
        }
        placement="bottomRight"
      >
        <div className="cursor-pointer">
          <DefaultTag>
            <span className="fs-16 font-medium uppercase">
              {status.length > 1 ? `+${status.length - 1}` : ""} More
            </span>
          </DefaultTag>
        </div>
      </HeadstarttDropdown>
    </div>
  );
};

export default ActivityStatus;
