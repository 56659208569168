import { useEffect, useState } from "react";

import { AdminData } from "core/repository/user/types";
import { IPrivateRoute } from "../types";
import NamedRoute from "./namedRoute";
import { useLocalStorage } from "core/hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

const PrivateRouteAdmin = ({ children, title, description }: IPrivateRoute) => {
  const [admin] = useLocalStorage<AdminData | undefined>(
    "adminUser",
    undefined
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (admin?.adminId) {
      setLoading(false);
    } else navigate("/admin/login");
  }, [admin?.adminId, navigate]);

  if (loading) return <>Loading</>;
  return (
    <NamedRoute title={title} description={description}>
      {children}
    </NamedRoute>
  );
};

export default PrivateRouteAdmin;
