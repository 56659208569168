import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  getAdmin,
  getToken,
  removeAdminData,
  removeToken,
} from "core/services/authService";

import { AdminData } from "core/repository/user/types";
import { LogoutIcon } from "@heroicons/react/outline";
import { RawButton } from "views/components/headstarttButton";
import { removeFromLocalStorage } from "core/clients/lockr";
import { useCallback } from "react";
import useLogoutAdmin from "core/services/admin/login/useLogoutAdmin";

const sidebarItems = [
  {
    name: "Activity",
    path: "/admin/activity/activity-list",
    icon: "/admin/Walking.svg",
  },
  {
    name: "Destination",
    path: "/admin/destination/destination-list",
    icon: "/admin/Signpost Tourist.svg",
  },
  {
    name: "Events",
    path: "/admin/event/event-list",
    icon: "/admin/Flag.svg",
  },
  {
    name: "Hosts",
    path: "/admin/host/host-list",
    icon: "/admin/Person.svg",
  },
  {
    name: "Projects",
    path: "/admin/project/project-list",
    icon: "/admin/Forest.svg",
  },
  {
    name: "Reviews",
    path: "/admin/review/review-list",
    icon: "/admin/Very Popular Topic.svg",
  },
  {
    name: "Users",
    path: "/admin/user/user-list",
    icon: "/admin/User Account.svg",
  },
  {
    name: "Support Requests",
    path: "/admin/support-request/support-request-list",
    icon: "/admin/Lifebuoy.svg",
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const { mutateAsync } = useLogoutAdmin();
  const isAdminLoggedIn = getToken() && getAdmin();
  const logoutForAdmin = useCallback(async () => {
    const adminUser: AdminData = JSON.parse(
      localStorage.getItem("adminUser") || "{}"
    );
    await mutateAsync(adminUser.refreshToken);
    removeToken();
    removeAdminData();
    removeFromLocalStorage("activityPayload");
    navigate("/admin/login");
    window.location.reload();
  }, [mutateAsync, navigate]);
  return (
    <>
      <div className="flex flex-row gap-2 mb-4">
        <img src={"/admin/admin-logo.svg"} alt="Discover Admin" />
        <Link to="/admin/activity/activity-list?page=1">
          <span className="fs-32 font-bold text-white">Discover</span>
        </Link>
      </div>
      <div className="flex flex-col gap-4">
        {sidebarItems.map((item) => (
          <NavLink
            to={item.path}
            key={item.name}
            className={({ isActive }) =>
              isActive
                ? "font-bold fs-16 text-purple-400 py-2 hover:text-purple-400"
                : "text-white font-normal fs-16 py-2 hover:text-purple-400"
            }
          >
            <div className="flex flex-row gap-2 items-center">
              <img src={item.icon} alt={item.name} />
              <div>{item.name}</div>
            </div>
          </NavLink>
        ))}
      </div>
      {isAdminLoggedIn && (
        <div className="absolute bottom-0 left-0 right-0 px-2 py-12">
          <RawButton onClick={logoutForAdmin} type="text">
            <div className="flex gap-2 items-center">
              <LogoutIcon height={24} width={24} stroke="#FFF" />
              <span className="text-white fs-16 font-medium">Logout</span>
            </div>
          </RawButton>
        </div>
      )}
    </>
  );
};

export default Sidebar;
