import { ActivityPayload, FacilitiesRoot, SubActivityRoot } from "./types";

import { PaginationParams } from "./../types";
import { apiRequest } from "core/services/apiServices";

export const addActivityToRepo = (data: ActivityPayload) =>
  apiRequest.post("/admin/activity/v1", data);

export const updateActivityToRepo = (
  data: Pick<ActivityPayload, "isPopular" | "activityId">
) => apiRequest.patch("/admin/activity/v1", data);

export const getSubActivitiesFromRepo = (params: PaginationParams) =>
  apiRequest.getByQueryParam<SubActivityRoot>(
    "/admin/activity/v1/listSubActivities",
    params
  );

export const getFacilitiesFromRepo = (params: PaginationParams) =>
  apiRequest.getByQueryParam<FacilitiesRoot>(
    "/admin/activity/v1/listFacilities",
    params
  );

export const deleteActivityFromRepo = (activityId: string) =>
  apiRequest.deleteWithoutId(`/admin/activity/v1`, {
    activityId,
    isDelete: true,
  });
