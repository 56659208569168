import { Divider, RadioChangeEvent } from "antd";

import FilterHeader from "./filterHeader";
import { HeadstarttRadio } from "../headstarttRadio";
import { useSearchContext } from "context/searchContext";
import { useSearchParams } from "react-router-dom";

const HasImageFilter = () => {
  const { filters } = useSearchContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const hasImage = searchParams.get("hasImage");
  const onChange = (e: RadioChangeEvent) => {
    searchParams.set("hasImage", `${e.target.value}`);
    setSearchParams(searchParams, { replace: true });
  };

  if (!filters?.hasImage?.length) return null;
  const value =
    hasImage === "true" ? true : hasImage === "false" ? false : undefined;

  return (
    <div>
      <FilterHeader
        heading={"Has image"}
        handleClear={() => {
          searchParams.delete("hasImage");
          setSearchParams(searchParams, { replace: true });
        }}
      />
      <HeadstarttRadio.Group onChange={onChange} value={value}>
        <div className="grid grid-cols-2 space-y-2 items-end">
          {filters?.hasImage.map((has) => {
            return (
              <div key={has.label} className="col-span-1">
                <HeadstarttRadio value={has.value}>{has.label}</HeadstarttRadio>
              </div>
            );
          })}
        </div>
      </HeadstarttRadio.Group>
      <Divider />
    </div>
  );
};

export default HasImageFilter;
