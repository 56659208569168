import { useLocation, useNavigate } from "react-router-dom";

import AdminCard from "admin/components/adminCard";
import ErrorCard from "views/components/errorCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttPagination from "views/components/headstarttPagination";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PageHeader from "admin/components/pageHeader";
import { SUPPORT_HEADER } from "admin/adminConstants";
import SupportRequestTableRow from "./supportRequestTableRow";
import TableHeader from "admin/components/table/tableHeader";
import { useCallback } from "react";
import useGetSupportList from "core/services/support/useGetSupportList";

const AdminSupportRequestList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleChangePage = useCallback(
    (page: number) => {
      navigate(`${location.pathname}?page=${page}`);
    },
    [location.pathname, navigate]
  );
  const { data, isLoading, isError, offset } = useGetSupportList();
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  if (!data?.data.total) return <>Data Not Found</>;

  return (
    <div>
      <PageHeader heading="Support Request List" />
      <HeadstarttContainer>
        <div className="py-4">
          <AdminCard>
            <TableHeader header={SUPPORT_HEADER} />
            <SupportRequestTableRow list={data?.data.list} />
          </AdminCard>
          <div className="flex justify-center my-4">
            <HeadstarttPagination
              total={data?.data.total}
              current={offset}
              onChange={handleChangePage}
            />
          </div>
        </div>
      </HeadstarttContainer>
    </div>
  );
};
export default AdminSupportRequestList;
