import { Project, ProjectRoot, ProjectsRoot } from "./types";

import { PaginationParams } from "./../types";
import { apiRequest } from "core/services/apiServices";

export const getProjectsFromRepo = (params: PaginationParams) =>
  apiRequest.getByQueryParam<ProjectsRoot>("/user/project/v1/list", params);

export const getProjectByIdFromRepo = (projectId: string) =>
  apiRequest.getByQueryParam<ProjectRoot>(`/user/project/v1`, { projectId });

export const addProjectToRepo = (payload: Project) => {
  return apiRequest.post("/admin/project/v1", payload);
};

export const updateProjectToRepo = (payload: Project) => {
  return apiRequest.patch("/admin/project/v1", payload);
};

export const deleteProjectFromRepo = (projectId: string) => {
  return apiRequest.deleteWithoutId(`/admin/project/v1`, {
    projectId,
  });
};
