import ActivityCalendar from "./activityCalendar";
import ActivityFacilityCardGroup from "./activityFacilityCardGroup";
import ActivityListCardGroup from "./activityListCardGroup";
import ActivityReviews from "./activityReviews";
import ActivityTrailCard from "./activityTrailCard";
import DetailsCoverSection from "views/components/detailsCoverSection";
import ErrorCard from "views/components/errorCard";
import GoogleMaps from "views/components/googleMaps";
import HeadStarttTabsGroup from "views/components/headstarttTabs/headstarttTabsGroup";
import HeadstarttContainer from "views/components/headstarttContainer";
import KnowBeforeYouGo from "./knowBeforeYouGo";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import OpenStatus from "./openStatus";
import ParkContact from "./parkContact";
import ParkOperationDate from "./parkOperationDate";
import { useCallback } from "react";
import useGetActivityDetails from "core/services/activity/useGetActivityDetails";

const ACTIVITY_TABS = [
  "Park Operation Date",
  "Location",
  "Park Contact",
  "Know Before You Go",
  "Activities",
  "Facilities",
  "Trails",
  "Reviews",
];
const ActivityDetails = () => {
  const { data, loading, error } = useGetActivityDetails();
  const handleClick = useCallback((tab: string[]) => {
    // get element by id
    const element = document.getElementById(tab[0]);
    if (element) {
      // scroll to element
      const headerOffset = 200;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  if (loading) return <LoaderFullScreen />;
  if (error) return <ErrorCard />;

  if (!data) return <div>No data available</div>;

  return (
    <HeadstarttContainer>
      <div className="py-24">
        <DetailsCoverSection
          description={data.description}
          title={data.title}
          images={data.images}
          id={data._id}
          isBookmark={data.isBookmark}
          isSubscribed={data.isSubscribed}
          rating={data.averageRating}
          totalCount={data.totalReviews}
          checkfrontProductId={0}
          importantNotice={data?.importantNotice}
          additionalStatus={data?.additionalStatus}
          category={data?.category}
        />
        <div className="my-16">
          <div className="border-b" />
        </div>
        <HeadStarttTabsGroup
          handleTabClick={handleClick}
          tabs={ACTIVITY_TABS}
        />
        <div>
          <div className="my-16" id="Park Operation Date">
            <div className="grid grid-cols-12 gap-12">
              <div className="md:col-span-8 col-span-12">
                <h2 className="fs-32 font-medium">Park Operation Date</h2>
                <ParkOperationDate
                  operationDateDescription={
                    data?.operationDate?.operationDateDescription
                  }
                />
              </div>
              <div className="md:col-span-4 col-span-12">
                <ActivityCalendar
                  availableDates={data?.operationDate?.availableDates}
                />
                <OpenStatus />
              </div>
            </div>
          </div>
          <div className="my-16">
            <div className="grid grid-cols-12 gap-12">
              <div className="md:col-span-8 col-span-12">
                <h2 className="fs-32 font-medium">Location</h2>
                <p>{data.locationDetail.locationDescription}</p>
                <div className="border h-128" id="Location">
                  <GoogleMaps
                    locationDetail={{
                      lat: data.locationDetail.latitude,
                      lng: data.locationDetail.longitude,
                    }}
                    markers={[
                      {
                        position: [
                          data.locationDetail.latitude,
                          data.locationDetail.longitude,
                        ],
                        label: data.title,
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12" id="Park Contact">
                <h2 className="fs-32 font-medium">Park Contact</h2>

                <ParkContact contact={data.contactDetail} />
              </div>
            </div>
          </div>
          <div className="my-16" id="Know Before You Go">
            <h2 className="fs-32 font-medium">Know Before You Go</h2>
            <KnowBeforeYouGo instructions={data.instructions} />
          </div>
          <div className="my-16" id="Activities">
            <h2 className="fs-32 font-medium">Activities</h2>
            <ActivityListCardGroup activities={data.subActivity} />
          </div>
          <div className="my-16" id="Facilities">
            <h2 className="fs-32 font-medium">Facilities</h2>
            <ActivityFacilityCardGroup facilities={data.facility} />
          </div>
          <div className="my-16" id="Trails">
            <h2 className="fs-32 font-medium">Trails</h2>
            <div className="space-y-4 divide-y-2">
              {data.trail.map((tr) => (
                <ActivityTrailCard key={tr._id} trail={tr} />
              ))}
            </div>
          </div>
          <div className="my-16" id="Reviews">
            <h2 className="fs-32 font-medium">Reviews</h2>
            <ActivityReviews
              reviews={data.reviewsList.list}
              averageRating={data.averageRating}
              totalReviews={data.totalReviews}
              groupRating={data.totalReviewsGroup}
            />
          </div>
        </div>
      </div>
    </HeadstarttContainer>
  );
};

export default ActivityDetails;
