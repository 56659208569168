import { DestinationRoot } from "core/repository/destination/types";
import { getDestinationByIdFromRepo } from "./../../repository/destination/index";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

const useGetDestinationById = (lazy = false) => {
  const params = useParams();
  const { id } = params as { id: string };

  const {
    data: destination,
    isError,
    isLoading,
    refetch,
  } = useQuery<DestinationRoot, Error>(
    ["destinationById", id],
    async () => {
      const { data } = await getDestinationByIdFromRepo(id);
      return data;
    },
    {
      enabled: !lazy && !!id,
    }
  );

  return { destination, isError, isLoading, refetch, id };
};

export default useGetDestinationById;
