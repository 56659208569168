import { HeadstarttRating } from "views/components/headstarttRating";
import MutedText from "views/components/typography/mutedText";
import { UserReview } from "core/repository/user/types";
import { dayjsClient } from "core/clients/dayjs";

const UserReviewCard = ({ userReview }: { userReview: UserReview }) => {
  return (
    <div className="flex gap-4">
      <div>
        <img
          src={userReview.images[0]}
          alt={userReview.title}
          className="h-12 w-12 rounded-xl object-cover"
        />
      </div>
      <div>
        <p className="fs-16 font-medium mb-0">{userReview.title}</p>
        <MutedText text={userReview.description} />
        <div className="my-2">
          <HeadstarttRating value={userReview.rating} disabled />
        </div>
        <p className="fs-12 font-medium mb-0">
          Commented on{" "}
          {dayjsClient(userReview.createdDate).format("MM-DD-YYYY")}
        </p>
      </div>
    </div>
  );
};

export default UserReviewCard;
