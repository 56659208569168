import { ActivityPayload, SubActivity } from "core/repository/activity/types";
import { useCallback, useEffect, useState } from "react";

import FormLabelWithDescription from "views/components/headstarttForm/formLabelWithDescription";
import { HeadstarttOption } from "views/components/headstarttSelect";
import { SelectSearch } from "views/components/headstarttSearch/selectSearch";
import { Spin } from "antd";
import { errorToast } from "views/components/headstarttNotifications";
import { getSubActivitiesFromRepo } from "core/repository/activity";

type OptionType = {
  key: string;
  value: string;
};
const ActivitySelection = ({
  values,
  setValues,
  subActivity,
}: {
  values: ActivityPayload["subActivity"];
  setValues: (v: string[]) => void;
  subActivity: SubActivity[];
}) => {
  const [selectedActivities, setSelectedActivities] = useState<OptionType[]>(
    values?.map((it) => ({
      key: it,
      value: it,
    }))
  );
  const [subActivities, setSubActivities] = useState<SubActivity[]>(
    subActivity || []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleSearch = useCallback((value: string) => {
    setLoading(true);
    setSelectedActivities([]);
    getSubActivitiesFromRepo({
      limit: 25,
      offset: 0,
      search: value,
    })
      .then((res) => {
        setSubActivities(res.data.data.list);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err.message);
      });
  }, []);

  useEffect(() => {
    if (values) {
      setSelectedActivities(
        values.map((it) => ({
          key: it,
          value: it,
        }))
      );
    }
  }, [values]);
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-4">
        <FormLabelWithDescription
          label={"Select activities"}
          description={"Select available activities"}
        />
      </div>
      <div className="col-span-6">
        <SelectSearch
          className="activity-select"
          style={{
            width: "100%",
          }}
          showSearch
          mode="multiple"
          loading={loading}
          clearIcon
          labelInValue
          onClear={() => {
            setSelectedActivities([]);
            setValues([]);
          }}
          onChange={(v) => {
            setSelectedActivities(v);
            setValues(v.map((it) => it.value));
          }}
          onSearch={handleSearch}
          defaultValue={selectedActivities}
          notFoundContent={loading ? <Spin size="small" /> : null}
          optionLabelProp="activityTitle  "
        >
          {subActivities.map((it) => (
            <HeadstarttOption key={it._id} value={it._id}>
              <div className="flex items-center">
                <div className="w-10 h-10 bg-gray-300 rounded-full">
                  <img src={it.activityImage} alt={it.activityTitle} />
                </div>
                <div className="ml-4">
                  <div className="font-bold">{it.activityTitle}</div>
                </div>
              </div>
            </HeadstarttOption>
          ))}
        </SelectSearch>

        <div className="my-4">
          <div className="font-bold">Selected Activities</div>
          <div className="flex flex-col space-y-4">
            {selectedActivities?.map((it) => {
              const activity = subActivities.find((a) => a._id === it.value);
              return (
                <div key={it.key} className="flex gap-2 items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full mr-2">
                    <img
                      src={activity?.activityImage}
                      alt={activity?.activityTitle}
                    />
                  </div>
                  <div>
                    <div className="font-bold">{activity?.activityTitle}</div>

                    <div className="text-xs text-gray-500">
                      {activity?.activityDescription}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitySelection;
