import CardSlider from "views/components/cardSlider";
import { HeadstarttButton } from "views/components/headstarttButton";
import { Project } from "core/repository/search/types";
import ProjectCard from "views/components/headstarttCard/projectCard";
import { useNavigate } from "react-router-dom";

const ProjectSlider = ({ items }: { items: Project[] }) => {
  const navigate = useNavigate();

  return (
    <CardSlider>
      {items.map((item, i) => {
        return i === items.length - 1 ? (
          <div className="br-16 relative w-52 h-64 mx-2 ">
            <div className="flex flex-col items-center h-full justify-center">
              <HeadstarttButton
                type="ghost"
                onClick={() => navigate("/explore/projects/?page=1")}
              >
                Show more
              </HeadstarttButton>
            </div>
          </div>
        ) : (
          <a
            key={item._id}
            href={item.redirectUrl}
            target="_blank"
            rel="noreferrer"
          >
            <ProjectCard
              standardImage
              className="br-16 relative w-52 h-64 mx-2"
              key={item._id}
              project={item}
            />
          </a>
        );
      })}
    </CardSlider>
  );
};

export default ProjectSlider;
