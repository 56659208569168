import AlertCard from "./alertCard";
import AlertHeader from "./alertHeader";
import ErrorCard from "views/components/errorCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttPagination from "views/components/headstarttPagination";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import { useCallback } from "react";
import useGetAlerts from "core/services/alerts/useGetAlerts";
import useMarkAllAsRead from "core/services/alerts/useMarkAllAsRead";
import useMarkNotificationAsRead from "core/services/alerts/useMarkNotificationAsRead";
import { useNavigate } from "react-router-dom";
import useRemoveAlert from "core/services/alerts/useRemoveAlert";

const UserAlerts = () => {
  const navigate = useNavigate();
  const { alerts, loader, error, getAlerts, handlePagination, page } =
    useGetAlerts();
  const { markAsRead, isLoading: markAsReadLoader } =
    useMarkNotificationAsRead();
  const { markAllAsRead, isLoading: markAllAsReadLoader } = useMarkAllAsRead();

  const { removeAlert, isLoading: removeLoader } = useRemoveAlert();
  const handleMarkAllAsRead = useCallback(async () => {
    await markAllAsRead();
    getAlerts();
  }, [markAllAsRead, getAlerts]);

  const handleMarkAsRead = useCallback(
    async (id: string) => {
      await markAsRead(id);
    },
    [markAsRead]
  );
  const handleRemoveAlert = useCallback(
    async (id: string) => {
      await removeAlert(id);
      getAlerts();
    },
    [removeAlert, getAlerts]
  );
  if (loader) return <LoaderFullScreen />;
  if (error) return <ErrorCard />;

  return (
    <div className="py-24">
      <HeadstarttContainer>
        <div className="grid-cols-12 grid">
          <div className="md:col-span-8 md:col-start-3">
            <AlertHeader
              onMarkAllAsRead={handleMarkAllAsRead}
              onNotificationSettings={() => navigate("/notifications-settings")}
              isLoading={markAllAsReadLoader}
            />
            <div className="divide-y-2">
              {alerts?.list.map((alert) => (
                <AlertCard
                  markLoader={markAsReadLoader}
                  onHandleMarkAsRead={handleMarkAsRead}
                  onRemoveAlert={handleRemoveAlert}
                  removeLoader={removeLoader}
                  alert={alert}
                  key={alert._id}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-center my-12">
          <HeadstarttPagination
            onChange={handlePagination}
            total={alerts?.total}
            current={page}
          />
        </div>
      </HeadstarttContainer>
    </div>
  );
};

export default UserAlerts;
