import { HostBookmarksList } from "core/repository/user/types";
import HostCard from "views/components/headstarttCard/hostCard";

const SavedHosts = ({ hosts }: { hosts: HostBookmarksList["list"] }) => {
  return (
    <div className="grid grid-cols-12 gap-2">
      {hosts.map((host) => (
        <div key={host._id} className="md:col-span-4 col-span-6">
          <HostCard host={host} />
        </div>
      ))}
    </div>
  );
};

export default SavedHosts;
