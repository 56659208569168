const NoteFromHost = ({ note }: { note: string }) => {
  return (
    <div>
      <h2 className="fs-32 font-medium">Note From Host</h2>
      <p>{note}</p>
    </div>
  );
};

export default NoteFromHost;
