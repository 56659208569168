import { getAuth, updateProfile } from "core/clients/firebase";
import { useCallback, useState } from "react";

import { SignUpPayload } from "core/repository/user/types";

const useUpdateCurrentUser = (
  onSuccess?: (user: SignUpPayload) => void,
  onError?: () => void
) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateCurrentUser = useCallback(
    async (displayName: string) => {
      setLoader(true);
      const auth = getAuth();
      if (auth.currentUser) {
        updateProfile(auth.currentUser, {
          displayName,
        })
          .then(async () => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // ...
            const user = auth.currentUser;
            if (user) {
              const credentials = await user.getIdTokenResult();
              const payloadForSignUp: SignUpPayload = {
                firstName: user?.displayName?.split(" ")[0] || "",
                lastName: user?.displayName?.split(" ")[1] || "",
                socialId: user?.uid,
                deviceToken: "",
                accessToken: credentials.token,
                loginType: "EMAIL",
              };
              onSuccess?.(payloadForSignUp);
            }
          })
          .catch((err) => {
            onError?.();
            setError(err.message);
          })
          .finally(() => {
            setLoader(false);
          });
      }
    },
    [onSuccess, onError]
  );
  return {
    updateCurrentUser,
    loader,
    error,
  };
};

export default useUpdateCurrentUser;
