import { ReactNode } from "react";
import { Search } from "../icons";

type HeadstarttSearchButtonProps = {
  buttonText: ReactNode;
  onClick: () => void;
};
const HeadstarttSearchButton = ({
  buttonText,
  onClick,
}: HeadstarttSearchButtonProps) => (
  <div
    onClick={onClick}
    className="border rounded-full px-8 py-3 flex gap-2 items-center cursor-pointer"
  >
    <Search height={24} width={24} color="#FFF" />
    {buttonText}
  </div>
);

export default HeadstarttSearchButton;
