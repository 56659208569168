import { ExploreAllRoot } from "core/repository/search/types";
import { SearchParams } from "views/layouts/explore/types";
import { getExploreAllFromRepo } from "core/repository/search";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";

const LIMIT = 24;

const useGetExploreAll = () => {
  const locationParams = parse(useLocation().search) as unknown as SearchParams;

  const { data, isLoading, isError } = useQuery<ExploreAllRoot, Error>(
    ["exploreAll", locationParams],
    async () => {
      const response = await getExploreAllFromRepo({
        ...locationParams,
        limit: LIMIT,
        offset: locationParams.page ? parseInt(locationParams.page) : 0,
      });
      return response.data;
    }
  );

  return {
    data,
    isLoading,
    isError,
  };
};

export default useGetExploreAll;
