import { ActivityListData } from "core/repository/activity/types";
import ActivityStatus from "./activityStatus";
import AdminButton from "admin/components/adminButton";
import { Divider } from "antd";
import QuickActionHolder from "admin/components/quickActionHolder";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const ActivityTableRow = ({
  list,
  handleBookmark,
  isBookmarkLoading,
  handleDelete,
}: {
  list: ActivityListData["list"];
  handleBookmark: (activityId: string, isPopular: boolean) => void;
  isBookmarkLoading: boolean;
  handleDelete: (activityId: string) => void;
}) => {
  const navigate = useNavigate();
  const handleEdit = useCallback(
    (id: string) => {
      navigate(`/admin/activity/${id}`);
    },
    [navigate]
  );
  return (
    <>
      {list.map((activity) => (
        <div key={activity._id}>
          <div className="grid grid-cols-12 py-1">
            <div className="col-span-3">
              <span className="fs-16 font-medium">{activity.title}</span>
            </div>
            <div className="col-span-3">
              <ActivityStatus
                status={[activity.status, ...activity.additionalStatus]}
              />
            </div>
            <div className="col-span-3">
              <AdminButton
                disabled={!activity.totalPendingReviews}
                text={`${activity.totalPendingReviews} Pending Reviews`}
                onClick={() =>
                  navigate(`/admin/activity/pending-reviews/${activity._id}`)
                }
              />
            </div>
            <div className="col-span-3">
              <QuickActionHolder
                isBookmarked={activity.isPopular}
                disabledBookmark={isBookmarkLoading}
                onBookmark={() =>
                  handleBookmark(activity._id, !activity.isPopular)
                }
                onDelete={() => handleDelete(activity._id)}
                onEdit={() => handleEdit(activity._id)}
              />
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </>
  );
};

export default ActivityTableRow;
