import { SuggestionType, Suggestions } from "core/repository/search/types";

import ListSearchItem from "views/components/headstarttSearch/listSearchItem";
import { Location } from "views/components/icons";

const SuggestionList = ({
  suggestions,
  handleSuggestionClick,
}: {
  suggestions: Suggestions;
  handleSuggestionClick: (type: SuggestionType, id: string) => void;
}) => {
  return (
    <>
      {suggestions.list.map((suggestion) => (
        <div
          key={suggestion._id}
          onClick={() => handleSuggestionClick(suggestion.type, suggestion._id)}
        >
          <ListSearchItem
            icon={<Location height={24} />}
            text={suggestion.title}
            subText={suggestion.type}
          />
        </div>
      ))}
    </>
  );
};

export default SuggestionList;
