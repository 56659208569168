import { Divider } from "antd";
type PageHeaderProps = {
  heading: string;
};
const PageHeader = ({ heading = "" }: PageHeaderProps) => {
  return (
    <div className="bg-white p-8">
      <h1 className="fs-32 font-bold">{heading}</h1>
      <Divider className="bg-gray-200" />
    </div>
  );
};

export default PageHeader;
