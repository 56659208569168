import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { subscribeNotificationsFromRepo } from "core/repository/user/subscribeNotifications";
import { useMutation } from "react-query";

const useHandleNotificationSubscription = () => {
  const { mutate: mutateSubscribe, isLoading: subscribeLoading } = useMutation(
    subscribeNotificationsFromRepo,
    {
      onSuccess: () => {
        successToast(`You will get notified if there is an update`);
      },
      onError: () => {
        errorToast("Something went wrong. Please try again later.");
      },
    }
  );

  const { mutate: mutateUnsubscribe, isLoading: unsubscribeLoading } =
    useMutation(subscribeNotificationsFromRepo, {
      onSuccess: () => {
        successToast(`Notifications unsubscribed successfully`);
      },
      onError: () => {
        errorToast("Something went wrong. Please try again later.");
      },
    });

  return {
    subscribeNotifications: async (id: string) => mutateSubscribe(id),
    unsubscribeNotifications: async (id: string) => mutateUnsubscribe(id),
    subscribeLoading,
    unsubscribeLoading,
  };
};

export default useHandleNotificationSubscription;
