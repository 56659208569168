import { HeadstarttButton } from "views/components/headstarttButton";
import { PencilIcon } from "@heroicons/react/outline";

const EditButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <HeadstarttButton
      onClick={onClick}
      style={{
        borderRadius: "8px",
        border: `1px solid #006653`,
        background: "#006653",
        padding: "12px",
        fontSize: "20px",
        fontWeight: "medium",
        color: "#fff",
        height: "48px",
        width: "48px",
      }}
    >
      <PencilIcon height={24} width={24} />
    </HeadstarttButton>
  );
};

export default EditButton;
