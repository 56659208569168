import { Link, useLocation, useNavigate } from "react-router-dom";

import BookingCard from "../components/bookingCard";
import ErrorCard from "views/components/errorCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttPagination from "views/components/headstarttPagination";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PrimaryHeading from "views/components/typography/primaryHeading";
import { useCallback } from "react";
import useGetBookingList from "core/services/bookings/useGetBookingList";

const BookingList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, isLoading, isError, offset } = useGetBookingList();

  const handleChangePage = useCallback(
    (page: number) => {
      navigate(`${location.pathname}?page=${page}`);
    },
    [location.pathname, navigate]
  );

  if (isLoading) {
    return <LoaderFullScreen />;
  }
  if (isError) {
    return <ErrorCard />;
  }
  const bookings = data?.data.list;
  const count = data?.data.total;
  if (!bookings?.length || !count) return <>No bookings found</>;
  return (
    <HeadstarttContainer>
      <div className="py-24">
        <PrimaryHeading heading={"My Bookings"} />
        {bookings.map((booking) => (
          <Link
            className="block my-8"
            key={booking._id}
            to={`/bookings/${booking.bookingId}`}
          >
            <BookingCard booking={booking} />
          </Link>
        ))}
        <div className="flex justify-center my-4">
          <HeadstarttPagination
            total={data?.data.total}
            current={offset}
            onChange={handleChangePage}
          />
        </div>
      </div>
    </HeadstarttContainer>
  );
};

export default BookingList;
