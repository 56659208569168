import { Dayjs } from "dayjs";
import { dayjsClient } from "core/clients/dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export const HeadstarttDatepicker = (
  props: React.ComponentProps<typeof DatePicker> & {
    width?: number;
    disabledPreviousDates?: boolean;
  }
) => {
  const { width, disabledPreviousDates, ...rest } = props;
  return (
    <DatePicker
      size="large"
      style={{
        borderRadius: "9999px",
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        padding: "8px 24px",
        fontSize: "20px",
        fontWeight: "medium",
        height: "100%",
        width: width || "100%",
      }}
      disabledDate={(current) => {
        if (disabledPreviousDates) {
          return current && current < dayjsClient().endOf("day");
        }
        return false;
      }}
      {...rest}
    />
  );
};
