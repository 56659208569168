import axios, {
  AxiosBasicCredentials,
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import { getAdmin, getToken, removeToken } from "./../authService/index";
import { ApiConfig, ContentType, HttpStatusCode } from "./types";

import { getAuth } from "core/clients/firebase";
import { ADMIN } from "core/repository/user/endpoint";
import { AdminData } from "core/repository/user/types";
import { removeAdminData } from "core/services/authService";
import querystring from "query-string";

const axiosClientApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL_DEV,
  timeout: ApiConfig.timeOut,
  headers: {
    "Content-Type": ContentType.JSON,
    Accept: ApiConfig.accept,
    Platform: ApiConfig.platform,
    Timezone: ApiConfig.timezone,
  },
});

const redirectToLogout = () => (window.location.href = "/login");
const redirectToAdminLogout = () => (window.location.href = "/admin/login");
const handleResponse = (res: AxiosResponse) => res;
const handleError = (err: AxiosError) => {
  if (err.response?.status === HttpStatusCode.UNAUTHORIZED) {
    const isAdmin = JSON.parse(getAdmin() as string) as AdminData;

    const auth = getAuth();
    removeToken();
    if (isAdmin?.adminId) {
      removeAdminData();
      redirectToAdminLogout();
    } else {
      auth.signOut();
      redirectToLogout();
    }
    return Promise.resolve({});
  }
  return Promise.reject(err);
};

const addTokenInRequest = (config: AxiosRequestConfig) => {
  const admin = JSON.parse(getAdmin() as string) as AdminData;
  if (!config?.headers?.Authorization) {
    const token = getToken();
    if (token && config.headers)
      config.headers.Authorization = `Bearer ${token}`;
    if (admin?.adminId) {
      if (
        !config.url?.includes(ADMIN.getUser) &&
        !config.url?.includes(ADMIN.patchUser)
      )
        config.url = config.url?.replace("user", "admin");
    }
  }
  return config;
};

axiosClientApi.interceptors.response.use(handleResponse, handleError);
axiosClientApi.interceptors.request.use(addTokenInRequest);

async function get<T>(url: string, headers?: AxiosRequestHeaders) {
  return axiosClientApi.get<T>(url, { headers });
}

async function getByQueryParam<T>(
  url: string,
  params: Record<string, unknown>,
  headers?: AxiosRequestHeaders
) {
  return axiosClientApi.get<T>(`${url}?${querystring.stringify(params)}`, {
    headers,
  });
}

async function getById(
  url: string,
  id: string | number,
  headers?: AxiosRequestHeaders
) {
  return axiosClientApi.get(`${url}/${id}`, {
    headers,
  });
}

async function post(
  url: string,
  body = {},
  headers?: AxiosRequestHeaders,
  auth?: AxiosBasicCredentials
) {
  return axiosClientApi.post(url, body, { headers, auth });
}

async function put(url: string, body = {}, headers?: AxiosRequestHeaders) {
  return axiosClientApi.put(url, body, { headers });
}

async function patch(url: string, body = {}, headers?: AxiosRequestHeaders) {
  return axiosClientApi.patch(url, body, { headers });
}

async function patchWithId(
  url: string,
  id: number | string,
  body = {},
  headers?: AxiosRequestHeaders
) {
  return axiosClientApi.patch(`${url}/${id}`, body, { headers });
}

async function deleteById(
  url: string,
  id: number | string,
  headers?: AxiosRequestHeaders
) {
  return axiosClientApi.delete(`${url}/${id}/`, { headers });
}

async function deleteWithoutId(
  url: string,
  body = {},
  headers?: AxiosRequestHeaders
) {
  return axiosClientApi({
    method: "delete",
    url,
    data: body,
    headers: headers,
  });
}

export const apiRequest = {
  get,
  getById,
  getByQueryParam,
  post,
  patch,
  put,
  patchWithId,
  deleteById,
  deleteWithoutId,
};

export default axiosClientApi;
