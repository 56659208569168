import { DiscoverAppLogoGradient } from "../icons/discoverAppLogoGradient";
import FooterLinks from "./footerLinks";
import HeadstarttContainer from "../headstarttContainer";

const HeadstarttFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-200 py-4 z-40">
      <HeadstarttContainer>
        <div className="flex gap-2">
          <div>
            <DiscoverAppLogoGradient />
          </div>
          <div>
            <p className="fs-12 text-gray-500 mb-1">
              © {currentYear} Discover Parks All rights reserved.
            </p>
            <FooterLinks />
          </div>
        </div>
      </HeadstarttContainer>
    </footer>
  );
};

export default HeadstarttFooter;
