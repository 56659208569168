import { BookingRoot } from "./../../repository/bookings/types";
import { getBookingDetailsFromRepo } from "core/repository/bookings";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

const useGetBookingById = () => {
  const params = useParams();
  const id = params.id as string;
  const {
    data: bookingDetails,
    isError,
    isLoading,
  } = useQuery<BookingRoot, Error>(["bookingById", id], async () => {
    const { data } = await getBookingDetailsFromRepo(id);
    return data;
  });

  return { bookingDetails, isError, isLoading };
};

export default useGetBookingById;
