import { getOpenStatus } from "views/layouts/home/components/landingCarousel";

const OpenStatus = ({ status }: { status: number[] }) => {
  const openStatus = getOpenStatus(status);

  return (
    <div className="flex gap-4">
      <div className="flex items-center gap-2">
        <div className="w-2.5 h-2.5 rounded-full bg-green-100 border-primary border opacity-8" />
        <div className="text-primary font-medium fs-16">
          {openStatus ? "Open" : "Close"}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-2.5 h-2.5 rounded-full bg-red-100 border-red-400 border opacity-8" />
        <div className="text-red-400 font-medium fs-16">
          Partially closes/ Closed
        </div>
      </div>
    </div>
  );
};

export default OpenStatus;
