import CardReviews from "views/components/headstarttCard/cardReviews";
import GroupByRatings from "views/components/groupByRatings";
import HeadstarttPagination from "views/components/headstarttPagination";
import { Review } from "core/repository/reviews/types";
import ReviewCard from "views/components/headstarttCard/reviewCard";
import SearchReviewInput from "views/components/searchReviewInput";
import { TotalReviewsGroup } from "core/repository/event/types";
import WriteReviewButton from "./writeReviewButton";
type DetailsReviewsProps = {
  reviews?: Review[];
  totalReviews?: number;
  avgRating?: number;
  groupRating?: TotalReviewsGroup;
};
const DetailsReviews = ({
  reviews,
  totalReviews,
  avgRating,
  groupRating,
}: DetailsReviewsProps) => {
  const isReviews = reviews && reviews.length > 0;
  return (
    <div className="grid grid-cols-12 gap-16">
      <div className="md:col-span-4 col-span-12">
        {isReviews ? (
          <>
            <CardReviews rating={avgRating} reviews={totalReviews} />
            <div className="my-4">
              <GroupByRatings groupReviews={groupRating} />
            </div>
          </>
        ) : (
          "No Reviews"
        )}
      </div>
      <div className="md:col-span-8 col-span-12 space-y-12">
        <WriteReviewButton type="event" />
        {!!isReviews && (
          <>
            <SearchReviewInput />
            {reviews?.map((review) => (
              <ReviewCard key={review._id} review={review} />
            ))}
            <HeadstarttPagination total={totalReviews ? totalReviews : 0} />
          </>
        )}
      </div>
    </div>
  );
};

export default DetailsReviews;
