import { useNavigate, useParams } from "react-router-dom";

import { HeadstarttButton } from "views/components/headstarttButton";
import { PencilIcon } from "views/components/icons";

const WriteReviewButton = ({ type }: { type: "activity" | "event" }) => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <HeadstarttButton
      onClick={() => navigate(`/write-review/${params.id}?type=${type}`)}
    >
      <div className="flex gap-2">
        <PencilIcon />
        <span className="mb-0">Write a review</span>
      </div>
    </HeadstarttButton>
  );
};

export default WriteReviewButton;
