import { updateActivityToRepo } from "core/repository/activity";
import { useMutation } from "react-query";

export const useUpdateActivity = (
  onSuccess?: () => void,
  onError?: () => void
) =>
  useMutation(updateActivityToRepo, {
    onSuccess,
    onError,
  });
