import { HeadstarttOutlineButton } from "views/components/headstarttButton";
import { Mail } from "views/components/icons";

type LoginWithEmailButtonProps = {
  onHandleClick: () => void;
};
const LoginWithEmailButton = ({ onHandleClick }: LoginWithEmailButtonProps) => {
  return (
    <HeadstarttOutlineButton
      type="ghost"
      onClick={onHandleClick}
      buttonspan="full"
      style={{
        borderRadius: "4rem",
        border: "1px solid #006653",
        color: "#000",
        fontSize: "16px",
        padding: "16px 24px",
        fontWeight: "500",
        height: "fit-content",
        width: "100%",
      }}
    >
      <div className="flex items-center gap-2">
        <Mail height={24} /> Login with Email
      </div>
    </HeadstarttOutlineButton>
  );
};

export default LoginWithEmailButton;
