import {
  HeadstarttRadio,
  RadioChangeEvent,
} from "views/components/headstarttRadio";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Divider } from "antd";
import FilterHeader from "./filterHeader";
import { useSearchContext } from "context/searchContext";

const AgesFilter = () => {
  const { filters } = useSearchContext();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const ages = searchParams.get("ages");

  const [value, setValue] = useState(ages || "");
  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      setValue(e.target.value);
      if (e.target.value) {
        searchParams.set("ages", e.target.value);
      } else {
        searchParams.delete("ages");
      }
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [location.pathname, navigate, searchParams]
  );
  if (!filters?.ages?.length) return null;
  return (
    <div>
      <FilterHeader
        heading={"Age"}
        handleClear={() => {
          setValue("");
          searchParams.delete("ages");
          navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
          });
        }}
      />
      <HeadstarttRadio.Group onChange={onChange} value={value}>
        <div className="grid grid-cols-3 space-y-2 items-end gap-4">
          {filters?.ages.map((age) => {
            return (
              <div key={age.value}>
                <HeadstarttRadio value={age.value}>{age.label}</HeadstarttRadio>
              </div>
            );
          })}
        </div>
      </HeadstarttRadio.Group>
      <Divider />
    </div>
  );
};

export default AgesFilter;
