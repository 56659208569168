import FormGroup from "views/components/headstarttForm/formGroup";
import { HeadstarttRadio } from "views/components/headstarttRadio";
type ActivityRadioGroupProps = {
  label: string;
  name: string;
  description: string;
  radioGroup: Record<string, string>[];
};
const ActivityRadioGroup = ({
  label,
  name,
  description,
  radioGroup,
}: ActivityRadioGroupProps) => {
  return (
    <FormGroup
      label={label}
      name={name}
      description={description}
      inputType="children"
    >
      <HeadstarttRadio.Group>
        {radioGroup.map((radio) => (
          <HeadstarttRadio key={radio.value} value={radio.value}>
            <div
              className={` ${
                radio.background ? "text-white px-8 py-2" : "text-black"
              } br-24 font-medium`}
              style={{
                backgroundColor: radio.background || "",
              }}
            >
              {radio.title}
            </div>
          </HeadstarttRadio>
        ))}
      </HeadstarttRadio.Group>
    </FormGroup>
  );
};

export default ActivityRadioGroup;
