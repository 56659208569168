import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { markAsReadFromRepo } from "core/repository/alerts";
import { useMutation } from "react-query";

const useMarkNotificationAsRead = () => {
  const { mutate, isLoading } = useMutation(markAsReadFromRepo, {
    onSuccess: () => {
      successToast("Notification marked as read");
    },
    onError: () => {
      errorToast("Something went wrong. Please try again later.");
    },
  });
  return {
    markAsRead: async (id: string) => mutate(id),
    isLoading,
  };
};

export default useMarkNotificationAsRead;
