import { getEventsFromRepo } from "core/repository/event";
import { EventListRoot } from "core/repository/event/types";
import { parse } from "query-string";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { SearchParams } from "views/layouts/explore/types";

const LIMIT = 12;
const useGetEventList = () => {
  const locationParams = parse(useLocation().search) as unknown as SearchParams;

  const { data, isLoading, isError, refetch } = useQuery<EventListRoot, Error>(
    ["events", locationParams],
    async () => {
      const { data: responseData } = await getEventsFromRepo({
        limit: LIMIT,
        offset: locationParams.page ? parseInt(locationParams.page) : 0,
        ...locationParams,
      });
      return responseData;
    }
  );

  return {
    data,
    isLoading,
    isError,
    offset: locationParams.page ? parseInt(locationParams.page) : 0,
    refetch,
  };
};
export default useGetEventList;
