import { ChevronLeft, ChevronRight } from "views/components/icons";
import {
  HeadstarttCarousel,
  HeadstarttCarouselRef,
} from "views/components/headstarttCarousel";
import { useCallback, useRef } from "react";

import LandingPageCarouselSlide from "./landingPageCarouselSlide";
import { PopularActivitiesList } from "core/repository/home/types";

// type UpdatedPopularActivities = PopularActivitiesList["list"][0] & {
//   calculatedDistance: string;
// };
export const getOpenStatus = (
  operatingMonths: PopularActivitiesList["list"][0]["operationDate"]["openMonths"]
) => {
  const currentMonth = new Date().getMonth();
  return operatingMonths.includes(currentMonth);
};
const LandingCarousel = ({
  popularActivities,
}: {
  popularActivities?: PopularActivitiesList["list"];
}) => {
  const carouseNodeRef = useRef<HeadstarttCarouselRef | null>(null);
  // const [currentLocation, setCurrentLocation] = useState<GeolocationPosition>();
  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     setCurrentLocation(position);
  //   });
  // }, []);
  // const calculateMatrixDistance = useCalculatedMatrixDistance();

  const next = useCallback(() => {
    if (carouseNodeRef?.current) carouseNodeRef.current.next();
  }, []);

  const previous = useCallback(() => {
    if (carouseNodeRef?.current) carouseNodeRef.current.prev();
  }, []);

  // const { latitude: currentLatitude, longitude: currentLongitude } =
  //   currentLocation?.coords || {};
  // TODO Fix
  // const updatedPopularActivities =
  //   (popularActivities?.map((activity) => {
  //     const { latitude, longitude } = activity.locationDetail;
  //     if (currentLatitude && currentLongitude) {
  //       const calculatedDistance = calculateMatrixDistance(
  //         [currentLatitude, currentLongitude],
  //         [latitude, longitude]
  //       );
  //       return {
  //         ...activity,
  //         calculatedDistance,
  //       };
  //     }
  //   }) as UpdatedPopularActivities[]) || [];
  // if (!updatedPopularActivities.filter((it) => it).length)
  //   return (
  //     <div className="flex items-center justify-center p-4 bg-white br-8 opacity-60 flex-col">
  //       Loading popular activities...
  //       <Loader />
  //     </div>
  //   );
  return (
    <div className="relative">
      <HeadstarttCarousel
        className="relative"
        ref={(node) => (carouseNodeRef.current = node)}
        effect="fade"
        autoplay
        dots={false}
      >
        {popularActivities?.map((item) => (
          <LandingPageCarouselSlide
            id={item._id}
            isBookmark={item.isBookmark}
            key={item._id}
            url={item.images[0]}
            title={item.title}
            description={item.description}
            reviews={item.totalReviews}
            ratings={item.averageRating}
            openStatus={getOpenStatus(item.operationDate.openMonths)}
            category={item.category}
            locationDetail={item.locationDetail}
          />
        ))}
      </HeadstarttCarousel>
      <div className="flex items-center justify-start absolute left-0 my-auto mx-5 top-0 bottom-0">
        <div
          className="bg-white rounded-full p-1 cursor-pointer"
          onClick={previous}
        >
          <ChevronLeft height={24} color="#006653" />
        </div>
      </div>
      <div className="flex items-center justify-end absolute right-0 my-auto mx-5 top-0 bottom-0">
        <div
          className="bg-white rounded-full p-1 cursor-pointer"
          onClick={next}
        >
          <ChevronRight height={24} color="#006653" />
        </div>
      </div>
    </div>
  );
};

export default LandingCarousel;
