import { AdminData, SignInAdminPayload } from "core/repository/user/types";
import { useCallback, useState } from "react";

import { setToken } from "core/services/authService";
import { signInAdminToRepo } from "core/repository/user/signInUserToRepo";

const useSignInAdmin = (
  onSuccess?: (adminData: AdminData) => void,
  onError?: (err: Error) => void
) => {
  const [loader, setLoader] = useState(false);

  const signInAdmin = useCallback(
    async (userData: SignInAdminPayload) => {
      setLoader(true);
      try {
        const { data } = await signInAdminToRepo(userData);
        setToken(data.data.token);
        onSuccess?.(data.data);
      } catch (err) {
        onError?.(err as Error);
      }
      setLoader(false);
    },
    [onSuccess, onError]
  );

  return { signInAdmin, adminLoader: loader };
};

export default useSignInAdmin;
