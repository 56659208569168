export enum Scopes {
  READ_ONLY = "https://www.googleapis.com/auth/contacts.readonly",
}
export enum LoginType {
  EMAIL = "EMAIL",
  GOOGLE = "GOOGLE",
  APPLE = "APPLE",
  FACEBOOK = "FACEBOOK",
}
export enum Platform {
  WEB = "WEB",
  ANDROID = "MOBILE",
  IOS = "IOS",
}
