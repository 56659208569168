import { HeadstarttButton } from "views/components/headstarttButton";
import { StarIcon } from "@heroicons/react/outline";

const BookmarkButton = ({
  isActive = false,
  onClick,
  disabled,
}: {
  isActive?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <HeadstarttButton
      style={{
        borderRadius: "8px",
        background: "rgba(0, 0, 0, 0.08)",
        padding: "12px",
        fontSize: "20px",
        fontWeight: "medium",
        color: "#fff",
        height: "48px",
        width: "48px",
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <StarIcon
        color={isActive ? "#FFC107" : "gray"}
        height={24}
        width={24}
        fill={isActive ? "#FFC107" : "#fff"}
      />
    </HeadstarttButton>
  );
};

export default BookmarkButton;
