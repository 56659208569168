import { ReactNode } from "react";
export enum TagType {
  primary = "primary",
  secondary = "secondary",
  danger = "danger",
}
const CardTag = ({
  text = "",
  icon,
  type = TagType.primary,
}: {
  text: string;
  icon?: ReactNode;
  type?: TagType;
}) => {
  if (!text) return <></>;
  return (
    <div
      className={
        "br-24 w-auto inline-block px-3 py-1" +
        `${
          type === "danger"
            ? "  border-[#EE7654] border"
            : type === "secondary"
            ? " border-gray-400 border"
            : " border-primary border "
        }`
      }
    >
      <span className="flex gap-1 items-center">
        {!!icon && icon}
        <span
          className={
            "fs-16 font-medium" +
            `${
              type === "danger"
                ? " text-[#EE7654]"
                : type === "secondary"
                ? " text-gray-400"
                : " text-primary"
            }`
          }
        >
          {text}
        </span>
      </span>
    </div>
  );
};
export default CardTag;
