import { useCallback, useState } from "react";

import { UpdatePayload } from "core/repository/user/types";
import { patchUserToRepo } from "core/repository/user/patchUserToRepo";

const usePatchUser = () => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const updateData = useCallback(async (payload: UpdatePayload) => {
    setLoader(true);
    try {
      await patchUserToRepo(payload);
    } catch (err) {
      setError(true);
    }
    setLoader(false);
  }, []);

  return {
    updateData,
    loader,
    error,
  };
};

export default usePatchUser;
