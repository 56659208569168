import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { SupportPayload } from "core/repository/support/types";
import { postSupportToRepo } from "core/repository/support/postSupportToRepo";
import { useMutation } from "react-query";

const usePostSupportForm = () => {
  const { mutate, isLoading } = useMutation(postSupportToRepo, {
    onSuccess: () => {
      successToast("Thank you. We will get back to you.");
    },
    onError: () => {
      errorToast("Something went wrong. Please try again later.");
    },
  });

  return {
    postSupport: (support: SupportPayload) => {
      mutate(support);
    },
    isLoading,
  };
};

export default usePostSupportForm;
