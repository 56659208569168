import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Divider } from "antd";
import { HeadstarttOutlineButton } from "../headstarttButton";
import { LocationMark } from "../icons";

type LocationFilterButtonProps = {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
  locationLoader: boolean;
};
const LocationFilterButton = ({
  icon,
  text,
  onClick,
  locationLoader,
}: LocationFilterButtonProps) => (
  <HeadstarttOutlineButton
    onClick={onClick}
    style={{
      border: "1px solid #006653",
      width: "100%",
      borderRadius: "8px",
      padding: "8px",
      height: "fit-content",
    }}
    disabled={locationLoader}
  >
    <div className="flex items-center gap-2">
      {icon}
      <span className="fs-14 font-medium text-primary">{text}</span>
    </div>
  </HeadstarttOutlineButton>
);

const LocationFilter = () => {
  const [locationLoader, setLocationLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleUseMyLocation = useCallback(() => {
    setLocationLoader(true);
    navigator.geolocation.getCurrentPosition((position) => {
      // get the current location
      const { latitude, longitude } = position.coords;
      // get existing query params
      const searchParams = new URLSearchParams(location.search);
      // set the new query params
      searchParams.set("latitude", latitude.toString());
      searchParams.set("longitude", longitude.toString());
      // navigate to the new url
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
      setLocationLoader(false);
    });
  }, [location.pathname, location.search, navigate]);
  return (
    <div>
      {/* <FilterHeader heading={"KM for Location"} handleClear={() => {}} /> */}
      {/* <div className="grid grid-cols-3 gap-1 mb-4">
        <div className="col-span-1">
          <HeadstarttInput
            size="middle"
            placeholder="km"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              borderRadius: "4px",
              border: "none",
            }}
          />
        </div>
        <div className="col-span-2">
          <HeadstarttInput
            size="middle"
            placeholder="from postal code"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              borderRadius: "4px",
              border: "none",
            }}
          />
        </div>
      </div> */}

      <div className="space-y-2">
        <LocationFilterButton
          locationLoader={locationLoader}
          icon={<LocationMark />}
          text="use my location"
          onClick={handleUseMyLocation}
        />
        {/* <LocationFilterButton
          locationLoader={locationLoader}
          icon={<MapMark />}
          text={"use map..."}
          onClick={() => {}}
        /> */}
      </div>
      <Divider />
    </div>
  );
};

export default LocationFilter;
