import { Destination, DestinationRoot, DestinationsRoot } from "./types";

import { apiRequest } from "core/services/apiServices";
import { PaginationParams } from "../types";

export const getDestinationFromRepo = (params: PaginationParams) =>
  apiRequest.getByQueryParam<DestinationsRoot>("/user/destination/v1/list", {
    ...params,
    ...(params.search && { search: params.search }),
  });

export const getDestinationByIdFromRepo = (destinationId: string) =>
  apiRequest.getByQueryParam<DestinationRoot>(`/user/destination/v1`, {
    destinationId,
  });

export const addDestinationToRepo = (destination: Destination) =>
  apiRequest.post("/user/destination/v1", destination);

export const updateDestinationToRepo = (destination: Destination) =>
  apiRequest.patch("/user/destination/v1", destination);

export const deleteDestinationFromRepo = (destinationId: string) =>
  apiRequest.deleteWithoutId(`/user/destination/v1`, {
    destinationId,
  });
