import { GoogleLogo } from "views/components/icons/brandIcons";
import { HeadstarttOutlineButton } from "views/components/headstarttButton";
import { SignUpPayload } from "core/repository/user/types";
import useLoginWithGoogle from "core/services/authService/hooks/useLoginWithGoogle";
import { useNavigate } from "react-router-dom";
import useSignInUser from "core/services/user/useSignInUser";
import useSignUpUser from "core/services/user/useSignUpUser";

const LoginWithGoogle = ({ onCancel }: { onCancel?: () => void }) => {
  const navigate = useNavigate();
  const { signUpUser, loader: signUpLoader } = useSignUpUser();
  const { signInUser, loader: signInLoader } = useSignInUser();
  const onSuccess = async (payload: SignUpPayload) => {
    await signUpUser(payload);
    const { firstName, lastName, ...signInPayload } = payload;
    await signInUser(signInPayload);
    // redirect to home page
    navigate("/");
    onCancel?.();
  };
  const { loginWithGoogle, loader } = useLoginWithGoogle(onSuccess);
  return (
    <HeadstarttOutlineButton
      onClick={loginWithGoogle}
      type="ghost"
      buttonspan="full"
      disabled={loader || signUpLoader || signInLoader}
      style={{
        borderRadius: "4rem",
        border: "1px solid #006653",
        color: "#000",
        fontSize: "16px",
        padding: "16px 24px",
        fontWeight: "500",
        height: "fit-content",
        width: "100%",
      }}
    >
      <div className="flex items-center gap-2">
        <GoogleLogo /> Login with Google
      </div>
    </HeadstarttOutlineButton>
  );
};

export default LoginWithGoogle;
