import { errorToast, successToast } from "../headstarttNotifications";

import AdminCard from "admin/components/adminCard";
import AdminImageUploader from "admin/components/adminImageUploader";
import { BannerPayload } from "core/repository/common/types";
import { Divider } from "antd";
import FormGroup from "../headstarttForm/formGroup";
import { HeadstarttForm } from "../headstarttForm";
import { HeadstarttSmallButton } from "../headstarttButton";
import { useState } from "react";
import { useUpdateBannerDetails } from "core/services/common";

type MetaDetailsFormProps = {
  type: "activity" | "event";
  payload: BannerPayload["activity"] | BannerPayload["event"];
};
const MetaDetailsForm = ({ payload, type }: MetaDetailsFormProps) => {
  const [uploadedImages, setUploadedImages] = useState<string[]>(
    payload?.image ? [payload?.image] : []
  );
  const [form] = HeadstarttForm.useForm();
  const { mutateAsync, isLoading } = useUpdateBannerDetails(
    () => {
      successToast("Banner details updated successfully");
    },
    () => {
      errorToast("Something went wrong");
    }
  );
  const onSubmit = (values: Record<string, string>) => {
    let newPayload: BannerPayload = {};
    if (type === "activity") {
      newPayload = {
        activity: {
          title: values.title,
          headline: values.headline,
          image: uploadedImages[0],
        },
      };
    }
    if (type === "event") {
      newPayload = {
        event: {
          title: values.title,
          headline: values.headline,
          image: uploadedImages[0],
        },
      };
    }
    mutateAsync(newPayload);
  };
  return (
    <div className="my-4">
      <AdminCard>
        <h1 className="fs-24">Meta Details Form</h1>
        <Divider />
        <HeadstarttForm
          onFinish={onSubmit}
          form={form}
          initialValues={{
            title: payload?.title,
            headline: payload?.headline,
            image: payload?.image,
          }}
        >
          <FormGroup label={"Title"} name={"title"} />
          <FormGroup label={"Headline"} name={"headline"} />
          <AdminImageUploader
            label={"Image"}
            name={"image"}
            setUploadedImages={setUploadedImages}
            uploadedImages={uploadedImages}
            form={form}
          />
          <HeadstarttSmallButton
            loading={isLoading}
            htmlType="submit"
            type="primary"
            newstyle={{
              padding: "0.5rem 1rem",
              backgroundColor: "#006653",
              color: "#fff",
            }}
          >
            Update
          </HeadstarttSmallButton>
        </HeadstarttForm>
      </AdminCard>
    </div>
  );
};

export default MetaDetailsForm;
