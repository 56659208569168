import { DayjsClient, dayjsClient } from "core/clients/dayjs";

import { Alert } from "core/repository/alerts/types";
import { DotsHorizontal } from "views/components/icons";
import { HeadstarttDropdown } from "views/components/headstarttDropdown";
import MutedText from "views/components/typography/mutedText";
import { RawButton } from "views/components/headstarttButton";

const Overlay = ({
  id,
  handleMarkAsRead,
  loader,
  handleRemoveAlert,
  removeLoader,
}: {
  handleMarkAsRead: (id: string) => void;
  id: string;
  loader: boolean;
  handleRemoveAlert: (id: string) => void;
  removeLoader: boolean;
}) => (
  <div className="br-16 bg-white space-y-2 shadow my-2 p-2 flex-col flex items-start">
    <RawButton
      loading={loader}
      disabled={loader}
      type="text"
      onClick={() => handleMarkAsRead(id)}
      className="fs-16 font-medium mb-0 cursor-pointer hover:bg-gray-100 p-2 br-8"
    >
      Mark as Read
    </RawButton>
    <RawButton
      onClick={() => handleRemoveAlert(id)}
      loading={removeLoader}
      type="text"
      disabled={removeLoader}
      className="fs-16 font-medium mb-0 text-red-400 cursor-pointer hover:bg-gray-100 p-2 br-8"
    >
      Remove this notification
    </RawButton>
  </div>
);

const timeAgo = (date: DayjsClient) => {
  const seconds = dayjsClient().diff(date, "second");
  const interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return `${interval} years ago`;
  }
  const interval2 = Math.floor(seconds / 2592000);
  if (interval2 > 1) {
    return `${interval2} months ago`;
  }
  const interval3 = Math.floor(seconds / 86400);
  if (interval3 > 1) {
    return `${interval3} days ago`;
  }
  const interval4 = Math.floor(seconds / 3600);
  if (interval4 > 1) {
    return `${interval4} hours ago`;
  }
  const interval5 = Math.floor(seconds / 60);
  if (interval5 > 1) {
    return `${interval5} minutes ago`;
  }
  return `${Math.floor(seconds)} seconds ago`;
};

type AlertCardProps = {
  alert: Alert;
  markLoader: boolean;
  onHandleMarkAsRead: (id: string) => void;
  onRemoveAlert: (id: string) => void;
  removeLoader: boolean;
};

const AlertCard = ({
  alert,
  markLoader,
  onHandleMarkAsRead,
  onRemoveAlert,
  removeLoader,
}: AlertCardProps) => {
  return (
    <div className="flex items-center justify-between py-4">
      <div className="flex gap-4 items-center">
        <div
          className={`${alert.isRead ? "" : "bg-primary"} h-3 w-3 rounded-full`}
        />
        <div className="flex gap-3">
          <img
            src={alert.notificationImage}
            alt={alert.notificationTitle}
            className="h-16 w-16 br-8 object-cover"
          />
          <div>
            <h1 className="fs-16 font-medium mb-0">
              {alert.notificationTitle}
            </h1>
            <MutedText text={alert.notificationDescription} />
            <p className="text-primary font-medium">
              {timeAgo(dayjsClient(alert.createdDate))}
            </p>
          </div>
        </div>
      </div>
      <HeadstarttDropdown
        overlay={
          <Overlay
            handleMarkAsRead={onHandleMarkAsRead}
            handleRemoveAlert={onRemoveAlert}
            removeLoader={removeLoader}
            loader={markLoader}
            id={alert._id}
          />
        }
        placement="bottomRight"
      >
        <div className="bg-gray-200 p-2 br-8 cursor-pointer">
          <DotsHorizontal height={24} />
        </div>
      </HeadstarttDropdown>
    </div>
  );
};

export default AlertCard;
