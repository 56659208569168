export const ACTIVITY_HEADER = [
  {
    title: "Name",
    isSortable: true,
  },
  {
    title: "Status",
    isSortable: false,
  },
  {
    title: "Pending Reviews",
    isSortable: false,
  },
  {
    title: "Quick Actions",
    isSortable: false,
  },
];

export const DESTINATION_HEADER = [
  {
    title: "Name",
    isSortable: true,
  },
  {
    title: "Description",
    isSortable: false,
  },
  {
    title: "Province",
    isSortable: false,
  },
  {
    title: "Total activities",
    isSortable: false,
  },
  {
    title: "Total events",
    isSortable: false,
  },
  {
    title: "Quick Actions",
    isSortable: false,
  },
];
export const EVENT_HEADER = [
  {
    title: "Name",
    isSortable: true,
  },
  {
    title: "Host Assigned",
    isSortable: false,
  },
  {
    title: "Pending Reviews",
    isSortable: false,
  },
  {
    title: "Quick Actions",
    isSortable: false,
  },
];
export const HOST_HEADER = [
  {
    title: "Name",
    isSortable: true,
  },
  {
    title: "Events Assigned",
    isSortable: true,
  },
  {
    title: "Ratings",
    isSortable: true,
  },
  {
    title: "Quick Actions",
    isSortable: false,
  },
];
export const PROJECT_HEADER = [
  {
    title: "Name",
    isSortable: true,
  },
  {
    title: "Description",
    isSortable: false,
  },
  {
    title: "Date Posted",
    isSortable: true,
  },
  {
    title: "Quick Actions",
    isSortable: false,
  },
];
export const USER_HEADER = [
  {
    title: "Name",
    isSortable: true,
  },
  {
    title: "Date joined",
    isSortable: true,
  },
  {
    title: "Email",
    isSortable: true,
  },
  {
    title: "Block user",
    isSortable: false,
  },
];
export const SUPPORT_HEADER = [
  {
    title: "Name",
    isSortable: true,
  },
  {
    title: "description",
    isSortable: true,
  },
  {
    title: "ticket number",
    isSortable: true,
  },
  {
    title: "quick action",
    isSortable: false,
  },
];

// Tabs
export const ADMIN_ACTIVITY_TABS = [
  "Name & Description",
  "Duration and Locations",
  "Contact Details",
  "Activities and Facilities",
  "Trails",
];

export const ADMIN_HOST_TABS = [
  "Name & Description",
  "Contact Details",
  "Assigned Events",
];

export const LIMIT = 12;

export const AVAILABLE_ACTIVITIES = [
  {
    title: "Cycling",
    description: "Cycling",
    value: "cycling",
    img: "/assets/images/cycling.png",
  },
  {
    title: "Hiking",
    description: "Hiking",
    value: "hiking",
    img: "/assets/images/hiking.png",
  },
];

export const TRAIL_DIFFICULTY = [
  {
    title: "Easy",
    value: "EASY",
    background: "#006653",
  },
  {
    title: "Moderate",
    value: "MODERATE",
    background: "#00365F",
  },
  {
    title: "Difficult",
    value: "DIFFICULT",
    background: "#272B27",
  },
];

export const YES_NO_RADIO_GROUP = [
  {
    title: "Yes",
    value: "yes",
  },
  {
    title: "No",
    value: "no",
  },
];
