import { HeadstarttButton } from "../headstarttButton";
import HeadstarttLikeButton from "../headstarttButton/headstarttLikeButton";
import { HeadstarttRating } from "../headstarttRating";
import { HostDetail } from "core/repository/event/types";
import { useNavigate } from "react-router-dom";

const AboutTheHostCard = ({ host }: { host: HostDetail }) => {
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-12 gap-12">
      <div className="md:col-span-4 col-span-12">
        <div className="card br-16 border p-4">
          <div className="flex justify-between mb-4">
            <div className="flex items-center gap-4">
              <img
                className="h-16 w-16 rounded-full object-cover"
                src={host.profilePicture}
                alt={host.firstName + " " + host.lastName}
              />
              <div>
                <p className="fs-24 font-medium mb-0">
                  {host.firstName + " " + host.lastName}
                </p>
              </div>
            </div>
            <div>
              <HeadstarttLikeButton
                id={host._id}
                type="HOST"
                isBookmark={true}
                className="bg-gray-200"
              />
            </div>
          </div>
          <p className="text-muted">
            Member since {new Date(host.createdDate).getFullYear()}
          </p>
          <div className="flex gap-2 items-center">
            <HeadstarttRating value={4} />
            <p className="text-gray-500 mb-0">
              ({host.hostTotalReviews} reviews)
            </p>
          </div>
          <div className="my-4">
            <HeadstarttButton
              onClick={() => navigate(`/about-the-host/${host._id}`)}
              buttonspan="full"
              type="ghost"
            >
              Discover More Events
            </HeadstarttButton>
          </div>
        </div>
      </div>
      <div className="md:col-span-8 col-span-12">
        <h2 className="fs-20 font-medium">Introduction</h2>
        <p>{host.introduction}</p>
      </div>
    </div>
  );
};

export default AboutTheHostCard;
