import { BookmarkType } from "core/repository/user/types";
import { Heart } from "../../icons";
import { HeartIcon } from "@heroicons/react/solid";
import useAddBookmark from "core/services/user/bookmarks/useAddBookmark";
import useDeleteBookmark from "core/services/user/bookmarks/useDeleteBookmark";
import { useState } from "react";
type HeadstarttLikeButtonProps = {
  id?: string;
  type?: BookmarkType;
  isBookmark?: boolean;
  className?: string;
};
const HeadstarttLikeButton = ({
  id,
  type,
  className = "bg-white",
  isBookmark,
}: HeadstarttLikeButtonProps) => {
  const { add } = useAddBookmark();
  const { deleteBookmark } = useDeleteBookmark();
  const [isLiked, setIsLiked] = useState(isBookmark);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // stop bubbling up to parent
    event.stopPropagation();
    setIsLiked(!isLiked);

    if (isLiked && id) {
      deleteBookmark(id);
    }
    if (!isLiked && id && type) {
      add({
        entityId: id,
        entityType: type,
      });
    }
  };

  return (
    <button
      className={`p-2 br-8 ${className} cursor-pointer`}
      onClick={(event) => handleClick(event)}
    >
      {isLiked ? (
        <HeartIcon color="#EE7654" height={28} width={28} />
      ) : (
        <Heart color="#666" height={28} width={28} />
      )}
    </button>
  );
};

export default HeadstarttLikeButton;
