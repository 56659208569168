import { HeadstarttButton } from "views/components/headstarttButton";
import HeadstarttUploader from "views/components/headstarttUploader";
import { UploadFilesResponseRoot } from "core/repository/uploadFiles/types";

const UploadPhotosSection = ({
  onSuccess,
}: {
  onSuccess: (res: UploadFilesResponseRoot["data"]) => void;
}) => {
  return (
    <>
      <HeadstarttUploader
        onSuccess={onSuccess}
        listType="picture"
        accept="image/png, image/jpeg"
      >
        <div className="border-primary dashed br-16 p-4">
          <div className="flex items-center justify-center flex-col space-y-8">
            <HeadstarttButton size="small">
              Select photos from your computer
            </HeadstarttButton>
            <p className="fs-12 text-muted">or</p>
            <p className="fs-16">Drag and drop photos at any time</p>
          </div>
        </div>
      </HeadstarttUploader>
    </>
  );
};

export default UploadPhotosSection;
