import { Cross } from "views/components/icons";
import { HeadstarttModal } from "views/components/headstarttModal";
import SignUpView from "./components/signUpView";

type SignUpModalProps = {
  visible: boolean;
  onCancel: () => void;
  onHandleSignInClick: () => void;
};

const SignUpModal = ({
  visible,
  onCancel,
  onHandleSignInClick,
}: SignUpModalProps) => {
  return (
    <HeadstarttModal
      footer={null}
      maskStyle={{
        backgroundColor: "rgba(256, 256, 256, 0.95)",
      }}
      className="headstartt-antd-modal"
      width={672}
      open={visible}
      onCancel={onCancel}
      closable={false}
    >
      <div className="border br-8 p-4 bg-white drop-shadow-2xl px-6 py-4">
        <div className="flex items-center justify-between mb-8">
          <p className="fs-24 mb-0 font-medium">Create an account</p>
          <div
            className="bg-gray-100 p-2 br-4 cursor-pointer"
            onClick={onCancel}
          >
            <Cross height={24} />
          </div>
        </div>

        <SignUpView onCancel={onCancel} />

        <p className="fs-16 my-4">
          Already have account?{" "}
          <span
            className="font-medium text-primary cursor-pointer"
            onClick={onHandleSignInClick}
          >
            Sign In
          </span>
        </p>
      </div>
    </HeadstarttModal>
  );
};

export default SignUpModal;
