import { useCallback, useContext } from "react";
import { Cross, Search } from "views/components/icons";

import { AppContext } from "context/appContext";
import { SuggestionType } from "core/repository/search/types";
import useGetSearchSuggestions from "core/services/search/useGetSearchSuggestions";
import useRecentlyViewed from "core/services/search/useRecentlyViewed";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import HeadstarttSearch from "views/components/headstarttSearch";
import ListSearchItem from "views/components/headstarttSearch/listSearchItem";
import Loader from "views/components/loader";
import RecentlyViewed from "./recentlyViewed";
import SuggestionList from "./suggestionList";

type SearchBodyProps = {
  onCancel: () => void;
};

const SearchBody = ({ onCancel }: SearchBodyProps) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AppContext);
  const {
    data: suggestions,
    isLoading,
    setParams,
    params,
  } = useGetSearchSuggestions();
  const { get, post, remove } = useRecentlyViewed(isAuthenticated);

  const debouncedSearch = debounce((value) => {
    if (value.length > 2) {
      setParams({
        ...params,
        search: value,
      });
    }
  }, 500);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    debouncedSearch(value);
  };

  const redirectUserToSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && params.search.length > 2) {
      onCancel();
      navigate({
        pathname: "/explore/all",
        search: `search=${params.search}`,
      });
    }
  };

  const handleClick = useCallback(
    (type: SuggestionType, id: string) => {
      if (type === "DESTINATION") {
        navigate({
          pathname: "/explore/destinations",
          search: `search=${params.search}`,
        });
      } else if (type === "ACTIVITY") {
        navigate({
          pathname: `/discover-activities/${id}`,
        });
      } else if (type === "EVENT") {
        navigate({
          pathname: `/discover-events/${id}`,
        });
      }
      if (isAuthenticated) {
        post.create({
          entityId: id,
          entityType: type,
        });
      }
      onCancel();
    },
    [isAuthenticated, onCancel, navigate, params.search, post]
  );

  return (
    <div className="border br-8 p-4 bg-white drop-shadow-2xl">
      <div className="flex items-center justify-between mb-8">
        <p className="fs-24 mb-0">Search</p>
        <div className="bg-gray-100 p-2 br-4 cursor-pointer" onClick={onCancel}>
          <Cross height={24} />
        </div>
      </div>
      <HeadstarttSearch
        value={params.search}
        redirectUserToSearch={redirectUserToSearch}
        handleSearchChange={handleSearchChange}
      />
      <div className="border-b border-gray-200 my-8" />

      {isLoading ? (
        <div className="flex justify-center mx-auto">
          <Loader />
        </div>
      ) : suggestions?.data.total ? (
        <>
          <SuggestionList
            handleSuggestionClick={handleClick}
            suggestions={suggestions.data}
          />
          <div
            onClick={() =>
              navigate({
                pathname: "/explore/all",
                search: `search=${params.search}`,
              })
            }
          >
            <ListSearchItem
              icon={<Search height={16} />}
              text={
                <p className="fs-16 font-medium text-primary mt-4">
                  See all results for {params.search}
                </p>
              }
            />
          </div>
        </>
      ) : (
        "No results found"
      )}

      {!suggestions?.data.total && get.data?.data.list && isAuthenticated && (
        <RecentlyViewed
          recentlyViewedList={get.data?.data.list}
          handleClick={handleClick}
          clearRecentlyViewed={remove.remove}
          removeLoader={remove.isLoading}
          recentlyViewLoader={get.isLoading}
        />
      )}
    </div>
  );
};

export default SearchBody;
