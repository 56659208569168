import ProjectCard from "views/components/headstarttCard/projectCard";
import { ProjectsList } from "core/repository/search/types";

const ProjectList = ({
  projectList,
}: {
  projectList: ProjectsList["list"];
}) => (
  <div className="grid md:grid-cols-4 grid-cols-1 gap-8">
    {projectList.map((project) => (
      <a
        key={project._id}
        href={project._id}
        rel="noopener noreferrer"
        target={"_blank"}
      >
        <ProjectCard project={project} />
      </a>
    ))}
  </div>
);

export default ProjectList;
