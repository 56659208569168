export enum BucketName {
  ActivityReviewImages = "activityReviewImages",
  EntityImages = "eventReviewImages",
  profilePicture = "profilePicture",
}

export type UploadFileBody = {
  bucketName: BucketName;
  mediaFiles: File;
};

export interface UploadFilesResponseRoot {
  data: Data;
  msg: string;
  statusCode: number;
}

export interface Data {
  mediaFiles: string[];
}
