import HeadstarttSearch from "views/components/headstarttSearch";

const SearchReviewInput = () => {
  return (
    <HeadstarttSearch
      placeholder="Search reviews..."
      style={{
        borderRadius: "9999px",
        fontSize: "20px",
        padding: "8px 24px",
        border: "1px solid #006653",
        color: "#006653",
      }}
    />
  );
};

export default SearchReviewInput;
