import {
  addDestinationToRepo,
  updateDestinationToRepo,
} from "core/repository/destination";
import { useCallback, useEffect, useState } from "react";

import ActivitiesAndEventsForDestination from "./activitiesAndEventsForDestination";
import AdminImageUploader from "admin/components/adminImageUploader";
import { Destination } from "core/repository/destination/types";
import ErrorCard from "views/components/errorCard";
import FormFooter from "views/components/headstarttForm/formFooter";
import FormGroup from "views/components/headstarttForm/formGroup";
import HeadstarttContainer from "views/components/headstarttContainer";
import { HeadstarttForm } from "views/components/headstarttForm";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import PageHeader from "admin/components/pageHeader";
import useAddUpdateGeneralized from "core/hooks/useAddUpdateGeneralized";
import useGetDestinationById from "core/services/destination/useGetDestinationById";
import { useNavigate } from "react-router-dom";

const AdminDestinationForm = () => {
  const [form] = HeadstarttForm.useForm();
  const { destination, isLoading, isError, id } = useGetDestinationById();
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const navigate = useNavigate();
  const { addUpdate, mutationLoading } = useAddUpdateGeneralized<Destination>(
    addDestinationToRepo,
    updateDestinationToRepo,
    id
  );

  useEffect(() => {
    if (destination?.data) {
      form.setFieldsValue({
        ...destination,
        projectImage: destination?.data?.destinationImages || [],
      });
      setUploadedImages(destination?.data?.destinationImages || []);
    }
  }, [destination, form, id]);

  const handleFinish = useCallback(
    async (values: Destination) => {
      if (id) {
        values.destinationId = id;
      }
      await addUpdate({
        ...values,
        destinationImages: uploadedImages,
      });
      navigate("/admin/destination/destination-list");
    },
    [addUpdate, id, uploadedImages, navigate]
  );

  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;

  const data = destination?.data;

  return (
    <div>
      <PageHeader heading={id ? "Edit Destination" : "Create Destination"} />
      <HeadstarttContainer>
        <div className="my-8">
          <div className="bg-white p-8 br-8">
            <h1 className="fs-18 font-light  border-b mb-4">
              Name and Description
            </h1>
            <HeadstarttForm
              form={form}
              onFinish={handleFinish}
              className="w-full space-y-8"
              initialValues={{
                destinationName: data?.destinationName,
                destinationDescription: data?.destinationDescription,
                province: data?.province,
              }}
            >
              <FormFooter
                flexClass="flex items-center justify-end gap-2"
                showBackButton={false}
                showNextButton={false}
                nextButtonDisabled={mutationLoading}
                onCancelButtonClick={() => form.resetFields()}
              />
              <FormGroup
                label={"Destination"}
                name={"destinationName"}
                required
                description="Enter name of the destination"
              />
              <FormGroup
                label={"Description"}
                name={"destinationDescription"}
                inputType={"textarea"}
                required
                description="Enter description of the destination"
              />
              <FormGroup
                label={"Province"}
                name={"province"}
                required
                description="Enter province of the destination"
              />
              <AdminImageUploader
                label={"Upload Images"}
                name={"destinationImages"}
                description="Upload destination images"
                uploadedImages={uploadedImages}
                form={form}
                multiple={false}
                setUploadedImages={setUploadedImages}
              />
            </HeadstarttForm>

            <ActivitiesAndEventsForDestination id={id} />
          </div>
        </div>
      </HeadstarttContainer>
    </div>
  );
};

export default AdminDestinationForm;
