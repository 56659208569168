import { useState } from "react";
import { HeadstarttModal } from "../headstarttModal";
import { Cross } from "../icons";
import ActivityTypesFilter from "./activityTypesFilter";
import AdditionalStatusFilter from "./additionalStatusFilter";
import AgesFilter from "./agesFilter";
import CategoryFilter from "./categoryFilter";
import DifficultyFilter from "./difficultyFilter";
import DurationFilter from "./durationFilter";
import FacilitiesFilter from "./facilitiesFilters";
import GroupsFilter from "./groupsFilter";
import HasImageFilter from "./hasImageFilter";
import LocationFilter from "./locationFilter";
import MultidayFilter from "./multidayFilter";
import PriceFilter from "./priceFilter";
import RatingsFilter from "./ratingsFilter";
import ResetFilter from "./resetFilter";
import ShowMoreFiltersCTA from "./showMoreFiltersCTA";
import StatusFilter from "./statusFilter";
import SubActivitiesFilter from "./subActivitiesFilter";

<div className="border my-4" />;
const Divider = () => <div className="border my-4" />;

const Filters = () => {
  const [showModal, setShowModal] = useState(false);
  const onCancel = () => setShowModal(false);
  return (
    <div>
      <LocationFilter />
      <DifficultyFilter />
      <DurationFilter />
      <PriceFilter />
      <CategoryFilter />
      <RatingsFilter />
      <MultidayFilter />
      <HasImageFilter />
      <HeadstarttModal
        closable={false}
        open={showModal}
        onCancel={onCancel}
        footer={null}
        maskStyle={{
          backgroundColor: "rgba(256, 256, 256, 0.95)",
        }}
        className="headstartt-antd-modal"
        width={600}
        destroyOnClose={true}
      >
        <div className="border br-8 p-4 bg-white drop-shadow-2xl">
          <div className="flex items-center justify-between mb-8">
            <p className="fs-24 mb-0">More Filters</p>
            <div
              className="bg-gray-100 p-2 br-4 cursor-pointer"
              onClick={onCancel}
            >
              <Cross height={24} />
            </div>
          </div>
          <Divider />
          <AgesFilter />
          <GroupsFilter />
          <ActivityTypesFilter />
          <StatusFilter />
          <AdditionalStatusFilter />
          <SubActivitiesFilter />
          <FacilitiesFilter />
        </div>
      </HeadstarttModal>
      <ShowMoreFiltersCTA onClick={() => setShowModal(true)} />
      <div className="flex items-center justify-center my-4">
        <ResetFilter />
      </div>
    </div>
  );
};

export default Filters;
