import { ChevronLeft, Cross } from "views/components/icons";

import { HeadstarttButton } from "views/components/headstarttButton";
import { Link } from "react-router-dom";
import { SignInModalProps } from "./signInModal";
import SignInWithEmail from "./signInWithEmail";

type SignInWithEmailModalContentProps = {
  onEmailModalCancel: () => void;
  onCancel: SignInModalProps["onCancel"];
  onSignUpClick?: SignInModalProps["onHandleSignUpClick"];
};

const SignInWithEmailModalContent = ({
  onEmailModalCancel,
  onCancel,
  onSignUpClick,
}: SignInWithEmailModalContentProps) => {
  return (
    <>
      <div className="flex items-center justify-between mb-8 space-y-4">
        <div
          onClick={onEmailModalCancel}
          className="fs-14 mb-0 font-medium flex gap-1 items-center cursor-pointer"
        >
          <ChevronLeft height={20} /> Go back
        </div>
        <div className="bg-gray-100 p-2 br-4 cursor-pointer" onClick={onCancel}>
          <Cross height={24} />
        </div>
      </div>
      <SignInWithEmail onCancel={onCancel} />
      <Link to="/forget-password" className="fs-16 font-medium text-primary">
        Forget Password?
      </Link>
      <div className="mt-12">
        <p className="fs-16 font-medium">Don&apos;t have an account?</p>
        <HeadstarttButton
          onClick={onSignUpClick}
          htmlType="submit"
          className="w-full hover: bg-green-800"
        >
          Sign up
        </HeadstarttButton>
      </div>
    </>
  );
};

export default SignInWithEmailModalContent;
