import { useLocation, useNavigate } from "react-router-dom";

import { BookingListRoot } from "core/repository/bookings/types";
import { getBookingListFromRepo } from "core/repository/bookings";
import { parse } from "query-string";
import { useEffect } from "react";
import { useQuery } from "react-query";

const LIMIT = 12;
const useGetBookingList = () => {
  const navigate = useNavigate();
  const locations = useLocation();
  const queryParams = parse(locations.search);
  const pageFromUrl = queryParams.page ?? "1";
  const offset = Number(pageFromUrl);

  const { data, isLoading, isError } = useQuery<BookingListRoot, Error>(
    ["bookingList", offset],
    async () => {
      const { data: responseData } = await getBookingListFromRepo({
        limit: LIMIT,
        offset: offset - 1,
      });
      return responseData;
    }
  );

  useEffect(() => {
    if (!queryParams.page) {
      locations.search = `?page=${pageFromUrl}`;
      navigate(locations, { replace: true });
    }
  }, [locations, navigate, pageFromUrl, queryParams.page]);

  return {
    data,
    isLoading,
    isError,
    offset,
  };
};
export default useGetBookingList;
