import "./App.less";

import { AppContextProvider } from "context/appContext";
import AppRoutes from "views/routes";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "views/routes/hoc/scrollToTop";
import { config } from "core/clients/firebase/config";
import { initializeApp } from "core/clients/firebase";

initializeApp(config.firebaseConfig);

function App() {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <ScrollToTop />
        <AppRoutes />
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;
