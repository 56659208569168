import { getAuth, onAuthStateChanged } from "core/clients/firebase";
import { useEffect, useState } from "react";

import AppNavbarHoc from "views/layouts/hoc/appNavbarHoc";
import HeadstarttFooter from "views/components/headstarttFooter";
import { IPrivateRoute } from "../types";
import NamedRoute from "./namedRoute";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({
  children,
  title,
  description,
  mode,
  withoutFooter,
  withoutHeader,
}: IPrivateRoute) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const AuthCheck = onAuthStateChanged(auth, (user) => {
    if (user) setLoading(false);
    else navigate("/login");
  });
  useEffect(() => {
    AuthCheck();
    return () => AuthCheck();
  }, [auth, AuthCheck]);
  if (loading) return <>Loading</>;
  return (
    <NamedRoute title={title} description={description}>
      {!withoutHeader && <AppNavbarHoc mode={mode} />}
      {children}
      {!withoutFooter && <HeadstarttFooter />}
    </NamedRoute>
  );
};

export default PrivateRoute;
