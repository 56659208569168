import {
  errorToast,
  successToast,
} from "views/components/headstarttNotifications";

import { UserBlockPayload } from "core/repository/user/types";
import { patchUserByAdminToRepo } from "core/repository/user/patchUserToRepo";
import { useMutation } from "react-query";

const useBlockUser = (onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation(
    (payload: UserBlockPayload) => patchUserByAdminToRepo(payload),
    {
      onSuccess: (res) => {
        successToast(res.data.msg);
        onSuccess();
      },
      onError: () => {
        errorToast("Something went wrong, please try again");
      },
    }
  );

  return {
    blockUser: mutate,
    loader: isLoading,
  };
};

export default useBlockUser;
