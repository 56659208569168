import { HeadstarttButton } from "../headstarttButton";

type FilterHeaderProps = {
  heading: string;
  handleClear: () => void;
};
const FilterHeader = ({ heading = "", handleClear }: FilterHeaderProps) => {
  return (
    <div className="flex justify-between items-center">
      <p className="fs-14 font-medium mb-0">{heading}</p>
      <HeadstarttButton
        style={{
          padding: "0px",
        }}
        type="text"
        onClick={handleClear}
      >
        <span className="fs-12 font-medium text-muted">Clear</span>
      </HeadstarttButton>
    </div>
  );
};

export default FilterHeader;
