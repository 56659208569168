import { ADMIN, USER } from "./endpoint";
import { UpdatePayload, UserBlockPayload } from "./types";

import { apiRequest } from "core/services/apiServices";

export const patchUserToRepo = (payload: UpdatePayload) =>
  apiRequest.patch(USER.user, payload);

export const patchUserByAdminToRepo = (payload: UserBlockPayload) =>
  apiRequest.patch(ADMIN.patchUser, payload);
