import BlockUserButton from "./blockUserButton";
import { Divider } from "antd";
import { UserListData } from "core/repository/user/types";
import { dayjsClient } from "core/clients/dayjs";
type UserTableRowProps = {
  list: UserListData["list"];
  onBlock: (userId: string, isBlock: boolean) => void;
  loader: boolean;
  selected: string;
};
const UserTableRow = ({
  list,
  onBlock,
  loader,
  selected,
}: UserTableRowProps) => {
  return (
    <>
      {list.map((user) => (
        <div key={user._id}>
          <div className="grid grid-cols-12 gap-4 py-1">
            <div className="col-span-3">
              <div className="flex gap-2 items-center">
                {user.profilePicture ? (
                  <img
                    src={user.profilePicture}
                    alt={user.firstName}
                    className={`rounded-full pointer flex items-center justify-center h-10 w-10 cursor-pointer object-cover`}
                  />
                ) : (
                  <div
                    className={`fs-16 font-medium h-10 w-10 rounded-full flex items-center justify-center cursor-pointer bg-gray-200 text-gray-500`}
                  >
                    {user.firstName.charAt(0)} {user.lastName.charAt(0)}
                  </div>
                )}
                <span className="fs-16 font-medium">
                  {user.firstName} {user.lastName}
                </span>
              </div>
            </div>
            <div className="col-span-3">
              <span className="fs-16 font-medium">
                {dayjsClient(user.createdDate).format("MM-DD-YYYY")}
              </span>
            </div>
            <div className="col-span-3">
              {" "}
              <span className="fs-16 font-medium">{user.email}</span>
            </div>
            <div className="col-span-3">
              <BlockUserButton
                userId={user._id}
                isBlocked={user.isBlock}
                onBlock={() => onBlock(user._id, user.isBlock)}
                loader={loader && selected === user._id ? loader : false}
              />
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </>
  );
};

export default UserTableRow;
