import { HeadstarttSmallButton } from "views/components/headstarttButton";
import HeadstarttUploader from "views/components/headstarttUploader";
import { UploadFilesResponseRoot } from "core/repository/uploadFiles/types";
import { UploadIcon } from "@heroicons/react/outline";
interface UploadButtonProps {
  buttonText: string;
  onSuccess: (res: UploadFilesResponseRoot["data"]) => void;
  multiple?: boolean;
  accept?: string;
}
const UploadButton = ({
  buttonText,
  onSuccess,
  multiple,
  accept,
}: UploadButtonProps) => (
  <HeadstarttUploader
    buttonView="simple"
    onSuccess={onSuccess}
    listType="picture"
    showUploadList={false}
    multiple={multiple}
    accept={accept}
  >
    <HeadstarttSmallButton
      type="primary"
      newstyle={{
        padding: "0.5rem 1rem",
      }}
    >
      <div className="flex gap-2">
        <UploadIcon height={24} />
        <span className="fs-16 font-medium">{buttonText}</span>
      </div>
    </HeadstarttSmallButton>
  </HeadstarttUploader>
);

export default UploadButton;
