import { getAdmin, getToken } from "core/services/authService";
import { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";
import Sidebar from "admin/components/sidebar";

const PageLayout = () => {
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  useEffect(() => {
    const admin = getAdmin();
    const token = getToken();
    if (admin && token) {
      setIsAdminLoggedIn(true);
    }
  }, []);

  return (
    <div className="flex flex-row gap-4">
      {!!isAdminLoggedIn && (
        <div className="w-[280px] bg-[#333333] p-6 fixed l-0 t-0 b-0 border-r-2 h-full">
          <Sidebar />
        </div>
      )}
      <div
        className={`
          ${
            isAdminLoggedIn ? "w-[calc(100%_-_280px)] ml-[280px]" : "w-full"
          }  bg-gray-100 min-h-screen h-full
          `}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default PageLayout;
