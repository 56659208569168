import { getAuth, onAuthStateChanged } from "core/clients/firebase";
import { createContext, useEffect, useState } from "react";

type AppContextValue = {
  isAuthenticated: boolean;
  loading: boolean;
};

interface AppContextProps {
  children: React.ReactNode;
}
export const AppContext = createContext<AppContextValue>({
  isAuthenticated: false,
  loading: true,
});

export const AppContextProvider = ({ children }: AppContextProps) => {
  const auth = getAuth();
  const [loading, setLoading] = useState(true);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth]);

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        loading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
