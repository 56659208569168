import { ActivityData, RootObject } from "../types";

import { ACTIVITY_ENDPOINTS } from "./../endpoints";
import { apiRequest } from "core/services/apiServices";

export const getActivityDetailFromRepo = (id: string) => {
  return apiRequest.getByQueryParam<RootObject<ActivityData>>(
    ACTIVITY_ENDPOINTS.getDetail,
    {
      activityId: id,
    }
  );
};
