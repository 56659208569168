import { FormItem } from ".";
import { HeadstarttSmallButton } from "../headstarttButton";

type FormFooterProps = {
  onBackButtonClick?: () => void;
  onNextButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  onSaveButtonClick?: () => void;
  saveButtonDisabled?: boolean;
  backButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
  cancelButtonDisabled?: boolean;
  flexClass?: string;
  showBackButton?: boolean;
  showNextButton?: boolean;
  showCancelButton?: boolean;
  showSaveButton?: boolean;
};

const buttonStyle = {
  padding: "4px 20px",
  height: "fit-content",
};

const FormFooter = ({
  onBackButtonClick,
  onNextButtonClick,
  onCancelButtonClick,
  onSaveButtonClick,
  backButtonDisabled = false,
  nextButtonDisabled = false,
  cancelButtonDisabled = false,
  saveButtonDisabled = false,
  showBackButton = true,
  showNextButton = true,
  showCancelButton = true,
  showSaveButton = true,
  flexClass = "flex items-center justify-end gap-2",
}: FormFooterProps) => {
  return (
    <FormItem>
      <div className={flexClass ?? "flex items-center justify-between"}>
        {showBackButton && (
          <HeadstarttSmallButton
            onClick={onBackButtonClick}
            disabled={backButtonDisabled}
            newstyle={buttonStyle}
          >
            Back
          </HeadstarttSmallButton>
        )}
        {showNextButton && (
          <HeadstarttSmallButton
            onClick={onNextButtonClick}
            disabled={nextButtonDisabled}
            newstyle={buttonStyle}
            type="primary"
            htmlType="submit"
          >
            Next
          </HeadstarttSmallButton>
        )}
        {showSaveButton && (
          <HeadstarttSmallButton
            onClick={onSaveButtonClick}
            disabled={saveButtonDisabled}
            newstyle={buttonStyle}
            type="primary"
            htmlType="submit"
          >
            Save
          </HeadstarttSmallButton>
        )}
        {showCancelButton && (
          <HeadstarttSmallButton
            onClick={onCancelButtonClick}
            disabled={cancelButtonDisabled}
            newstyle={buttonStyle}
          >
            Cancel
          </HeadstarttSmallButton>
        )}
      </div>
    </FormItem>
  );
};

export default FormFooter;
