import { Link, useParams } from "react-router-dom";

import ActivitySlider from "views/layouts/explore/exploreAll/activitySlider";
import ErrorCard from "views/components/errorCard";
import EventSlider from "views/layouts/explore/exploreAll/eventSlider";
import { ExploreHeading } from "views/components/typography/popularHeading";
import ExploreSidebar from "views/layouts/explore/exploreSidebar";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttSegmentMenu from "views/components/headstarttSegmentMenu";
import HostSlider from "views/layouts/explore/exploreAll/hostSlider";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import { MarkerProps } from "views/layouts/listWithMaps/types";
import SavedActivities from "./savedActivities";
import SavedHosts from "./savedHosts";
import useGetBookmarks from "core/services/user/bookmarks/useGetBookmarks";

const SavedItems = () => {
  const { path } = useParams();
  const { bookmarks, error, loader } = useGetBookmarks();
  if (error) return <ErrorCard />;
  const eventList = bookmarks?.activityBookmarksList.list || [];
  const activityList = bookmarks?.eventBookmarksList.list || [];

  const cumulativeList = [...eventList, ...activityList];
  const markers: MarkerProps[] =
    cumulativeList.map((item) => ({
      position: [item.locationDetail.latitude, item.locationDetail.longitude],
      label: item.title,
    })) || [];
  if (loader) return <LoaderFullScreen />;
  if (!eventList.length && !activityList.length)
    return (
      <div className="h-screen text-center flex items-center justify-center fs-18">
        Nothing to show. Go ahead and wishlist some &nbsp;
        <Link to="/explore/events?page=1">events</Link>
        &nbsp; or &nbsp;
        <Link to="/explore/activities?page=1">activities</Link>.
      </div>
    );
  return (
    <>
      <div className="pt-24 pb-8 fixed w-full bg-white z-30">
        <HeadstarttContainer>
          <ExploreHeading headingPartOne={"search"} />
          <HeadstarttSegmentMenu
            selected={`/saved/${path}`}
            segmentMenu={[
              {
                title: "All",
                path: "/saved/all",
              },
              {
                title: "Activities",
                path: "/saved/activities",
              },
              {
                title: "Events",
                path: "/saved/events",
              },
              {
                title: "Hosts",
                path: "/saved/hosts",
              },
            ]}
          />
        </HeadstarttContainer>
      </div>
      <div className="bg-rainee pt-64">
        <HeadstarttContainer>
          <div className="py-12">
            <div className="grid grid-cols-12 gap-12">
              <div className="col-span-3 w-72 fixed z-20 overflow-auto h-full">
                <div className="px-4 pb-24">
                  <ExploreSidebar markers={markers} />
                </div>
              </div>
              {path === "all" ? (
                <div className="col-span-9 w-full ml-72 space-y-8">
                  {bookmarks?.activityBookmarksList.total && (
                    <div className="bg-white br-16 p-4">
                      <h1 className="fs-26 lh-38 font-medium">
                        Self guided tours
                      </h1>

                      <ActivitySlider
                        navigateTo="/saved/activities"
                        items={bookmarks.activityBookmarksList.list}
                      />
                    </div>
                  )}
                  {!!bookmarks?.eventBookmarksList.total && (
                    <div className="bg-white br-16 p-4">
                      <h1 className="fs-26 lh-38 font-medium">Events</h1>
                      <EventSlider items={bookmarks.eventBookmarksList.list} />
                    </div>
                  )}
                  {!!bookmarks?.hostBookmarksList.total && (
                    <div className="bg-white br-16 p-4">
                      <h1 className="fs-26 lh-38 font-medium">Hosts</h1>
                      <HostSlider items={bookmarks.hostBookmarksList.list} />
                    </div>
                  )}
                </div>
              ) : path === "activities" ? (
                !!bookmarks?.activityBookmarksList.total && (
                  <div className="col-span-9 w-full ml-72 space-y-8">
                    <SavedActivities
                      activities={bookmarks.activityBookmarksList.list}
                    />
                  </div>
                )
              ) : path === "hosts" ? (
                !!bookmarks?.hostBookmarksList.total && (
                  <div className="col-span-9 w-full ml-72 space-y-8">
                    <SavedHosts hosts={bookmarks.hostBookmarksList.list} />
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </HeadstarttContainer>
      </div>
    </>
  );
};

export default SavedItems;
