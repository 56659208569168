import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useCallback, useRef, useState } from "react";

import { ActivityPayload } from "core/repository/activity/types";
import { CircleCross } from "../icons";
import { HeadstarttInput } from "../headstarttInput";
import { RawButton } from "../headstarttButton";
import { mapStyles } from "../googleMaps/mapStyles";

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: true,
  styles: mapStyles,
};
const LocationPicker = ({
  handleSelectedLocation,
  locationDetail,
}: {
  handleSelectedLocation: (location: ActivityPayload["locationDetail"]) => void;
  locationDetail: ActivityPayload["locationDetail"];
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: ["places"],
  });
  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);
  const onLoad = useCallback(
    (newAutocomplete: google.maps.places.Autocomplete) => {
      autocomplete.current = newAutocomplete;
    },
    []
  );

  const [places, setPlaces] = useState<
    {
      lat?: number;
      lng?: number;
      label?: string;
    }[]
  >(
    [
      {
        lat: locationDetail?.latitude || 0,
        lng: locationDetail?.longitude || 0,
        label: locationDetail?.locationDescription || "",
      },
    ] || []
  );

  const onPlaceChanged = useCallback(() => {
    if (autocomplete.current) {
      const placeMarker = {
        lat: autocomplete.current.getPlace().geometry?.location?.lat(),
        lng: autocomplete.current.getPlace().geometry?.location?.lng(),
        label: autocomplete.current.getPlace().name,
      };
      setPlaces([...places, placeMarker]);
      handleSelectedLocation({
        latitude: placeMarker.lat || 0,
        longitude: placeMarker.lng || 0,
        locationDescription: placeMarker.label || "",
      });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }, [places, handleSelectedLocation]);

  const handleRemovePlaces = useCallback(
    (index: number) => {
      const newPlaces = [...places];
      newPlaces.splice(index, 1);
      setPlaces(newPlaces);
    },
    [places]
  );

  if (!isLoaded) return null;
  return (
    <div className="h-128 max-h-full pb-24">
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <HeadstarttInput multiple={false} allowClear />
      </Autocomplete>
      <div className="my-4" />
      <GoogleMap
        mapContainerStyle={{
          height: "100%",
          width: "100%",
          borderRadius: "8px",
        }}
        zoom={15}
        options={options}
        center={{
          lat: places[places.length - 1]?.lat || 0,
          lng: places[places.length - 1]?.lng || 0,
        }}
      >
        {places.map((place, index) => (
          <Marker
            key={index}
            position={{
              lat: place.lat || 0,
              lng: place.lng || 0,
            }}
            label={place.label}
          />
        ))}
      </GoogleMap>
      {/* Remove Points */}
      <div className="my-4 br-8 border px-4">
        <div className="my-4">
          <h1 className="fs-20 font-bold">Selected Places</h1>
          {places
            .filter((place) => place.lat && place.lng)
            .map((place, index) => (
              <div
                key={place.label}
                className="flex items-center h-full justify-between"
              >
                <p className="font-bold">
                  {place.label || `${place.lat},${place.lng}`}
                </p>
                <RawButton
                  type="text"
                  onClick={() => handleRemovePlaces(index)}
                >
                  <CircleCross height={24} />
                </RawButton>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LocationPicker;
