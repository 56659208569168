import {
  FormItem,
  HeadstarttCheckboxChangeEvent,
  HeadstarttForm,
} from "views/components/headstarttForm";
import { Mail, User } from "views/components/icons";
import { useCallback, useRef } from "react";

import { HeadstarttButton } from "views/components/headstarttButton";
import { HeadstarttCheckbox } from "views/components/headstarttCheckbox";
import HeadstarttContainer from "views/components/headstarttContainer";
import { HeadstarttInput } from "views/components/headstarttInput";
import { HeadstarttTextArea } from "views/components/headstarttInput/textArea";
import InputLabel from "views/components/typography/inputLabel";
import usePostSupportForm from "core/services/support/usePostSupportForm";

const SERVICES = ["ACTIVITY", "EVENT", "HOST"];
type SupportForm = {
  email: string;
  firstName: string;
  lastName: string;
  services: string[];
  comment: string;
};
const Support = () => {
  const servicesRef = useRef<string[]>([]);
  const handleCheckboxChange = useCallback(
    (e: HeadstarttCheckboxChangeEvent, service: string) => {
      if (e.target.checked) {
        if (servicesRef.current.indexOf(service) === -1) {
          servicesRef.current.push(service);
        }
      } else {
        const index = servicesRef.current.indexOf(service);
        if (index !== -1) {
          servicesRef.current.splice(index, 1);
        }
      }
    },
    []
  );
  const { postSupport, isLoading } = usePostSupportForm();
  const onSubmit = (values: SupportForm) => {
    const payload = {
      ...values,
      services: servicesRef.current,
    };
    postSupport(payload);
  };
  return (
    <HeadstarttContainer>
      <div className="grid grid-cols-12 gap-24 py-24">
        <div className="md:col-start-4 md:col-span-7 col-span-12 col-start-1">
          <p className="fs-16 font-medium text-muted mb-0">Support</p>
          <p className="fs-24 font-medium mb-0">
            Got a question about using Discover Parks?
          </p>
          <p className="text-primary fs-24 font-medium">
            You can reach us anytime via{" "}
            <a href="mailto: info@discoverparks.ca">info@discoverparks.ca</a>
          </p>
          <HeadstarttForm onFinish={onSubmit} className="space-y-2">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                <InputLabel>Name</InputLabel>
              </div>
              <div className="col-span-6">
                <FormItem
                  name={"firstName"}
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <HeadstarttInput
                    placeholder="John"
                    prefix={<User color="#696969" height={24} />}
                    type={"text"}
                  />
                </FormItem>
              </div>
              <div className="col-span-6">
                <FormItem
                  name={"lastName"}
                  rules={[
                    { required: true, message: "Please input your last name!" },
                  ]}
                >
                  <HeadstarttInput
                    placeholder="Doe"
                    prefix={<User color="#696969" height={24} />}
                    type={"text"}
                  />
                </FormItem>
              </div>
            </div>
            <InputLabel>Email</InputLabel>
            <div className="col-span-12">
              <FormItem
                name={"email"}
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <HeadstarttInput
                  placeholder="user@email.com"
                  prefix={<Mail color="#696969" height={24} />}
                  type={"email"}
                />
              </FormItem>
            </div>
            <InputLabel>How can we help?</InputLabel>
            <div className="col-span-12">
              <FormItem
                name={"comment"}
                rules={[
                  { required: true, message: "Please input your issue!" },
                ]}
              >
                <HeadstarttTextArea placeholder="Type here" rows={4} />
              </FormItem>
            </div>
            <InputLabel>Services</InputLabel>
            <div className="col-span-12">
              <FormItem name={"services"}>
                <div className="grid grid-cols-12 gap-2">
                  {SERVICES.map((service) => (
                    <div className="col-span-6" key={service}>
                      <HeadstarttCheckbox
                        onChange={(e) => handleCheckboxChange(e, service)}
                      >
                        <p className="fs-20 mb-0 text-muted font-medium">
                          {service}
                        </p>
                      </HeadstarttCheckbox>
                    </div>
                  ))}
                </div>
              </FormItem>
            </div>

            <FormItem>
              <HeadstarttButton
                loading={isLoading}
                disabled={isLoading}
                htmlType="submit"
                type="ghost"
                className="w-full hover: bg-green-800"
              >
                Send
              </HeadstarttButton>
            </FormItem>
          </HeadstarttForm>
        </div>
      </div>
    </HeadstarttContainer>
  );
};

export default Support;
