import { ActivityPayload, Facility } from "core/repository/activity/types";
import { useCallback, useEffect, useState } from "react";

import FormLabelWithDescription from "views/components/headstarttForm/formLabelWithDescription";
import { HeadstarttOption } from "views/components/headstarttSelect";
import { SelectSearch } from "views/components/headstarttSearch/selectSearch";
import { Spin } from "antd";
import { errorToast } from "views/components/headstarttNotifications";
import { getFacilitiesFromRepo } from "core/repository/activity";

type OptionType = {
  key: string;
  value: string;
};
const FacilitySelection = ({
  values,
  setValues,
  dataFacility,
}: {
  values: ActivityPayload["facility"];
  setValues: (v: string[]) => void;
  dataFacility: Facility[];
}) => {
  const [selectedFacilities, setSelectedFacilities] = useState<OptionType[]>(
    values?.map((it) => ({
      key: it,
      value: it,
    }))
  );
  const [facilities, setFacilities] = useState<Facility[]>(dataFacility || []);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSearch = useCallback((value: string) => {
    setLoading(true);
    setFacilities([]);
    getFacilitiesFromRepo({
      limit: 25,
      offset: 0,
      search: value,
    })
      .then((res) => {
        setFacilities(res.data.data.list);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err.message);
      });
  }, []);

  useEffect(() => {
    if (values) {
      setSelectedFacilities(
        values.map((it) => ({
          key: it,
          value: it,
        }))
      );
    }
  }, [values]);
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-4">
        <FormLabelWithDescription
          label={"Select facilities"}
          description={"Select available facilities"}
        />
      </div>
      <div className="col-span-6">
        <SelectSearch
          className="activity-select"
          style={{
            width: "100%",
          }}
          showSearch
          clearIcon
          onClear={() => {
            setSelectedFacilities([]);
            setValues([]);
          }}
          labelInValue
          mode="multiple"
          loading={loading}
          onChange={(v) => {
            setSelectedFacilities(v);
            setValues(v.map((it) => it.value));
          }}
          onSearch={handleSearch}
          defaultValue={selectedFacilities}
          notFoundContent={loading ? <Spin size="small" /> : null}
          optionLabelProp="facilityTitle"
        >
          {facilities.map((it) => (
            <HeadstarttOption key={it._id} value={it._id}>
              <div className="flex items-center">
                <div className="w-10 h-10 bg-gray-300 rounded-full">
                  <img src={it.facilityImage} alt={it.facilityTitle} />
                </div>
                <div className="ml-4">
                  <div className="font-bold">{it.facilityTitle}</div>
                </div>
              </div>
            </HeadstarttOption>
          ))}
        </SelectSearch>

        <div className="my-4">
          <div className="font-bold">Selected Facilities</div>
          <div className="flex flex-col space-y-4">
            {selectedFacilities?.map((it) => {
              const facility = facilities.find((a) => a._id === it.value);
              return (
                <div key={it.key} className="flex gap-2 items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full mr-2">
                    <img
                      src={facility?.facilityImage}
                      alt={facility?.facilityTitle}
                    />
                  </div>
                  <div>
                    <div className="font-bold">{facility?.facilityTitle}</div>

                    <div className="text-xs text-gray-500">
                      {facility?.facilityDescription}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilitySelection;
