import { HeadstarttProgress } from "../headstarttProgress";
import { TotalReviewsGroup } from "core/repository/event/types";

const GroupByRatings = ({
  groupReviews,
}: {
  groupReviews?: TotalReviewsGroup;
}) => {
  return (
    <div className="flex grid-cols-12 gap-4">
      <div>
        <p className="fs-16 w-20 font-medium mb-0">Excellent</p>
      </div>
      <HeadstarttProgress
        showInfo={false}
        strokeColor={"#00BFA5"}
        percent={100}
      />
      <div>
        <p className="fs-12 font-medium mb-0 col-span-2 text-gray-400">
          {groupReviews?.fiveStar}
        </p>
      </div>
    </div>
  );
};

export default GroupByRatings;
