import { useCallback, useEffect, useState } from "react";

import { HostRoot } from "core/repository/host/types";
import { getHostFromRepo } from "core/repository/host/getHostFromRepo";
import { useParams } from "react-router-dom";

const useGetHostById = () => {
  const params = useParams();
  const hostId = params.id;
  const [host, setHost] = useState<HostRoot["data"] | undefined>(undefined);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  const fetchData = useCallback(async () => {
    if (hostId) {
      setLoader(true);
      try {
        const { data } = await getHostFromRepo({
          hostId,
        });
        const hostData = data.data;
        setHost(hostData);
      } catch (err) {
        setError(true);
      }
      setLoader(false);
    }
  }, [hostId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { host, fetchData, loader, error, hostId };
};

export default useGetHostById;
