import Slider, { Settings } from "react-slick";

type CardSliderProps = {
  children: React.ReactNode;
};

const CardSlider = (props: CardSliderProps) => {
  const settings: Settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: true,
    draggable: true,
    accessibility: true,
  };
  return <Slider {...settings}>{props.children}</Slider>;
};
export default CardSlider;
