import { useNavigate, useParams } from "react-router-dom";

import { HeadstarttButton } from "views/components/headstarttButton";
import { PencilIcon } from "views/components/icons";

const WriteReviewButton = ({ type }: { type: "activity" | "event" }) => {
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  return (
    <HeadstarttButton
      onClick={() => navigate(`/write-review/${id}?type=${type}`)}
    >
      <div className="flex gap-2">
        <PencilIcon />
        <p className="mb-0">Write a review</p>
      </div>
    </HeadstarttButton>
  );
};

export default WriteReviewButton;
