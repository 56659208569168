import { useLocation, useNavigate } from "react-router-dom";

import { Document } from "views/components/icons";
import ErrorCard from "views/components/errorCard";
import HeadstarttPagination from "views/components/headstarttPagination";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import { ProjectCardHorizontal } from "views/components/headstarttCard/projectCard";
import SecondaryHeading from "views/components/typography/secondaryHeading";
import { useCallback } from "react";
import useGetProject from "core/services/project/useGetProjects";

const ExploreProjects = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, isLoading, isError, offset } = useGetProject();
  const handleChangePage = useCallback(
    (page: number) => {
      navigate(`${location.pathname}?page=${page}`);
    },
    [location.pathname, navigate]
  );
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;

  return (
    <>
      <div className="p-8 bg-white br-16">
        <div className="flex gap-2 items-center mb-8">
          <div className="bg-gray-100 rounded-full h-12 w-12 flex items-center justify-center">
            <Document height={24} />
          </div>
          <SecondaryHeading className="mb-0" heading={"Projects"} />
        </div>
        <div className="space-y-8">
          {data?.data.list.map((project) => (
            <div key={project._id}>
              <a href={project.redirectUrl} target="_blank" rel="noreferrer">
                <ProjectCardHorizontal project={project} />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center bg-white my-4 p-3 br-16">
        <HeadstarttPagination
          total={data?.data.total}
          current={offset}
          onChange={handleChangePage}
        />
      </div>
    </>
  );
};

export default ExploreProjects;
