import ErrorCard from "views/components/errorCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import HeadstarttPagination from "views/components/headstarttPagination";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import UserReviewCard from "./userReviewCard";
import useGetUserReviews from "core/services/user/useGetUserReviews";

const UserReviews = () => {
  const { reviews, loader, error, page, handlePagination } =
    useGetUserReviews();

  if (loader) return <LoaderFullScreen />;
  if (error) return <ErrorCard />;

  return (
    <div className="py-24">
      <HeadstarttContainer>
        <div className="grid grid-cols-12">
          <div className="col-span-8 col-start-3">
            <h2 className="fs-24 font-medium">My reviews</h2>
            <div className="divide-y-2">
              {reviews?.list.map((review) => (
                <div key={review._id} className="py-8">
                  <UserReviewCard userReview={review} />
                </div>
              ))}
            </div>
            <div className="flex justify-center bg-white my-4 p-3 br-16">
              <HeadstarttPagination
                total={reviews?.total}
                current={page}
                onChange={handlePagination}
              />
            </div>
          </div>
        </div>
      </HeadstarttContainer>
    </div>
  );
};

export default UserReviews;
