import {
  AgeIcon,
  DurationIcon,
  GroupSize,
  PriceIcon,
} from "views/components/icons";

import AboutTheHostCard from "views/components/aboutTheHostCard";
import CancellationPolicy from "./cancellationPolicy";
import { CurrencyDollarIcon } from "@heroicons/react/outline";
import DetailsCalendar from "./detailsCalendar";
import DetailsCoverSection from "views/components/detailsCoverSection";
import DetailsReviews from "./detailsReviews";
import ErrorCard from "views/components/errorCard";
import EventInformation from "./eventInformation";
import EventLocation from "./eventLocation";
import HeadStarttTabsGroup from "views/components/headstarttTabs/headstarttTabsGroup";
import HeadstarttContainer from "views/components/headstarttContainer";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import NoteFromHost from "./noteFromHost";
import OpenStatus from "./openStatus";
import ParkOperationDate from "./parkOperationDate";
import { Review } from "core/repository/reviews/types";
import { useCallback } from "react";
import useGetEventById from "core/services/event/useGetEventById";

const EVENT_TABS = [
  "Operation Date",
  "Location",
  "Age",
  "Group size",
  "Price",
  "Duration",
  "Note from host",
  "Cancellation policy",
  "About host",
  "Reviews",
];
const EventDetails = () => {
  const { event, isError, isLoading } = useGetEventById();

  const handleClick = useCallback((tab: string[]) => {
    // get element by id
    const element = document.getElementById(tab[0]);
    if (element) {
      // scroll to element
      const headerOffset = 200;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  if (!event) return <div>Not Found</div>;
  const { data } = event;
  return (
    <HeadstarttContainer>
      <div className="py-24">
        <DetailsCoverSection
          importantNotice={data.importantNotice}
          description={data.description}
          showCheckoutButton
          title={data.title}
          images={data.images}
          isBookmark={data.isBookmark}
          isSubscribed={data.isSubscribed}
          rating={data.averageRating}
          totalCount={data.totalReviews}
          checkfrontProductId={data.checkFrontProductId}
          id={data._id}
        />
        <div className="my-16">
          <div className="border-b" />
        </div>
        <HeadStarttTabsGroup handleTabClick={handleClick} tabs={EVENT_TABS} />
        <div className="my-16" id="Operation Date">
          <div className="grid grid-cols-12 gap-12">
            <div className="md:col-span-8 col-span-12">
              <h2 className="fs-32 font-medium">Operation Date</h2>
              <ParkOperationDate
                description={data.operationDate.operationDateDescription}
              />
            </div>
            <div className="md:col-span-4 col-span-12">
              <DetailsCalendar calendar={data.operationDate.availableDates} />
              <OpenStatus status={data.operationDate.openMonths} />
            </div>
          </div>
        </div>
        <div className="my-16" id="Location">
          <div className="grid grid-cols-12 gap-12">
            <div className="md:col-span-4 col-span-12">
              <EventLocation locationDetail={data.locationDetail} />
            </div>
            <div className="md:col-span-8 col-span-12">
              <div className="grid grid-cols-12 gap-8">
                <div className="md:col-span-6 col-span-12" id="Age">
                  <EventInformation
                    icon={AgeIcon}
                    heading="Age"
                    title={data.age}
                    description={data.ageDescription}
                  />
                </div>
                <div className="md:col-span-6 col-span-12" id="Group size">
                  <EventInformation
                    icon={GroupSize}
                    heading="Group Size"
                    title={data.groupSize}
                    description={data.groupDescription}
                  />
                </div>
                <div className="md:col-span-6 col-span-12" id="Price">
                  <EventInformation
                    icon={PriceIcon}
                    heading="Price"
                    title={
                      <div className="flex items-center gap-1">
                        <CurrencyDollarIcon height={24} />
                        <div>{data?.price} CAD</div>
                      </div>
                    }
                    description={data.priceDescription}
                  />
                </div>
                <div className="md:col-span-6 col-span-12" id="Duration">
                  <EventInformation
                    icon={DurationIcon}
                    heading="Duration"
                    title={data.eventDuration}
                    description={data.eventDurationDescription}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-16" id="Note from host">
          <NoteFromHost note={data.instructions} />
        </div>
        <div className="my-16" id="Cancellation policy">
          <CancellationPolicy cancellationPolicy={data.cancellationPolicy} />
        </div>
        <div className="my-16" id="About host">
          <h2 className="fs-32 font-medium">About Host</h2>
          <AboutTheHostCard host={data.hostDetail} />
        </div>
        <div className="my-16" id="Reviews">
          <h2 className="fs-32 font-medium">Reviews</h2>
          <DetailsReviews
            groupRating={data.totalReviewsGroup}
            avgRating={data.averageRating}
            totalReviews={data.totalReviews}
            reviews={data.reviewsList.list as unknown as Review[]}
          />
        </div>
      </div>
    </HeadstarttContainer>
  );
};

export default EventDetails;
