import CardImage from "./cardImage";
import CardOverlay from "./cardOverlay";
import { HeadstarttRating } from "../headstarttRating";
import { HostRoot } from "core/repository/host/types";
import styles from "./headstarttCard.module.scss";
type HostCardProps = {
  host: HostRoot["data"];
  className?: string;
};
const HostCard = ({ host, className }: HostCardProps) => {
  return (
    <div className={`br-16 relative ${className}`}>
      <CardImage
        className={`${styles["host-card"]}`}
        alt={`${host?.firstName}  ${host?.lastName}`}
        url={host.profilePicture}
      />
      <CardOverlay>
        <h2 className="fs-20 lh-28 mb-0">
          {host?.firstName} ${host.lastName}
        </h2>
        {/* <DefaultTag
          style={{
            border: "1px solid #006653",
            borderRadius: "99px",
          }}
        >
          <div className="flex items-center gap-1 font-bold"></div>
        </DefaultTag> */}
        <HeadstarttRating disabled value={host.hostAverageRating} />
        <p className="mb-0 text-gray-500">({host.hostTotalReviews})</p>
      </CardOverlay>
    </div>
  );
};

export default HostCard;
