import { ChevronLeft, ChevronRight } from "views/components/icons";

import { ReactNode } from "react";

type CarouselWrapperWithIndicatorsProps = {
  carousel: ReactNode;
  previous: () => void;
  next: () => void;
};
const CarouselWrapperWithIndicators = ({
  carousel,
  previous,
  next,
}: CarouselWrapperWithIndicatorsProps) => {
  return (
    <div className="relative">
      <div
        className={`flex justify-between absolute z-10 left-0 right-0 mx-2 my-auto items-center top-0 bottom-0`}
      >
        <div
          className="bg-white rounded-full p-1 cursor-pointer"
          onClick={previous}
        >
          <ChevronLeft height={24} color="#006653" />
        </div>
        <div
          className="bg-white rounded-full p-1 cursor-pointer"
          onClick={next}
        >
          <ChevronRight height={24} color="#006653" />
        </div>
      </div>
      {carousel}
    </div>
  );
};

export default CarouselWrapperWithIndicators;
