import { useCallback, useState } from "react";

import { SignInPayload } from "core/repository/user/types";
import { setToken } from "./../authService/index";
import { signInUserToRepo } from "core/repository/user/signInUserToRepo";

const useSignInUser = (
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  const [loader, setLoader] = useState(false);

  const signInUser = useCallback(
    async (userData: SignInPayload) => {
      setLoader(true);
      try {
        const { data } = await signInUserToRepo(userData);
        setToken(data.data.token);
        onSuccess?.();
      } catch (err) {
        onError?.(err as Error);
      }
      setLoader(false);
    },
    [onSuccess, onError]
  );

  return { signInUser, loader };
};

export default useSignInUser;
