import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
const { TextArea } = Input;

export const HeadstarttTextArea = (props: TextAreaProps) => (
  <TextArea
    size="large"
    style={{
      borderRadius: "16px",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      padding: "8px 24px",
      fontSize: "20px",
      fontWeight: "medium",
    }}
    {...props}
  />
);
