import { BanIcon } from "@heroicons/react/outline";
import { HeadstarttButton } from "views/components/headstarttButton";
const baseStyle = {
  borderRadius: "20px",
  padding: "4px 24px",
  fontSize: "16px",
  fontWeight: 500,
  color: "#fff",
  height: "fit-content",
};
const blockStyle = {
  ...baseStyle,
  backgroundColor: "#D72828",
  border: "1px solid #D72828",
};
const unBlockStyle = {
  ...baseStyle,
  backgroundColor: "#5F5F5F",
  border: "1px solid #5F5F5F",
};
type BlockUserButtonProps = {
  userId: string;
  isBlocked: boolean;
  onBlock: () => void;
  loader?: boolean;
};
const BlockUserButton = ({
  isBlocked,
  onBlock,
  loader,
}: BlockUserButtonProps) => {
  return (
    <HeadstarttButton
      onClick={onBlock}
      disabled={loader}
      style={isBlocked ? unBlockStyle : blockStyle}
    >
      {isBlocked ? (
        "Unblock"
      ) : (
        <div className="flex gap-1 items-center">
          <BanIcon height={24} /> Block
        </div>
      )}
    </HeadstarttButton>
  );
};

export default BlockUserButton;
